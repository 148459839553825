import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { GroupTd } from 'src/app/viewModels/group-td';
import { GroupTp } from 'src/app/viewModels/group-tp';
import { Section } from 'src/app/viewModels/section';
import { Sector } from 'src/app/viewModels/sector';

@Component({
  selector: 'app-group-tp-form',
  templateUrl: './group-tp-form.component.html',
  styleUrls: ['./group-tp-form.component.css']
})
export class GroupTpFormComponent implements OnInit {

 
  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<GroupTpFormComponent>,

    @Inject(MAT_DIALOG_DATA) public data:GroupTp,
    private apis: HiararchyService,
  ) { }

  sectors : Sector[];
  sections: Section[] = [];

  form: FormGroup = new FormGroup({
    'designation': new FormControl(Validators.required),
    'abbreviation': new FormControl(Validators.required),
    'section_id':  new FormControl(Validators.required),
    'sector_id' : new FormControl(Validators.required),
    'group_td_id' : new FormControl()
  })
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    
    this.apis.getHiararchySectors()
    .subscribe((data : Sector[])=>{
      this.sectors = data;
      // in case of update
      if(this.data.id){
        this.data.sector_id = this.data.section?.sector_id;
        this.changeSector();
        this.changeSection();
    }
    })

      //get sections
  }

  tds = []
  changeSector(){
    if(this.data.sector_id){
      this.apis.getSectorSections(this.data.sector_id)
      .subscribe((data : Section[])=>{
        this.sections = data;
        
      })
    }else{
      this.sections = [];
      this.tds = []
      this.data.section_id = undefined;
      this.data.group_td_id = undefined
    }
  }

  changeSection(){
    if(this.data.sector_id){
      this.apis.getSectionGroupTds(this.data.section_id)
      .subscribe((data : GroupTd[])=>{
        this.tds = data;
        
      })
    }else{
      this.tds = [];
      this.data.group_td_id = undefined;
    }
  }

}
