import { Cycle } from "./cycle";
import { Department } from "./department";
import { Level } from "./level"

export class Sector {
    id : number
    designation : string
    abbreviation : string
    level_id : number;
    department_id? : number;
    establishment_id : number = JSON.parse(localStorage.getItem('user-infos')).establishment_id;



    //only for Angular
    level : Level 
    cycle_id : number;
    cycle : Cycle;
    department : Department;
}
