<app-content-header 
    [icon]="'card_membership'" 
    [title]="'Cards'" 
    [hideBreadcrumb]="false"
    [hasBgImage]="true" 
    [class]="'pb-4'"></app-content-header>

<div fxLayout="column" fxLayout.gt-sm="row wrap" class="p-2">
    <div fxFlex="33.3" class="p-2"> 
        <mat-card class="custom-card">
            <mat-card-header class="bg-primary p-1" fxLayoutAlign="space-between center">
                <mat-card-title class="m-0"><h3>Primary Card</h3></mat-card-title>
                <button mat-icon-button><mat-icon>more_vert</mat-icon></button>
            </mat-card-header>
            <img mat-card-image src="assets/img/app/5.jpg" alt="5">
            <mat-card-content>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia nihil quis mollitia odio magni eaque id porro unde inventore recusandae. Debitis necessitatibus voluptates magnam eveniet odit distinctio odio provident sit.
                </p>
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions fxLayoutAlign="end center" class="secondary-text-color">
                <button mat-icon-button><mat-icon>favorite</mat-icon></button>
                <button mat-icon-button><mat-icon>share</mat-icon></button>
            </mat-card-actions>
        </mat-card>
    </div>
    <div fxFlex="33.3" class="p-2">
        <mat-card class="custom-card">
            <mat-card-header class="bg-accent p-1" fxLayoutAlign="space-between center">
                <mat-card-title class="m-0"><h3>Accent Card</h3></mat-card-title>
                <button mat-icon-button><mat-icon>more_vert</mat-icon></button>
            </mat-card-header>
            <img mat-card-image src="assets/img/app/7.jpg" alt="7">
            <mat-card-content>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia nihil quis mollitia odio magni eaque id porro unde inventore recusandae. Debitis necessitatibus voluptates magnam eveniet odit distinctio odio provident sit.
                </p>
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions fxLayoutAlign="end center" class="secondary-text-color">
                <button mat-icon-button><mat-icon>favorite</mat-icon></button>
                <button mat-icon-button><mat-icon>share</mat-icon></button>
            </mat-card-actions>
        </mat-card>
    </div> 
    <div fxFlex="33.3" class="p-2"> 
        <mat-card class="custom-card">
            <mat-card-header class="bg-warn p-1" fxLayoutAlign="space-between center">
                <mat-card-title class="m-0"><h3>Warn Card</h3></mat-card-title>
                <button mat-icon-button><mat-icon>more_vert</mat-icon></button>
            </mat-card-header>
            <img mat-card-image src="assets/img/app/2.jpg" alt="2">
            <mat-card-content>
                <p>
                    Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quia nihil quis mollitia odio magni eaque id porro unde inventore recusandae. Debitis necessitatibus voluptates magnam eveniet odit distinctio odio provident sit.
                </p>
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions fxLayoutAlign="end center" class="secondary-text-color">
                <button mat-icon-button><mat-icon>favorite</mat-icon></button>
                <button mat-icon-button><mat-icon>share</mat-icon></button>
            </mat-card-actions>
        </mat-card>
    </div>
    <div fxFlex="50" class="p-2"> 
        <mat-card>
            <img mat-card-image src="assets/img/app/1.jpg" alt="Photo 1">
            <mat-card-content>
                <p>
                  The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
                  A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
                  bred for hunting.
                </p>
                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Culpa facilis optio nihil eos perferendis a ut,
                   iste quae eaque distinctio voluptate officia doloremque, ab, fugiat nemo. Maiores, quasi laboriosam amet?
                </p>
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions>
                <button mat-icon-button><mat-icon>favorite</mat-icon></button>
                <button mat-icon-button><mat-icon>share</mat-icon></button>
            </mat-card-actions>
        </mat-card>
    </div>
    <div fxFlex="50" class="p-2"> 
        <mat-card>
            <mat-card-header>
                <mat-card-title>Basic Card Title</mat-card-title>
                <mat-card-subtitle>Subtitle</mat-card-subtitle>
            </mat-card-header> 
            <img mat-card-image src="assets/img/app/6.jpg" alt="Photo 6">        
            <mat-card-content>
                <p>
                  The Shiba Inu is the smallest of the six original and distinct spitz breeds of dog from Japan.
                  A small, agile dog that copes very well with mountainous terrain, the Shiba Inu was originally
                  bred for hunting.
                </p>                
            </mat-card-content>
            <mat-divider></mat-divider>
            <mat-card-actions>
                <button mat-button>LIKE</button>
                <button mat-button>SHARE</button>
            </mat-card-actions>
        </mat-card>
    </div>            
</div>