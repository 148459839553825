<div mat-dialog-title>
    <h1>{{translate.instant('subject_data')}}</h1>
</div>
<div mat-dialog-content>
    <form fxLayout="column" [formGroup]="form">
        <div fxLayout="row">
            <mat-form-field class="pr-2 pl-2" fxFlex="30">
                <input matInput placeholder="{{translate.instant('subject_code')}}" [(ngModel)]="data.subject_code"
                    required [formControlName]="'subject_code'">


            </mat-form-field>

            <mat-form-field class="pr-2 pl-2" fxFlex="40">
                <input matInput placeholder="{{translate.instant('designation')}}" [(ngModel)]="data.designation"
                    required [formControlName]="'designation'">

            </mat-form-field>
            <mat-form-field class="pr-2 pl-2" fxFlex="40">
                <input matInput placeholder="{{translate.instant('abbreviation')}}" [(ngModel)]="data.abbreviation"
                    required [formControlName]="'abbreviation'">

            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field class="pr-2 pl-2" fxFlex="30">
                <input matInput placeholder="{{translate.instant('coefficient')}}" type="number"
                    [(ngModel)]="data.coefficient" required [formControlName]="'coefficient'">

            </mat-form-field>
            <mat-form-field fxFlex="40">
                <mat-select [placeholder]="translate.instant('subject_module')" [formControlName]="'subject_module_id'"
                    [(ngModel)]="data.subject_module_id" required>
                    <mat-option *ngFor="let module of modules" [value]="module.id">
                        {{module.designation}}</mat-option>
                </mat-select>


            </mat-form-field>

            <mat-form-field fxFlex="40">
                <mat-select [placeholder]="translate.instant('type_ue')" [formControlName]="'type_ue'"
                    [(ngModel)]="data.type_ue" required>
                    <mat-option *ngFor="let type_ue of types_ue" [value]="type_ue.slug">
                        {{type_ue.value}}</mat-option>
                </mat-select>


            </mat-form-field>
        </div>
        <div fxLayout="row">
            <mat-form-field fxFlex="30">
                <mat-select [placeholder]="translate.instant('sector')" [formControlName]="'sector_id'"
                    [(ngModel)]="data.sector_id" required (selectionChange)="changeSector()">
                    <mat-option *ngFor="let sector of sectors" [value]="sector.id">
                        {{sector.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>
            <mat-form-field fxFlex="30" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('semestre_trimestre')"
                    [formControlName]="'year_division_id'" [(ngModel)]="data.year_division_id"
                    (selectionChange)="changeDivision()">
                    <mat-option *ngFor="let division of year_divisions" [value]="division.id">
                        {{division.abbreviation}} </mat-option>
                </mat-select>


            </mat-form-field>
        </div>

    </form>
    <span class="addform">
        <mat-form-field fxFlex="15" class="pr-2 pl-2">

            <mat-select [placeholder]="translate.instant('subject_type')" (selectionChange)="handleTypeChange($event)"
                [(ngModel)]="new_regime_line.subject_type_id">
                <mat-option *ngFor="let type of subject_types" [value]="type.id">
                    {{type.abbreviation}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="15">
            <input matInput placeholder="1.5" type="number" [(ngModel)]="new_regime_line.nb_hours" required>
        </mat-form-field>
        <mat-form-field fxFlex="15" class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('section')" [(ngModel)]="new_regime_line.group_id" multiple
                required>
                <mat-option *ngFor="let section of groupes" [value]="section.id">
                    {{section.abbreviation}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field fxFlex="20" class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('teacher')" [(ngModel)]="new_regime_line.teacher_id">
                <mat-option *ngFor="let teacher of teachers" [value]="teacher.id">
                    {{teacher.first_name}} {{teacher.last_name}}</mat-option>
            </mat-select>


        </mat-form-field>
        <mat-form-field fxFlex="20" class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('blocks')" [(ngModel)]="new_regime_line.blocks"
                (selectionChange)="handleBlockChange($event)" multiple>

                <mat-option *ngFor="let block of blocks" [value]="block.id">
                    {{block.abbreviation}}</mat-option>
            </mat-select>


        </mat-form-field>
        <mat-form-field fxFlex="20" class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('classrooms')" [(ngModel)]="new_regime_line.classrooms"
                multiple>

                <button mat-raised-button color="primary" class="ums_primary_button form-save" style="width: 100%;"
                    (click)="checkUncheckAllClassrooms()"> {{'buttons.select/unselectAll' | translate}}</button>
                <mat-option *ngFor="let classroom of classrooms" [value]="classroom.id">
                    {{classroom.abbreviation}}</mat-option>
            </mat-select>


        </mat-form-field>
        <mat-form-field fxFlex="20" class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('equipements')" [(ngModel)]="new_regime_line.equipments"
                multiple>

               
                <mat-option *ngFor="let equipment of equipments" [value]="equipment.id">
                    {{equipment.abbreviation}}</mat-option>
            </mat-select>


        </mat-form-field>
        <mat-form-field fxFlex="15" class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('week_type')" [(ngModel)]="new_regime_line.week_type_id"
              >

                <mat-option *ngFor="let week of week_types" [value]="week.id">
                    {{week.designation}} ( {{week.week1}} / {{week.week2}} )  </mat-option>
            </mat-select>


        </mat-form-field>
        <button fxFlex="15" (click)="addRegime()" mat-raised-button color="primary" class="ums_primary_button form-save"
            [disabled]="!new_regime_line.subject_type_id || !new_regime_line.nb_hours">
            <mat-icon class="fs20">add</mat-icon>
        </button>
    </span>
    <span *ngFor="let regime of data.regimes ; let i = index">
        <mat-form-field fxFlex="18" class="pr-2 pl-2">

            <mat-select [placeholder]="translate.instant('subject_type')" [(ngModel)]="regime.subject_type_id" >
                <mat-option *ngFor="let type of subject_types" [value]="type.id">
                    {{type.abbreviation}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <input matInput placeholder="1.5" type="number" [(ngModel)]="regime.nb_hours" required>
        </mat-form-field>
        <mat-form-field fxFlex="15" class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('section')" [(ngModel)]="regime.group_id" multiple required>
                <mat-option
                    *ngFor="let section of (regime.subject_type_id == 1 ? sections : (regime.subject_type_id == 2 ? groups_td : groups_tp))"
                    [value]="section.id">
                    {{section.abbreviation}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="15" class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('teacher')" [(ngModel)]="regime.teacher_id">
                <mat-option *ngFor="let teacher of teachers" [value]="teacher.id">
                    {{teacher.first_name}} {{teacher.last_name}}</mat-option>
            </mat-select>


        </mat-form-field>


        <mat-form-field fxFlex="15" class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('blocks')" [(ngModel)]="regime.blocks"
                (selectionChange)="handleRegimeBlockChange($event , i)" multiple>

                <mat-option *ngFor="let block of blocks" [value]="block.id">
                    {{block.abbreviation}}</mat-option>
            </mat-select>


        </mat-form-field>
        <mat-form-field fxFlex="15" class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('classrooms')" [(ngModel)]="regime.classrooms" multiple>

                <button mat-raised-button color="primary" class="ums_primary_button form-save" style="width: 100%;"
                    (click)="checkUncheckRegimeAllClassrooms(i)">
                    {{'select/unselectAll' | translate}}</button>
                <mat-option *ngFor="let classroom of classrooms" [value]="classroom.id">
                    {{classroom.abbreviation}}</mat-option>
            </mat-select>


        </mat-form-field>
        <mat-form-field fxFlex="15" class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('equipments')" [(ngModel)]="regime.equipments" multiple>

                <button mat-raised-button color="primary" class="ums_primary_button form-save" style="width: 100%;"
                    (click)="checkUncheckRegimeAllClassrooms(i)">
                    {{'select/unselectAll' | translate}}</button>
                <mat-option *ngFor="let equipment of equipments" [value]="equipment.id">
                    {{equipment.abbreviation}}</mat-option>
            </mat-select>


        </mat-form-field>
        <mat-form-field fxFlex="15" class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('week_type')" [(ngModel)]="regime.week_type_id"
              >

                <mat-option *ngFor="let week of week_types" [value]="week.id">
                    {{week.designation}} ( {{week.week1}} / {{week.week2}} ) </mat-option>
            </mat-select>


        </mat-form-field>
        <button fxFlex="15" mat-raised-button color="danger" class="ums_primary_button form-save"
            (click)="removeRegime(i)">
            <mat-icon class="fs20">delete_outline</mat-icon>
        </button>

    </span>
    <!--  <span *ngFor="let type of subject_types ; let i = index">
        <div>

            <mat-checkbox (change)="checkType($event.checked, type.id)" [checked]="IsChecked(type.id)">
                {{type.abbreviation}}</mat-checkbox> &nbsp; &nbsp;
            <mat-form-field *ngIf="isCheckedType(type.id)">
                <input matInput placeholder="1.5" [value]="getNbHours(type.id)" type="number"
                    (change)="changeNbHours(type.id , $event.target.value)" required>
            </mat-form-field>
            <mat-form-field *ngIf="isCheckedType(type.id)" fxFlex="50" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('section')"  multiple
                    required (selectionChange)="changeSection(type.id , $event.value)">
                    <mat-option *ngFor="let section of sections" [value]="section.id">
                        {{section.abbreviation}}</mat-option>
                </mat-select>
            </mat-form-field>
            <mat-form-field *ngIf="isCheckedType(type.id)" fxFlex="50" class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('teacher')" [value]="getTeacher(type.id)"
                (selectionChange)="changeTeacher(type.id , $event.value)">
                <mat-option *ngFor="let teacher of teachers" [value]="teacher.id">
                    {{teacher.first_name}} {{teacher.last_name}}</mat-option>
            </mat-select>


            </mat-form-field>
        </div>
    </span> -->

</div>
<div mat-dialog-actions>

    <button mat-raised-button color="basic" class=" ums_basic_button form-cancel"
        (click)="onNoClick()">{{'buttons.no_thanks' | translate}}</button>
    <button mat-raised-button color="primary" [disabled]="!form.valid" class="ums_primary_button form-save"
        [mat-dialog-close]="data" cdkFocusInitial>
        {{'buttons.save' | translate}}
    </button>
</div>