<div mat-dialog-title><h1 >{{translate.instant('sector_data')}}</h1></div>
<div mat-dialog-content >
    <form fxLayout="column" [formGroup]="form"  >
      

        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('designation')}}" [(ngModel)]="data.designation"
                required [formControlName]="'designation'">
            
        </mat-form-field>
        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('abbreviation')}}"  [(ngModel)]="data.abbreviation"
                required [formControlName]="'abbreviation'">
            
        </mat-form-field>
      
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('cycle')" formControlName="cycle_id"
                [(ngModel)]="data.cycle_id" (selectionChange)="handleCycleChange()" required>
                <mat-option *ngFor="let cycle of cycles" [value]="cycle.id">
                    {{cycle.abbreviation}}</mat-option>
            </mat-select>

           
        </mat-form-field>
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('level')" [formControlName]="'level_id'"
                [(ngModel)]="data.level_id" required>
                <mat-option *ngFor="let level of levels" [value]="level.id">
                    {{level.abbreviation}}</mat-option>
            </mat-select>

           
        </mat-form-field>

        <mat-form-field>
            <mat-select [placeholder]="translate.instant('department')" formControlName="department_id"
                [(ngModel)]="data.department_id" >
                <mat-option *ngFor="let department of departments" [value]="department.id">
                    {{department.abbreviation}}</mat-option>
            </mat-select>

           
        </mat-form-field>

       
    </form>
</div>
<div mat-dialog-actions>
 
    <button  mat-raised-button color="basic"  class=" ums_basic_button form-cancel" (click)="onNoClick()">{{'buttons.no_thanks' | translate}}</button>
    <button  mat-raised-button color="primary" [disabled]="!form.valid" class="ums_primary_button form-save" [mat-dialog-close]="data" cdkFocusInitial>
     {{'buttons.save' | translate}}
    </button>
</div>

