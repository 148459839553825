import { Component, OnInit } from '@angular/core';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { Building } from 'src/app/viewModels/building';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-buildings',
  templateUrl: './buildings.component.html',
  styleUrls: ['./buildings.component.css']
})
export class BuildingsComponent implements OnInit {

  constructor(
    private apis : EstablishmentService,
    public translate : TranslateService,
    private toastr : ToastrService) { }
  buildings : Building[];
  new_build = new Building();
  show_form = false;

  form : FormGroup = new FormGroup({
    'designation' : new FormControl(Validators.required),
    'abbreviation' : new FormControl(Validators.required)

  })
  ngOnInit(): void { 
    this.getData(); 
  }

  getData(){
    this.apis.getEstablishmentBuildings()
    .subscribe((data : Building[])=>{
      this.buildings = data;
      console.log(this.buildings);
      
    })
  }
  showNewBuildForm(){
    this.show_form = true;
    this.new_build = new Building();
  }
  editBuild(building){
    this.buildings = this.buildings.filter((x)=> x.id != building.id)
    this.new_build = building;
    this.show_form = true;
  }

  cancel(){
    this.show_form = false;
    this.new_build = new Building();
  }

  save(){
    this.apis.setBuilding(this.new_build)
    .subscribe((data)=>{
      console.log(data);
      this.showSuccess('success_save')
      this.getData();
      this.cancel();
    })

  }

  
  showSuccess(msg){

    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg){
    this.toastr.error(this.translate.instant((msg)))
  }

}
