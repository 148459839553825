import { TimingInstituteService } from './../../apis/timing-institute.service';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DragulaService } from 'ng2-dragula';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ExcelService } from 'src/app/apis/excel/excel.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { TimingService } from 'src/app/apis/timing.service';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { Day } from 'src/app/viewModels/day';
import { GenerateSetting } from 'src/app/viewModels/generate-setting';
import { GroupTd } from 'src/app/viewModels/group-td';
import { GroupTp } from 'src/app/viewModels/group-tp';
import { Period } from 'src/app/viewModels/period';
import { Section } from 'src/app/viewModels/section';
import { Sector } from 'src/app/viewModels/sector';
import { GenerateDialogComponent } from './generate-dialog/generate-dialog.component';
import { UpdateDialogComponent } from './update-dialog/update-dialog.component';
import { UpdateSessionDialogComponent } from './update-session-dialog/update-session-dialog.component';

declare var $: any;
@Component({
  selector: 'app-time-table-institute',
  templateUrl: './time-table-institute.component.html',
  styleUrls: ['./time-table-institute.component.css']
})
export class TimeTableInstituteComponent implements OnInit {


  public settings: Settings;
  subs = new Subscription();
  colors=['#f8f68f' , '#f8e68f' , '#f8d68f' , '#f8c68f' , '#f8e68fa1' , '#f8f688ba' ,'#f8f48f' , '#f8f28f']

  constructor(private settingService: SettingsService,
    public appSettings: AppSettings,
    private excelService: ExcelService,
    public translate: TranslateService,
    private hierarchyService: HiararchyService,
    private apis: TimingInstituteService,
    public dialog: MatDialog,
    private dragulaService: DragulaService,
    private toastr: ToastrService) {
    this.settings = this.appSettings.settings;




    this.subs.add(this.dragulaService.drop("bag-1")
      .subscribe(({ name, el, target, source, sibling }) => {
        // ...

       // console.log(source)
        let sourceTab = source.id.split('-')
        let targetTab = target.id.split('-')

        let seance_id;


        if(sourceTab[0] == 'pending'){
          let body = {
  
            target_day: this.days.find((x) => x.id == Number(targetTab[0])),
            target_period: this.periods.find((x) => x.id == Number(targetTab[1])),
            session_id: sourceTab[1]
            
          }
          console.log(body)
          this.updatePendingSessionDialog(body);
        }else{
          console.log('from ' + sourceTab[0] + ':' + sourceTab[1] + '     to ' + targetTab[0] + ' : ' + targetTab[1])
          target.childNodes.forEach((x: any) => {
            if (x.dataset && x.dataset.name == 'seance' && x.id.indexOf(source.id) == 0) {
              console.log(x.id)
              seance_id = x.id.split('-')[2]
            }
          })
          let body = {
            source_day: this.days.find((x) => x.id == Number(sourceTab[0])),
            source_period: this.periods.find((x) => x.id == Number(sourceTab[1])),
            target_day: this.days.find((x) => x.id == Number(targetTab[0])),
            target_period: this.periods.find((x) => x.id == Number(targetTab[1])),
            session_id: seance_id
          }
  
          console.log(body)
          this.updateSessionDialog(body);
        }
        


      })
    );


  }
  days: Day[];
  periods: Period[];
  width = 20;
  timetable: any[];
  timetableGTP1: any[];
  timetableGTP2: any[];
  groupTP: GroupTp[];
  groupTD: GroupTp[];

  selectedSector;
  selectedSection;
  selectedGroupTp;
  selectedGroupTd;

  sectors: Sector[];
  sections: Section[];
  groupsTp  : GroupTp[];
  
  groupsTd  : GroupTd[];
  pending_list: any[];

  semestres_trimestres: any = [];
  selectedSemestre;
  ngOnInit(): void {
    this.hierarchyService.getHiararchySectors()
      .subscribe((data: Sector[]) => {
        this.sectors = data;
      })
    this.settingService.getDays()
      .subscribe((data: Day[]) => {
        this.days = data;
      })
    this.settingService.getYearDivisions()
      .subscribe((data: any[]) => {
        this.semestres_trimestres = data;
      })


    this.apis.getHiararchyPeriods()
      .subscribe((data: Period[]) => {
        this.periods = data.filter((x) => x.type == 'session').sort((a, b) => a.start_hour > b.start_hour ? 0 : -1);
        this.width = (100 - 6) / (this.periods.length);
      })


  }


  handleSectorChange() {
    if (this.selectedSector) {
      this.hierarchyService.getSectorSections(this.selectedSector)
        .subscribe((data: Section[]) => {
          this.sections = data;
        })
    } else {
      this.sections = [];
      this.timetable = []

      this.timetableGTP1 = [];
      this.timetableGTP2 = [];
      this.pending_list = [];
    }
  }

  handleSectionChange() {
   console.log("cancel")
    if (this.selectedSection && this.selectedSemestre) {
      this.apis.getSectionTimeTable(this.selectedSection, this.selectedSemestre)
        .subscribe((data: any) => {
          console.log("updated data")
          this.timetable = data.g1;
          this.timetableGTP2 = data.g2;


          console.log(data)
        })

      this.apis.getSectionPending(this.selectedSection, this.selectedSemestre)
        .subscribe((data: any[]) => {
          this.pending_list = data;
        })

       
    } else {

      this.timetable = [];
      this.timetableGTP1 = [];
      this.timetableGTP2 = [];
      this.pending_list = [];
    }
    this.hierarchyService.getSectionGroupTps(this.selectedSection)
    .subscribe((data: GroupTp[]) => {
      this.groupTP = data;
    })
    this.hierarchyService.getSectionGroupTds(this.selectedSection)
    .subscribe((data: GroupTd[]) => {
      //this.groupTD = data;
    })
  }
  handleGroupChange() {
    console.log("cancel")
     if (this.selectedGroupTp && this.selectedSemestre) {
       this.apis.getSectionGroupTpTimeTable(this.selectedGroupTp, this.selectedSemestre)
         .subscribe((data: any) => {
           console.log("updated data")
           this.timetable = data.g1;
           //this.timetableGTP2 = data.g2;
 
 
           console.log(data)
         })
 
    
        
     } else {
 
       this.timetable = [];
       this.timetableGTP1 = [];
       this.timetableGTP2 = [];
       this.pending_list = [];
     }
   }

   handleGroupTDChange() {
    console.log("cancel")
     if (this.selectedGroupTd && this.selectedSemestre) {
       this.apis.getSectionGroupTdTimeTable(this.selectedGroupTd, this.selectedSemestre)
         .subscribe((data: any) => {
           console.log("updated data")
           this.timetable = data.g1;
           //this.timetableGTP2 = data.g2;
 
 
           console.log(data)
         })
 
    
        
     } else {
 
       this.timetable = [];
       this.timetableGTP1 = [];
       this.timetableGTP2 = [];
       this.pending_list = [];
     }
   }
  generateSemestre;
  show_progess = false;
  handleGenerateChange() {
    this.show_progess = true;
    this.apis.generateTimeTable(this.generateSemestre)
      .subscribe((data) => {
        console.log(data);
        this.show_progess = false;
        this.showSuccess('success')

      }, (err) => {
        console.log(err)
        this.show_progess = false;
        this.showError('generate_server_error')

      })
  }


  export() {
    this.excelService.exportToFile('timetable', 'table_group');
  }


  showSuccess(msg) {
    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg) {
    this.toastr.error(this.translate.instant(msg))
  }

  print() {
    var $print = $('#print_ums_id')
      .clone()
      .addClass('ums_print')
      .prependTo('body');
    window.print();
    $print.remove();
  }


  generateDialog() {
    const dialogRef = this.dialog.open(GenerateDialogComponent, {
      width: '500px',
      data: new GenerateSetting()
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      // console.log(this.item)
      if (result) {
        this.show_progess = true;
        console.log(result)
        this.apis.generateTimeTable(result)
          .subscribe((data) => {
            console.log(data);
            this.show_progess = false;
            this.showSuccess('success')

          }, (err) => {
            console.log(err)
            this.show_progess = false;
            this.showError('generate_server_error')

          })
      }

    });
  }

  showEditDialog(period) {
    console.log(period)
    const dialogRef = this.dialog.open(UpdateDialogComponent, {
      width: '500px',
      data: period
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.show_progess = true;
        console.log(result)


        let ss_body = { target_day_id: result.day_id, target_period_id: result.period_id, session_id: result.id , teacher_id : result.teacher_id , classroom_id : result.classroom_id};
         
        this.apis.checkSessionTeacher(ss_body)
          .subscribe((check: any) => {

            if (check.error) {
              this.showError(check.error);
              this.handleSectionChange();
              this.show_progess = false;
            } else {
              console.log("check")
              this.handleSectionChange();
              this.show_progess = false;

            }
          }) 
      } else {
        this.handleSectionChange();
        this.show_progess = false;
      }
    })
  }

  showEditPendingDialog(period) {
    console.log(period)
    const dialogRef = this.dialog.open(UpdateDialogComponent, {
      width: '500px',
      data: period
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.show_progess = true;
        console.log(result)


        let ss_body = { target_day_id: result.day_id, target_period_id: result.period_id, session_id: result.id , teacher_id : result.teacher_id , classroom_id : result.classroom_id};
         
        this.apis.checkSessionTeacher(ss_body)
          .subscribe((check: any) => {

            if (check.error) {
              this.showError(check.error);
              this.handleSectionChange();
              this.show_progess = false;
            } else {
              console.log("check")
              this.handleSectionChange();
              this.show_progess = false;

            }
          }) 
      } else {
        this.handleSectionChange();
        this.show_progess = false;
      }
    })
  }
  updateSessionDialog(body) {
    let ss_body = { source_day_id: body.source_day.id, source_period_id: body.source_period.id, target_day_id: body.target_day.id, target_period_id: body.target_period.id, session_id: body.session_id };
    this.apis.checkSession(ss_body)
      .subscribe((check: any) => {
        if (check.error) {
          this.showError(check.error);
          this.handleSectionChange();
          this.show_progess = false;
          console.log(check.error)
        } else {
          console.log(check)
          const dialogRef = this.dialog.open(UpdateSessionDialogComponent, {
            width: '500px',
            data: body
          });

          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed', result);
            // console.log(this.item)


            if (result) {
              this.show_progess = true;

              this.apis.checkDropSession(ss_body)
                .subscribe((resultt) => {
                  console.log(resultt)
                  this.handleSectionChange();
                  this.show_progess = false;
                })

            } else {
              console.log('undefined')
              this.handleSectionChange();
              this.show_progess = false;
            }

          });

        }
      })
  }


  updatePendingSessionDialog(body) {
    let ss_body = {  target_day_id: body.target_day.id, target_period_id: body.target_period.id, session_id: body.session_id };
    this.apis.checkPendingSession(ss_body)
      .subscribe((check: any) => {
        if (check.error) {
          this.showError(check.error);
          this.handleSectionChange();
          this.show_progess = false;
          console.log(check.error)
        } else {
          console.log(check)
          const dialogRef = this.dialog.open(UpdateSessionDialogComponent, {
            width: '500px',
            data: body
          });

          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed', result);
            // console.log(this.item)


            if (result) {
              this.show_progess = true;

              this.apis.checkDropPendingSession(ss_body)
                .subscribe((resultt) => {
                  console.log(resultt)
                  this.handleSectionChange();
                  this.show_progess = false;
                })

            } else {
              console.log('undefined')
              this.handleSectionChange();
              this.show_progess = false;
            }

          });

        }
      })
  }
}
