<div mat-dialog-title>
    <h1>{{translate.instant('period_data')}}</h1>
</div>
<div mat-dialog-content>
    <form fxLayout="column" [formGroup]="form">
        <mat-form-field class="pr-2 pl-2">
            <input matInput placeholder="{{translate.instant('designation')}}" [(ngModel)]="data.designation" required
                [formControlName]="'designation'">


        </mat-form-field>

        <mat-form-field class="pr-2 pl-2">
            <input matInput placeholder="{{translate.instant('abbreviation')}}" [(ngModel)]="data.abbreviation" required
                [formControlName]="'abbreviation'">

        </mat-form-field>
        <mat-form-field class="pr-2 pl-2">
            <input matInput placeholder="{{translate.instant('start_hour')}}" type="time" [(ngModel)]="data.start_hour"
                required [formControlName]="'start_hour'">

        </mat-form-field>
        <mat-form-field class="pr-2 pl-2">
            <input matInput placeholder="{{translate.instant('end_hour')}}" type="time" [(ngModel)]="data.end_hour"
                required [formControlName]="'end_hour'">

        </mat-form-field>




        <mat-form-field>
            <mat-select [placeholder]="translate.instant('type')" [formControlName]="'type'" [(ngModel)]="data.type"
                required>
                <mat-option *ngFor="let type of periods_types" [value]="type.slug">
                    {{type.value | translate}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('overlap')" [formControlName]="'overlap_ids'"
                [(ngModel)]="data.overlap_ids" multiple>
                <mat-option *ngFor="let p of periods" [value]="p.id">
                    {{p.designation}}</mat-option>
            </mat-select>


        </mat-form-field>

    </form>
</div>
<div mat-dialog-actions>

    <button mat-raised-button color="basic" class=" ums_basic_button form-cancel"
        (click)="onNoClick()">{{'buttons.no_thanks' | translate}}</button>
    <button mat-raised-button color="primary" [disabled]="!form.valid" class="ums_primary_button form-save"
        [mat-dialog-close]="data" cdkFocusInitial>
        {{'buttons.save' | translate}}
    </button>
</div>
