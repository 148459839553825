import { TimeTableInstituteComponent } from './pages/time-table/time-table-institute.component';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { OverlayContainer } from '@angular/cdk/overlay';
import { CustomOverlayContainer } from './theme/utils/custom-overlay-container';

import { AgmCoreModule } from '@agm/core';
import { PerfectScrollbarModule } from 'ngx-perfect-scrollbar';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  wheelPropagation: true,
  suppressScrollX: true
};


//ngx-translate
import {HttpClientModule, HttpClient} from '@angular/common/http';
import {TranslateModule, TranslateLoader, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import { ToastrModule } from 'ngx-toastr';

import {MatDialogModule, MatDialogConfig} from '@angular/material/dialog';

import { CalendarModule } from 'angular-calendar';
import {  DateAdapter } from 'angular-calendar';
import { adapterFactory } from 'angular-calendar/date-adapters/date-fns';
import { SharedModule } from './shared/shared.module';
import { PipesModule } from './theme/pipes/pipes.module';

import { AppSettings } from './app.settings';
import { AppComponent } from './app.component';
import { PagesComponent } from './pages/pages.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { ErrorComponent } from './pages/errors/error/error.component';

import { TopInfoContentComponent } from './theme/components/top-info-content/top-info-content.component';
import { SidenavComponent } from './theme/components/sidenav/sidenav.component';
import { VerticalMenuComponent } from './theme/components/menu/vertical-menu/vertical-menu.component';
import { HorizontalMenuComponent } from './theme/components/menu/horizontal-menu/horizontal-menu.component';
import { FlagsMenuComponent } from './theme/components/flags-menu/flags-menu.component';
import { FullScreenComponent } from './theme/components/fullscreen/fullscreen.component';
import { ApplicationsComponent } from './theme/components/applications/applications.component';
import { MessagesComponent } from './theme/components/messages/messages.component';
import { UserMenuComponent } from './theme/components/user-menu/user-menu.component';
import { FavoritesComponent } from './theme/components/favorites/favorites.component';
import { ChartsModule } from './pages/charts/charts.module';
import { ChatModule } from './pages/chat/chat.module';
import { DragDropModule } from './pages/drag-drop/drag-drop.module';
import { DynamicMenuModule } from './pages/dynamic-menu/dynamic-menu.module';
import { FormControlsModule } from './pages/form-controls/form-controls.module';
import { IconsModule } from './pages/icons/icons.module';
import { LandingModule } from './pages/landing/landing.module';
import { LoginModule } from './pages/login/login.module';
import { MailboxModule } from './pages/mailbox/mailbox.module';
import { MapsModule } from './pages/maps/maps.module';
import { RegisterModule } from './pages/register/register.module';
import { ScheduleModule } from './pages/schedule/schedule.module';
import { TablesModule } from './pages/tables/tables.module';
import { UiModule } from './pages/ui/ui.module';
import { UsersModule } from './pages/users/users.module';
import { EstablishmentComponent } from './pages/establishment/establishment.component';
import { BuildingsComponent } from './pages/establishment/buildings/buildings.component';
import { CyclesComponent } from './pages/hierarchy/cycles/cycles.component';
import { LevelsComponent } from './pages/hierarchy/levels/levels.component';
import { SectorsComponent } from './pages/hierarchy/sectors/sectors.component';
import { SectionsComponent } from './pages/hierarchy/sections/sections.component';
import { GroupsTdComponent } from './pages/hierarchy/groups-td/groups-td.component';
import { GroupsTpComponent } from './pages/hierarchy/groups-tp/groups-tp.component';
import { SubjectsComponent } from './pages/subjects/subjects.component';
import { SubjectTypesComponent } from './pages/subjects/subject-types/subject-types.component';
import { SubjectRegimesComponent } from './pages/subjects/subject-regimes/subject-regimes.component';
import { TeachersComponent } from './pages/teachers/teachers.component';
import { TeacherGradesComponent } from './pages/teachers/teacher-grades/teacher-grades.component';
import { TeacherTypesComponent } from './pages/teachers/teacher-types/teacher-types.component';
import { TimeTableComponent } from './pages/time-table/time-table.component';
import { AppRoutingModule } from './app-routing.module';
import { BlocksComponent } from './pages/establishment/blocks/blocks.component';
import { ClassroomsComponent } from './pages/establishment/classrooms/classrooms.component';
import { TeacherFormComponent } from './pages/teachers/teacher-form/teacher-form.component';
import { SubjectFormComponent } from './pages/subjects/subject-form/subject-form.component';
import { SectionFormComponent } from './pages/hierarchy/sections/section-form/section-form.component';
import { GroupTdFormComponent } from './pages/hierarchy/groups-td/group-td-form/group-td-form.component';
import { GroupTpFormComponent } from './pages/hierarchy/groups-tp/group-tp-form/group-tp-form.component';
import { TeacherAvailibilityComponent } from './pages/teachers/teacher-availibility/teacher-availibility.component';
import { SectorFormComponent } from './pages/hierarchy/sectors/sector-form/sector-form.component';
import { DepartmentsComponent } from './pages/hierarchy/departments/departments.component';
import { DepartmentFormComponent } from './pages/hierarchy/departments/department-form/department-form.component';
import { PeriodsComponent } from './pages/establishment/periods/periods.component';
import { PeriodFormComponent } from './pages/establishment/period-form/period-form.component';
import { ClassroomFormComponent } from './pages/establishment/classrooms/classroom-form/classroom-form.component';
import { VacationsExamsComponent } from './pages/establishment/vacations-exams/vacations-exams.component';
import { WeekTypesComponent } from './pages/establishment/week-types/week-types.component';
import { WeekTypeFormComponent } from './pages/establishment/week-types/week-type-form/week-type-form.component';
import { VacationExamFormComponent } from './pages/establishment/vacations-exams/vacation-exam-form/vacation-exam-form.component';
import { DragulaModule } from 'ng2-dragula';
import { TeacherTimeTableComponent } from './pages/time-table/teacher-time-table/teacher-time-table.component';
import { ClassroomsScheduleComponent } from './pages/time-table/classrooms-schedule/classrooms-schedule.component';
import { SubjectModulesComponent } from './pages/subjects/subject-modules/subject-modules.component';
import { SubjectModuleFormComponent } from './pages/subjects/subject-module-form/subject-module-form.component';
import { GenerateDialogComponent } from './pages/time-table/generate-dialog/generate-dialog.component';
import { SectorAvailibilityComponent } from './pages/hierarchy/sectors/sector-availibility/sector-availibility.component';
import { UpdateSessionDialogComponent } from './pages/time-table/update-session-dialog/update-session-dialog.component';
import { ExamsComponent } from './pages/exams/exams.component';
import { ExamsGenerateDialogComponent } from './pages/exams/exams-generate-dialog/exams-generate-dialog.component';
import { ExamsUpdateDialogComponent } from './pages/exams/exams-update-dialog/exams-update-dialog.component';
import { MonotoringScheduleComponent } from './pages/exams/monotoring-schedule/monotoring-schedule.component';
import { UpdateDialogComponent } from './pages/time-table/update-dialog/update-dialog.component';
import { EquipmentsComponent } from './pages/establishment/equipments/equipments.component';
import { EquipmentFormComponent } from './pages/establishment/equipments/equipment-form/equipment-form.component';
import { NumberStudyHoursComponent } from './pages/study-plan/number-study-hours/number-study-hours.component';
import { TotalStudyHoursComponent } from './pages/study-plan/total-study-hours/total-study-hours.component';
import { AnnualStudyHoursComponent } from './pages/study-plan/annual-study-hours/annual-study-hours.component';
import { AddSessionDialogComponent } from './pages/time-table/add-session-dialog/add-session-dialog.component';
import { CreatePendingDialogComponent } from './pages/time-table/create-pending-dialog/create-pending-dialog.component';
import { ArchivesComponent } from './pages/time-table/archives/archives.component';
import { ClassroomOccupationsComponent } from './pages/time-table/classroom-occupations/classroom-occupations.component';
import { MonotoringGenerateDialogComponent } from './pages/exams/monotoring-generate-dialog/monotoring-generate-dialog.component';
import { PrintComponent } from './print/print.component';
import { PrintTeacherTimetableComponent } from './print-teacher-timetable/print-teacher-timetable.component';

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
    }
    }),
    FormsModule,
    ReactiveFormsModule,
    AgmCoreModule.forRoot({
      apiKey: 'AIzaSyBNcjxo_35qnEG17dQvvftWa68eZWepYE0'
    }),
    PerfectScrollbarModule,
    CalendarModule.forRoot({
      provide: DateAdapter,
      useFactory: adapterFactory,
    }),
    SharedModule,
    PipesModule,
    ChartsModule,
    ChatModule,
    DragDropModule,
    DynamicMenuModule,
    FormControlsModule,
    IconsModule,
    LandingModule,
    LoginModule,
    MailboxModule,
    MapsModule,
    RegisterModule,
    ScheduleModule,
    TablesModule,
    UiModule,
    MatDialogModule,
    ToastrModule.forRoot(), // ToastrModule added,
    
    DragulaModule.forRoot()

  ],
  declarations: [
    AppComponent,
    PagesComponent,
    BlankComponent,
    SearchComponent,
    NotFoundComponent,
    ErrorComponent,
    TopInfoContentComponent,
    SidenavComponent,
    VerticalMenuComponent,
    HorizontalMenuComponent,
    FlagsMenuComponent,
    FullScreenComponent,
    ApplicationsComponent,
    MessagesComponent,
    UserMenuComponent,
    FavoritesComponent,
    EstablishmentComponent,
    BuildingsComponent,
    CyclesComponent,
    LevelsComponent,
    SectorsComponent,
    SectionsComponent,
    GroupsTdComponent,
    GroupsTpComponent,
    SubjectsComponent,
    SubjectTypesComponent,
    SubjectRegimesComponent,
    TeachersComponent,
    TeacherGradesComponent,
    TeacherTypesComponent,
    TimeTableComponent,
    TimeTableInstituteComponent,
    BlocksComponent,
    ClassroomsComponent,
    TeacherFormComponent,
    SubjectFormComponent,
    SectorFormComponent,
    SectionFormComponent,
    GroupTdFormComponent,
    GroupTpFormComponent,
    TeacherAvailibilityComponent,
    DepartmentsComponent,
    DepartmentFormComponent,
    PeriodsComponent,
    PeriodFormComponent,
    ClassroomFormComponent,
    VacationsExamsComponent,
    WeekTypesComponent,
    WeekTypeFormComponent,
    VacationExamFormComponent,
    TeacherTimeTableComponent,
    ClassroomsScheduleComponent,
    SubjectModulesComponent,
    SubjectModuleFormComponent,
    GenerateDialogComponent,
    SectorAvailibilityComponent,
    UpdateSessionDialogComponent,
    ExamsComponent,
    ExamsGenerateDialogComponent,
    ExamsUpdateDialogComponent,
    MonotoringScheduleComponent,
    UpdateDialogComponent,
    EquipmentsComponent,
    EquipmentFormComponent,
    NumberStudyHoursComponent,
    TotalStudyHoursComponent,
    AnnualStudyHoursComponent,
    AddSessionDialogComponent,
    CreatePendingDialogComponent,
    ArchivesComponent,
    ClassroomOccupationsComponent,
    MonotoringGenerateDialogComponent,
    PrintComponent,
    PrintTeacherTimetableComponent

  ],
  providers: [
    AppSettings,MatDialogConfig,
    { provide: PERFECT_SCROLLBAR_CONFIG, useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG },
    { provide: OverlayContainer, useClass: CustomOverlayContainer }
  ],

  bootstrap: [
    AppComponent

  ]
})
export class AppModule { }
