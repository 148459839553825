<app-content-header 
    [icon]="'chat_bubble'" 
    [title]="'Tooltip'" 
    [hideBreadcrumb]="false"
    [hasBgImage]="true" 
    [class]="'pb-4'"></app-content-header>

<div fxLayout="column" class="p-2">
    <div class="p-2">
        <mat-card>
            <mat-card-header fxLayoutAlign="center">                
                <mat-card-subtitle><h2>Tooltip Overview</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayoutAlign="center">
                <div matTooltip="Tooltip!" [matTooltipPosition]="position">
                    <span class="mx-2">Show tooltip</span>
                    <mat-form-field>
                        <mat-select [(ngModel)]="position">
                            <mat-option value="before">Before</mat-option>
                            <mat-option value="after">After</mat-option>
                            <mat-option value="above">Above</mat-option>
                            <mat-option value="below">Below</mat-option>
                            <mat-option value="left">Left</mat-option>
                            <mat-option value="right">Right</mat-option>
                        </mat-select>
                    </mat-form-field>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
</div>