import { Cycle } from './../../../viewModels/cycle';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HiararchyService } from 'src/app/apis/hiararchy.service';

@Component({
  selector: 'app-cycles',
  templateUrl: './cycles.component.html',
  styleUrls: ['./cycles.component.css']
})
export class CyclesComponent implements OnInit {

  constructor(
    private apis :HiararchyService,
    public translate : TranslateService,
    private toastr : ToastrService) { }
  cycles : Cycle[];
  new_cycle = new Cycle();
  show_form = false;

  form : FormGroup = new FormGroup({
    'designation' : new FormControl(Validators.required),
    'abbreviation' : new FormControl(Validators.required)

  })
  ngOnInit(): void { 
    this.getData(); 
  }
  getData(){
    this.apis.getHiararchyCycles()
    .subscribe((data : Cycle[])=>{
      this.cycles = data;
      console.log(this.cycles);
      
    })
  }
  showNewCycleForm(){
    this.show_form = true;
    
    
    this.new_cycle = new Cycle();
  }

  editCycle(cycle){
    this.cycles = this.cycles.filter((x)=> x.id != cycle.id)
    this.new_cycle = cycle;
    this.show_form = true;
  }
  cancel(){
    this.show_form = false;
    this.new_cycle = new Cycle();
  }

  save(){
    this.apis.setCycle(this.new_cycle)
    .subscribe((data)=>{
      console.log(data);
      this.showSuccess('success_save')
      this.getData();
      this.cancel();
    })

  }

  
  showSuccess(msg){

    this.toastr.success(this.translate.instant(msg));
  }

  showError(msg){
    this.toastr.error(this.translate.instant((msg)));
  }

}

