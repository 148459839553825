import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { SubjectType } from '../viewModels/subject-type';
import { Subject } from '../viewModels/subject';

@Injectable({
  providedIn: 'root'
})
export class SubjectService {

  constructor(
    private http: HttpClient,
    private config: ConfigService) { }


  user = JSON.parse(localStorage.getItem('user-infos'));
  getEstablishmentSubjectTypes() {
    return this.http.get(this.config.baseUrl + '/subject-types', this.config.getOptions())
  }

  createOrUpdateSubjectType(body : SubjectType) {
    return this.http.post(this.config.baseUrl + '/subject-types', body, this.config.getOptions())
  }

  removeSubjectType(id) {
    return this.http.delete(this.config.baseUrl + '/subject-types/'+id, this.config.getOptions())
  }

  getEstablishmentSubjectModules() {
    return this.http.get(this.config.baseUrl + '/subject-modules', this.config.getOptions())
  }

  createOrUpdateSubjectModule(body : SubjectType) {
    return this.http.post(this.config.baseUrl + '/subject-modules', body, this.config.getOptions())
  }

  removeSubjectModule(id) {
    return this.http.delete(this.config.baseUrl + '/subject-modules/'+id, this.config.getOptions())
  }

  getSectorSubjects(sector_id) {
    return this.http.get(this.config.baseUrl + '/sector/subjects/'+sector_id, this.config.getOptions())
  }

  createOrUpdateSubject(body : Subject) {
    return this.http.post(this.config.baseUrl + '/subjects', body, this.config.getOptions())
  }

  removeSubject(id) {
    return this.http.delete(this.config.baseUrl + '/subjects/'+id, this.config.getOptions())
  }



  importSubjects(body : Subject[]){
    console.log(body)
    return this.http.post(this.config.baseUrl+'/import/subjects' , body , this.config.getOptions())
  }
}
