import { FormGroup, FormControl, Validators } from '@angular/forms';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { Equipment } from './../../../../viewModels/equipment';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from './../../../../apis/config.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-equipment-form',
  templateUrl: './equipment-form.component.html',
  styleUrls: ['./equipment-form.component.css']
})
export class EquipmentFormComponent implements OnInit {

 
  
  constructor(
    public translate: TranslateService,
    private config : ConfigService,
    public dialogRef: MatDialogRef<EquipmentFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Equipment,
    private apis: EstablishmentService,
  ) { }
  new_eq : Equipment;
  

  form: FormGroup = new FormGroup({
    'designation': new FormControl(Validators.required),
    'abbreviation': new FormControl(Validators.required),
    'reference': new FormControl(Validators.required),
    'description': new FormControl(Validators.required)

  })
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
   
  
  }




 

}
