import { Component, OnInit } from '@angular/core';
import { Menu } from '../menu/menu.model';
import { MenuService } from '../menu/menu.service';

@Component({
  selector: 'app-favorites',
  templateUrl: './favorites.component.html',
  styleUrls: ['./favorites.component.scss']
})
export class FavoritesComponent implements OnInit {
  toppings;
  toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  public menuItems;
  public favorites: Menu[] = [];
  constructor(public menuService: MenuService) { }

  ngOnInit() {
    this.menuItems = this.menuService.getHorizontalSubMenuItems().filter(menu => menu.routerLink != null || menu.href != null);

    if (localStorage.getItem('favorites')) {
      let favs = JSON.parse(localStorage.getItem('favorites'));
      // to get data checked as default , try to cast content as Menu Items ( type)
      favs.map((f) => {

        let m_fav = this.menuItems.find((m) => m.id == f.id)
        this.favorites.push(m_fav)
      })

    }




  }

  change() {
    console.log(this.favorites)
    localStorage.setItem('favorites', JSON.stringify(this.favorites))
  }

}
