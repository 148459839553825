export class GenerateSetting {
    nb_course_per_day : number = 3;
    nb_courses_basic_unity_per_day : number= 2;
    nb_courses_same_unity_per_day : number= 3; 
    nb_courses_transversal_per_day: number = 3;
    set_generation_priority_to : string = 'C';
    set_major_tp_on : string = 'varied'; // start - end - varied
    division_id : number;
    sectors_ids : number[];
    is_all_sectors : boolean = true;
    generate_vacant_sessions : boolean= true;
}
