<div fxLayout="row wrap" fxLayoutAlign="center center">
    <div *ngFor="let item of items" fxFlex="100" fxFlex.gt-sm="25" fxFlex.sm="50" class="p-2">
        <mat-card class="pricing-card text-center p-0">
            <h2 class="pricing-title my-3">{{item.name}}</h2>
            <div class="bg-accent pricing-header p-3" fxLayout="column" fxLayoutAlign="center center">   
                <h1>$ {{item.price}}<small> /mo</small></h1>
                <p class="desc mb-2">{{item.desc}}</p>
            </div>
            <div class="secondary-text-color p-3">
                <p class="py-2">{{item.count}} Items</p>
                <p class="py-2">{{item.storage}} Storage</p>
                <p class="py-2" [class.del]="!item.support">24/7 Support</p>
                <p class="py-2" [class.del]="!item.ssl">SSL Certificate</p>
                <button mat-raised-button color="primary" class="mt-3">Buy now</button>
            </div>
        </mat-card>
    </div>
</div>