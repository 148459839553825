<div mat-dialog-title><h1 >{{translate.instant('settings')}}</h1></div>
<div mat-dialog-content >
    <form fxLayout="column" [formGroup]="form"  >
        <mat-form-field class="pr-2 pl-2" >
            <input matInput type="number" placeholder="{{translate.instant('nb_course_per_day')}}" [(ngModel)]="data.nb_course_per_day"
                required [formControlName]="'nb_course_per_day'">
           
            
        </mat-form-field>
        <mat-form-field class="pr-2 pl-2" >
            <input matInput type="number" placeholder="{{translate.instant('nb_courses_basic_unity_per_day')}}" [(ngModel)]="data.nb_courses_basic_unity_per_day"
                required [formControlName]="'nb_courses_basic_unity_per_day'">
           
            
        </mat-form-field>
        <mat-form-field class="pr-2 pl-2" >
            <input matInput type="number" placeholder="{{translate.instant('nb_courses_same_unity_per_day')}}" [(ngModel)]="data.nb_courses_same_unity_per_day"
                required [formControlName]="'nb_courses_same_unity_per_day'">
           
            
        </mat-form-field>
        <mat-form-field class="pr-2 pl-2" >
            <input matInput type="number" placeholder="{{translate.instant('nb_courses_transversal_per_day')}}" [(ngModel)]="data.nb_courses_transversal_per_day"
                required [formControlName]="'nb_courses_transversal_per_day'">
           
            
        </mat-form-field>
        
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('set_generation_priority_to')" [formControlName]="'set_generation_priority_to'"
                [(ngModel)]="data.set_generation_priority_to" required>
                <mat-option *ngFor="let type of subject_types" [value]="type.abbreviation">
                    {{type.abbreviation}}</mat-option>
            </mat-select>

           
        </mat-form-field>
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('set_major_tp_on')" [formControlName]="'set_major_tp_on'"
                [(ngModel)]="data.set_major_tp_on" required>
                <mat-option *ngFor="let type of tp_major_sessions" [value]="type.slug">
                    {{type.value | translate}}</mat-option>
            </mat-select>

           
        </mat-form-field>

        <mat-form-field>
            <mat-select [placeholder]="translate.instant('division')" [formControlName]="'division_id'"
                [(ngModel)]="data.division_id" required>
                <mat-option *ngFor="let division of divisions" [value]="division.id">
                    {{division.abbreviation}}</mat-option>
            </mat-select>

           
        </mat-form-field>

        <mat-checkbox formControlName="is_all_sectors" [(ngModel)]="data.is_all_sectors">
            {{'is_all_sectors' | translate}}</mat-checkbox>
        <mat-form-field *ngIf="!data.is_all_sectors">
            <mat-select [placeholder]="'sectors' | translate" [formControlName]="'sectors_ids'"
                [(ngModel)]="data.sectors_ids" multiple>
                <mat-option *ngFor="let sector of sectors" [value]="sector.id">{{sector.abbreviation}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-checkbox formControlName="generate_vacant_sessions" [(ngModel)]="data.generate_vacant_sessions">
            {{'generate_vacant_sessions' | translate}}</mat-checkbox>

    </form>
</div>
<div mat-dialog-actions>
   
    <button  mat-raised-button color="basic"  class=" ums_basic_button form-cancel" (click)="onNoClick()">{{'buttons.no_thanks' | translate}}</button>
    <button  mat-raised-button color="primary" [disabled]="!form.valid" class="ums_primary_button form-save" [mat-dialog-close]="data" cdkFocusInitial>
     {{'buttons.generate' | translate}}
    </button>
</div>

