import { Component, OnInit } from '@angular/core';
import { SubjectType } from 'src/app/viewModels/subject-type';
import { MatTableDataSource } from '@angular/material/table';
import { SubjectService } from 'src/app/apis/subject.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2'

@Component({
  selector: 'app-subject-types',
  templateUrl: './subject-types.component.html',
  styleUrls: ['./subject-types.component.css']
})
export class SubjectTypesComponent implements OnInit {
  subject_types : SubjectType[];
  displayedColumns: string[] = ['designation', 'abbreviation' , 'actions'];
  dataSource ;
  selectedItem : any;
  
  constructor(
    private apis : SubjectService,
    public translate : TranslateService,
    private router : Router,
    private toastr : ToastrService

  ) { }
  
  new_subject_type : SubjectType = new SubjectType() ;
   show_form = false;

   form : FormGroup = new FormGroup({
    'designation' : new FormControl(Validators.required),
    'abbreviation' : new FormControl(Validators.required),

  })
  ngOnInit(): void {
    this.getData();
  }


  getData(){
    this.apis.getEstablishmentSubjectTypes()
    .subscribe((data : SubjectType[])=>{
      this.subject_types = data;
      this.dataSource = new MatTableDataSource<SubjectType>(this.subject_types);
    })
  }






 

 



  
  save(){
   
    this.apis.createOrUpdateSubjectType(this.new_subject_type)
    .subscribe((data)=>{
      this.getData()
      this.showSuccess('saved');
      this.new_subject_type = new SubjectType();
    })
  }

  edit(element){
    this.new_subject_type = element
  }

  remove(id){
    Swal.fire({
      title: this.translate.instant('alerts.delete_title'),
      text: this.translate.instant('alerts.delete_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('alerts.delete_yes'),
      cancelButtonText: this.translate.instant('alerts.delete_no')
    }).then((result) => {
      if (result.value) {
        this.apis.removeSubjectType(id)
        .subscribe(()=>{
          this.getData();
          Swal.fire(
            this.translate.instant('alerts.deleted'),
            this.translate.instant('alerts.deleted_text'),
            'success'
          )
        })
       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.translate.instant('alerts.canceled'),
          this.translate.instant('alerts.canceled_text'),
          'error'
        )
      }
    })
  }

  showSuccess(msg){

    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg){
    this.toastr.error(this.translate.instant((msg)))
  }

}
