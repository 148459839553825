import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { GenerateSetting } from '../viewModels/generate-setting';

@Injectable({
  providedIn: 'root'
})
export class TimingInstituteService {

  constructor(
    private http: HttpClient,
    private config: ConfigService) { }





  /* Departments */

  getHiararchyPeriods() {

    return this.http.get(this.config.baseUrl + '/periods', this.config.getOptions());
  }



  getPeriodById(id) {

    return this.http.get(this.config.baseUrl + '/period/' + id, this.config.getOptions());
  }
  setPeriod(body: any) {

    return this.http.post(this.config.baseUrl + '/period', body, this.config.getOptions());
  }
  
  removePeriod(id) {
    return this.http.delete(this.config.baseUrl + '/period/' + id, this.config.getOptions());
  }


  getSectionTimeTable(section_id , semestre_id) {
    console.log(this.config.establishment_id)
    return this.http.get(this.config.baseUrl + '/inst/section/timetable/' + section_id +'/'+semestre_id, this.config.getOptions());
  }
  getSectionGroupTpTimeTable(group_id , semestre_id) {
    console.log(this.config.establishment_id)
    return this.http.get(this.config.baseUrl + '/inst/section/tp/timetable/' + group_id +'/'+semestre_id, this.config.getOptions());
  }
  getSectionGroupTdTimeTable(group_id , semestre_id) {
    console.log(this.config.establishment_id)
    return this.http.get(this.config.baseUrl + '/inst/section/td/timetable/' + group_id +'/'+semestre_id, this.config.getOptions());
  }






  getSectionPending(section_id , semestre_id){
    
    return this.http.get(this.config.baseUrl + '/inst/section/pending/' + section_id+'/'+semestre_id, this.config.getOptions());

  }
  getTeacherTimeTable(teacher_id , division_id){
    return this.http.get(this.config.baseUrl + '/inst/teacher/timetable/'+teacher_id +'/'+division_id, this.config.getOptions());
  }
  getTeacherPendingTimeTable(teacher_id , division_id){
    return this.http.get(this.config.baseUrl + '/inst/teacher/pending/'+teacher_id +'/'+division_id, this.config.getOptions());
  }


  

  getClassroomsSchedule(block_id , division_id){
    return this.http.get(this.config.baseUrl + '/inst/block/schedule/'+block_id +'/'+division_id, this.config.getOptions());

  }


   generateTimeTable(body : GenerateSetting){
   
    return this.http.post(this.config.baseUrl+'/inst/timetable/generate' , body, this.config.getOptions() )
  } 

 /*  generateEcoleTimeTable(body : GenerateSetting){
   console.log(body)
    return this.http.post(this.config.baseUrl+'/timetable/ecole/generate' , body, this.config.getOptions() )
  } */

 

  checkDropSession(body : any){
    return this.http.put(this.config.baseUrl+'/inst/timetable/drop' , body ,this.config.getOptions() )
  }
  checkDropPendingSession(body : any){
    return this.http.put(this.config.baseUrl+'/inst/timetable/droppending' , body ,this.config.getOptions() )
  }
  checkSession(body : any){
    return this.http.post(this.config.baseUrl+'/inst/timetable/check' , body ,this.config.getOptions() )
  }

  checkPendingSession(body : any){
    return this.http.post(this.config.baseUrl+'/inst/timetable/checkpending' , body ,this.config.getOptions() )
  }
  checkSessionTeacher(body : any){
    return this.http.post(this.config.baseUrl+'/inst/timetable/checkteacher' , body ,this.config.getOptions() )
  }
  getAvailableClassrooms(body : any){
    return this.http.post(this.config.baseUrl+'/inst/timetable/availableclassrooms' , body ,this.config.getOptions() )
  }


  generateExamsSchedule(body : GenerateSetting){
    return this.http.post(this.config.baseUrl+'/exams/generate' , body, this.config.getOptions() )
  }
  getSectorExamsSchedule(sector_id , vacation_exam_id) {
    return this.http.get(this.config.baseUrl + '/exams/sector/'+ sector_id +'/'+vacation_exam_id, this.config.getOptions());
  }


  generateMonotoringSchedule(){
    return this.http.post(this.config.baseUrl + '/monotoring/generate', this.config.getOptions());
  }

  getMonotingSchedule(vacation_exam_id){
    return this.http.get(this.config.baseUrl + '/monotoring/exams/'+vacation_exam_id, this.config.getOptions());

  }
  




}
