<app-content-header 

    [hideBreadcrumb]="true"
    [class]="'py-3'"
    [target]="'subjectsAndTeachers'"></app-content-header>

<div fxLayout="row wrap" fxLayoutAlign="center" class="p-2">
    <div fxFlex="100" class="p-2">
        <p>Your content here.</p>
    </div>
</div>