import { Component, OnInit } from '@angular/core';
import { TimingService } from '../apis/timing.service';
import { Period } from '../viewModels/period';

@Component({
  selector: 'app-print-teacher-timetable',
  templateUrl: './print-teacher-timetable.component.html',
  styleUrls: ['./print-teacher-timetable.component.css']
})
export class PrintTeacherTimetableComponent implements OnInit {

  constructor(private api : TimingService) { }

  data=[]
  periods = []

  ngOnInit(): void {


    this.api.printTeachers(2).subscribe(
      (data : any[])=>{
  this.data = data
        console.log(data)
        setTimeout(() => {
          window.print()
        }, 1);
      }
    )

    this.api.getHiararchyPeriods()
    .subscribe((data: Period[]) => {
      this.periods = data.filter((x) => x.type == 'session').sort((a, b) => a.start_hour > b.start_hour ? 0 : -1);

    })
  }

}
