import { Component, OnInit , Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/apis/config.service';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { SubjectService } from 'src/app/apis/subject.service';
import { TimingService } from 'src/app/apis/timing.service';
import { VacationExam } from 'src/app/viewModels/vacation-exam';

@Component({
  selector: 'app-exams-update-dialog',
  templateUrl: './exams-update-dialog.component.html',
  styleUrls: ['./exams-update-dialog.component.css']
})
export class ExamsUpdateDialogComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private config : ConfigService,
    private establishmentApis : EstablishmentService,
    public dialogRef: MatDialogRef<ExamsUpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apis: TimingService,
    private settingsApis : SettingsService,
    private hierarchyApis : HiararchyService,
    private subjectApis : SubjectService
  ) { }

 /*  subject_types : SubjectType[];
  divisions : any[];
  sectors : Sector[];
periods_types : any[];

nb_exam_per_day : number = 1;
   
tp_major_sessions = this.config.tp_major_sessions
selectedExamPeriod
all_dates : any[]
examsperiods : VacationExam[] */
  form: FormGroup = new FormGroup({
    'id': new FormControl(Validators.required),
    'start': new FormControl(Validators.required),
    'end': new FormControl(Validators.required),
    'date': new FormControl(Validators.required),
    'vacation_exam_id' : new FormControl()
  })
  onNoClick(): void {
    this.dialogRef.close();
  }
  all_dates = []

  ngOnInit() {
console.log(this.data)
    this.establishmentApis.getVacationExamById(this.data.vacation_exam_id)
    .subscribe((data:  VacationExam) => {
      this.all_dates  =this.getDates(new Date(data.start_date) , new Date(data.end_date));

      
    })
    
  }

  
  
  getDates(startDate,  stopDate) {
    console.log(startDate , stopDate)
      let dateArray = new Array();
      let currentDate : Date = startDate;
      while (currentDate <= stopDate) {
        let y = currentDate.getFullYear();
        let m = currentDate.getMonth()+1;
        let d = currentDate.getDate();
          dateArray.push(y+'-'+(m>9?'':'0')+(currentDate.getMonth()+1)+'-'+(d>9?'':'0')+currentDate.getDate());
        
         currentDate.setDate(currentDate.getDate()+1);
      }
      return dateArray;
  }

}
