<app-content-header     
    [title]="'Chat'" 
    [hideBreadcrumb]="false"
    [hasBgImage]="true" 
    [class]="'pb-5'"></app-content-header>
    <div fxLayout="column" class="p-2">
        <div class="p-2">
        
            <mat-card-content>
                <mat-expansion-panel>
                    <mat-expansion-panel-header>
                        <mat-panel-title>{{'create_new_ticket' | translate}}</mat-panel-title>
                    </mat-expansion-panel-header>  
                    <form fxLayout="row" [formGroup]="form" (ngSubmit)="save()">                 
                    <mat-form-field>
                        <input matInput placeholder="{{'subject' | translate}}"   [formControlName]="'subject'">
                    </mat-form-field>                    
                    <mat-form-field>
                        <input matInput placeholder="{{'content' | translate}}"   [formControlName]="'content'">
                    </mat-form-field>
                    <div fxFlex="10">
                        <button  [disabled]="!form.valid" mat-raised-button color="primary" class="ums_primary_button" [style.float]="'inverse_float' | translate" >
                            <mat-icon class="fs20">check</mat-icon> {{'buttons.save' | translate}}
                          </button>
                    </div>
                    </form>
                </mat-expansion-panel>
            </mat-card-content>
        </div>
        </div>
  


<div fxLayout="row wrap">
    <div fxFlex="100"> 
        <mat-card class="p-0 chat">
            <mat-drawer-container class="chat-container">
                <mat-drawer #sidenav [opened]="sidenavOpen" [mode]="sidenavOpen ? 'side' : 'over'" class="chat-sidenav mat-elevation-z1" autoFocus="false">
                    <mat-nav-list class="p-0 chat-sidenav-list" perfectScrollbar>
                      
                        <mat-list-item *ngFor="let ticket of tickets" (click)="getChat(ticket)">
                          
                            <img matListAvatar [src]="ticket.status == 'new' ? 'assets/error.png' : 'assets/done.png'">
                             <h6 matLine> {{ticket.subject}} </h6>
                             <p matLine fxLayout="row" fxLayoutAlign="none center" class="secondary-text-color" style="min-width: 200px;">
 
                              
                                {{ticket.created_at | date:"dd MMMM, yyyy 'at' HH:mm"}}
                             </p>
                         </mat-list-item>
                    </mat-nav-list>
                </mat-drawer>
                
                <div>
                    <div class="chat-content"  [class.actions-visible]="responses" perfectScrollbar>
                        <mat-toolbar color="primary" fxLayout="row" fxLayoutAlign="space-between center" class="px-2 chat-toolbar">
 
                            <div fxLayout="row"  *ngIf="currentChat" style="width: 100%;">
                            

                                <button mat-menu-item color="success" (click)="closeTicket()" style="text-align: right;">
                                    <mat-icon>check</mat-icon>
                                    <span>{{'close_ticket'| translate}}</span>
                                </button>


                               <!--  <button mat-icon-button [matMenuTriggerFor]="personMenu" #personMenuTrigger="matMenuTrigger">
                                    <mat-icon>more_vert</mat-icon>
                                </button>
                                <mat-menu #personMenu="matMenu" xPosition="before">
                                    <span (mouseleave)="personMenuTrigger.closeMenu()">
                                       <button mat-menu-item (click)="personInfo.toggle()">
                                            <mat-icon>account_circle</mat-icon>
                                            <span>Contact info</span>
                                        </button> 
                                        <button mat-menu-item (click)="closeTicket()">
                                            <mat-icon>check</mat-icon>
                                            <span>{{'close_ticket'| translate}}</span>
                                        </button>
                                    </span>
                                </mat-menu> -->
                            </div>
                        </mat-toolbar>
                        <mat-list *ngIf="responses" class="p-0" >
                            <mat-list-item class="response-item">
                                <img matListAvatar [src]="storage_name+currentChat.user?.avatar">
                                <p matLine class="message">
                                    <span [ngClass]="(user.id != currentChat.user_id) ? 'bg-primary' : 'bg-accent'">{{currentChat.content}}</span>
                                </p>                             
                                <p matLine class="message-date">
                                    <small>{{currentChat.created_at | date:"dd MMMM, yyyy 'at' HH:mm"}}</small>
                                </p> 
                            </mat-list-item>
                            <mat-list-item *ngFor="let response of responses" class="response-item">
                                <img matListAvatar [src]="storage_name+response.user?.avatar">
                                <p matLine class="message">
                                    <span [ngClass]="(user.id != response.user_id) ? 'bg-primary' : 'bg-accent'">{{response.content}}</span>
                                </p>                             
                                <p matLine class="message-date">
                                    <small>{{response.created_at | date:"dd MMMM, yyyy 'at' HH:mm"}}</small>
                                </p> 
                            </mat-list-item>
                        </mat-list>
                        <div *ngIf="!responses" fxLayout="column" fxLayoutAlign="center center" class="h-100 empty">
                            <mat-icon>chat</mat-icon>            
                            <p>Select a interlocutor for response</p>
                        </div> 
                    </div>
                    <mat-card *ngIf="responses" class="chat-actions"> 
                        <div fxLayout="row" fxLayoutAlign="space-between center">
                            <mat-form-field class="w-100">
                                <input matInput placeholder="Enter your text..." (keyup)="sendMessage($event)" [(ngModel)]="newMessage">
                            </mat-form-field>
                            <div fxLayout="row" fxLayoutAlign="scenter center">
                                <button mat-icon-button type="button">
                                    <mat-icon>attach_file</mat-icon>
                                </button>
                                <button mat-mini-fab color="primary" type="button" (click)="sendMessage($event)">
                                    <mat-icon>send</mat-icon>
                                </button>
                            </div>
                            </div>
                    </mat-card>
                </div>

                <mat-drawer #personInfo mode="over" position="end" class="person-info-sidenav mat-elevation-z1" autoFocus="false">
                    <div class="p-0 h-100" perfectScrollbar>

                        <div class="bg-primary w-100 text-center"> 
                            <div fxLayout="row" fxLayoutAlign="space-between center" class="p-2">
                                <h2>Contact Info</h2>
                                <button mat-icon-button (click)="personInfo.toggle()"><mat-icon>close</mat-icon></button>
                            </div>  
                            <img *ngIf="currentChat" [src]="storage_name+currentChat.user?.avatar" class="py-2">
                            <h2 *ngIf="currentChat" class="pb-3">{{currentChat.user_id}}</h2>
                        </div> 
                        <mat-list>
                            <mat-list-item>
                                <mat-icon matListIcon class="secondary-text-color">mail_outline</mat-icon>
                                <h3 matLine>author@gmail.com</h3>
                            </mat-list-item>
                            <mat-list-item>
                                <mat-icon matListIcon class="secondary-text-color">call</mat-icon>
                                <h3 matLine>(202) 756-9756</h3>
                            </mat-list-item>
                            <mat-list-item>
                                <mat-icon matListIcon class="secondary-text-color">location_on</mat-icon>
                                <h3 matLine>2903 Avenue Z, Brooklyn, NY</h3>
                            </mat-list-item>
                        </mat-list>                       
                    </div>
                </mat-drawer>
            
            </mat-drawer-container>
        </mat-card>
      </div>
</div>
