<button mat-icon-button [matMenuTriggerFor]="flagsMenu" #flagsMenuTrigger="matMenuTrigger">
    <mat-icon>flag</mat-icon>
</button>
<!--<button mat-icon-button [matMenuTriggerFor]="flagsMenu" #flagsMenuTrigger="matMenuTrigger">
    <img src="assets/img/flags/gb.svg" width="20" alt="english"> 
</button>-->
<mat-menu #flagsMenu="matMenu" [overlapTrigger]="false" class="toolbar-dropdown-menu flags">
    <span (mouseleave)="flagsMenuTrigger.closeMenu()">  
        <button mat-menu-item (click)="changeLanguage('en')">
            <img src="assets/img/flags/gb.svg" width="20" alt="english"> 
            English
        </button>
        
        <button mat-menu-item (click)="changeLanguage('fr')">
            <img src="assets/img/flags/fr.svg" width="20" alt="french"> 
            Français
        </button>
        <button mat-menu-item (click)="changeLanguage('ar')">
            <img src="assets/img/flags/ar.png" width="20" alt="arabic"> 
            عربي
        </button>
        
    </span>
</mat-menu>