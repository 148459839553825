import { Component, OnInit, HostListener, ViewChild } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { config } from 'rxjs';
import { ConfigService } from 'src/app/apis/config.service';
import { TicketService } from 'src/app/apis/ticket.service';
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';
import { Chat } from './chat.model';
import { ChatService } from './chat.service';

@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss'],
  providers: [ ChatService ]
})
export class ChatComponent implements OnInit {
  @ViewChild('sidenav') sidenav: any;
  public settings: Settings;
  public tickets: any[];
  public responses: any[];
  public sidenavOpen:boolean = true;
  public currentChat:any;
  public newMessage:string;

  constructor(public appSettings:AppSettings,
    private toastr : ToastrService, public translate : TranslateService,
    private api:TicketService , private config : ConfigService) { 
    this.settings = this.appSettings.settings; 
  }

  user = JSON.parse(localStorage.getItem('user-infos'));
  storage_name=this.config.storage_name
  ngOnInit() {
    this.api.getUserTickets().subscribe((data : any[])=>{
      this.tickets = data;
    }); 

    if(window.innerWidth <= 768){
      this.sidenavOpen = false;
    }    
  } 

  @HostListener('window:resize')
  public onWindowResize():void {
    (window.innerWidth <= 768) ? this.sidenavOpen = false : this.sidenavOpen = true;
  }
  form : FormGroup = new FormGroup({
    'user_id' : new FormControl(this.user.id),
    'establishment_id' : new FormControl(this.user.establishment_id),
    'subject' : new FormControl( '',Validators.required),
    'content' : new FormControl( '',Validators.required),
    'status' : new FormControl('new')

  })
  public getChat(ticket){
      
    this.responses = ticket.responses
  //this.responses.unshift(ticket)
    this.currentChat = ticket;      
    
    if(window.innerWidth <= 768){
      this.sidenav.close();
    }     


  }

  save(){
    console.log(this.form.value)
    this.api.createTicket(this.form.value)
    .subscribe((data)=>{
      this.ngOnInit();
      this.showSuccess('saved');
    })
  }
  public sendMessage($event) {       
     if (($event.which === 1 || $event.which === 13) && this.newMessage.trim() != '') {
       if(this.newMessage){
         let new_response={
           user_id : this.user.id,
           ticket_id : this.currentChat.id,
           content : this.newMessage,
           user : this.user
         }
         if(!this.responses){
           this.responses = [];
         }

         this.api.createTicketResponse(new_response)
         .subscribe(()=>{})

         this.responses.push(new_response)

       }
    
        this.newMessage = '';
      /*   let chatContainer = document.querySelector('.chat-content');
        if(chatContainer){
          setTimeout(() => {
            var nodes = chatContainer.querySelectorAll('.mat-list-item');
            let newChatTextHeight = nodes[nodes.length- 1];
            chatContainer.scrollTop = chatContainer.scrollHeight + newChatTextHeight.clientHeight;
          }); 
        } */
      
    } 
  }

  public ngOnDestroy(){
  /*   if(this.talks)
      this.talks.length = 2; */
  }


  showSuccess(msg){

    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg){
    this.toastr.error(this.translate.instant((msg)))
  }


  closeTicket(){
    console.log(this.currentChat)
     this.api.closeTicket(this.currentChat.id)
    .subscribe((data)=>{
       console.log(data)
      this.showSuccess('saved')
      this.ngOnInit() 
    }) 
  }
}