
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { GroupTd } from 'src/app/viewModels/group-td';
import { Section } from 'src/app/viewModels/section';
import { Sector } from 'src/app/viewModels/sector';

@Component({
  selector: 'app-group-td-form',
  templateUrl: './group-td-form.component.html',
  styleUrls: ['./group-td-form.component.css']
})
export class GroupTdFormComponent implements OnInit {


  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<GroupTdFormComponent>,

    @Inject(MAT_DIALOG_DATA) public data:GroupTd,
    private apis: HiararchyService,
  ) { }

  sectors : Sector[];
  sections: Section[] = [];

  form: FormGroup = new FormGroup({
    'designation': new FormControl(Validators.required),
    'abbreviation': new FormControl(Validators.required),
    'section_id': new FormControl(Validators.required),
    'sector_id': new FormControl(Validators.required)
  })
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    
    this.apis.getHiararchySectors()
    .subscribe((data : Sector[])=>{
      this.sectors = data;
      // in case of update
      if(this.data.id){
        this.data.sector_id = this.data.section?.sector_id;
        this.changeSector();
    }
    })

      //get sections
  }

  changeSector(){
    if(this.data.sector_id){
      this.apis.getSectorSections(this.data.sector_id)
      .subscribe((data : Section[])=>{
        this.sections = data;
        
      })
    }else{
      this.sections = [];
      this.data.section_id = undefined;
    }
  }

}
