import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/apis/config.service';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { SubjectService } from 'src/app/apis/subject.service';
import { TeacherService } from 'src/app/apis/teacher.service';
import { TimingService } from 'src/app/apis/timing.service';
import { Classroom } from 'src/app/viewModels/classroom';
import { Sector } from 'src/app/viewModels/sector';
import { SubjectType } from 'src/app/viewModels/subject-type';
import { Teacher } from 'src/app/viewModels/teacher';

@Component({
  selector: 'app-update-dialog',
  templateUrl: './update-dialog.component.html',
  styleUrls: ['./update-dialog.component.css']
})
export class UpdateDialogComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private config: ConfigService,
    public dialogRef: MatDialogRef<UpdateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apis: TimingService,
    private settingsApis: SettingsService,
    private establishmentApis: EstablishmentService,
    private subjectApis: SubjectService,
    private hierarchyApis : HiararchyService,
    private teacherApis: TeacherService
  ) { }

  subject_types: SubjectType[];
  teachers: Teacher[];
  divisions: any[];
  sectors: Sector[];
  classrooms: Classroom[];
  periods_types: any[];
  tp_major_sessions = this.config.tp_major_sessions

  allsections = []
  form: FormGroup = new FormGroup({
    'teacher_id': new FormControl(Validators.required),
    'classroom_id': new FormControl(Validators.required),
    'start_hour': new FormControl(),
    'end_hour': new FormControl(),
    'section2_id' : new FormControl()
  })
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    console.log(this.data)
    //this.allsections = this.data.sections
    //this.getSubjectTypes();
    //this.getYearDivisions();
    this.getSections();
    this.getTeachers();
    this.getClassrooms();
  }

  getTeachers() {
    this.teacherApis.getEstablishmentTeachers()
      .subscribe((data: Teacher[]) => {
        this.teachers = data;
      })
  }
  getClassrooms() {
    let result = this.data
    let ss_body = { target_day_id: result.day_id, target_period_id: result.period_id, session_id: result.id , subject_type : result.subject_type.abbreviation};
        console.log(ss_body)
    this.apis.getAvailableClassrooms(ss_body)
      .subscribe((data: Classroom[]) => {
        console.log(data)
        this.classrooms = data
        if(!this.classrooms.find((x)=> x.id == this.data.classroom_id)){
          this.classrooms.unshift(this.data.classroom)
        }
      }, (err)=>{
        console.log(err)
      })
  }
 /*  getSubjectTypes() {

    this.subjectApis.getEstablishmentSubjectTypes()
      .subscribe((data: SubjectType[]) => {
        this.subject_types = data.filter((x) => x.abbreviation != 'TD');
      })
  }

  getYearDivisions() {
    this.settingsApis.getYearDivisions()
      .subscribe((data: any[]) => {
        this.divisions = data;
      })
  }
*/
  getSections() {
    this.hierarchyApis.establishmentSections()
      .subscribe((data: any[]) => {
        this.allsections = data;
      })
  }

}
