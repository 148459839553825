<div mat-dialog-title>
    <h1>{{translate.instant('settings')}}</h1>
</div>
<div mat-dialog-content>
    <form fxLayout="column" [formGroup]="form">
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('division')" [formControlName]="'year_division_id'"
                [(ngModel)]="data.year_division_id" required>
                <mat-option *ngFor="let division of divisions" [value]="division.id">
                    {{division.abbreviation}}</mat-option>
            </mat-select>

           
        </mat-form-field>
        <mat-form-field class="pr-2 pl-2">
            <input matInput type="number" placeholder="{{translate.instant('nb_exam_per_day')}}"
                [(ngModel)]="data.nb_exam_per_day" required [formControlName]="'nb_exam_per_day'">


        </mat-form-field>
        
        <mat-form-field class="pr-2 pl-2">

            <mat-select [placeholder]="translate.instant('ExamsType')" [(ngModel)]="selectedExamPeriod"
                [formControlName]="'exam_period_id'" (selectionChange)="handleExamPeriodChange()">
                <mat-option *ngFor="let exam_data of examsperiods" [value]="exam_data">
                    {{exam_data.abbreviation}}</mat-option>
            </mat-select>

        </mat-form-field>
        
        <mat-form-field class="pr-2 pl-2">
            <mat-select [placeholder]="'schedule_dates' | translate" [formControlName]="'schedule_dates'"
                [(ngModel)]="data.schedule_dates" multiple>
                <mat-option *ngFor="let date of all_dates" [value]="date">{{date | date}}</mat-option>
            </mat-select>



        </mat-form-field>

        <mat-form-field class="pr-2 pl-2">
            <input matInput type="time" placeholder="{{translate.instant('period1_start_time')}}"
                [(ngModel)]="data.period1_start_time" required [formControlName]="'period1_start_time'">


        </mat-form-field>
        <mat-form-field class="pr-2 pl-2">
            <input matInput type="time" placeholder="{{translate.instant('period1_end_time')}}"
                [(ngModel)]="data.period1_end_time" required [formControlName]="'period1_end_time'">


        </mat-form-field>


        <mat-form-field class="pr-2 pl-2" *ngIf="data.nb_exam_per_day == 2">
            <input matInput type="time" placeholder="{{translate.instant('period2_start_time')}}"
                [(ngModel)]="data.period2_start_time" required [formControlName]="'period2_start_time'">


        </mat-form-field>
        <mat-form-field class="pr-2 pl-2" *ngIf="data.nb_exam_per_day == 2">
            <input matInput type="time" placeholder="{{translate.instant('period2_end_time')}}"
                [(ngModel)]="data.period2_end_time" required [formControlName]="'period2_end_time'">


        </mat-form-field>

        <mat-checkbox formControlName="is_all_sectors" [(ngModel)]="data.is_all_sectors">
            {{'is_all_sectors' | translate}}</mat-checkbox>
        <mat-form-field *ngIf="!data.is_all_sectors">
            <mat-select [placeholder]="'sectors' | translate" [formControlName]="'sectors_ids'"
                [(ngModel)]="data.sectors_ids" multiple>
                <mat-option *ngFor="let sector of sectors" [value]="sector.id">{{sector.abbreviation}}</mat-option>
            </mat-select>
        </mat-form-field>



    </form>
</div>
<div mat-dialog-actions>

    <button mat-raised-button color="basic" class=" ums_basic_button form-cancel"
        (click)="onNoClick()">{{'buttons.no_thanks' | translate}}</button>
    <button mat-raised-button color="primary" [disabled]="!form.valid" class="ums_primary_button form-save"
        [mat-dialog-close]="data" cdkFocusInitial>
        {{'buttons.generate' | translate}}
    </button>
</div>