import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { Section } from 'src/app/viewModels/section';
import { Sector } from 'src/app/viewModels/sector';

@Component({
  selector: 'app-section-form',
  templateUrl: './section-form.component.html',
  styleUrls: ['./section-form.component.css']
})
export class SectionFormComponent implements OnInit {

  
  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<SectionFormComponent>,

    @Inject(MAT_DIALOG_DATA) public data: Section,
    private apis: HiararchyService,
  ) { }

  sectors: Sector[] = [];

  form: FormGroup = new FormGroup({
    'designation': new FormControl(Validators.required),
    'abbreviation': new FormControl(Validators.required),
    'sector_id': new FormControl()
  })
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
   
    this.apis.getHiararchySectors()
    .subscribe((data : Sector[])=>{
      this.sectors = data;
     
    })
      //get sections
  }

}
