import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/apis/config.service';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { AnnualCalendar } from 'src/app/viewModels/annual-calendar';
import { WeekType } from 'src/app/viewModels/week-type';

@Component({
  selector: 'app-week-type-form',
  templateUrl: './week-type-form.component.html',
  styleUrls: ['./week-type-form.component.css']
})
export class WeekTypeFormComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private config : ConfigService,
    public dialogRef: MatDialogRef<WeekTypeFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: WeekType,
    private apis: EstablishmentService,
  ) { }

  week_types : any[];
  min_date : Date;
  max_date : Date;
  form: FormGroup = new FormGroup({
    'designation': new FormControl(Validators.required),
    'week1': new FormControl(Validators.required),
    'week2': new FormControl(Validators.required),
    'start_date': new FormControl(Validators.required),
    'end_date': new FormControl(Validators.required),
    'type': new FormControl(Validators.required)

  })
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.apis.getEstablishmentCurrentAnnualCalendar()
    .subscribe((data : AnnualCalendar)=>{
      this.min_date = new Date(data.start_date);
      this.max_date = new Date(data.end_date);

    })
   this.week_types = this.config.week_types;
  }

}
