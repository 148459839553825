<app-content-header [hideBreadcrumb]="true" [class]="'py-3'" [target]="'subjectsAndTeachers'"></app-content-header>

<mat-card>
    <mat-card-content>
        <form fxLayout="row"  [formGroup]="form" (ngSubmit)="save()">
            <mat-form-field class="pr-2 pl-2" fxFlex="55">
                <input matInput placeholder="{{'designation' | translate}}" [(ngModel)]="new_subject_type.designation"
                    required [formControlName]="'designation'">
               
                
            </mat-form-field>

            <mat-form-field  class="pr-2 pl-2" fxFlex="35">
                <input matInput placeholder="{{'abbreviation' | translate}}" [(ngModel)]="new_subject_type.abbreviation"
                    required [formControlName]="'abbreviation'">
                
            </mat-form-field>
            <div fxFlex="10">
                <button  [disabled]="!form.valid" mat-raised-button color="primary" class="ums_primary_button" [style.float]="'inverse_float' | translate" >
                    <mat-icon class="fs20">check</mat-icon> {{'buttons.save' | translate}}
                  </button>
              <!-- 
                <button  [disabled]="!form.valid"  [style.float]="'inverse_float' | translate" class="button-add"
                type="submit">
                <mat-icon>check</mat-icon>
            </button> -->
            </div>
            
        </form>

    </mat-card-content>
</mat-card>
<div fxLayout="row wrap" fxLayoutAlign="center" class="p-2">
    <div fxFlex="100" class="p-2">

        <div class="mat-elevation-z8">
            <table mat-table style="width: 100%;" [dataSource]="dataSource">

                <!-- Position Column -->
                <ng-container matColumnDef="designation">
                    <th mat-header-cell *matHeaderCellDef> {{'designation' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.designation}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="abbreviation">
                    <th mat-header-cell *matHeaderCellDef> {{'abbreviation' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.abbreviation}} </td>
                </ng-container>
              

                <ng-container matColumnDef="actions" justify="end" >
                    <th mat-header-cell *matHeaderCellDef [style.text-align]="'inverse_float' | translate"> {{'actions' | translate}} </th>
                    <td mat-cell *matCellDef="let element" [style.text-align]="'inverse_float' | translate">
                        <mat-icon class="warning" (click)="edit(element)">edit</mat-icon>
                        <mat-icon class="danger" (click)="remove(element.id)">delete_outline</mat-icon>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>

</div>