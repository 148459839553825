import { Component, OnInit } from '@angular/core';
import { FormControl, Validators, FormGroup } from '@angular/forms';
import { Establishment } from 'src/app/viewModels/establishment';
import { EstablishmentSetting } from 'src/app/viewModels/establishment-setting';
import { EstablishmentService } from 'src/app/apis/establishment.service';

import { ToastrService } from "ngx-toastr";
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { Period } from 'src/app/viewModels/period';
import { Day } from 'src/app/viewModels/day';
import { TimingService } from 'src/app/apis/timing.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { ConfigService } from 'src/app/apis/config.service';
import { NotAvailableEstablishment } from 'src/app/viewModels/not-available-establishment';

@Component({
  selector: 'app-establishment',
  templateUrl: './establishment.component.html',
  styleUrls: ['./establishment.component.css']
})
export class EstablishmentComponent implements OnInit {

  periods: Period[]

  days: Day[];

  filtred_days: Day[];
  all_languages = [
    {
      id: "1",
      designation: 'Français',
      abbreviation: 'fr'
    },
    {
      id: "2",
      designation: 'English',
      abbreviation: 'en'
    }
  ]
  establishment: Establishment = new Establishment();
  establishment_setting: EstablishmentSetting = new EstablishmentSetting()
  constructor(
    private apis: EstablishmentService,
    private timingService: TimingService,
    private settingService: SettingsService,
    public translate: TranslateService,
    private config: ConfigService,
    private toastr: ToastrService) { }
  host_name = this.config.storage_name;
  form: FormGroup = new FormGroup({
    'designation': new FormControl(Validators.required),
    'abbreviation': new FormControl(Validators.required),
    'logo': new FormControl(Validators.required),
    'use_group_td': new FormControl(Validators.required),
    'session_numbers': new FormControl(Validators.required),
    'standard_duration': new FormControl(Validators.required),
    'cours_best_sessions': new FormControl(Validators.required),
    'td_best_sessions': new FormControl(Validators.required),
    'tp_best_sessions': new FormControl(Validators.required),
    'weekend_days': new FormControl(Validators.required),
    'language': new FormControl(Validators.required)

  });
  ngOnInit(): void {
    this.getdata()
  }

  avoided_sessions : NotAvailableEstablishment[];
  closed_sessions : NotAvailableEstablishment[];
  async getdata() {
    await this.settingService.getDays()
      .subscribe((data: Day[]) => {
        this.days = data;

      })
    this.timingService.getHiararchyPeriods()
      .subscribe((data: Period[]) => {
        this.periods = data.filter((x) => x.type == 'session');
      })
    this.apis.getEstablishmentById()
      .subscribe((data: Establishment) => {
        this.establishment = data


      }, err => {
      })


    this.apis.getEstablishmentSettings()
      .subscribe((data: EstablishmentSetting) => {
        if (data.establishment_id) {
          this.establishment_setting = data
          console.log(this.establishment_setting);
          this.filtred_days = this.days.filter((x: any) => JSON.stringify(this.establishment_setting.weekend_days).indexOf(x.id) == -1)

        }
      }, err => {
      })


      this.apis.getEstablishmentAvailibility()
     .subscribe((data : NotAvailableEstablishment[])=>{
       if(data && data.length >0){
         this.avoided_sessions = data.filter((x)=> x.type == 'avoided')
         this.closed_sessions = data.filter((x)=> x.type == 'closed')
       }
       else{
         this.avoided_sessions = [];
         this.closed_sessions = [];
       }
     })
  }



  update() {
    console.log(this.establishment_setting)
    this.apis.createOrUpdateEstablishmentSettings(this.establishment_setting)
      .subscribe((data: EstablishmentSetting) => {
        console.log(data);
        this.establishment_setting = data;
        this.establishment_setting.weekend_days = JSON.parse(this.establishment_setting.weekend_days + '')
        this.establishment_setting.tp_best_sessions = JSON.parse(this.establishment_setting.tp_best_sessions + '')
        this.establishment_setting.td_best_sessions = JSON.parse(this.establishment_setting.td_best_sessions + '')
        this.establishment_setting.cours_best_sessions = JSON.parse(this.establishment_setting.cours_best_sessions + '')
        console.log(this.establishment_setting);
        this.filtred_days = this.days.filter((x: any) => JSON.stringify(this.establishment_setting.weekend_days).indexOf(x.id) == -1)

        this.showSuccess(this.translate.instant('toastr.success_update'))
      })
  }
  IsClosedChecked( day_id , session_id){
    if(this.closed_sessions){
      if(this.closed_sessions.find(x=> x.day_id == day_id && x.period_id == session_id)){
        return true
      }else{
        return false
      }
    }else{
      return false
    }
  }

  IsAvoidedChecked( day_id , session_id){
    if(this.avoided_sessions){
      if(this.avoided_sessions.find(x=> x.day_id == day_id && x.period_id == session_id)){
        return true
      }else{
        return false
      }
    }else{
      return false
    }
  }


  checkClosedSessionDay(status , day_id , session_id){
    console.log(status);
    if(status){
      console.log("true")
      if(!this.closed_sessions.find(x=> x.day_id == day_id && x.period_id == session_id)){
        let r : NotAvailableEstablishment = new NotAvailableEstablishment();
        r.day_id = day_id;
        r.period_id = session_id;
        r.type = 'closed'
        this.closed_sessions.push(r)
      } 
    }else{
      console.log("false")
     this.closed_sessions =  this.closed_sessions.filter(x => !(x.day_id == day_id && x.period_id == session_id))
    }
  }

  checkAvoidedSessionDay(status , day_id , session_id){
    console.log(status);
    if(status){
      console.log("true")
      if(!this.avoided_sessions.find(x=> x.day_id == day_id && x.period_id == session_id)){
        let r : NotAvailableEstablishment = new NotAvailableEstablishment();
        r.day_id = day_id;
        r.period_id = session_id;
        r.type = 'avoided'
        this.avoided_sessions.push(r)
      } 
    }else{
      console.log("false")
     this.avoided_sessions =  this.avoided_sessions.filter(x => !(x.day_id == day_id && x.period_id == session_id))
    }
  }


  updateAvoided(){

    this.apis.setEstablishmentAvoidedAvailibility(this.avoided_sessions)
    .subscribe((data)=>{
      this.showSuccess(this.translate.instant('toastr.success_update'))
    })
  }


  updateClosed(){
    this.apis.setEstablishmentClosedAvailibility(this.closed_sessions)
    .subscribe((data)=>{
      this.showSuccess(this.translate.instant('toastr.success_update'))
    })
  }


  showSuccess(msg) {

    this.toastr.success(msg)
  }

  showError(msg) {
    this.toastr.error(msg)
  }
}
