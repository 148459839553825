<div class="ttt">
<div *ngFor="let groupe of data" class="page">




        <div class="row" >
          <div class="col-md-12" style="height: 50px;">
            <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbREewxOjqMfMJdZRV1d35u4hhO1xYhcoPI8VvXB4p-m_sBkC9Q-he9gyFalBX-VW1dw&usqp=CAU" alt="" style="width: 5cm; margin-left : 50px ; float: left;">
            <h3 style="float: right; margin-right:50px"> Groupe TD : {{groupe.group_td?.abbreviation}}</h3>
          </div>
            <div class="col-md-12 ">


                        <div class="schedule-table" id="emploi">
                            <table class="table bg-white" style="zoom: 0.8;">


                                <thead>

                                    <tr>
                                        <th></th>
                                            <th *ngFor="let period of periods ; let j = index" style="font-size: 12pt;"   class="{{j == 1 ?'bright' : ''}}">

                                              {{j}}
                                              <span *ngIf="period.start_hour == '08:30:00'"> 08:30</span>
                                              <span *ngIf="period.start_hour == '10:15:00'"> 10:15</span>
                                              <span *ngIf="period.start_hour == '11:45:00'"> 12:15</span>
                                              <span *ngIf="period.start_hour == '13:30:00'"> 14h00</span>
                                              <span *ngIf="period.start_hour == '15:15:00'"> 15:45</span>
                                              <span *ngIf="period.start_hour == '16:45:00'"> 17:15</span>
                                              -

                                              <span *ngIf="period.end_hour == '10:00:00'"> 10:00</span>
                                              <span *ngIf="period.end_hour == '11:45:00'"> 11:45</span>
                                              <span *ngIf="period.end_hour == '13:15:00'"> 13h45</span>
                                              <span *ngIf="period.end_hour == '15:00:00'"> 15:30</span>
                                              <span *ngIf="period.end_hour == '16:45:00'"> 17:15</span>
                                              <span *ngIf="period.end_hour == '18:00:00'"> 18:30</span>


                                    </tr>
                                </thead>
                                <tbody>
                                        <tr *ngFor="let line of groupe.timetable ">
                                            <td class="day  " style="width: 4%;"><p style="min-height: 90px;">{{ line.abbreviation }}</p></td>

                                                <td  *ngFor="let col of line.days ; let j =index" style="width: 16%;" class="active {{j == 1 ?'bright' : ''}}">
                                                  <ng-container *ngFor="let seance of col.seances">
                                                    <div class="div_seance
                                                    {{seance.subject_type.abbreviation == 'C' ? 'cours' : seance.subject_type.abbreviation == 'TD' ? 'td' : 'tp'}}"

                                                    style="position:relative;padding: 15px" [style.width]="seance.next && seance.next > 1 ? '190%' : 'unset' " [style.display]="seance.related_id ? 'none' : 'block'">


                                                    <div
                                                        style="position: absolute;top: 0;right: 0;background: #666 !important;padding: 10px;  color: white; font-weight: 600;">
                                                        {{ seance.subject_type?.abbreviation}}
                                                    </div>
                                                    <h4 style="margin-right: 22px;">
                                                        {{ seance.subject?.abbreviation }}

                                                        <span *ngIf="seance.subject_type.abbreviation == 'TP'" style="color: red; font-weight: 600;"> &nbsp; (
                                                          <span *ngIf="seance.period && seance.period.start_hour == '11:45:00'">12:15 {{seance.next && seance.next >1 ? ' - 15:15' : '13:45'}}</span>
                                                          <span *ngIf="seance.period && seance.period.start_hour == '08:30:00'">08:30 {{seance.next && seance.next >1 ? ' - 11h30' : '10:00'}}</span>
                                                          <span *ngIf="seance.period && seance.period.start_hour == '15:15:00'">15:30 {{seance.next && seance.next >1 ? ' - 18:30' : '17:00'}}</span>

                                                          <span *ngIf="seance.period && seance.period.start_hour == '10:15:00'">10:15 {{seance.next && seance.next >1 ? ' - 15:15' : '11:45'}}</span>
                                                          <span *ngIf="seance.period && seance.period.start_hour == '14:00:00'">14:00 {{seance.next && seance.next >1 ? ' - 17h00' : '15:30'}}</span>

                                                        </span>


                                                      </h4>


                                                        <span class="specific"  *ngIf="seance.start_hour != seance.period.start_hour || seance.end_hour != seance.period.end_hour">

                                                         <span *ngIf="seance.start_hour == '15:15:00' && (seance.end_hour == '18:00:00' || seance.end_hour == '18:15:00') ; else show_2"> 15:45 - 18:30</span>
                                                          <ng-template #show_2>
                                                            ({{ seance.start_hour }} -
                                                            {{ seance.end_hour }})
                                                          </ng-template>

                                                          </span>

                                                    <p>{{ seance.classroom?.abbreviation }} |
                                                        {{ seance.week_name }}  - <span>{{ seance.teacher?.first_name != 'vacant' ? seance.teacher?.first_name : "pas d'enseignant" }}</span>
                                                        <span>&nbsp;{{seance.teacher?.last_name != 'vacant' ? seance.teacher?.last_name : '' }}</span>
                                                            <span *ngIf="seance.group_tp">&nbsp;{{ seance.group_tp?.abbreviation }}</span></p>



                                                </div>
                                                  </ng-container>

                                                </td>

                                        </tr>


                                </tbody>
                            </table>
                        </div>


            </div>
        </div>


</div>
</div>
