import { AnnualCalendar } from './../../../viewModels/annual-calendar';
import { SubjectType } from './../../../viewModels/subject-type';
import { Classroom } from './../../../viewModels/classroom';
import { WeekType } from './../../../viewModels/week-type';
import { Day } from './../../../viewModels/day';
import { Period } from './../../../viewModels/period';
import { Teacher } from './../../../viewModels/teacher';
import { Subject } from './../../../viewModels/subject';
import { Validators, FormControl, FormGroup } from '@angular/forms';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { TeacherService } from 'src/app/apis/teacher.service';
import { SubjectService } from 'src/app/apis/subject.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { TimingService } from 'src/app/apis/timing.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { ConfigService } from 'src/app/apis/config.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-create-pending-dialog',
  templateUrl: './create-pending-dialog.component.html',
  styleUrls: ['./create-pending-dialog.component.css']
})
export class CreatePendingDialogComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private config: ConfigService,
    public dialogRef: MatDialogRef<CreatePendingDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apis: TimingService,
    private settingsApis: SettingsService,
    private hierarchyApis: HiararchyService,
    private subjectApis: SubjectService,
    private teacherApis: TeacherService,
    private establishmentApis: EstablishmentService
  ) { }


  form: FormGroup = new FormGroup({
    'section_id': new FormControl(this.data.section_id),
    'group_tp_id': new FormControl(this.data.group_tp_id),
    'group_td_id': new FormControl(this.data.group_td_id),
    'division_id': new FormControl(this.data.division_id, Validators.required),
    'subject_id': new FormControl('', Validators.required),
    'teacher_id': new FormControl('', Validators.required),
    'subject_type_id': new FormControl('', Validators.required),
    'week_type_id': new FormControl('', Validators.required),

  })

  session_types = this.config.session_types
  subjects: Subject[] = []
  teachers: Teacher[] = []
  periods: Period[] = []
  days: Day[] = []
  week_types: WeekType[] = []
  classrooms: Classroom[] = []
  subject_types: SubjectType[] = []
  ngOnInit(): void {
    console.log(this.data)
    this.getSubjects();
    this.getTeachers();
    this.getSubjectTypes();
    this.getWeekTypes();
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  getSubjects() {
    this.subjectApis.getSectorSubjects(this.data.sector_id)
      .subscribe((result: Subject[]) => {
        this.subjects = result
        //console.log(result)
      })
  }

  getTeachers() {
    this.teacherApis.getEstablishmentTeachers()
      .subscribe((result: Teacher[]) => {
        this.teachers = result
      })
  }

  getSubjectTypes() {
    this.subjectApis.getEstablishmentSubjectTypes()
      .subscribe((result: SubjectType[]) => {
        this.subject_types = result
      })

  }

  getWeekTypes() {

    this.establishmentApis.getEstablishmentCurrentAnnualCalendar()
      .subscribe((data: AnnualCalendar) => {
        console.group('data', data)
        this.establishmentApis.getAnnualCalendarWeekTypes(data.id)
          .subscribe((data2: WeekType[]) => {
            this.week_types = data2.sort((a, b) => a.start_date > b.start_date ? 0 : -1);
            console.group('data', data2)
          })
      })
  }
}
