<div mat-dialog-title><h1 >{{translate.instant('subject_data')}}</h1></div>
<div mat-dialog-content  >
    <form fxLayout="column" [formGroup]="form" >


        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('designation')}}" [(ngModel)]="data.designation"
                required [formControlName]="'designation'">

        </mat-form-field>
        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('abbreviation')}}"  [(ngModel)]="data.abbreviation"
                required [formControlName]="'abbreviation'">

        </mat-form-field>
        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('Discipline')}}"  [(ngModel)]="data.discipline"
                required [formControlName]="'discipline'">

        </mat-form-field>


    </form>
    <mat-checkbox [(ngModel)]="data.basic_module"
    ></mat-checkbox> {{translate.instant('basic_module')}}



</div>
<div mat-dialog-actions>
  <button  class="form-cancel" (click)="onNoClick()">{{'buttons.no_thanks' | translate }}</button>
  <button class="form-save" [disabled]="!form.valid" [mat-dialog-close]="data"cdkFocusInitial>Ok</button>
</div>

