import { Component, OnInit } from '@angular/core';
import { TeacherGrade } from 'src/app/viewModels/teacher-grade';
import { MatTableDataSource } from '@angular/material/table';
import { TeacherService } from 'src/app/apis/teacher.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-teacher-grades',
  templateUrl: './teacher-grades.component.html',
  styleUrls: ['./teacher-grades.component.css']
})
export class TeacherGradesComponent implements OnInit {

  items : TeacherGrade[];
  displayedColumns: string[] = ['code','designation', 'abbreviation' ,'actions'];
  dataSource ;
  selectedItem : any;
  
  constructor(
    private apis : TeacherService,
    public translate : TranslateService,
    private router : Router,
    private toastr : ToastrService

  ) { }
  
  item : TeacherGrade = new TeacherGrade() ;
   show_form = false;

   form : FormGroup = new FormGroup({
    'designation' : new FormControl(Validators.required),
    'abbreviation' : new FormControl(Validators.required),

  })
  ngOnInit(): void {
    console.log(this.item);
    
    this.getData();
  }


  getData(){
    this.apis.getEstablishmentTeacherGrades()
    .subscribe((data : TeacherGrade[])=>{
      this.items = data;
      this.dataSource = new MatTableDataSource<TeacherGrade>(this.items);
    })
  }



  
  save(){
   console.log(this.item)
    this.apis.createOrUpdateTeacherGrade(this.item)
    .subscribe((data)=>{
      this.getData()
      this.showSuccess('saved');
      this.item = new TeacherGrade();
    })
  }

  edit(element){
    this.item = element
  }

  remove(id){
    Swal.fire({
      title: this.translate.instant('alerts.delete_title'),
      text: this.translate.instant('alerts.delete_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('alerts.delete_yes'),
      cancelButtonText: this.translate.instant('alerts.delete_no')
    }).then((result) => {
      if (result.value) {
        this.apis.removeTeacherGrade(id)
        .subscribe(()=>{
          this.getData();
          Swal.fire(
            this.translate.instant('alerts.deleted'),
            this.translate.instant('alerts.deleted_text'),
            'success'
          )
        })
       
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.translate.instant('alerts.canceled'),
          this.translate.instant('alerts.canceled_text'),
          'error'
        )
      }
    })
  }

  showSuccess(msg){

    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg){
    this.toastr.error(this.translate.instant((msg)))
  }
}
