import { Component, OnInit,Inject } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/apis/config.service';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { GenerateExamsSetting } from 'src/app/viewModels/generate-exams-setting';
import { VacationExam } from 'src/app/viewModels/vacation-exam';
import { GenerateDialogComponent } from '../../time-table/generate-dialog/generate-dialog.component';

@Component({
  selector: 'app-monotoring-generate-dialog',
  templateUrl: './monotoring-generate-dialog.component.html',
  styleUrls: ['./monotoring-generate-dialog.component.css']
})
export class MonotoringGenerateDialogComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private establishmentApis : EstablishmentService,
    public dialogRef: MatDialogRef<GenerateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenerateExamsSetting
  ) { }


examsperiods : VacationExam[]
  form: FormGroup = new FormGroup({
    'vacation_exam_id': new FormControl(Validators.required)

  })
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

    console.log(this.data)
   
    this.getExamsPeriods();
  }

 

 



  getExamsPeriods() {
    this.establishmentApis.getAnnualCalendarVacationsExams(this.data.annual_calendar_id)
      .subscribe((data:  VacationExam[]) => {
        this.examsperiods = data.sort((a,b)=> a.start_date > b.start_date ? 0 : -1);
        
      })
  }


}
