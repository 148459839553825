import { GroupsTdComponent } from './../pages/hierarchy/groups-td/groups-td.component';
import { Section } from './../viewModels/section';
import { Sector } from './../viewModels/sector';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';
import { GroupTd } from '../viewModels/group-td';


@Injectable({
  providedIn: 'root'
})
export class HiararchyService {

  constructor(
    private http : HttpClient,
    private config: ConfigService) { }

    user = JSON.parse(localStorage.getItem('user-infos'));





      //cycle  CRUD

  getHiararchyCycles() {

    return this.http.get(this.config.baseUrl + '/cycles', this.config.getOptions());
  }



  getCycleById(id) {

    return this.http.get(this.config.baseUrl + '/cycle/' + id, this.config.getOptions());
  }
  setCycle(body: any) {

    return this.http.post(this.config.baseUrl + '/cycle', body, this.config.getOptions());
  }
  removeCycle(id) {
    return this.http.delete(this.config.baseUrl + '/cycle/' + id, this.config.getOptions());
  }


  getCycleLevels(cycle_id) {
    return this.http.get(this.config.baseUrl + '/cycle/levels/' + cycle_id, this.config.getOptions());

  }
  setLevel(body: any) {

    return this.http.post(this.config.baseUrl + '/level', body, this.config.getOptions());
  }

/* Departments */

getHiararchyDepartments() {

  return this.http.get(this.config.baseUrl + '/departments', this.config.getOptions());
}



getDepartmentById(id) {

  return this.http.get(this.config.baseUrl + '/department/' + id, this.config.getOptions());
}
setDepartment(body: any) {

  return this.http.post(this.config.baseUrl + '/department', body, this.config.getOptions());
}
removeDepartment(id) {
  return this.http.delete(this.config.baseUrl + '/department/' + id, this.config.getOptions());
}


/*Sector */

getHiararchySectors() {

  return this.http.get(this.config.baseUrl + '/sectors', this.config.getOptions());
}

getLevelSectors(level_id) {
  return this.http.get(this.config.baseUrl + '/level/sectors/'+level_id, this.config.getOptions())
}

createOrUpdateSector(body : Sector) {
  return this.http.post(this.config.baseUrl + '/sectors', body, this.config.getOptions())
}

removeSector(id) {
  return this.http.delete(this.config.baseUrl + '/sectors/'+id, this.config.getOptions())
}
getSectorAvailibility(sector_id){
  return this.http.get(this.config.baseUrl+'/sectors/availibility/'+sector_id , this.config.getOptions());
}

setSectorAvailibility(sector_id , body){
  return this.http.post(this.config.baseUrl+'/sectors/availibility/'+sector_id , body, this.config.getOptions());
}


/* Section CRUD*/

getSectorSections(sector_id) {
  return this.http.get(this.config.baseUrl + '/sector/sections/'+sector_id, this.config.getOptions())
}
createOrUpdateSection(body : Section) {
  return this.http.post(this.config.baseUrl + '/sections', body, this.config.getOptions())
}
removeSection(id) {
  return this.http.delete(this.config.baseUrl + '/sections/'+id, this.config.getOptions())
}

/* Group-TD CRUD    */
getSectorGroupTds(sector_id) {
  return this.http.get(this.config.baseUrl + '/sector/groups-td/'+sector_id, this.config.getOptions())
}

getSectionGroupTds(section_id) {
  return this.http.get(this.config.baseUrl + '/section/groups-td/'+section_id, this.config.getOptions())
}
createOrUpdateGroupTd(body : GroupTd) {
  return this.http.post(this.config.baseUrl + '/groups-td', body, this.config.getOptions())
}
removeGroupTd(id) {
  return this.http.delete(this.config.baseUrl + '/groups-td/'+id, this.config.getOptions())
}




/* Group-TP CRUD    */

getSectorGroupTps(sector_id) {
  return this.http.get(this.config.baseUrl + '/sector/groups-tp/'+sector_id, this.config.getOptions())
}
getSectionGroupTps(section_id) {
  return this.http.get(this.config.baseUrl + '/section/groups-tp/'+section_id, this.config.getOptions())
}
createOrUpdateGroupTp(body : GroupTd) {
  return this.http.post(this.config.baseUrl + '/groups-tp', body, this.config.getOptions())
}
removeGroupTp(id) {
  return this.http.delete(this.config.baseUrl + '/groups-tp/'+id, this.config.getOptions())
}

importSectors(body : Sector[]){
  console.log(body)
  return this.http.post(this.config.baseUrl+'/import/sectors' , body , this.config.getOptions())
}


establishmentSections(){
  return this.http.get(this.config.baseUrl+'/timetable/establishment_sections'  , this.config.getOptions())

}
}
