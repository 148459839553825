<app-content-header 
    [icon]="'check_box_outline_blank'" 
    [title]="'Page title'" 
    [desc]="'Lorem ipsum dolor sit amet, consectetur adipiscing elit. Fusce sollicitudin ante lectus, id pretium mi dignissim non.'"
    [hideBreadcrumb]="false"
    [hasBgImage]="true" 
    [class]="'pb-4'"></app-content-header>

<div fxLayout="row wrap" fxLayoutAlign="center" class="p-2">
    <div fxFlex="100" class="p-2">
        <p>Your content here.</p>
    </div>
</div>