import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { ExcelService } from 'src/app/apis/excel/excel.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { TimingService } from 'src/app/apis/timing.service';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { AnnualCalendar } from 'src/app/viewModels/annual-calendar';
import { Day } from 'src/app/viewModels/day';
import { Level } from 'src/app/viewModels/level';
import { Sector } from 'src/app/viewModels/sector';
import { VacationExam } from 'src/app/viewModels/vacation-exam';
declare var $ : any;
@Component({
  selector: 'app-number-study-hours',
  templateUrl: './number-study-hours.component.html',
  styleUrls: ['./number-study-hours.component.css']
})
export class NumberStudyHoursComponent implements OnInit {

  public settings: Settings;
  subs = new Subscription();

  constructor(private settingService: SettingsService,
    private establishmentApis : EstablishmentService,
    public appSettings: AppSettings,
    private excelService: ExcelService,
    public translate: TranslateService,
    private hierarchyService: HiararchyService,
    private apis: TimingService,
    public dialog: MatDialog,
    private toastr: ToastrService) {
    this.settings = this.appSettings.settings;





 
  }
  days: Day[];
  width = 20;
  schedule: any[];
  selectedSector;
  sectors: Sector[];
  levels : Level[];
  semestres_trimestres: any = [];
  selectedSemestre;
  selectedLevel
  periods : String[];
  ngOnInit(): void {
    this.hierarchyService.getHiararchySectors()
      .subscribe((data: Sector[]) => {
        this.sectors = data;
      })
    this.settingService.getDays()
      .subscribe((data: Day[]) => {
        this.days = data;
      })
    this.settingService.getYearDivisions()
      .subscribe((data: any[]) => {
        this.semestres_trimestres = data;
      })

this.getCurrentAnnualCalendar();
   


  }
  current_annual_calendar
  examsperiods : VacationExam[]
  getCurrentAnnualCalendar()
  {
    this.establishmentApis.getEstablishmentCurrentAnnualCalendar()
    .subscribe((data : AnnualCalendar)=>{
      this.current_annual_calendar = data;
      if(this.current_annual_calendar){
        this.getExamsPeriods()
       
      }
    })
  }
  getExamsPeriods() {
    this.establishmentApis.getAnnualCalendarVacationsExams(this.current_annual_calendar.id)
      .subscribe((data:  VacationExam[]) => {
        this.examsperiods = data.sort((a,b)=> a.start_date > b.start_date ? 0 : -1);
        console.log(this.examsperiods)
      })
  }

  handleSectorChange() {
    if (this.selectedSector ) {
      console.log(this.selectedLevel)
     this.apis.getNbHoursStudyPlanWithSectorId(this.selectedSector)
     .subscribe((data : any[])=>{
      console.log('aaa' , data)
      this.schedule = data;
      this.getTotall()
     })


    }
  }
  JSONparse(ch)
  {
    return JSON.parse(ch).length
  }


  totale_c = 0;
  totale_tp = 0
  totale_td = 0;

  getTotal(type , subjects){
    let result = 0
    subjects.map((s)=>{
      if(type == 'td'){
        if(s.td && s.td.length >0){
          let groups =  JSON.parse(s.td[0].pivot.group_id)
          if(groups.length > 0 ){
            result +=(s.td[0].pivot.nb_hours*14*groups.length)

          }else{
            result +=(s.td[0].pivot.nb_hours*14)

          }
        }
      }else  if(type == 'c'){
        if(s.c && s.c.length >0){
          let groups =  JSON.parse(s.c[0].pivot.group_id)
          if(groups.length > 0 ){
            result +=(s.c[0].pivot.nb_hours*14*groups.length)

          }else{
            result +=(s.c[0].pivot.nb_hours*14)

          }
        }
       
      }
      else  if(type == 'tp'){
        if(s.tp && s.tp.length >0){
          let groups =  JSON.parse(s.tp[0].pivot.group_id)
          if(groups.length > 0 ){
            result +=(s.tp[0].pivot.nb_hours*14*groups.length)

          }else{
            result +=(s.tp[0].pivot.nb_hours*14)

          }
        }
    
      }

    })
    return result
  }

  getTotall( ){
    let result = 0
    this.totale_c = 0;
    this.totale_tp = 0;
    this.totale_td = 0;
    this.schedule.map((row)=>{
      row.subjects.map((s)=>{
      
          if(s.td && s.td.length >0){
            let groups =  JSON.parse(s.td[0].pivot.group_id)
            if(groups.length > 0 ){
              this.totale_td +=(s.td[0].pivot.nb_hours*14*groups.length)
  
            }else{
              this.totale_td +=(s.td[0].pivot.nb_hours*14)
  
            }
          }
         
          if(s.c && s.c.length >0){
            let groups =  JSON.parse(s.c[0].pivot.group_id)
            if(groups.length > 0 ){
              this.totale_c +=(s.c[0].pivot.nb_hours*14*groups.length)
  
            }else{
              this.totale_c +=(s.c[0].pivot.nb_hours*14)
  
            }
          }
         
        
          if(s.tp && s.tp.length >0){
            let groups =  JSON.parse(s.tp[0].pivot.group_id)
            if(groups.length > 0 ){
              this.totale_tp +=(s.tp[0].pivot.nb_hours*14*groups.length)
  
            }else{
              this.totale_tp +=(s.tp[0].pivot.nb_hours*14)
  
            }
          }
      
        
  
      })
    })
   
    return result
  }

  generateSemestre;
  show_progess = false;
  handleGenerateChange() {
    this.show_progess = true;
    this.apis.generateExamsSchedule(this.generateSemestre)
      .subscribe((data) => {
       
        this.show_progess = false;
        this.showSuccess('success')

      }, (err) => {
        console.log(err)
        this.show_progess = false;
        this.showError('generate_server_error')

      })
  }
  handleExamPeriodChange(){
    console.log(this.selectedLevel && this.selectedSector)
    if(this.selectedLevel){
      this.periods = this.getDates(new Date(this.selectedLevel.start_date) , new Date(this.selectedLevel.end_date));
      console.log(this.periods)
    }
  }
getDates(startDate,  stopDate) {
  console.log(startDate , stopDate)
    let dateArray = new Array();
    let currentDate : Date = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date (currentDate));
      
       currentDate.setDate(currentDate.getDate()+1);
    }
    return dateArray;
}
  export() {
    this.excelService.exportToFile('examsschedule', 'print_ums_id');
  }


  showSuccess(msg) {
    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg) {
    this.toastr.error(this.translate.instant(msg))
  }

  print() {
    var $print = $('#print_ums_id')
      .clone()
      .addClass('ums_print')
      .prependTo('body');
    window.print();
    $print.remove();
  }


 


}
