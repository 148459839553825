import { Menu } from './menu.model';

export const verticalMenuItems = [ 

    new Menu (1, 'Dashboard', '/ums/dashboard', null, 'dashboard', null, false, 0),
    new Menu (3, 'establishment', null, null, 'account_balance', null, true, 0),   
    new Menu (4, 'hierarchy', null, null, 'account_tree', null, true, 0), 
    new Menu (5, 'subjectsAndTeachers', null, null, 'auto_stories', null, true, 0),
   
    new Menu (2, 'timetable', null, null, 'date_range', null, true, 0), 

    new Menu (6, 'exams', null, null, 'event_available', null, true, 0), 
    new Menu (7, 'Plan d études', null, null, 'table', null, true, 0), 
    new Menu (8, 'tickets', '/ums/chat', null, 'chat', null, false, 0),

/* 
    new Menu (21, 'sectiontimetable', '/ums/timetable', null, 'date_range', 'timetable', false, 2),  */
   // new Menu (22, 'sectiontimetable', '/ums/timetables', null, 'date_range', 'timetable', false, 2), 

    new Menu (21, 'sectiontimetable', '/ums/timetable', null, 'date_range', 'timetable', false, 2), 
    new Menu (22, 'archives', '/ums/archives_timetables', null, 'date_range', 'timetable', false, 2), 

    new Menu (23, 'teachertimetable', '/ums/teachertimetable', null, 'date_range', 'timetable', false, 2),
    new Menu (24, 'classroomsSchedule', '/ums/classrooms-schedule', null, 'date_range', 'timetable', false, 2),
    new Menu (25, 'classroomsOccupations', '/ums/classrooms-occupations', null, 'date_range', 'timetable', false, 2),

    new Menu (31, 'coordonates', '/ums/establishment', null, 'assignment', 'establishment', false, 3),  
    new Menu (32, 'buildings', '/ums/buildings', null, 'location_city', 'establishment', false, 3),  
    new Menu (33, 'blocks', '/ums/blocks', null, 'corporate_fare', 'establishment', false, 3), 
    new Menu (34, 'classrooms', '/ums/classrooms', null, 'meeting_room', 'establishment', false, 3), 
    new Menu (38, 'equipments', '/ums/equipments', null, 'meeting_room', 'establishment', false, 3), 
    new Menu (35, 'periods', '/ums/periods', null, 'alarm', 'establishment', false, 3), 
    new Menu (36, 'vacationsAndExams', '/ums/vacations-exams', null, 'alarm', 'establishment', false, 3), 
    new Menu (37, 'weekTypes', '/ums/week-types', null, 'alarm', 'establishment', false, 3), 

    //hiarchy : 4
    new Menu (41, 'departments', '/ums/departments', null, 'domain', 'hierarchy', false, 4),
    new Menu (42, 'cycles', '/ums/cycles', null, 'tab', 'hierarchy', false, 4), 
    new Menu (43, 'levels', '/ums/levels', null, 'format_list_numbered', 'hierarchy', false, 4), 
    new Menu (44, 'sectors', '/ums/sectors', null, 'dns', 'hierarchy', false, 4),
    new Menu (45, 'sections', '/ums/sections', null, 'group_work', 'hierarchy', false, 4),
    new Menu (46, 'groupsTd', '/ums/groups-td', null, 'group_work', 'hierarchy', false, 4),
    new Menu (47, 'groupsTp', '/ums/groups-tp', null, 'group_work', 'hierarchy', false, 4), 
   
    //subjects & teachers : 5
    new Menu (51, 'subjects', '/ums/subjects', null, 'library_books', 'subjectsAndTeachers', false, 5), 
    new Menu (57, 'subjectModules', '/ums/subject-modules', null, 'subject', 'subjectsAndTeachers', false, 5), 

/*     new Menu (52, 'subjectTypes', '/ums/subject-types', null, 'list', 'subjectsAndTeachers', false, 5), 
 *//*     new Menu (53, 'regimes', '/ums/subject-regimes', null, 'list', 'subjectsAndTeachers', false, 5),
 */    new Menu (54, 'teachers', '/ums/teachers', null, 'assignment_ind', 'subjectsAndTeachers', false, 5),
    new Menu (55, 'teachersTypes', '/ums/teacher-types', null, 'list', 'subjectsAndTeachers', false, 5),
    new Menu (56, 'teachersGrades', '/ums/teacher-grades', null, 'list', 'subjectsAndTeachers', false, 5),

    new Menu (51, 'exams_calendar', '/ums/exams', null, 'fact_check', 'exams', false, 6), 
    new Menu (57, 'monotoring', '/ums/monotoring', null, 'track_changes', 'exams', false, 6),


    new Menu (71, 'Nombre des heures d études', '/ums/nb-study-hours', null, 'date_range', 'Plan d études', false, 7), 

    new Menu (72, 'Totale des heures d études', '/ums/total-study-hours', null, 'date_range', 'Plan d études', false, 7),
    new Menu (73, 'Plan d étude annuel', '/ums/annual-study-hours', null, 'date_range', 'timetaPlan d étudesble', false, 7),
    /* new Menu (1, 'Dashboard', '/ums/dashboard', null, 'dashboard', null, false, 0),
    new Menu (2, 'Users', '/ums/users', null, 'supervisor_account', null, false, 0), 
    new Menu (3, 'UI Features', null, null, 'computer', null, true, 0),   
    new Menu (4, 'Buttons', '/ums/ui/buttons', null, 'keyboard', null, false, 3),  
    new Menu (5, 'Cards', '/ums/ui/cards', null, 'card_membership', null, false, 3), 
    new Menu (6, 'Lists', '/ums/ui/lists', null, 'list', null, false, 3), 
    new Menu (7, 'Grids', '/ums/ui/grids', null, 'grid_on', null, false, 3),
    new Menu (8, 'Tabs', '/ums/ui/tabs', null, 'tab', null, false, 3), 
    new Menu (9, 'Expansion Panel', '/ums/ui/expansion-panel', null, 'dns', null, false, 3),
    new Menu (10, 'Chips', '/ums/ui/chips', null, 'label', null, false, 3),
    new Menu (11, 'Progress', '/ums/ui/progress', null, 'data_usage', null, false, 3), 
    new Menu (12, 'Dialog', '/ums/ui/dialog', null, 'open_in_new', null, false, 3), 
    new Menu (13, 'Tooltip', '/ums/ui/tooltip', null, 'chat_bubble', null, false, 3), 
    new Menu (14, 'Snackbar', '/ums/ui/snack-bar', null, 'sms', null, false, 3), 
    new Menu (15, 'Dynamic Menu', '/ums/dynamic-menu', null, 'format_list_bulleted', null, false, 0),    
    new Menu (16, 'Mailbox', '/ums/mailbox', null, 'email', null, false, 0),
    new Menu (17, 'Chat', '/ums/chat', null, 'chat', null, false, 0),
    new Menu (20, 'Form Controls', null, null, 'dvr', null, true, 0), 
    new Menu (21, 'Autocomplete', '/ums/form-controls/autocomplete', null, 'short_text', null, false, 20), 
    new Menu (22, 'Checkbox', '/ums/form-controls/checkbox', null, 'check_box', null, false, 20), 
    new Menu (23, 'Datepicker', '/ums/form-controls/datepicker', null, 'today', null, false, 20), 
    new Menu (24, 'Form field', '/ums/form-controls/form-field', null, 'view_stream', null, false, 20), 
    new Menu (25, 'Input', '/ums/form-controls/input', null, 'input', null, false, 20), 
    new Menu (26, 'Radio button', '/ums/form-controls/radio-button', null, 'radio_button_checked', null, false, 20), 
    new Menu (27, 'Select', '/ums/form-controls/select', null, 'playlist_add_check', null, false, 20), 
    new Menu (28, 'Slider', '/ums/form-controls/slider', null, 'tune', null, false, 20), 
    new Menu (29, 'Slide toggle', '/ums/form-controls/slide-toggle', null, 'star_half', null, false, 20), 
    new Menu (30, 'Tables', null, null, 'view_module', null, true, 0),
    new Menu (31, 'Basic', '/ums/tables/basic', null, 'view_column', null, false, 30), 
    new Menu (32, 'Paging', '/ums/tables/paging', null, 'last_page', null, false, 30), 
    new Menu (33, 'Sorting', '/ums/tables/sorting', null, 'sort', null, false, 30),
    new Menu (34, 'Filtering', '/ums/tables/filtering', null, 'format_line_spacing', null, false, 30),
    new Menu (35, 'Selecting', '/ums/tables/selecting', null, 'playlist_add_check', null, false, 30),
    new Menu (36, 'NGX DataTable', '/ums/tables/ngx-table', null, 'view_array', null, false, 30), 
    new Menu (40, 'Pages', null, null, 'library_books', null, true, 0),
    new Menu (43, 'Login', '/ums/login', null, 'exit_to_app', null, false, 40),    
    new Menu (44, 'Register', '/ums/register', null, 'person_add', null, false, 40),
    new Menu (45, 'Blank', '/ums/blank', null, 'check_box_outline_blank', null, false, 40),
    new Menu (46, 'Page Not Found', '/ums/pagenotfound', null, 'error_outline', null, false, 40),
    new Menu (47, 'Error', '/ums/error', null, 'warning', null, false, 40),
    new Menu (48, 'Search', '/ums/search', null, 'search', null, false, 40),
    new Menu (49, 'Landing', '/ums/landing', null, 'filter', null, false, 40),
    new Menu (50, 'Schedule', '/ums/schedule', null, 'event_note', null, false, 0),
    new Menu (66, 'Maps', null, null, 'map', null, true, 0),
    new Menu (67, 'Google Maps', '/ums/maps/googlemaps', null, 'location_on', null, false, 66),
    new Menu (68, 'Leaflet Maps', '/ums/maps/leafletmaps', null, 'my_location', null, false, 66),
    new Menu (70, 'Charts', null, null, 'multiline_chart', null, true, 0),
    new Menu (71, 'Bar Charts', '/ums/charts/bar', null, 'insert_chart', null, false, 70),
    new Menu (72, 'Pie Charts', '/ums/charts/pie', null, 'pie_chart', null, false, 70),
    new Menu (73, 'Line Charts', '/ums/charts/line', null, 'show_chart', null, false, 70),
    new Menu (74, 'Bubble Charts', '/ums/charts/bubble', null, 'bubble_chart', null, false, 70), 
    new Menu (81, 'Drag & Drop', '/ums/drag-drop', null, 'mouse', null, false, 0),  
    new Menu (85, 'Material Icons', '/ums/icons', null, 'insert_emoticon', null, false, 0),   */
    /* new Menu (1, 'Dashboard', '/ums/dashboard', null, 'dashboard', null, false, 0),
    new Menu (2, 'Users', '/ums/users', null, 'supervisor_account', null, false, 0), 
    new Menu (3, 'UI Features', null, null, 'computer', null, true, 0),   
    new Menu (4, 'Buttons', '/ums/ui/buttons', null, 'keyboard', null, false, 3),  
    new Menu (5, 'Cards', '/ums/ui/cards', null, 'card_membership', null, false, 3), 
    new Menu (6, 'Lists', '/ums/ui/lists', null, 'list', null, false, 3), 
    new Menu (7, 'Grids', '/ums/ui/grids', null, 'grid_on', null, false, 3), 
    new Menu (8, 'Tabs', '/ums/ui/tabs', null, 'tab', null, false, 3), 
    new Menu (9, 'Expansion Panel', '/ums/ui/expansion-panel', null, 'dns', null, false, 3),
    new Menu (10, 'Chips', '/ums/ui/chips', null, 'label', null, false, 3),
    new Menu (11, 'Progress', '/ums/ui/progress', null, 'data_usage', null, false, 3), 
    new Menu (12, 'Dialog', '/ums/ui/dialog', null, 'open_in_new', null, false, 3), 
    new Menu (13, 'Tooltip', '/ums/ui/tooltip', null, 'chat_bubble', null, false, 3), 
    new Menu (14, 'Snackbar', '/ums/ui/snack-bar', null, 'sms', null, false, 3), 
    new Menu (15, 'Dynamic Menu', '/ums/dynamic-menu', null, 'format_list_bulleted', null, false, 0),    
    new Menu (16, 'Mailbox', '/ums/mailbox', null, 'email', null, false, 0),
    new Menu (17, 'Chat', '/ums/chat', null, 'chat', null, false, 0),
    new Menu (20, 'Form Controls', null, null, 'dvr', null, true, 0), 
    new Menu (21, 'Autocomplete', '/ums/form-controls/autocomplete', null, 'short_text', null, false, 20), 
    new Menu (22, 'Checkbox', '/ums/form-controls/checkbox', null, 'check_box', null, false, 20), 
    new Menu (23, 'Datepicker', '/ums/form-controls/datepicker', null, 'today', null, false, 20), 
    new Menu (24, 'Form field', '/ums/form-controls/form-field', null, 'view_stream', null, false, 20), 
    new Menu (25, 'Input', '/ums/form-controls/input', null, 'input', null, false, 20), 
    new Menu (26, 'Radio button', '/ums/form-controls/radio-button', null, 'radio_button_checked', null, false, 20), 
    new Menu (27, 'Select', '/ums/form-controls/select', null, 'playlist_add_check', null, false, 20), 
    new Menu (28, 'Slider', '/ums/form-controls/slider', null, 'tune', null, false, 20), 
    new Menu (29, 'Slide toggle', '/ums/form-controls/slide-toggle', null, 'star_half', null, false, 20), 
    new Menu (30, 'Tables', null, null, 'view_module', null, true, 0),
    new Menu (31, 'Basic', '/ums/tables/basic', null, 'view_column', null, false, 30), 
    new Menu (32, 'Paging', '/ums/tables/paging', null, 'last_page', null, false, 30), 
    new Menu (33, 'Sorting', '/ums/tables/sorting', null, 'sort', null, false, 30),
    new Menu (34, 'Filtering', '/ums/tables/filtering', null, 'format_line_spacing', null, false, 30),
    new Menu (35, 'Selecting', '/ums/tables/selecting', null, 'playlist_add_check', null, false, 30),
    new Menu (36, 'NGX DataTable', '/ums/tables/ngx-table', null, 'view_array', null, false, 30), 
    new Menu (40, 'Pages', null, null, 'library_books', null, true, 0),
    new Menu (43, 'Login', '/ums/login', null, 'exit_to_app', null, false, 40),    
    new Menu (44, 'Register', '/ums/register', null, 'person_add', null, false, 40),
    new Menu (45, 'Blank', '/ums/blank', null, 'check_box_outline_blank', null, false, 40),
    new Menu (46, 'Page Not Found', '/ums/pagenotfound', null, 'error_outline', null, false, 40),
    new Menu (47, 'Error', '/ums/error', null, 'warning', null, false, 40),
    new Menu (48, 'Search', '/ums/search', null, 'search', null, false, 40),
    new Menu (49, 'Landing', '/ums/landing', null, 'filter', null, false, 40),
    new Menu (50, 'Schedule', '/ums/schedule', null, 'event_note', null, false, 0),
    new Menu (66, 'Maps', null, null, 'map', null, true, 0),
    new Menu (67, 'Google Maps', '/ums/maps/googlemaps', null, 'location_on', null, false, 66),
    new Menu (68, 'Leaflet Maps', '/ums/maps/leafletmaps', null, 'my_location', null, false, 66),
    new Menu (70, 'Charts', null, null, 'multiline_chart', null, true, 0),
    new Menu (71, 'Bar Charts', '/ums/charts/bar', null, 'insert_chart', null, false, 70),
    new Menu (72, 'Pie Charts', '/ums/charts/pie', null, 'pie_chart', null, false, 70),
    new Menu (73, 'Line Charts', '/ums/charts/line', null, 'show_chart', null, false, 70),
    new Menu (74, 'Bubble Charts', '/ums/charts/bubble', null, 'bubble_chart', null, false, 70), 
    new Menu (81, 'Drag & Drop', '/ums/drag-drop', null, 'mouse', null, false, 0),  
    new Menu (85, 'Material Icons', '/ums/icons', null, 'insert_emoticon', null, false, 0),  
    new Menu (140, 'Level 1', null, null, 'more_horiz', null, true, 0),
    new Menu (141, 'Level 2', null, null, 'folder_open', null, true, 140),
    new Menu (142, 'Level 3', null, null, 'folder_open', null, true, 141),
    new Menu (143, 'Level 4', null, null, 'folder_open', null, true, 142),
    new Menu (144, 'Level 5', null, 'http://themeseason.com', 'link', null, false, 143),
    new Menu (200, 'External Link', null, 'http://themeseason.com', 'open_in_new', '_blank', false, 0) */
]

export const horizontalMenuItems = [ 
    new Menu (1, 'Dashboard', '/ums/dashboard', null, 'dashboard', null, false, 0),
    new Menu (2, 'timetable', '/ums/timetable', null, 'date_range', null, false, 0), 
    new Menu (6, 'exams', '/ums/exams', null, 'event_available', null, false, 0), 
    new Menu (3, 'establishment', '/ums/establishment', null, 'account_balance', null, false, 0),   
    new Menu (4, 'hierarchy', '/ums/departments', null, 'account_tree', null, false, 0), 
    new Menu (5, 'subjectsAndTeachers', '/ums/subjects', null, 'auto_stories', null, false, 0), 

]
export const horizontalSubMenuItems = [
    new Menu (21, 'sectiontimetable', '/ums/timetable', null, 'date_range', 'timetable', false, 2), 
    new Menu (21, 'sectiontimetable', '/ums/timetables', null, 'date_range', 'timetable', false, 2), 

    new Menu (23, 'teachertimetable', '/ums/teachertimetable', null, 'date_range', 'timetable', false, 2),
    new Menu (24, 'classroomsSchedule', '/ums/classrooms-schedule', null, 'date_range', 'timetable', false, 2),

    new Menu (31, 'coordonates', '/ums/establishment', null, 'assignment', 'establishment', false, 3),  
    new Menu (32, 'buildings', '/ums/buildings', null, 'location_city', 'establishment', false, 3),  
    new Menu (33, 'blocks', '/ums/blocks', null, 'corporate_fare', 'establishment', false, 3), 
    new Menu (34, 'classrooms', '/ums/classrooms', null, 'meeting_room', 'establishment', false, 3), 
    new Menu (35, 'periods', '/ums/periods', null, 'alarm', 'establishment', false, 3), 
    new Menu (36, 'vacationsAndExams', '/ums/vacations-exams', null, 'alarm', 'establishment', false, 3), 
    new Menu (37, 'weekTypes', '/ums/week-types', null, 'alarm', 'establishment', false, 3), 

    //hiarchy : 4
    new Menu (41, 'departments', '/ums/departments', null, 'domain', 'hierarchy', false, 4),
    new Menu (42, 'cycles', '/ums/cycles', null, 'tab', 'hierarchy', false, 4), 
    new Menu (43, 'levels', '/ums/levels', null, 'format_list_numbered', 'hierarchy', false, 4), 
    new Menu (44, 'sectors', '/ums/sectors', null, 'dns', 'hierarchy', false, 4),
    new Menu (45, 'sections', '/ums/sections', null, 'group_work', 'hierarchy', false, 4),
    new Menu (46, 'groupsTd', '/ums/groups-td', null, 'group_work', 'hierarchy', false, 4),
    new Menu (47, 'groupsTp', '/ums/groups-tp', null, 'group_work', 'hierarchy', false, 4), 
   
    //subjects & teachers : 5
    new Menu (51, 'subjects', '/ums/subjects', null, 'library_books', 'subjectsAndTeachers', false, 5), 
    new Menu (57, 'subjectModules', '/ums/subject-modules', null, 'subject', 'subjectsAndTeachers', false, 5), 

    new Menu (52, 'subjectTypes', '/ums/subject-types', null, 'list', 'subjectsAndTeachers', false, 5), 
    new Menu (53, 'regimes', '/ums/subject-regimes', null, 'list', 'subjectsAndTeachers', false, 5),
    new Menu (54, 'teachers', '/ums/teachers', null, 'assignment_ind', 'subjectsAndTeachers', false, 5),
    new Menu (55, 'teachersTypes', '/ums/teacher-types', null, 'list', 'subjectsAndTeachers', false, 5),
    new Menu (56, 'teachersGrades', '/ums/teacher-grades', null, 'list', 'subjectsAndTeachers', false, 5),

    new Menu (51, 'exams_calendar', '/ums/exams', null, 'fact_check', 'exams', false, 6), 
    new Menu (57, 'monotoring', '/ums/monotoring', null, 'track_changes', 'exams', false, 6),
]
/*
export const horizontalMenuItems = [ 
    new Menu (1, 'Dashboard', '/dashboard', null, 'dashboard', null, false, 0),
    new Menu (2, 'Users', '/users', null, 'supervisor_account', null, false, 0), 
    new Menu (3, 'UI Features', null, null, 'computer', null, true, 0),   
    new Menu (4, 'Buttons', '/ui/buttons', null, 'keyboard', null, false, 3),  
    new Menu (5, 'Cards', '/ui/cards', null, 'card_membership', null, false, 3), 
    new Menu (6, 'Lists', '/ui/lists', null, 'list', null, false, 3), 
    new Menu (7, 'Grids', '/ui/grids', null, 'grid_on', null, false, 3), 
    new Menu (8, 'Tabs', '/ui/tabs', null, 'tab', null, false, 3), 
    new Menu (9, 'Expansion Panel', '/ui/expansion-panel', null, 'dns', null, false, 3), 
    new Menu (10, 'Chips', '/ui/chips', null, 'label', null, false, 3),
    new Menu (11, 'Progress', '/ui/progress', null, 'data_usage', null, false, 3), 
    new Menu (12, 'Dialog', '/ui/dialog', null, 'open_in_new', null, false, 3), 
    new Menu (13, 'Tooltip', '/ui/tooltip', null, 'chat_bubble', null, false, 3), 
    new Menu (14, 'Snackbar', '/ui/snack-bar', null, 'sms', null, false, 3),
    new Menu (16, 'Mailbox', '/mailbox', null, 'email', null, false, 40), 
    new Menu (17, 'Chat', '/chat', null, 'chat', null, false, 40), 
    new Menu (20, 'Form Controls', null, null, 'dvr', null, true, 0), 
    new Menu (21, 'Autocomplete', '/form-controls/autocomplete', null, 'short_text', null, false, 20), 
    new Menu (22, 'Checkbox', '/form-controls/checkbox', null, 'check_box', null, false, 20), 
    new Menu (23, 'Datepicker', '/form-controls/datepicker', null, 'today', null, false, 20), 
    new Menu (24, 'Form field', '/form-controls/form-field', null, 'view_stream', null, false, 20), 
    new Menu (25, 'Input', '/form-controls/input', null, 'input', null, false, 20), 
    new Menu (26, 'Radio button', '/form-controls/radio-button', null, 'radio_button_checked', null, false, 20), 
    new Menu (27, 'Select', '/form-controls/select', null, 'playlist_add_check', null, false, 20), 
    new Menu (28, 'Slider', '/form-controls/slider', null, 'tune', null, false, 20), 
    new Menu (29, 'Slide toggle', '/form-controls/slide-toggle', null, 'star_half', null, false, 20),    
    new Menu (30, 'Tables', null, null, 'view_module', null, true, 0),
    new Menu (31, 'Basic', '/tables/basic', null, 'view_column', null, false, 30), 
    new Menu (32, 'Paging', '/tables/paging', null, 'last_page', null, false, 30), 
    new Menu (33, 'Sorting', '/tables/sorting', null, 'sort', null, false, 30),
    new Menu (34, 'Filtering', '/tables/filtering', null, 'format_line_spacing', null, false, 30),
    new Menu (35, 'Selecting', '/tables/selecting', null, 'playlist_add_check', null, false, 30),
    new Menu (36, 'NGX DataTable', '/tables/ngx-table', null, 'view_array', null, false, 30),  
    new Menu (70, 'Charts', null, null, 'multiline_chart', null, true, 0),
    new Menu (71, 'Bar Charts', '/charts/bar', null, 'insert_chart', null, false, 70),
    new Menu (72, 'Pie Charts', '/charts/pie', null, 'pie_chart', null, false, 70),
    new Menu (73, 'Line Charts', '/charts/line', null, 'show_chart', null, false, 70),
    new Menu (74, 'Bubble Charts', '/charts/bubble', null, 'bubble_chart', null, false, 70),    
    new Menu (81, 'Drag & Drop', '/drag-drop', null, 'mouse', null, false, 3), 
    new Menu (85, 'Material Icons', '/icons', null, 'insert_emoticon', null, false, 3),
    new Menu (40, 'Pages', null, null, 'library_books', null, true, 0),
    new Menu (43, 'Login', '/login', null, 'exit_to_app', null, false, 40),    
    new Menu (44, 'Register', '/register', null, 'person_add', null, false, 40),
    new Menu (45, 'Blank', '/blank', null, 'check_box_outline_blank', null, false, 40),
    new Menu (46, 'Page Not Found', '/pagenotfound', null, 'error_outline', null, false, 40),
    new Menu (47, 'Error', '/error', null, 'warning', null, false, 40),
    new Menu (48, 'Search', '/search', null, 'search', null, false, 40),
    new Menu (49, 'Landing', '/landing', null, 'filter', null, false, 40),
    new Menu (50, 'Schedule', '/schedule', null, 'event_note', null, false, 40),
    new Menu (200, 'External Link', null, 'http://themeseason.com', 'open_in_new', '_blank', false, 40),
    new Menu (66, 'Maps', null, null, 'map', null, true, 0),
    new Menu (67, 'Google', '/maps/googlemaps', null, 'location_on', null, false, 66),
    new Menu (68, 'Leaflet', '/maps/leafletmaps', null, 'my_location', null, false, 66),
]*/