import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-flags-menu',
  templateUrl: './flags-menu.component.html',
  styleUrls: ['./flags-menu.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class FlagsMenuComponent implements OnInit {

  public settings: Settings;
  constructor(public appSettings:AppSettings , private translate : TranslateService){
      this.settings = this.appSettings.settings; 
      
  }

  ngOnInit() {
  }
  changeLanguage(language){
    this.translate.use(language);
    localStorage.setItem('language' , language);
    if(language == 'ar'){
      this.appSettings.settings.rtl = true;
    }else{
      this.appSettings.settings.rtl = false;
    }
  }
}
