import { Component, ViewChild} from '@angular/core';
import { AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  public settings: Settings;
  currentLang = localStorage.getItem('language');
  constructor(public appSettings:AppSettings , translate: TranslateService){
      this.settings = this.appSettings.settings;
      // this language will be used as a fallback when a translation isn't found in the current language
    translate.setDefaultLang('fr');
     // the lang to use, if the lang isn't available, it will use the current loader to get them
    if(this.currentLang){
      translate.use(this.currentLang);
      if(this.currentLang == 'ar'){
        this.appSettings.settings.rtl = true;
      }
    }else{
      translate.use('fr');
    }

  }

  ngOnInit() { }
}
