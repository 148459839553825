<div class="ttt">
  <div *ngFor="let teacher of data" class="page">




          <div class="row" >
            <div class="col-md-12" style="height: 50px;">
              <img src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQbREewxOjqMfMJdZRV1d35u4hhO1xYhcoPI8VvXB4p-m_sBkC9Q-he9gyFalBX-VW1dw&usqp=CAU" alt="" style="width: 5cm; margin-left : 50px ; float: left;">
              <h3 style="float: right; margin-right:50px"> Enseignant(e) : {{teacher.teacher?.first_name}} &nbsp; {{teacher.teacher?.last_name}}</h3>
            </div>
              <div class="col-md-12 ">



                            <table class="table" style="margin-bottom: 15pt ; width:100%" *ngFor="let table of teacher.timetable">
                              <caption>{{table.abbreviation}}</caption>
                              <thead class="thead-dark">
                                  <tr class="trhead">
                                      <th></th>
                                      <th>Horaire</th>
                                      <th>Matière</th>
                                      <th>Groupe</th>
                                      <th>Salle</th>
                                      <th>Type</th>
                                  </tr>
                              </thead>

                              <ng-container *ngFor="let seances of table.days">
                                <tr *ngFor="let seance of seances" [style.display]="seance.related_id ? 'none' : 'table-row'">
                                  <td style="width:5% !important">
                                      <div class=" {{ seance.subject_type?.abbreviation == 'C'? 'cours'  : (seance.subject_type?.abbreviation== 'TD' ? 'td' : 'tp')}} p-1 text-center">
                                          <b>{{seance.subject_type?.abbreviation }}</b>
                                      </div>
                                  </td>
                                  <td style="width:22% !important ; ; text-align: center ; font-weight: 600;">

                                    <p *ngIf="seance.subject_type.abbreviation == 'TP' ; else nottp" >
                                      <span *ngIf="seance.period && seance.period.start_hour == '11:45:00'">12:15 {{seance.next && seance.next >1 ? ' - 15:15' : '13:45'}}</span>
                                                          <span *ngIf="seance.period && seance.period.start_hour == '08:30:00'">08:30 {{seance.next && seance.next >1 ? ' - 11h30' : '10:00'}}</span>
                                                          <span *ngIf="seance.period && seance.period.start_hour == '15:15:00'">15:30 {{seance.next && seance.next >1 ? ' - 18:30' : '17:00'}}</span>

                                                          <span *ngIf="seance.period && seance.period.start_hour == '10:15:00'">10:15 {{seance.next && seance.next >1 ? ' - 15:15' : '11:45'}}</span>
                                                          <span *ngIf="seance.period && seance.period.start_hour == '14:00:00'">14:00 {{seance.next && seance.next >1 ? ' - 17h00' : '15:30'}}</span>

                                    </p>

                                    <ng-template #nottp>
<!--
                                      {{ seance.start_hour? (seance.start_hour | slice:0:5) : (seance.period_id.start_hour | slice:0:5)  }} - {{ seance.end_hour ? (seance.end_hour | slice:0:5) :(seance.period_id.end_hour | slice:0:5)  }}
 -->

                                      <span *ngIf="seance.start_hour == '15:15:00' && (seance.end_hour == '18:00:00' || seance.end_hour == '18:15:00') ; else show_2"> 15:45 - 18:30</span>
                                      <ng-template #show_2>

                                      <span *ngIf="seance.start_hour ; else no_start_hour">
                                              <span *ngIf="seance.start_hour == '08:30:00'"> 08:30</span>
                                              <span *ngIf="seance.start_hour == '10:15:00'"> 10:15</span>
                                              <span *ngIf="seance.start_hour == '11:45:00'"> 12:15</span>
                                              <span *ngIf="seance.start_hour == '13:30:00'"> 14:00</span>
                                              <span *ngIf="seance.start_hour == '13:00:00'"> 13:00</span>
                                              <span *ngIf="seance.start_hour == '15:15:00'"> 15:45</span>
                                              <span *ngIf="seance.start_hour == '15:45:00'"> 15:45</span>
                                              <span *ngIf="seance.start_hour == '16:45:00'"> 17:15</span>
                                              <span *ngIf="seance.start_hour == '10:45:00'"> 10:45</span>

                                      </span>


                                      <ng-template #no_start_hour>
                                        <span *ngIf="seance.period_id?.start_hour == '08:30:00'"> 08:30</span>
                                        <span *ngIf="seance.period_id?.start_hour == '10:15:00'"> 10:15</span>
                                        <span *ngIf="seance.period_id?.start_hour == '11:45:00'"> 12:15</span>
                                        <span *ngIf="seance.period_id?.start_hour == '13:30:00'"> 14:00</span>
                                        <span *ngIf="seance.period_id?.start_hour == '15:15:00'"> 15:45</span>
                                        <span *ngIf="seance.period_id?.start_hour == '16:45:00'"> 17:15</span>

                                      </ng-template>



                                      -

                                      <span *ngIf="seance.end_hour ; else no_end_hour">
                                              <span *ngIf="seance.end_hour == '10:00:00'"> 10:00</span>
                                              <span *ngIf="seance.end_hour == '10:30:00'"> 10:30</span>
                                              <span *ngIf="seance.end_hour == '11:45:00'"> 11:45</span>
                                              <span *ngIf="seance.end_hour == '11:30:00'"> 11:30</span>
                                              <span *ngIf="seance.end_hour == '12:15:00'"> 12:15</span>
                                              <span *ngIf="seance.end_hour == '12:45:00'"> 12:45</span>
                                              <span *ngIf="seance.end_hour == '13:15:00'"> 13:45</span>
                                              <span *ngIf="seance.end_hour == '14:45:00'"> 14:45</span>
                                              <span *ngIf="seance.end_hour  == '15:00:00' && seance.start_hour== '13:00:00'"> 15:00</span>
                                              <span *ngIf="seance.end_hour  == '15:00:00' && seance.start_hour != '13:00:00'"> 15:30</span>
                                              <span *ngIf="seance.end_hour == '16:45:00'"> 17:15</span>
                                              <span *ngIf="seance.end_hour == '17:45:00'"> 17:45</span>
                                              <span *ngIf="seance.end_hour == '17:15:00'"> 17:45</span>
                                              <span *ngIf="seance.end_hour == '18:00:00'"> 18:30</span>

                                      </span>
                                      <ng-template #no_end_hour>
                                        <span *ngIf="seance.period_id?.end_hour == '10:00:00'"> 10:00</span>
                                        <span *ngIf="seance.period_id?.end_hour == '11:45:00'"> 11:45</span>
                                        <span *ngIf="seance.period_id?.end_hour == '13:15:00'"> 13:45</span>
                                        <span *ngIf="seance.period_id?.end_hour == '15:00:00'"> 15:30</span>
                                        <span *ngIf="seance.period_id?.end_hour == '16:45:00'"> 17:15</span>
                                        <span *ngIf="seance.period_id?.end_hour == '18:00:00'"> 18:30</span>

                                      </ng-template>




                                      </ng-template>


                                    </ng-template>


                                  </td>
                                  <td style="width:35% !important">
                                      {{ seance.subject?.abbreviation }}

                                  </td>
                                  <td style="width:15% !important">
                                      <span *ngIf="!seance.group_td && !seance.group_tp">{{ seance.section?.abbreviation }} </span>

                                      <span *ngIf="seance.section2"> & {{ seance.section2?.abbreviation }}</span>

                                      <span *ngIf="seance.group_td">
                                         {{ seance.group_td?.abbreviation }}
                                      </span>
                                      <span *ngIf="seance.group_tp">
                                         {{ seance.group_tp?.abbreviation }}
                                      </span>
                                    <!--   @if (seance.group_td)
                                          - {{ seance.group_td?.abbreviation }}
                                      @endif
                                      @if (seance.group_tp)
                                          - {{ seance.group_tp?.abbreviation }}
                                      @endif -->
                                  </td>
                                  <td style="width:8% !important ; text-align: center">
                                      {{ seance.classroom?.abbreviation }}
                                  </td>


                                  <td style="width:15% !important ; text-align: center">
                                      {{ seance.week_name }}
                                  </td>
                              </tr>
                              </ng-container>

                          </table>




              </div>
          </div>


  </div>
  </div>
