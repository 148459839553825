import { Period } from "./period";

export class EstablishmentSetting {

  id : number;
  use_group_td: Boolean;
  session_numbers : number;
  standard_duration : number;
  cours_best_sessions: any;
  td_best_sessions: any;
  tp_best_sessions: any;
  weekend_days: any;
  language: any;
  establishment_id : number;


  constructor(){
    this.establishment_id = JSON.parse(localStorage.getItem('user-infos')).establishment_id;
  }

}
