import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigService } from 'src/app/apis/config.service';

@Component({
  selector: 'app-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class UserMenuComponent implements OnInit {
  public userImage = "assets/img/users/user.jpg";
  constructor(private router : Router , private config : ConfigService) { }

  user = JSON.parse(localStorage.getItem('user-infos'))
  storage_name = this.config.storage_name;
  ngOnInit() {
  }

  logout(){
    localStorage.removeItem('user-infos');
    this.router.navigate(['/login'])
  }
}
