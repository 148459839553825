<app-content-header

    [hideBreadcrumb]="true"
    [class]="'py-3'" [hasBgImage]="true"
    [target]="'hierarchy'"></app-content-header>


    <mat-card>
        <mat-form-field fxFlex="25" class="pr-2 pl-2">
            <input   matInput placeholder="{{'search' | translate}}" (keyup)="filter($event.target.value)">
    
        </mat-form-field>
        <mat-form-field fxFlex="25" class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('sector')" 
                [(ngModel)]="selectedSector"  (selectionChange)="changeSector()">
                <mat-option *ngFor="let sector of sectors" [value]="sector.id">
                    {{sector.abbreviation}}</mat-option>
            </mat-select>

           
        </mat-form-field>
        <div fxFlex="50">
            <button mat-raised-button color="primary" class="ums_primary_button" [style.float]="'inverse_float' | translate" (click)="openDialog()">
                <mat-icon class="fs20">add</mat-icon> {{'buttons.add' | translate}}
              </button>
           
        </div>
    </mat-card>
    <div fxLayout="row wrap" fxLayoutAlign="center" class="p-2">
        <div fxFlex="100" class="p-2">
            
            <div class="mat-elevation-z8">
                <table mat-table style="width: 100%;" [dataSource]="dataSource">
    
                    
    
                    <!-- Name Column -->
                    <ng-container matColumnDef="designation">
                        <th mat-header-cell *matHeaderCellDef> {{'designation' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.designation}} </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="abbreviation">
                        <th mat-header-cell *matHeaderCellDef> {{'abbreviation' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.abbreviation}} </td>
                    </ng-container>
                   
                    <ng-container matColumnDef="sector">
                        <th mat-header-cell *matHeaderCellDef> {{'sector' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.sector?.abbreviation}} </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="actions" justify="end" >
                        <th mat-header-cell *matHeaderCellDef [style.text-align]="'inverse_float' | translate"> {{'actions' | translate}} </th>
                        <td mat-cell *matCellDef="let element" [style.text-align]="'inverse_float' | translate">
                            <mat-icon class="warning" (click)="openEditDialog(element)">edit</mat-icon>
                            <mat-icon class="danger" (click)="remove(element.id)">delete_outline</mat-icon>
    
                        </td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
    
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    
    </div>