import { ArchivesComponent } from './pages/time-table/archives/archives.component';
import { EquipmentsComponent } from './pages/establishment/equipments/equipments.component';
import { TimeTableInstituteComponent } from './pages/time-table/time-table-institute.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PagesComponent } from './pages/pages.component';
import { EstablishmentComponent } from './pages/establishment/establishment.component';
import { BuildingsComponent } from './pages/establishment/buildings/buildings.component';
import { CyclesComponent } from './pages/hierarchy/cycles/cycles.component';
import { LevelsComponent } from './pages/hierarchy/levels/levels.component';
import { SectorsComponent } from './pages/hierarchy/sectors/sectors.component';
import { SectionsComponent } from './pages/hierarchy/sections/sections.component';
import { GroupsTdComponent } from './pages/hierarchy/groups-td/groups-td.component';
import { GroupsTpComponent } from './pages/hierarchy/groups-tp/groups-tp.component';
import { SubjectsComponent } from './pages/subjects/subjects.component';
import { SubjectRegimesComponent } from './pages/subjects/subject-regimes/subject-regimes.component';
import { SubjectTypesComponent } from './pages/subjects/subject-types/subject-types.component';
import { TeachersComponent } from './pages/teachers/teachers.component';
import { TeacherGradesComponent } from './pages/teachers/teacher-grades/teacher-grades.component';
import { TeacherTypesComponent } from './pages/teachers/teacher-types/teacher-types.component';
import { TimeTableComponent } from './pages/time-table/time-table.component';
import { BlankComponent } from './pages/blank/blank.component';
import { SearchComponent } from './pages/search/search.component';
import { ErrorComponent } from './pages/errors/error/error.component';
import { NotFoundComponent } from './pages/errors/not-found/not-found.component';
import { TranslateModule } from '@ngx-translate/core';
import { AuthGuardService } from './apis/auth/auth-guard.service';
import { BlocksComponent } from './pages/establishment/blocks/blocks.component';
import { ClassroomsComponent } from './pages/establishment/classrooms/classrooms.component';
import { DepartmentsComponent } from './pages/hierarchy/departments/departments.component';
import { PeriodsComponent } from './pages/establishment/periods/periods.component';
import { VacationsExamsComponent } from './pages/establishment/vacations-exams/vacations-exams.component';
import { WeekTypesComponent } from './pages/establishment/week-types/week-types.component';
import { TeacherTimeTableComponent } from './pages/time-table/teacher-time-table/teacher-time-table.component';
import { ClassroomsScheduleComponent } from './pages/time-table/classrooms-schedule/classrooms-schedule.component';
import { SubjectModulesComponent } from './pages/subjects/subject-modules/subject-modules.component';
import { ExamsComponent } from './pages/exams/exams.component';
import { MonotoringScheduleComponent } from './pages/exams/monotoring-schedule/monotoring-schedule.component';
import { NumberStudyHoursComponent } from './pages/study-plan/number-study-hours/number-study-hours.component';
import { TotalStudyHoursComponent } from './pages/study-plan/total-study-hours/total-study-hours.component';
import { AnnualStudyHoursComponent } from './pages/study-plan/annual-study-hours/annual-study-hours.component';
import { ClassroomOccupationsComponent } from './pages/time-table/classroom-occupations/classroom-occupations.component';
import { PrintComponent } from './print/print.component';
import { PrintTeacherTimetableComponent } from './print-teacher-timetable/print-teacher-timetable.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: '/login',
    pathMatch: 'full'
  },
  {path : 'print' , component: PrintComponent, data: { breadcrumb: 'Print' }},
  {path : 'print_teachers' , component: PrintTeacherTimetableComponent, data: { breadcrumb: 'Print teachers' }},
  {
    path: 'ums',
    component: PagesComponent,
    canActivate: [AuthGuardService],
    children: [
      { path: 'establishment', component: EstablishmentComponent },
      { path: 'buildings', component: BuildingsComponent },
      { path: 'blocks', component: BlocksComponent },
      { path: 'periods', component: PeriodsComponent },
      { path: 'vacations-exams', component: VacationsExamsComponent },
      { path: 'week-types', component: WeekTypesComponent },
      { path: 'classrooms', component: ClassroomsComponent },
      { path: 'equipments', component: EquipmentsComponent },
      { path: 'departments', component: DepartmentsComponent },
      { path: 'cycles', component: CyclesComponent },
      { path: 'levels', component: LevelsComponent },
      { path: 'sectors', component: SectorsComponent },
      { path: 'sections', component: SectionsComponent },
      { path: 'groups-td', component: GroupsTdComponent },
      { path: 'groups-tp', component: GroupsTpComponent },
      { path: 'subjects', component: SubjectsComponent },

      { path: 'subject-regimes', component: SubjectRegimesComponent },
      { path: 'subject-types', component: SubjectTypesComponent },
      { path: 'subject-modules', component: SubjectModulesComponent },
      { path: 'nb-study-hours', component: NumberStudyHoursComponent },
      { path: 'total-study-hours', component: TotalStudyHoursComponent },
      { path: 'annual-study-hours', component: AnnualStudyHoursComponent },

      { path: 'teachers', component: TeachersComponent },
      { path: 'teacher-grades', component: TeacherGradesComponent },
      { path: 'teacher-types', component: TeacherTypesComponent },
      { path: 'teachertimetable', component: TeacherTimeTableComponent },
      { path: 'classrooms-schedule', component: ClassroomsScheduleComponent },
      { path: 'classrooms-occupations', component: ClassroomOccupationsComponent },
      { path: 'timetable', component: TimeTableComponent },
      { path: 'timetables', component: TimeTableInstituteComponent },
      { path: 'archives_timetables', component: ArchivesComponent },
      { path: 'exams', component: ExamsComponent },
      { path: 'monotoring', component: MonotoringScheduleComponent },
      { path: '', loadChildren: './pages/dashboard/dashboard.module#DashboardModule', data: { breadcrumb: 'Dashboard' } },
      { path: 'dashboard', loadChildren: './pages/dashboard/dashboard.module#DashboardModule', data: { breadcrumb: 'Dashboard' } },
      { path: 'dynamic-menu', loadChildren: './pages/dynamic-menu/dynamic-menu.module#DynamicMenuModule', data: { breadcrumb: 'Dynamic Menu' } },
      { path: 'ui', loadChildren: './pages/ui/ui.module#UiModule', data: { breadcrumb: 'UI' } },
      { path: 'mailbox', loadChildren: './pages/mailbox/mailbox.module#MailboxModule', data: { breadcrumb: 'Mailbox' } },
      { path: 'chat', loadChildren: './pages/chat/chat.module#ChatModule', data: { breadcrumb: 'Chat' } },
      { path: 'form-controls', loadChildren: './pages/form-controls/form-controls.module#FormControlsModule', data: { breadcrumb: 'Form Controls' } },
      { path: 'tables', loadChildren: './pages/tables/tables.module#TablesModule', data: { breadcrumb: 'Tables' } },
      { path: 'schedule', loadChildren: './pages/schedule/schedule.module#ScheduleModule', data: { breadcrumb: 'Schedule' } },
      { path: 'maps', loadChildren: './pages/maps/maps.module#MapsModule', data: { breadcrumb: 'Maps' } },
      { path: 'charts', loadChildren: './pages/charts/charts.module#ChartsModule', data: { breadcrumb: 'Charts' } },
      { path: 'drag-drop', loadChildren: './pages/drag-drop/drag-drop.module#DragDropModule', data: { breadcrumb: 'Drag & Drop' } },
      { path: 'icons', loadChildren: './pages/icons/icons.module#IconsModule', data: { breadcrumb: 'Material Icons' } },
      { path: 'blank', component: BlankComponent, data: { breadcrumb: 'Blank page' } },
      { path: 'search', component: SearchComponent, data: { breadcrumb: 'Search' } },
      { path: 'search/:name', component: SearchComponent, data: { breadcrumb: 'Search' } }
    ]
  },
  { path: 'landing', loadChildren: './pages/landing/landing.module#LandingModule' },
  { path: 'login', loadChildren: './pages/login/login.module#LoginModule' },
  { path: 'register', loadChildren: './pages/register/register.module#RegisterModule' },
  { path: 'error', component: ErrorComponent, data: { breadcrumb: 'Error' } },
  { path: '**', component: NotFoundComponent }
];
@NgModule({
  imports: [RouterModule.forRoot(routes), TranslateModule],
  exports: [RouterModule, TranslateModule]
})
export class AppRoutingModule { }
