<div fxLayout="row wrap" fxLayoutAlign="space-between center" class="info-content has-bg-image" [class.show]="showInfoContent">
    <button mat-mini-fab color="warn" class="close-btn" (click)="closeInfoContent(showInfoContent)"><mat-icon>close</mat-icon></button>
    <div fxFlex="100" fxFlex.gt-sm="40" class="py-3 col">
        <div fxLayout="row" fxLayoutAlign="center center">
            <img src="assets/nachdit.png" class="mat-elevation-z2" alt="" style="background: #fff;">
            <img src="assets/startupact.png" class="mat-elevation-z2" alt="" style="background: #fff;">
            <div class="user-details px-4">
                <h2>Nachd IT</h2>
                <h3 class="secondary-text-color">IT Company</h3>
                <p class="secondary-text-color mt-1">Since May. 2017</p>
                <div class="secondary-text-color">
                    <button mat-icon-button matTooltip="Facebook" class="mx-1" >
                        <a href="https://www.facebook.com/nachd.it" target="_blank">
                        <svg class="mat-icon" viewBox="0 0 24 24">
                            <path d="M17,2V2H17V6H15C14.31,6 14,6.81 14,7.5V10H14L17,10V14H14V22H10V14H7V10H10V6A4,4 0 0,1 14,2H17Z" />
                        </svg>
                        </a>
                    </button>
                    <button mat-icon-button matTooltip="Linkedin" class="mx-1">
                        <a href="https://www.linkedin.com/company/nachd-it" target="_blank">
                        <svg class="mat-icon" viewBox="0 0 24 24">
                            <path d="M22.46,6C21.69,6.35 20.86,6.58 20,6.69C20.88,6.16 21.56,5.32 21.88,4.31C21.05,4.81 20.13,5.16 19.16,5.36C18.37,4.5 17.26,4 16,4C13.65,4 11.73,5.92 11.73,8.29C11.73,8.63 11.77,8.96 11.84,9.27C8.28,9.09 5.11,7.38 3,4.79C2.63,5.42 2.42,6.16 2.42,6.94C2.42,8.43 3.17,9.75 4.33,10.5C3.62,10.5 2.96,10.3 2.38,10C2.38,10 2.38,10 2.38,10.03C2.38,12.11 3.86,13.85 5.82,14.24C5.46,14.34 5.08,14.39 4.69,14.39C4.42,14.39 4.15,14.36 3.89,14.31C4.43,16 6,17.26 7.89,17.29C6.43,18.45 4.58,19.13 2.56,19.13C2.22,19.13 1.88,19.11 1.54,19.07C3.44,20.29 5.7,21 8.12,21C16,21 20.33,14.46 20.33,8.79C20.33,8.6 20.33,8.42 20.32,8.23C21.16,7.63 21.88,6.87 22.46,6Z" />
                        </svg>
                        </a>
                    </button>
                    <button mat-icon-button matTooltip="Website" class="mx-1">
                        <a href="https://www.nachd-it.com" target="_blank">
                        <mat-icon style="color: red;">
                            language
                        </mat-icon>
                        </a>
                    </button>
                </div>
            </div> 
        </div>                          
    </div>
    <div fxFlex="100" fxFlex.gt-sm="35" fxFlex.sm="50" ngClass.xs="pb-4" class="top-contact-form col">
       <!--  <form [formGroup]="contactForm" (ngSubmit)="onContactFormSubmit(contactForm.value)" fxLayout="row wrap" class="px-2">            
            <div fxFlex="100" class="">
                <mat-form-field class="w-100">
                    <input matInput placeholder="Email" formControlName="email" required>
                    <mat-error *ngIf="contactForm.controls.email.errors?.required">Email is required</mat-error>
                    <mat-error *ngIf="contactForm.controls.email.hasError('invalidEmail')">Invalid email address</mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="100" class="">
                <mat-form-field class="w-100">
                    <input matInput placeholder="Subject" formControlName="subject" required>
                    <mat-error *ngIf="contactForm.controls.subject.errors?.required">Subject is required</mat-error>
                </mat-form-field>
            </div>
            <div fxFlex="100" class="">
                <mat-form-field class="w-100">
                    <input matInput placeholder="Message" formControlName="message" required>
                    <mat-error *ngIf="contactForm.controls.message.errors?.required">Message is required</mat-error>
                </mat-form-field>
            </div> 
            <div class="w-100 mt-1 text-center">
                <button mat-raised-button color="accent" type="submit">
                    <mat-icon class="mr-2">send</mat-icon>Send
                </button>
            </div> 
        </form> -->
        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3235.4897751254284!2d10.635443515622642!3d35.812457280163876!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1302758ce6612a71%3A0xef9ba1ce6271c19!2sNachd%20IT!5e0!3m2!1sfr!2stn!4v1627133381481!5m2!1sfr!2stn" width="400" height="220" style="border:0;" allowfullscreen="" loading="lazy"></iframe>
    </div>
    <div fxFlex="50" fxFlex.gt-sm="25" fxShow="false" fxShow.gt-xs class="py-3 col">
        <div fxLayout="column" fxLayoutAlign="center start" class="controls secondary-text-color" style="width: auto; margin : 17px">
           <p> <mat-icon>call</mat-icon> +216 27 751 305</p>
           <p><mat-icon>call</mat-icon> +216 90 541 577</p>
            <p>  <mat-icon>alternate_email</mat-icon> nachd-it.service@gmail.com</p>
                <p> <mat-icon>place</mat-icon>Cité Tafféla , IBN Khaldoun , Sousse 4003</p>
           <!--  <mat-checkbox *ngFor="let control of controls" class="my-1" [(ngModel)]="control.checked" labelPosition="after">
                {{control.name}}
            </mat-checkbox> -->
        </div>
    </div>    
</div>