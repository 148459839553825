<app-content-header [hasBgImage]="true" [hideBreadcrumb]="true" [class]="'py-3'" [target]="'exams'">
</app-content-header>


<div fxLayout="row" class="p-2">
    <div fxFlex="100" class="p-2">
        <mat-card>
            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('sector')" [(ngModel)]="selectedSector"
                    (selectionChange)="handleSectorChange()">
                    <mat-option *ngFor="let sector of sectors" [value]="sector.id">
                        {{sector.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>
            <!--    <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('ExamsType')" [(ngModel)]="selectedLevel"
                    (selectionChange)="handleSectorChange()">
                    <mat-option *ngFor="let level of levels" [value]="level">
                        {{level.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>
       -->

            <div fxFlex="60">

                <!--              <mat-icon class="icon-add" [style.float]="'inverse_float' | translate" (click)="export()"
                    title="export data"> cloud_download</mat-icon> &nbsp;
                &nbsp;
                <mat-icon class="icon-add" [style.float]="'inverse_float' | translate" (click)="print()" title="print">
                    print</mat-icon> &nbsp; &nbsp;
                <mat-icon class="icon-add" [style.float]="'inverse_float' | translate" (click)="generateDialog()"
                    title="start generation">model_training</mat-icon> &nbsp; -->

                <button mat-raised-button color="basic" class="ums_basic_button"
                    [style.float]="'inverse_float' | translate" (click)="print()">
                    <mat-icon class="fs20">print</mat-icon> {{'buttons.print' | translate}}
                </button>
                <button mat-raised-button color="basic" class="ums_basic_button"
                    [style.float]="'inverse_float' | translate" (click)="export()">
                    <mat-icon class="fs20">download</mat-icon> {{'buttons.export' | translate}}
                </button>

            </div>
        </mat-card>
    </div>
</div>
<mat-progress-bar *ngIf="show_progess" mode="indeterminate"></mat-progress-bar>

<div fxLayout="row" class="p-2">
    <div fxFlex="100" class="p-2">

        <mat-card>
            <mat-card-content>
                <table class="table" id="print_ums_id" *ngIf="schedule && schedule.length >0 ; else no_data">
                    <thead>
                        <tr>
                            <th rowspan="3">Discipline</th>
                            <th rowspan="3">Module</th>
                            <th rowspan="1">Periode</th>
                            <th colspan="16">Nombre des heures d'études</th>
                        </tr>
                        <tr>

                            <th rowspan="2">(*)</th>
                            <th colspan="4">Théorique</th>
                            <th colspan="4">Dirigé</th>
                            <th colspan="4">Pratique</th>
                            <th colspan="4">Intégré</th>
                        </tr>
                        <tr>

                            <th>Nombre des heures / semaine</th>
                            <th>Nombre des groupes</th>
                            <th> Nombre des semaines</th>
                            <th>Nombre totale des heures</th>

                            <th>Nombre des heures / semaine</th>
                            <th>Nombre des groupes</th>
                            <th> Nombre des semaines</th>
                            <th>Nombre totale des heures</th>

                            <th>Nombre des heures / semaine</th>
                            <th>Nombre des groupes</th>
                            <th> Nombre des semaines</th>
                            <th>Nombre totale des heures</th>

                            <th>Nombre des heures / semaine</th>
                            <th>Nombre des groupes</th>
                            <th> Nombre des semaines</th>
                            <th>Nombre totale des heures</th>
                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let row of schedule">
                            <tr *ngFor="let subject of row.subjects ; let i = index">
                                <th *ngIf="i==0" [attr.rowspan]="row.subjects.length"> {{row.designation}}</th>
                                <td>{{subject.abbreviation}}</td>
                                <td>{{subject.year_division_id}}</td>
                                <ng-container *ngFor="let regime of subject.regimes"></ng-container>
                                <ng-container *ngIf="subject.c && subject.c.length > 0">
                                    <td> {{subject.c[0]?.pivot?.nb_hours }} </td>
                                    <td> {{ subject.c[0]?.pivot.group_id && subject.c[0].pivot.group_id.length >2 ?
                                        JSONparse(subject.c[0]?.pivot?.group_id) : 1}}</td>
                                    <td> 14 </td>
                                    <td>{{subject.c[0]?.pivot.group_id && subject.c[0].pivot.group_id.length >2 ?
                                        JSONparse(subject.c[0]?.pivot?.group_id)*14*subject.c[0]?.pivot?.nb_hours : 14}}
                                    </td>

                                </ng-container>

                                <ng-container *ngIf="!subject.c || subject.c.length == 0">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </ng-container>
                                <ng-container *ngIf="subject.td && subject.td.length > 0">
                                    <td> {{subject.td[0]?.pivot?.nb_hours }} </td>
                                    <td> {{ subject.td[0]?.pivot.group_id && subject.td[0].pivot.group_id.length >2 ?
                                        JSONparse(subject.td[0]?.pivot?.group_id) : 1}}</td>
                                    <td> 14 </td>
                                    <td>{{subject.td[0]?.pivot.group_id && subject.td[0].pivot.group_id.length >2 ?
                                        JSONparse(subject.td[0]?.pivot?.group_id)*14*subject.td[0]?.pivot?.nb_hours :
                                        14}} </td>

                                </ng-container>
                                <ng-container *ngIf="!subject.td || subject.td.length == 0">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </ng-container>
                                <!--                         <td> {{subject.td && subject.td.length > 0 ? subject.td[0]?.pivot?.nb_hours :''}} </td>
                            <td> {{subject.td && subject.td.length > 0 ? (subject.td[0]?.pivot.group_id && subject.td[0].pivot.group_id.length >2 ? JSONparse(subject.td[0]?.pivot?.group_id) : 1) :''}}</td>
                            <td> 14</td>
                            <td> {{subject.td && subject.td.length > 0 ? (subject.td[0]?.pivot.group_id && subject.td[0].pivot.group_id.length >2 ? JSONparse(subject.td[0]?.pivot?.group_id)*14 : 14) :''}}</td>
 -->


                                <ng-container *ngIf="subject.tp && subject.tp.length > 0">
                                    <td> {{subject.tp[0]?.pivot?.nb_hours }} </td>
                                    <td> {{ subject.tp[0]?.pivot.group_id && subject.tp[0].pivot.group_id.length >2 ?
                                        JSONparse(subject.tp[0]?.pivot?.group_id) : 1}}</td>
                                    <td> 14 </td>
                                    <td>{{subject.tp[0]?.pivot.group_id && subject.tp[0].pivot.group_id.length >2 ?
                                        JSONparse(subject.tp[0]?.pivot?.group_id)*14*subject.tp[0]?.pivot?.nb_hours :
                                        14}} </td>

                                </ng-container>
                                <ng-container *ngIf="!subject.tp || subject.tp.length == 0">
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </ng-container>
                                <td></td>
                                <td></td>
                                <td></td>
                                <td></td>

                            </tr>
                            <tr>
                                <th>Totale discipline</th>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td > {{getTotal('c' , row.subjects)}} </td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td >  {{getTotal('td' , row.subjects)}} </td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td >  {{getTotal('tp' , row.subjects)}} </td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td > 0</td>

                            </tr>
                        </ng-container>
                        <tr>
                            <th>Totale Général</th>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <th > {{totale_c}}</th>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <th > {{totale_td}}</th>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <th > {{totale_tp}}</th>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <th > 0</th>

                    </tbody>
                </table>
                <ng-template #no_data>
                    <div class="empty_frame">
                        <img src="assets/empty.png">
                    </div>
                </ng-template>
            </mat-card-content>
        </mat-card>

    </div>

</div>