import { Cycle } from './../../../viewModels/cycle';
import { Level } from './../../../viewModels/level';
import { Sector } from './../../../viewModels/sector';
import { Component, OnInit } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { Subject } from 'src/app/viewModels/subject';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { ToastrService } from 'ngx-toastr';
import { MatDialog } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { SectorFormComponent } from './sector-form/sector-form.component';
import { SectorAvailibilityComponent } from './sector-availibility/sector-availibility.component';
import { ExcelService } from 'src/app/apis/excel/excel.service';
declare var $ : any;
@Component({
  selector: 'app-sectors',
  templateUrl: './sectors.component.html',
  styleUrls: ['./sectors.component.css']
})
export class SectorsComponent implements OnInit {

  items: Sector[] = [];

  displayedColumns: string[] = ['code','designation', 'abbreviation', 'level', 'department', 'actions'];
  dataSource = new MatTableDataSource<Sector>(this.items);
  selectedCycle: number;
  selectedLevel: number;
  levels: Level[] = []
  cycles: Cycle[] = []
  sectors: Sector[];

  constructor(
    private apis: HiararchyService,
    public translate: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private excelService : ExcelService

  ) { }

  item: Sector = new Sector();
  show_form = false;




  ngOnInit(): void {

    this.getCycles()
  }


  getCycles() {
    this.apis.getHiararchyCycles()
      .subscribe((data: Cycle[]) => {
        if (!data || data.length == 0) {
          this.router.navigate(['/ums/cycles'])
        } else {
          this.cycles = data;

          this.selectedCycle = this.cycles[0].id;
          console.log(this.cycles);
          this.getLevels();



        }
      })
  }



  changeCycle() {
    console.log(this.selectedCycle)
    this.apis.getCycleLevels(this.selectedCycle)
      .subscribe((data: Level[]) => {
        this.levels = data;
        if(this.levels && this.levels.length >0){
          this.selectedLevel = this.levels[0].id;
          this.getSectors();
        }
      })
  }

  getLevels() {
    if (this.selectedCycle) {
      this.apis.getCycleLevels(this.selectedCycle)
        .subscribe((data: Level[]) => {
          this.levels = data;
          if (this.levels && this.levels.length > 0) {
            this.selectedLevel = this.levels[0].id
            this.getSectors();
          }
        })
    }

  }

  getSectors() {
    if (this.selectedLevel) {
      this.apis.getLevelSectors(this.selectedLevel)
        .subscribe((data: Sector[]) => {
          this.items = data;
          console.log(this.items)
          this.dataSource = new MatTableDataSource<Sector>(this.items);
        })
    } else {
      this.items = [];
    }

  }




  save() {
    console.log(this.item)
    this.apis.createOrUpdateSector(this.item)
      .subscribe((data) => {
        this.getSectors()
        this.showSuccess('saved');
        this.item = new Sector();
      })
  }

  edit(element) {
    this.item = element
  }

  remove(id) {
    Swal.fire({
      title: this.translate.instant('alerts.delete_title'),
      text: this.translate.instant('alerts.delete_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('alerts.delete_yes'),
      cancelButtonText: this.translate.instant('alerts.delete_no')
    }).then((result) => {
      if (result.value) {
        this.apis.removeSector(id)
          .subscribe(() => {
            this.getSectors();
            Swal.fire(
              this.translate.instant('alerts.deleted'),
              this.translate.instant('alerts.deleted_text'),
              'success'
            )
          })

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.translate.instant('alerts.canceled'),
          this.translate.instant('alerts.canceled_text'),
          'error'
        )
      }
    })
  }

  showSuccess(msg) {

    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg) {
    this.toastr.error(this.translate.instant((msg)))
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(SectorFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.createOrUpdateSector(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.getSectors();

            this.item = new Sector()
          })
      }

    });
  }

  openEditDialog(sector): void {
    this.item = sector;
    const dialogRef = this.dialog.open(SectorFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.createOrUpdateSector(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.getSectors();

            this.item = new Sector()
          })
      }

    });
  }

  filter(v) {
    this.dataSource.filter = v.trim().toLowerCase();
  }

  changeLevel() {
    console.log(this.selectedLevel)
    this.getSectors()
  }


  async openAvailabilityDialog(sector){
    
    const dialogRef = this.dialog.open(SectorAvailibilityComponent, {
      width: '500px',
      data: {sector : sector ,  availibility : []}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(result)
      if (result) {
        this.apis.setSectorAvailibility(sector.id , result.availibility)
        .subscribe(()=>{
          this.showSuccess('saved')
        })
      }

    });
  }


  handleFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {

      const bstr: string = e.target.result;
      const data = <any[]>this.excelService.importFromFile(bstr);

      const header = data[0];
      //const header: string[] = Object.getOwnPropertyNames(new Subject());
      const importedData = data.slice(1, -1);
  

      let imported_subjects = importedData.map(arr => {
        
        const obj = {};
         for (let i = 0; i < header.length; i++) {
          const k = header[i];          
          obj[k] = arr[i];
        } 
        return <Sector>obj;
      })

     console.log(imported_subjects)
        this.apis.importSectors(imported_subjects)
      .subscribe((data : any)=>{
        console.log(data)
        if(this.selectedLevel){
          this.changeLevel();
        }

        if(data && data.imported_rows > 0){
          this.showSuccess( 'files_imported '+ data.imported_rows)
        }
        if(data && data.failed_rows > 0){
          this.showError( 'failed_imported ' + data.failed_rows)
        }
      });  
      

    };
    reader.readAsBinaryString(target.files[0]);

  }
  import(){
    $('#file').click();
  }
}

