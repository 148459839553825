<div mat-dialog-title>
    <h1>{{translate.instant('CreateNewSession/Extra-session')}}</h1>
</div>
<div mat-dialog-content>
    <form fxLayout="column" [formGroup]="form">
        <mat-form-field class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('subject')" [formControlName]="'subject_id'" [(ngModel)]="data.subject_id" (selectionChange)="handleSubjectchange()">
                <mat-option *ngFor="let subject of  subjects" [value]="subject.id">
                    {{subject.abbreviation}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="pr-2 pl-2">
            <input matInput type="text" placeholder="{{translate.instant('display_name')}} {{translate.instant('display_name_not_required')}}"
                [formControlName]="'display_name'"  [(ngModel)]="data.display_name">
        </mat-form-field>
        <mat-form-field class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('teacher')" [formControlName]="'teacher_id'"  [(ngModel)]="data.teacher_id">
                <mat-option *ngFor="let teacher of  teachers" [value]="teacher.id">
                    {{teacher.first_name}} {{teacher.last_name}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('day')" [formControlName]="'day_id'"  [(ngModel)]="data.day_id" (selectionChange)="handleDayChange()">
                <mat-option *ngFor="let day of  days" [value]="day.id">
                    {{day.designation}}</mat-option>
            </mat-select>
        </mat-form-field>
        <mat-form-field class="pr-2 pl-2" >
            <input matInput type="date"  placeholder="{{translate.instant('date')}}"  
                [formControlName]="'start_date'"  [(ngModel)]="data.start_date">
        </mat-form-field>
        <mat-form-field class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('period')" [formControlName]="'period_id'"  [(ngModel)]="data.period_id" (selectionChange)="handlePeriodChange()">
                <mat-option *ngFor="let period of  periods" [value]="period.id">
                    {{period.abbreviation}}</mat-option>
            </mat-select>
         </mat-form-field>
         <mat-form-field class="pr-2 pl-2" *ngIf="data.period_id">
            <input matInput type="time"  placeholder="{{translate.instant('start_hour')}}"
                [formControlName]="'start_hour'"  [(ngModel)]="data.start_hour">
        </mat-form-field>
        <mat-form-field class="pr-2 pl-2" *ngIf="data.period_id">
            <input matInput type="time"  placeholder="{{translate.instant('end_hour')}}"
                [formControlName]="'end_hour'"  [(ngModel)]="data.end_hour">
        </mat-form-field>
      
         <mat-form-field class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('type')" [formControlName]="'type'"  [(ngModel)]="data.type">
                <mat-option *ngFor="let type of  session_types" [value]="type.slug">
                    {{type.value}}</mat-option>
            </mat-select>
         </mat-form-field>
         <mat-form-field class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('subject_type')" [formControlName]="'subject_type_id'"  [(ngModel)]="data.subject_type_id" >
                <mat-option *ngFor="let type of  subject_types" [value]="type.id">
                    {{type.abbreviation}}</mat-option>
            </mat-select>
        </mat-form-field>
         <mat-form-field class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('regime')" [formControlName]="'week_type_id'"  [(ngModel)]="data.week_type_id"  (selectionChange)="handleWeekChange()">
                <mat-option *ngFor="let week of  week_types" [value]="week.id">
                    {{week.designation}}</mat-option>
            </mat-select>
         </mat-form-field>
         <mat-form-field class="pr-2 pl-2" *ngIf="data.week_type_id">
            <mat-select [placeholder]="translate.instant('week_name')" [formControlName]="'week_name'"  [(ngModel)]="data.week_name">
                <mat-option *ngFor="let week of  week_names" [value]="week">
                    {{week}}</mat-option>
            </mat-select>
         </mat-form-field>
         
         <!-- <mat-form-field class="pr-2 pl-2" *ngIf="">
            <mat-select [placeholder]="translate.instant('regime')" [formControlName]="'week_type_id'">
                <mat-option *ngFor="let week of  week_names" [value]="week.id">
                    {{week.designation}}</mat-option>
            </mat-select>
         </mat-form-field> -->

         <mat-form-field class="pr-2 pl-2">
            <mat-select [placeholder]="translate.instant('classroom')" [formControlName]="'classroom_id'"  [(ngModel)]="data.classroom_id">
                <mat-option *ngFor="let classroom of  classrooms" [value]="classroom.id">
                    {{classroom.designation}}</mat-option>
            </mat-select>
         </mat-form-field>
        <!-- 
        <mat-form-field class="pr-2 pl-2">
            <input matInput type="number" placeholder="{{translate.instant('nb_courses_same_unity_per_day')}}"
                [(ngModel)]="data.nb_courses_same_unity_per_day" required
                [formControlName]="'nb_courses_same_unity_per_day'">


        </mat-form-field>
        <mat-form-field class="pr-2 pl-2">
            <input matInput type="number" placeholder="{{translate.instant('nb_courses_transversal_per_day')}}"
                [(ngModel)]="data.nb_courses_transversal_per_day" required
                [formControlName]="'nb_courses_transversal_per_day'">


        </mat-form-field>

        <mat-form-field>
            <mat-select [placeholder]="translate.instant('set_generation_priority_to')"
                [formControlName]="'set_generation_priority_to'" [(ngModel)]="data.set_generation_priority_to" required>
                <mat-option *ngFor="let type of subject_types" [value]="type.abbreviation">
                    {{type.abbreviation}}</mat-option>
            </mat-select>


        </mat-form-field>
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('set_major_tp_on')" [formControlName]="'set_major_tp_on'"
                [(ngModel)]="data.set_major_tp_on" required>
                <mat-option *ngFor="let type of tp_major_sessions" [value]="type.slug">
                    {{type.value | translate}}</mat-option>
            </mat-select>


        </mat-form-field>

        <mat-form-field>
            <mat-select [placeholder]="translate.instant('division')" [formControlName]="'division_id'"
                [(ngModel)]="data.division_id" required>
                <mat-option *ngFor="let division of divisions" [value]="division.id">
                    {{division.abbreviation}}</mat-option>
            </mat-select>


        </mat-form-field>

        <mat-checkbox formControlName="is_all_sectors" [(ngModel)]="data.is_all_sectors">
            {{'is_all_sectors' | translate}}</mat-checkbox>
        <mat-form-field *ngIf="!data.is_all_sectors">
            <mat-select [placeholder]="'sectors' | translate" [formControlName]="'sectors_ids'"
                [(ngModel)]="data.sectors_ids" multiple>
                <mat-option *ngFor="let sector of sectors" [value]="sector.id">{{sector.abbreviation}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-checkbox formControlName="generate_vacant_sessions" [(ngModel)]="data.generate_vacant_sessions">
            {{'generate_vacant_sessions' | translate}}</mat-checkbox> -->

    </form>
</div>
<div mat-dialog-actions>

    <button mat-raised-button color="basic" class=" ums_basic_button form-cancel"
        (click)="onNoClick()">{{'buttons.no_thanks' | translate}}</button>
    <button mat-raised-button color="primary" [disabled]="!form.valid" class="ums_primary_button form-save"
        [mat-dialog-close]="data" cdkFocusInitial>
        {{'buttons.generate' | translate}}
    </button>
</div>