import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { Department } from 'src/app/viewModels/department';

@Component({
  selector: 'app-departments',
  templateUrl: './departments.component.html',
  styleUrls: ['./departments.component.css']
})
export class DepartmentsComponent implements OnInit {

  constructor(
    private apis :HiararchyService,
    public translate : TranslateService,
    private toastr : ToastrService) { }
  departments : Department[];
  new_department = new Department();
  show_form = false;

  form : FormGroup = new FormGroup({
    'designation' : new FormControl(Validators.required),
    'abbreviation' : new FormControl(Validators.required)

  })
  ngOnInit(): void { 
    this.getData(); 
  }
  getData(){
    this.apis.getHiararchyDepartments()
    .subscribe((data : Department[])=>{
      this.departments = data;
      
    })
  }
  showNewDepartmentForm(){
    this.show_form = true;
    
    
    this.new_department = new Department();
  }

  editDepartment(department){
    this.departments = this.departments.filter((x)=> x.id != department.id)
    this.new_department = department;
    this.show_form = true;
  }
  cancel(){
    this.show_form = false;
    this.new_department = new Department();
  }

  save(){
    this.apis.setDepartment(this.new_department)
    .subscribe((data)=>{
      console.log(data);
      this.showSuccess('success_save')
      this.getData();
      this.cancel();
    })

  }

  
  showSuccess(msg){

    this.toastr.success(this.translate.instant(msg));
  }

  showError(msg){
    this.toastr.error(this.translate.instant((msg)));
  }
}
