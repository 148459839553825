<app-content-header 

    [hideBreadcrumb]="true"
    [class]="'py-3'" [hasBgImage]="true"
    [target]="'hierarchy'">
</app-content-header> 

<div fxLayout="row wrap" fxLayoutAlign="center">
    <mat-card fxFlex="40" fxLayoutAlign="center">
        <mat-select [placeholder]="'select_cycle' | translate" [(ngModel)]="selected_cycle"
        (selectionChange)="changeCycle()">
        <mat-option *ngFor="let cycle of cycles" [value]="cycle.id">
            {{cycle.designation}}</mat-option>
    </mat-select>
    </mat-card>
  
        
    
</div>

<div fxLayout="row wrap" fxLayoutAlign="center" class="p-2">
    <div fxFlex="100" class="p-2">

        <div class=" p-2">
            <div fxLayout="row wrap" fxFlex="100">
                <div *ngFor="let level of levels " fxFlex="25" class="p-2">
                    <mat-card fxLayout="row" class="p-0" (click)="editLevel(level)">
                        <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center">

                            <div class="block-img bg-primary">
                                {{level.abbreviation}}
                            </div>



                            {{level.designation}}
                        </div>

                    </mat-card>
                </div>

                <div fxFlex="25" class="p-2">
                    <mat-card fxLayout="row" class="p-0">
                        <mat-card-header fxLayoutAlign="center" fxFlex="100" *ngIf="!show_form">
                            <mat-icon (click)="showNewLevelForm()">add</mat-icon>
                        </mat-card-header>
                        <mat-card-content>
                            <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center" class="p-2" *ngIf="show_form">

                                <form fxLayout="column" fxLayoutAlign="center" [formGroup]="form" (ngSubmit)="save()">
                                    <mat-form-field>
                                        <input matInput placeholder="{{'designation' | translate}}"
                                            [(ngModel)]="new_level.designation" required
                                            [formControlName]="'designation'">
                                        <mat-hint></mat-hint>

                                    </mat-form-field>

                                    <mat-form-field>
                                        <input matInput placeholder="{{'abbreviation' | translate}}"
                                            [(ngModel)]="new_level.abbreviation" required
                                            [formControlName]="'abbreviation'">
                                        <mat-hint></mat-hint>

                                    </mat-form-field>
                                    <div fxFlex="100">
                                        <button mat-raised-button color="primary" fxFlex="50"
                                            class="mat-elevation-z6 w-50 mt-3 mr-1"
                                            type="submit">{{'buttons.save' | translate}}</button>
                                        <button mat-raised-button color="secondary" (click)="cancel()" fxFlex="50"
                                            class="mat-elevation-z6 w-50 mt-3 ml-1">{{'buttons.cancel' | translate}}</button>

                                    </div>


                                </form>
                            </div>
                        </mat-card-content>


                    </mat-card>
                </div>
            </div>



        </div>
    </div>

</div>