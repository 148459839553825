<app-content-header 
[hideBreadcrumb]="true" 
[class]="'py-3'" 
[hasBgImage]="true"
[target]="'establishment'">
</app-content-header>

<div fxLayout="row wrap" fxLayoutAlign="center">
    <mat-card fxFlex="40" fxLayoutAlign="center">
        <mat-select [placeholder]="'select_building' | translate" [(ngModel)]="selected_building"
        (selectionChange)="changeBuilding()">
        <mat-option *ngFor="let building of buildings" [value]="building.id">
            {{building.designation}}</mat-option>
    </mat-select>
    </mat-card>
  
        
    
</div>

<div fxLayout="row wrap" fxLayoutAlign="center" class="p-2">
    <div fxFlex="100" class="p-2">

        <div class=" p-2">
            <div fxLayout="row wrap" fxFlex="100">
                <div *ngFor="let block of blocks " fxFlex="25" class="p-2">
                    <mat-card fxLayout="row" class="p-0" (click)="editBlock(block)">
                        <div fxFlex="100" fxLayout="column" fxLayoutAlign="center center">

                            <div class="block-img bg-primary">
                                {{block.abbreviation}}
                            </div>



                            {{block.designation}}
                        </div>

                    </mat-card>
                </div>

                <div fxFlex="25" class="p-2">
                    <mat-card fxLayout="row" class="p-0" fxFlex="100">
                        <mat-card-header fxLayoutAlign="center" fxFlex="100" *ngIf="!show_form">
                            <mat-icon (click)="showNewBuildForm()">add</mat-icon>
                        </mat-card-header>
                        <mat-card-content  *ngIf="show_form" fxFlex="100">
                            <div  fxLayout="column" fxLayoutAlign="center center" class="p-2" >

                                <form fxLayout="column" style="width : 100% " fxLayoutAlign="center" [formGroup]="form" (ngSubmit)="save()">
                                    <mat-form-field>
                                        <input matInput placeholder="{{'designation' | translate}}"
                                            [(ngModel)]="new_block.designation" required
                                            [formControlName]="'designation'">
                                        <mat-hint></mat-hint>

                                    </mat-form-field>

                                    <mat-form-field>
                                        <input matInput placeholder="{{'abbreviation' | translate}}"
                                            [(ngModel)]="new_block.abbreviation" required
                                            [formControlName]="'abbreviation'">
                                        <mat-hint></mat-hint>

                                    </mat-form-field>
                                   
                                    <div fxFlex="100">
                                        <button mat-raised-button color="primary" fxFlex="50"
                                            class="mat-elevation-z6 w-50 mt-3 mr-1"
                                            type="submit">{{'buttons.save' | translate}}</button>
                                        <button mat-raised-button color="secondary" (click)="cancel()" fxFlex="50"
                                            class="mat-elevation-z6 w-50 mt-3 ml-1">{{'buttons.cancel' | translate}}</button>

                                    </div>


                                </form>
                            </div>
                        </mat-card-content>


                    </mat-card>
                </div>
            </div>



        </div>
    </div>

</div>