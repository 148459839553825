<div mat-dialog-title><h2 >{{translate.instant('update_session')}}</h2>
<b style="color: black"> {{ data.subject?.designation}}</b>
<hr/>
</div>
<div mat-dialog-content >
  <!--   <div class="pr-2 pl-2">
       <h4> {{'from' | translate }} </h4>
       {{period.source_day?.abbreviation}} - {{data.source_period?.abbreviation}}
    </div>
    <div class="pr-2 pl-2">
        <h4> {{'to' | translate }} </h4>
        {{data.target_day?.abbreviation}} - {{data.target_period?.abbreviation}}
     </div> -->
    <form fxLayout="column" [formGroup]="form"  >
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('teacher')" [formControlName]="'teacher_id'"
                [(ngModel)]="data.teacher_id" required>
                <mat-option *ngFor="let teacher of teachers" [value]="teacher?.id">
                    {{teacher?.first_name}}  {{teacher?.last_name}}</mat-option>
            </mat-select>


        </mat-form-field>
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('classroom')" [formControlName]="'classroom_id'"
                [(ngModel)]="data.classroom_id" required>
                <mat-option *ngFor="let classroom of classrooms" [value]="classroom?.id">
                    {{classroom?.abbreviation}}</mat-option>
            </mat-select>


        </mat-form-field>
     <mat-form-field class="pr-2 pl-2" >
        <input matInput type="time" placeholder="{{translate.instant('start_hour')}}" [(ngModel)]="data.start_hour"
                 [formControlName]="'start_hour'">
    <!--
        Start : {{ data.start_hour ? data.start_hour  : data.period.start_hour}}
        End : {{ data.end_hour ? data.end_hour  : data.period.end_hour}}     -->
        </mat-form-field>

        <mat-form-field class="pr-2 pl-2" >
            <input matInput type="time" placeholder="{{translate.instant('end_hour')}}" [(ngModel)]="data.end_hour"
                     [formControlName]="'end_hour'">

            </mat-form-field>

            <mat-form-field>
              <mat-select [placeholder]="'Groupe additionnel'" [formControlName]="'section2_id'"
                  [(ngModel)]="data.section2_id" >
                  <mat-option *ngFor="let section of allsections" [value]="section?.id">
                      {{section.abbreviation}}</mat-option>
              </mat-select>
        </mat-form-field>
        <!--
        <mat-form-field class="pr-2 pl-2" >
            <input matInput type="number" placeholder="{{translate.instant('nb_courses_basic_unity_per_day')}}" [(ngModel)]="data.nb_courses_basic_unity_per_day"
                required [formControlName]="'nb_courses_basic_unity_per_day'">


        </mat-form-field>
        <mat-form-field class="pr-2 pl-2" >
            <input matInput type="number" placeholder="{{translate.instant('nb_courses_same_unity_per_day')}}" [(ngModel)]="data.nb_courses_same_unity_per_day"
                required [formControlName]="'nb_courses_same_unity_per_day'">


        </mat-form-field>
        <mat-form-field class="pr-2 pl-2" >
            <input matInput type="number" placeholder="{{translate.instant('nb_courses_transversal_per_day')}}" [(ngModel)]="data.nb_courses_transversal_per_day"
                required [formControlName]="'nb_courses_transversal_per_day'">


        </mat-form-field>

        <mat-form-field>
            <mat-select [placeholder]="translate.instant('set_generation_priority_to')" [formControlName]="'set_generation_priority_to'"
                [(ngModel)]="data.set_generation_priority_to" required>
                <mat-option *ngFor="let type of subject_types" [value]="type.abbreviation">
                    {{type.abbreviation}}</mat-option>
            </mat-select>


        </mat-form-field>
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('set_major_tp_on')" [formControlName]="'set_major_tp_on'"
                [(ngModel)]="data.set_major_tp_on" required>
                <mat-option *ngFor="let type of tp_major_sessions" [value]="type.slug">
                    {{type.value}}</mat-option>
            </mat-select>


        </mat-form-field>

        <mat-form-field>
            <mat-select [placeholder]="translate.instant('division')" [formControlName]="'division_id'"
                [(ngModel)]="data.division_id" required>
                <mat-option *ngFor="let division of divisions" [value]="division.id">
                    {{division.abbreviation}}</mat-option>
            </mat-select>


        </mat-form-field>

        <mat-checkbox formControlName="is_all_sectors" [(ngModel)]="data.is_all_sectors">
            {{'is_all_sectors' | translate}}</mat-checkbox>
        <mat-form-field *ngIf="!data.is_all_sectors">
            <mat-select [placeholder]="'sectors' | translate" [formControlName]="'sectors_ids'"
                [(ngModel)]="data.sectors_ids" multiple>
                <mat-option *ngFor="let sector of sectors" [value]="sector.id">{{sector.abbreviation}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-checkbox formControlName="generate_vacant_sessions" [(ngModel)]="data.generate_vacant_sessions">
            {{'generate_vacant_sessions' | translate}}</mat-checkbox> -->

    </form>
</div>
<div mat-dialog-actions>
  <button  class="form-cancel" (click)="onNoClick()">{{'buttons.no_thanks' | translate }}</button>
  <button class="form-save" [disabled]="!form.valid" [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>

