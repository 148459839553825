import { Level } from './../../../viewModels/level';
import { Component, OnInit } from '@angular/core';
import { Cycle } from 'src/app/viewModels/cycle';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';

@Component({
  selector: 'app-levels',
  templateUrl: './levels.component.html',
  styleUrls: ['./levels.component.css']
})
export class LevelsComponent implements OnInit {

  selectedItem : any;
  cycles : Cycle[];
  selected_cycle : number;
  constructor(
    private apis : HiararchyService,
    public translate : TranslateService,
    private router : Router,
    private toastr : ToastrService

  ) { }
   levels : Level[];
   new_level: Level ;
   show_form = false;

   form : FormGroup = new FormGroup({
    'designation' : new FormControl(Validators.required),
    'abbreviation' : new FormControl(Validators.required)

  })
  ngOnInit(): void {
    this.getData();
  }


  getData(){
    this.apis.getHiararchyCycles()
    .subscribe((data : Cycle[])=>{
      if(!data || data.length ==0){
        this.router.navigate(['/ums/cycles'])
      }else{
        this.cycles = data;
        
        this.selected_cycle = this.cycles[0].id;
        console.log(this.cycles);

        this.getCycleLevels();
        
      }
    })
  }
  

  getCycleLevels(){
    
    this.apis.getCycleLevels(this.selected_cycle)
    .subscribe((data : Level[])=>{
      this.levels = data;
    })
  }

  changeCycle(){
    console.log(this.selected_cycle)
    this.apis.getCycleLevels(this.selected_cycle)
        .subscribe((data : Level[])=>{
          this.levels = data;
        })
  }

  editLevel(l){
    this.levels = this.levels.filter((x)=> x.id != l.id)
    this.new_level = l;
    this.show_form = true;
  }


  showNewLevelForm(){
    if(this.selected_cycle){

      this.show_form = true;
      this.new_level = new Level(this.selected_cycle);
    }
  }


  cancel(){
    this.show_form = false;
    this.new_level = undefined;
  }

  save(){
    this.apis.setLevel(this.new_level)
    .subscribe((data)=>{
      console.log(data);
      this.showSuccess('toastr.success_save')
      this.getCycleLevels();
      this.cancel();
    })

  }

  showSuccess(msg){

    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg){
    this.toastr.error(this.translate.instant((msg)))
  }
 
}
