import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ConfigService } from './config.service';
import { GenerateSetting } from '../viewModels/generate-setting';

@Injectable({
  providedIn: 'root'
})
export class TimingService {

  constructor(
    private http: HttpClient,
    private config: ConfigService) { }





  /* Departments */

  getHiararchyPeriods() {

    return this.http.get(this.config.baseUrl + '/periods', this.config.getOptions());
  }



  getPeriodById(id) {

    return this.http.get(this.config.baseUrl + '/period/' + id, this.config.getOptions());
  }
  setPeriod(body: any) {

    return this.http.post(this.config.baseUrl + '/period', body, this.config.getOptions());
  }

  removePeriod(id) {
    return this.http.delete(this.config.baseUrl + '/period/' + id, this.config.getOptions());
  }


  getSectionTimeTable(section_id , semestre_id , start_date , end_date) {
    console.log(this.config.establishment_id)
    return this.http.get(this.config.baseUrl + '/section/timetable/' + section_id +'/'+semestre_id+'/'+start_date+'/'+end_date, this.config.getOptions());
  }
  getSectionGroupTpTimeTable(group_id , semestre_id) {
    console.log(this.config.establishment_id)
    return this.http.get(this.config.baseUrl + '/section/tp/timetable/' + group_id +'/'+semestre_id, this.config.getOptions());
  }
  getSectionGroupTdTimeTable(group_id , semestre_id) {
    console.log(this.config.establishment_id)
    return this.http.get(this.config.baseUrl + '/section/td/timetable/' + group_id +'/'+semestre_id, this.config.getOptions());
  }




  getInstSectionTimeTable(section_id , semestre_id) {
    console.log(this.config.establishment_id)
    return this.http.get(this.config.baseUrl + '/inst/section/timetable/' + section_id +'/'+semestre_id, this.config.getOptions());
  }
  getInstSectionGroupTpTimeTable(group_id , semestre_id) {
    console.log(this.config.establishment_id)
    return this.http.get(this.config.baseUrl + '/inst/section/tp/timetable/' + group_id +'/'+semestre_id, this.config.getOptions());
  }


  getSectionPending(section_id , semestre_id){

    return this.http.get(this.config.baseUrl + '/section/pending/' + section_id+'/'+semestre_id, this.config.getOptions());

  }
  getTeacherTimeTable(teacher_id , division_id){
    return this.http.get(this.config.baseUrl + '/teacher/timetable/'+teacher_id +'/'+division_id, this.config.getOptions());
  }
  getTeacherPendingTimeTable(teacher_id , division_id){
    return this.http.get(this.config.baseUrl + '/teacher/pending/'+teacher_id +'/'+division_id, this.config.getOptions());
  }




  getClassroomsSchedule(block_id , division_id){
    return this.http.get(this.config.baseUrl + '/block/schedule/'+block_id +'/'+division_id, this.config.getOptions());

  }


  /* generateTimeTable(body : GenerateSetting){

    return this.http.post(this.config.baseUrl+'/timetable/generate' , body, this.config.getOptions() )
  } */

  generateEcoleTimeTable(body : GenerateSetting){
   console.log(body)
    return this.http.post(this.config.baseUrl+'/timetable/ecole/generate' , body, this.config.getOptions() )
  }



  checkDropSession(body : any){
    return this.http.put(this.config.baseUrl+'/timetable/drop' , body ,this.config.getOptions() )
  }
  checkDropPendingSession(body : any){
    return this.http.put(this.config.baseUrl+'/timetable/droppending' , body ,this.config.getOptions() )
  }
  checkSession(body : any){
    return this.http.post(this.config.baseUrl+'/timetable/check' , body ,this.config.getOptions() )
  }

  checkPendingSession(body : any){
    return this.http.post(this.config.baseUrl+'/timetable/checkpending' , body ,this.config.getOptions() )
  }
  checkSessionTeacher(body : any){
    return this.http.post(this.config.baseUrl+'/timetable/checkteacher' , body ,this.config.getOptions() )
  }
  getAvailableClassrooms(body : any){
    return this.http.post(this.config.baseUrl+'/timetable/availableclassrooms' , body ,this.config.getOptions() )
  }


  generateExamsSchedule(body : any){
    return this.http.post(this.config.baseUrl+'/exams/generate' , body, this.config.getOptions() )
  }
  getSectorExamsSchedule(sector_id , vacation_exam_id) {
    return this.http.get(this.config.baseUrl + '/exams/sector/'+ sector_id +'/'+vacation_exam_id, this.config.getOptions());
  }
  checkDropExam(body : any){
    return this.http.post(this.config.baseUrl+'/exams/drop' , body ,this.config.getOptions() )
  }
  updateExam(body : any){
    return this.http.post(this.config.baseUrl+'/exams/update' , body ,this.config.getOptions() )
  }

  generateMonotoringSchedule(body){
    return this.http.post(this.config.baseUrl + '/monotoring/generate',body, this.config.getOptions());
  }

  getMonotingSchedule(vacation_exam_id){
    return this.http.get(this.config.baseUrl + '/monotoring/exams/'+vacation_exam_id, this.config.getOptions());

  }

  getTeachersMonotingSchedule(vacation_exam_id){
    return this.http.get(this.config.baseUrl + '/monotoring/teacher/exams/'+vacation_exam_id, this.config.getOptions());

  }


  getNbHoursStudyPlanWithSectorId( sector_id){
    return this.http.get(this.config.baseUrl +'/study-plan/nb-hours/'+sector_id , this.config.getOptions())
  }

  getTotalHoursStudyPlan( ){
    return this.http.get(this.config.baseUrl +'/study-plan/total-hours' , this.config.getOptions())
  }





  //move session from timetable to pending list
  moveSessionToPending(body){
    return this.http.post(this.config.baseUrl+'/timetable/move/pending' , body , this.config.getOptions());
  }


  addPendingSession(body){
    return this.http.post(this.config.baseUrl+'/timetable/add/pending' , body , this.config.getOptions())
  }

  lock_unlock(id){
    return this.http.get(this.config.baseUrl+'/timetable/lock_unlock_session/'+id  , this.config.getOptions())

  }



  verifExtraSession(body){
    return this.http.post(this.config.baseUrl+'/timetable/verif_extra', body , this.config.getOptions())
  }
  createExtraSession(body){
    return this.http.post(this.config.baseUrl+'/timetable/extra_session', body , this.config.getOptions())
  }
  publier(){

    return this.http.post(this.config.baseUrl+'/timetable/publier', {} , this.config.getOptions())

  }

  getArchives(){

    return this.http.get(this.config.baseUrl+'/timetable/archives' , this.config.getOptions())

  }

  getSectionArchives(log_id , section_id){
    let body = {log_id : log_id , section_id : section_id}
    return this.http.post(this.config.baseUrl+'/timetable/section_archives' , body , this.config.getOptions())
  }


  print(division_id){
    return this.http.get(this.config.baseUrl+'/timetable/print/'+division_id  , this.config.getOptions())
  }

  printTeachers(division_id){
    return this.http.get(this.config.baseUrl+'/timetable/printTeachers/'+division_id  , this.config.getOptions())
  }

}
