import { Equipment } from './../../../../viewModels/equipment';
import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/apis/config.service';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { Block } from 'src/app/viewModels/block';
import { Building } from 'src/app/viewModels/building';
import { Classroom } from 'src/app/viewModels/classroom';

@Component({
  selector: 'app-classroom-form',
  templateUrl: './classroom-form.component.html',
  styleUrls: ['./classroom-form.component.css']
})
export class ClassroomFormComponent implements OnInit {

  blocks : Block[];
  buildings : Building[];
  equipments : Equipment[]
  constructor(
    public translate: TranslateService,
    private config : ConfigService,
    public dialogRef: MatDialogRef<ClassroomFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Classroom,
    private apis: EstablishmentService,
  ) { }
  new_class: Classroom;
  subjectTypes = this.config.classroom_type;
periods_types : any[];

  form: FormGroup = new FormGroup({
    'designation': new FormControl(Validators.required),
    'abbreviation': new FormControl(Validators.required),
    'block_id': new FormControl(Validators.required),
    'build_id': new FormControl(Validators.required),
    'capacity': new FormControl(Validators.required),
    'subject_type': new FormControl(Validators.required),
    'equipments': new FormControl(Validators.required),

  })
  onNoClick(): void {
    this.dialogRef.close();
  }
  selected_equipments = []

  ngOnInit() {
   console.log(this.data)
   if(this.data.equipments){
     this.selected_equipments = this.data.equipments.map((e)=>{return e.id})
     console.log(this.selected_equipments)
   }
    this.getBuildings();
   this.periods_types = this.config.period_types;

   this.apis.getEstablishmentEquipment()
   .subscribe((data : any)=>{
     this.equipments = data
   })
  }


  getBuildings(){
    this.apis.getEstablishmentBuildings()
    .subscribe((data : Building[])=>{
      this.buildings = data;
    })
  }


  changeBuild(){
    if(this.data.build_id){
      this.apis.getBuildingBlocks(this.data.build_id)
      .subscribe((data : Block[])=>{
        this.blocks = data;
      })
    }
  }
}
