import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class TicketService {

  constructor(private http: HttpClient,
    private config: ConfigService) { }


  user = JSON.parse(localStorage.getItem('user-infos'));


  getEstablishmentTickets() {
    return this.http.get(this.config.baseUrl + '/tikets', this.config.getOptions())
  }
  getUserTickets() {
    return this.http.get(this.config.baseUrl + '/tikets/'+this.user.id, this.config.getOptions())
  }
  createTicket(body) {
    return this.http.post(this.config.baseUrl + '/tiket' , body, this.config.getOptions())
  }
  createTicketResponse(body) {
    return this.http.post(this.config.baseUrl + '/tiket/response' , body, this.config.getOptions())
  }
  closeTicket(id) {
    return this.http.get(this.config.baseUrl + '/tiket/close/'+id ,this.config.getOptions() )
  }
}


