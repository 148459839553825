<app-content-header [hasBgImage]="true" [hideBreadcrumb]="true" [class]="'py-3'" [target]="'timetable'">
</app-content-header>


<div fxLayout="row" class="p-2">
    <div fxFlex="100" class="p-2">
        <mat-card>
            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('semestre')" [(ngModel)]="selectedSemestre"
                    (selectionChange)="handleSectionChange()">
                    <mat-option *ngFor="let sem of semestres_trimestres" [value]="sem.id">
                        {{sem.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>
            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('sector')" [(ngModel)]="selectedSector"
                    (selectionChange)="handleSectorChange()">
                    <mat-option *ngFor="let sector of sectors" [value]="sector.id">
                        {{sector.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>

            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('section')" [(ngModel)]="selectedSection"
                    (selectionChange)="handleSectionChange()">
                    <mat-option *ngFor="let section of sections" [value]="section.id">
                        {{section.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>
            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('group_tp')" [(ngModel)]="selectedGroupTp"
                    (selectionChange)="handleGroupChange()">
                    <mat-option *ngFor="let grp of groupTP" [value]="grp.id">
                        {{grp.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>
            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('group_td')" [(ngModel)]="selectedGroupTd"
                    (selectionChange)="handleGroupTDChange()">
                    <mat-option *ngFor="let grp of groupTD" [value]="grp.id">
                        {{grp.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>

            <div fxFlex="40">



                <button mat-raised-button color="primary" class="ums_primary_button"
                    [style.float]="'inverse_float' | translate" (click)="generateDialog()">
                    <mat-icon class="fs20">model_training</mat-icon> {{'buttons.generate' | translate}}
                </button>
                <button mat-raised-button color="basic" class="ums_basic_button"
                    [style.float]="'inverse_float' | translate" (click)="print()">
                    <mat-icon class="fs20">print</mat-icon> {{'buttons.print' | translate}}
                </button>
                <button mat-raised-button color="basic" class="ums_basic_button"
                    [style.float]="'inverse_float' | translate" (click)="export()">
                    <mat-icon class="fs20">download</mat-icon> {{'buttons.export' | translate}}
                </button>
                <button mat-raised-button color="primary" class="ums_primary_button"
                    [style.float]="'inverse_float' | translate" style="background-color: #f44336 !important;">
                    <mat-icon class="fs20">publish</mat-icon> {{'buttons.publish' | translate}}
                </button>






            </div>
        </mat-card>
    </div>
</div>
<mat-progress-bar *ngIf="show_progess" mode="indeterminate"></mat-progress-bar>

<div fxLayout="row" class="p-2">
    <div fxFlex="85" class="p-2">

        <mat-card>
            <mat-card-content>
                <table class="table" id="print_ums_id" *ngIf="timetable && timetable.length > 0 ; else no_data">
                    <tr style="margin-bottom: 1px!important;">
                        <td fxFlex="6" class="bg-dark"></td>
                        <td fxFlex="{{width}}" *ngFor="let period of periods ; let index = index"
                            class="{{index % 2 == 0 ? 'bg-dark2' : 'bg-dark' }}">
                            {{period.start_hour}} - {{period.end_hour}}
                        </td>

                    </tr>
                    <tr *ngFor="let day of timetable ; let index = index" [style.background]="index % 2 == 0 ? '#f3f3f3' : ''">
                        <td fxFlex="6" style="margin-bottom: 1px;" class="{{index % 2 == 0 ? 'bg-dark2' : 'bg-dark' }}">
                            {{day.abbreviation}}</td>
                        <td fxFlex="{{width}}" *ngFor="let periods of day.days" class="draggable" dragula="bag-1"
                            id="{{day.day_id}}-{{periods.period_id}}" class="bag">

                            <span *ngFor="let period of periods.seances"
                                id="{{day.day_id}}-{{periods.period_id}}-{{period.id}}" data-name="seance">
                                <mat-card [class.bg-c]="period.subject_type && period.subject_type.abbreviation =='C'"
                                    [class.bg-tp]="period.subject_type && period.subject_type.abbreviation =='TP'"
                                    [class.bg-td]="period.subject_type && period.subject_type.abbreviation =='TD'"
                                    [style.background]="period.group_tp ? colors[period.group_tp.abbreviation.substring(period.group_tp.abbreviation.length -1)] :''"
                                    >

                                    <span id="{{period.id}}">
                                        <span (click)="showEditDialog(period)"
                                            style="font-size: large; text-transform:uppercase  !important; font-weight: bold;">
                                            {{period.subject?.abbreviation}}
                                        </span>
                                        <br>
                                        <span style="font-size: large; text-transform:uppercase  !important;">
                                            {{period.classroom ? period.classroom.abbreviation : 'Pas Salle'}} |
                                            {{period.week_name}}
                                        </span>
                                        <span style="font-size:small;">
                                            <!-- <br>{{period.teacher?.first_name}} -->
                                            {{period.teacher?.last_name}}<br>

                                        </span>
                                    </span>
                                    <div [class.bg-c1]="period.subject_type && period.subject_type.abbreviation =='C'"
                                        class="draggable p5"
                                        [class.bg-tp1]="period.subject_type && period.subject_type.abbreviation =='TP'"
                                        [class.bg-td1]="period.subject_type && period.subject_type.abbreviation =='TD'"
                                        class="top-right">
                                        {{period.subject_type.abbreviation}}
                                    </div>
                                    <div *ngIf="period.group_td || period.group_tp"
                                    class="top-bottom">
                                    {{ period.group_td?.abbreviation}}
                                    {{period.group_tp?.abbreviation}}
                                </div>


                                </mat-card>
                            </span>



                        </td>
                    </tr>
                </table>
                <ng-template #no_data>
                    <div class="empty_frame">
                        <img src="assets/empty.png">
                    </div>
                </ng-template>
            </mat-card-content>
        </mat-card>
        <!-- <mat-card class="hide_print" *ngIf="timetableGTP2 && timetableGTP2.length > 0">
            <mat-card-content>
                <table class="table">
                    <tr>
                        <td fxFlex="6" class="bg-dark"></td>
                        <td fxFlex="{{width}}" *ngFor="let period of periods ; let index = index"
                            class="{{index % 2 == 0 ? 'bg-dark2' : 'bg-dark' }}">
                            {{period.abbreviation}}
                        </td>

                    </tr>
                    <tr *ngFor="let day of timetableGTP2 ; let index = index">
                        <td fxFlex="6" class="{{index % 2 == 0 ? 'bg-dark2' : 'bg-dark' }}">{{day.abbreviation}}</td>
                        <td fxFlex="{{width}}" *ngFor="let periods of day.days" class="draggable" [dragula]="'bag-1'"
                            class="bag">
                            <span *ngFor="let period of periods.seances">
                                <mat-card [class.bg-c]="period.subject_type && period.subject_type.abbreviation =='C'"
                                    [class.bg-tp]="period.subject_type && period.subject_type.abbreviation =='TP'"
                                    [class.bg-td]="period.subject_type && period.subject_type.abbreviation =='TD'">


                                    <span>
                                        <span
                                            style="font-size: large; text-transform:uppercase  !important; font-weight: bold;">
                                            {{period.subject?.abbreviation}}
                                        </span>
                                        <br>
                                        <span style="font-size: large; text-transform:uppercase  !important;">
                                            {{period.classroom ? period.classroom.abbreviation : 'Pas Salle'}} |
                                            {{period.week_name}}
                                        </span>
                                        <span style="font-size:small;">
                                            <br>{{period.teacher?.first_name}}
                                            {{period.teacher?.last_name}}<br>

                                        </span>

                                    </span>
                                    <div [class.bg-c1]="period.subject_type && period.subject_type.abbreviation =='C'"
                                        class="draggable p5"
                                        [class.bg-tp1]="period.subject_type && period.subject_type.abbreviation =='TP'"
                                        [class.bg-td1]="period.subject_type && period.subject_type.abbreviation =='TD'"
                                        class="top-right">
                                        {{period.subject_type.abbreviation}}
                                    </div>
                                </mat-card>
                            </span>

                        </td>
                    </tr>
                </table>

            </mat-card-content>


        </mat-card> -->
    </div>
    <div fxFlex="15" class="p-2">
        <mat-card>
            <mat-card-header fxLayoutAlign="center">
                <mat-card-subtitle fxLayoutAlign="center">
                    <h2>Pending list</h2>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content class="bag">
                <!--  <mat-card *ngFor="let item of pending_list"
                    [class.bg-primary]="item.subject_type && item.subject_type.abbreviation =='C'" class="draggable p5"
                    [class.bg-accent]="item.subject_type && item.subject_type.abbreviation =='TP'"
                    [class.bg-default]="item.subject_type && item.subject_type.abbreviation =='TD'">
                    {{item.subject?.abbreviation}}<br>{{item.teacher?.first_name}} &nbsp;
                    {{item.teacher?.last_name}}<br>

                    {{item.section?.abbreviation}}
                </mat-card> -->
                <span *ngFor="let item of pending_list" id="pending-{{item.id}}" [dragula]="'bag-1'">
                    <mat-card style="background-color: #9c9b3857;">


                        <span>
                            <span style="font-size: large; text-transform:uppercase  !important; font-weight: bold;">
                              {{item.id}}<br> {{item.subject?.abbreviation}}
                            </span>
                            <br>
                            <span style="font-size: large; text-transform:uppercase  !important;">
                               <!--  {{item.classroom ? item.classroom.abbreviation : 'Pas Salle'}} |
                                {{item.week_name}} -->
                                {{ item.group_td?.abbreviation}}
                                {{item.group_tp?.abbreviation}}
                            </span>
                            <span style="font-size:small;">
                                 <br>{{item.teacher?.first_name}}
                                {{item.teacher?.last_name}}<br>

                            </span>

                        </span>
                        <div [class.bg-primary]="item.subject_type && item.subject_type.abbreviation =='C'"
                            class="draggable p5"
                            [class.bg-accent]="item.subject_type && item.subject_type.abbreviation =='TP'"
                            [class.bg-default]="item.subject_type && item.subject_type.abbreviation =='TD'"
                            class="top-right">
                            {{item.subject_type.abbreviation}}
                        </div>
                        <div class="bottom-right">
                            <i class="fa fa-edit" (click)="showEditPendingDialog(item)">Edit</i>
                        </div>
                    </mat-card>
                </span>
            </mat-card-content>
        </mat-card>
    </div>
</div>
