import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/apis/config.service';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { SubjectService } from 'src/app/apis/subject.service';
import { TimingService } from 'src/app/apis/timing.service';
import { AnnualCalendar } from 'src/app/viewModels/annual-calendar';
import { GenerateExamsSetting } from 'src/app/viewModels/generate-exams-setting';
import { GenerateSetting } from 'src/app/viewModels/generate-setting';
import { Sector } from 'src/app/viewModels/sector';
import { SubjectType } from 'src/app/viewModels/subject-type';
import { VacationExam } from 'src/app/viewModels/vacation-exam';
import { GenerateDialogComponent } from '../../time-table/generate-dialog/generate-dialog.component';

@Component({
  selector: 'app-exams-generate-dialog',
  templateUrl: './exams-generate-dialog.component.html',
  styleUrls: ['./exams-generate-dialog.component.css']
})
export class ExamsGenerateDialogComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private config : ConfigService,
    private establishmentApis : EstablishmentService,
    public dialogRef: MatDialogRef<GenerateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenerateExamsSetting,
    private apis: TimingService,
    private settingsApis : SettingsService,
    private hierarchyApis : HiararchyService,
    private subjectApis : SubjectService
  ) { }

  subject_types : SubjectType[];
  divisions : any[];
  sectors : Sector[];
periods_types : any[];

nb_exam_per_day : number = 1;
   
tp_major_sessions = this.config.tp_major_sessions
selectedExamPeriod
all_dates : any[]
examsperiods : VacationExam[]
  form: FormGroup = new FormGroup({
    'nb_exam_per_day': new FormControl(Validators.required),
    'period1_start_time': new FormControl(Validators.required),
    'period1_end_time': new FormControl(Validators.required),
    'period2_start_time': new FormControl(Validators.required),
    'period2_end_time': new FormControl(Validators.required),
    'schedule_dates': new FormControl(Validators.required),
    'exam_period_id': new FormControl(Validators.required),
    'sectors_ids': new FormControl(Validators.required),
    'is_all_sectors': new FormControl(Validators.required),
    'year_division_id': new FormControl(Validators.required),

  })
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

    console.log(this.data)
    this.getSectors();
    this.getExamsPeriods();
    this.getYearDivisions();
  }

  getSubjectTypes(){

    this.subjectApis.getEstablishmentSubjectTypes()
    .subscribe((data : SubjectType[])=>{
      this.subject_types = data.filter((x)=> x.abbreviation != 'TD');
    })
  }

  getYearDivisions(){
    this.settingsApis.getYearDivisions()
    .subscribe((data : any[])=>{
      this.divisions = data;
    })
  }

  getSectors(){
    this.hierarchyApis.getHiararchySectors()
    .subscribe((data : Sector[])=>{
      this.sectors = data;
    })
  }

  getExamsPeriods() {
    this.establishmentApis.getAnnualCalendarVacationsExams(this.data.annual_calendar_id)
      .subscribe((data:  VacationExam[]) => {
        this.examsperiods = data.sort((a,b)=> a.start_date > b.start_date ? 0 : -1);
        
      })
  }
  
  handleExamPeriodChange(){
    console.log(this.selectedExamPeriod)
    if(this.selectedExamPeriod){
      this.data.exam_period_id = this.selectedExamPeriod.id
      this.all_dates  =this.getDates(new Date(this.selectedExamPeriod.start_date) , new Date(this.selectedExamPeriod.end_date));
      this.data.schedule_dates =  this.all_dates
    
      console.log(this.data.schedule_dates)
    }
  }
getDates(startDate,  stopDate) {
  console.log(startDate , stopDate)
    let dateArray = new Array();
    let currentDate : Date = startDate;
    while (currentDate <= stopDate) {
      let y = currentDate.getFullYear();
      let m = currentDate.getMonth()+1;
      let d = currentDate.getDate();
        dateArray.push(y+'-'+(m>9?'':'0')+(currentDate.getMonth()+1)+'-'+(d>9?'':'0')+currentDate.getDate());
      
       currentDate.setDate(currentDate.getDate()+1);
    }
    return dateArray;
}
}
