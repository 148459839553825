import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { TimingService } from 'src/app/apis/timing.service';
import { Period } from 'src/app/viewModels/period';
import Swal from 'sweetalert2';
import { PeriodFormComponent } from '../period-form/period-form.component';

@Component({
  selector: 'app-periods',
  templateUrl: './periods.component.html',
  styleUrls: ['./periods.component.css']
})
export class PeriodsComponent implements OnInit {

 

  items: Period[];
  displayedColumns: string[] = ['designation', 'abbreviation', 'start_hour','end_hour','type','actions'];
  dataSource ;
  selectedItem: any;

  constructor(
    private apis: TimingService,
    public translate: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog

  ) { }

  item: Period = new Period();
  show_form = false;


  ngOnInit(): void {
    console.log(this.item);

    this.getData();
  }


  getData() {
    this.apis.getHiararchyPeriods()
      .subscribe((data: Period[]) => {
        this.items = data.sort((a,b)=> a.start_hour > b.start_hour ? 0 : -1);
        console.log(this.items)
        this.dataSource = new MatTableDataSource<Period>(this.items);
      })
  }




  save() {
    console.log(this.item)
    this.apis.setPeriod(this.item)
      .subscribe((data) => {
        this.getData()
        this.showSuccess('saved');
        this.item = new Period();
      })
  }

  edit(element) {
    this.item = element
  }

  remove(id) {
    Swal.fire({
      title: this.translate.instant('alerts.delete_title'),
      text: this.translate.instant('alerts.delete_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('alerts.delete_yes'),
      cancelButtonText: this.translate.instant('alerts.delete_no')
    }).then((result) => {
      if (result.value) {
        this.apis.removePeriod(id)
          .subscribe(() => {
            this.getData();
            Swal.fire(
              this.translate.instant('alerts.deleted'),
              this.translate.instant('alerts.deleted_text'),
              'success'
            )
          })

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.translate.instant('alerts.canceled'),
          this.translate.instant('alerts.canceled_text'),
          'error'
        )
      }
    })
  }

  showSuccess(msg) {

    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg) {
    this.toastr.error(this.translate.instant((msg)))
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(PeriodFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if(this.item.overlap_ids){
        this.item.overlap_ids = JSON.stringify(this.item.overlap_ids)
      }
      if (result) {
        this.apis.setPeriod(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.getData();

            this.item = new Period()
          })
      }

    });
  }

  openEditDialog(period): void {
    this.item = period;
    if(period.overlap_ids){
      this.item.overlap_ids = JSON.parse(period.overlap_ids)
    }
    const dialogRef = this.dialog.open(PeriodFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if(this.item.overlap_ids){
        this.item.overlap_ids = JSON.stringify(this.item.overlap_ids)
      }
      if (result) {
        this.apis.setPeriod(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.getData();

            this.item = new Period()
          })
      }

    });
  }



  filter(v){
    this.dataSource.filter = v.trim().toLowerCase();
  }

}
