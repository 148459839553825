<app-content-header [hasBgImage]="true" [hideBreadcrumb]="true" [class]="'py-3'" [target]="'timetable'">
</app-content-header>


<div fxLayout="row" class="p-2">
    <div fxFlex="100" class="p-2">
        <mat-card class="hide_print">
            <mat-form-field fxFlex="25" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('building')" [(ngModel)]="selectedBuilding"
                    (selectionChange)="getBlocks()">
                    <mat-option *ngFor="let building of buildings" [value]="building.id">
                        {{building.abbreviation}} </mat-option>
                </mat-select>


            </mat-form-field>
            <mat-form-field fxFlex="25" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('block')" [(ngModel)]="selectedBlock"
                    (selectionChange)="getBlockSchedule()">
                    <mat-option *ngFor="let block of blocks" [value]="block.id">
                        {{block.abbreviation}} </mat-option>
                </mat-select>


            </mat-form-field>
            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('semestre')" [(ngModel)]="selectedSemestre"
                    (selectionChange)="getBlockSchedule()">
                    <mat-option *ngFor="let sem of semestres_trimestres" [value]="sem.id">
                        {{sem.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>
          <!--   <mat-form-field fxFlex="25" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('days')" [(ngModel)]="selectedDay"
                    (selectionChange)="handleDayChange()">
                    <mat-option *ngFor="let day of days" [value]="day.id">
                        {{day.designation}} </mat-option>
                </mat-select>


            </mat-form-field> -->
            <div fxFlex="50">

                <button mat-raised-button color="basic" class="ums_basic_button"
                    [style.float]="'inverse_float' | translate" (click)="print()">
                    <mat-icon class="fs20">print</mat-icon> {{'buttons.print' | translate}}
                </button>

                <button mat-raised-button color="basic" class="ums_basic_button"
                [style.float]="'inverse_float' | translate" (click)="export()">
                <mat-icon class="fs20">download</mat-icon> {{'buttons.export' | translate}}
            </button>

            </div>
        </mat-card>
        <mat-card style="width: auto; overflow: scroll;">
            <mat-card-content>
                <table class="table" id="print_ums_id" *ngIf="schedule && schedule.length > 0 ; else no_data">
                    <tr *ngFor="let day of schedule">
                        <td>

                            <table class="table">
                                <caption>{{day.abbreviation}}</caption>
                                <tr>
                                    <th>

                                    </th>
                                    <th style="width: 10%;" *ngFor="let classroom of classrooms">
                                        {{classroom.abbreviation}}
                                    </th>
                                </tr>

                                <tr *ngFor="let period of day.periods ; let k = index" [style.background]="k%2 == 0 ? '' :'#eee'" >
                                    <th width="5%" >
                                        {{period.abbreviation}}<br> {{period.start_hour}} {{period.end_hour}}
                                    </th>
                                    <td *ngFor="let classroom of period.classrooms" style="height : 50px">
                                        <table>
                                            <tr>

                                                <td width="35%" *ngFor="let seance of classroom.seances" style="border:none">
                                                    {{seance.subject?.abbreviation}}
                                                    <b>{{seance.teacher?.first_name}}
                                                    {{seance.teacher?.last_name}} </b>
                                                    {{seance.week_name}}

                                                   <b> {{seance.section?.abbreviation}}</b>


                                                  </td>
                                                <!--   <td  width="35%">
                                                    {{seance.teacher?.first_name}}
                                                    {{seance.teacher?.first_name}}
                                                </td>
                                                <td  width="15%">
                                                    {{seance.week_name}}

                                                </td>
                                                <td width="15%">

                                                </td> -->
                                            </tr>
                                        </table>
                                    </td>

                                </tr>

                            </table>
                        </td>
                    </tr>
                </table>

                <ng-template #no_data>
                    <div class="empty_frame">
                        <img src="assets/empty.png">
                    </div>
                </ng-template>

            </mat-card-content>
        </mat-card>
    </div>

</div>
