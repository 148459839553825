export class Level {

    id : number
    designation : string;
    abbreviation : string;
    cycle_id : number;
    establishment_id : number = JSON.parse(localStorage.getItem('user-infos')).establishment_id;
    constructor(cycle_id){
        this.cycle_id = cycle_id;
    }
}

