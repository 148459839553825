<app-content-header 
    [icon]="'dns'" 
    [title]="'Expansion Panels'" 
    [hideBreadcrumb]="false"
    [hasBgImage]="true" 
    [class]="'pb-4'"></app-content-header>

<div fxLayout="column" class="p-2">
    <div class="p-2">
        <mat-card-header fxLayoutAlign="center center">                
            <mat-card-subtitle><h2>Basic expansion panel</h2></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-expansion-panel>
                <mat-expansion-panel-header>
                    <mat-panel-title>Personal data</mat-panel-title>
                    <mat-panel-description>Type your name and age</mat-panel-description>
                </mat-expansion-panel-header>                    
                <mat-form-field>
                    <input matInput placeholder="First name">
                </mat-form-field>                    
                <mat-form-field>
                    <input matInput placeholder="Age">
                </mat-form-field>
            </mat-expansion-panel>
        </mat-card-content>
    </div>
    <div class="p-2">
        <mat-card-header fxLayoutAlign="center center">                
            <mat-card-subtitle><h2>Expansion panel as accordion</h2></mat-card-subtitle>
        </mat-card-header>
        <mat-card-content>
            <mat-accordion class="example-headers-align">
                <mat-expansion-panel [expanded]="step === 0" (opened)="setStep(0)" hideToggle="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Personal data</mat-panel-title>
                        <mat-panel-description fxLayoutAlign="space-between center">Type your name and age<mat-icon>account_circle</mat-icon></mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-form-field>
                        <input matInput placeholder="First name">
                    </mat-form-field>
                    <mat-form-field>
                        <input matInput type="number" min="1" placeholder="Age">
                    </mat-form-field>
                    <mat-action-row>
                        <button mat-button color="primary" (click)="nextStep()">Next</button>
                    </mat-action-row>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="step === 1" (opened)="setStep(1)" hideToggle="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Destination</mat-panel-title>
                        <mat-panel-description fxLayoutAlign="space-between center">Type the country name<mat-icon>map</mat-icon></mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-form-field>
                        <input matInput placeholder="Country">
                    </mat-form-field>
                    <mat-action-row>
                        <button mat-button color="warn" (click)="prevStep()">Previous</button>
                        <button mat-button color="primary" (click)="nextStep()">Next</button>
                    </mat-action-row>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="step === 2" (opened)="setStep(2)" hideToggle="true">
                    <mat-expansion-panel-header>
                        <mat-panel-title>Day of the trip</mat-panel-title>
                        <mat-panel-description fxLayoutAlign="space-between center">Inform the date you wish to travel<mat-icon>date_range</mat-icon></mat-panel-description>
                    </mat-expansion-panel-header>
                    <mat-form-field>
                        <input matInput placeholder="Date" [matDatepicker]="picker" (focus)="picker.open()" readonly>
                    </mat-form-field>
                    <mat-datepicker #picker></mat-datepicker>
                    <mat-action-row>
                        <button mat-button color="warn" (click)="prevStep()">Previous</button>
                        <button mat-button color="primary" (click)="nextStep()">End</button>
                    </mat-action-row>
                </mat-expansion-panel>
            </mat-accordion>
        </mat-card-content>
    </div>
</div>