<app-content-header 
    [icon]="'today'" 
    [title]="'Datepicker'" 
    [hideBreadcrumb]="false"
    [hasBgImage]="true" 
    [class]="'pb-4'"></app-content-header>

<div fxLayout="column" fxLayout.gt-sm="row wrap" class="p-2"> 
    <div fxFlex="50" class="p-2"> 
        <mat-card>
            <mat-card-header fxLayoutAlign="center">                
                <mat-card-subtitle><h2>Basic datepicker</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayoutAlign="center">
                <mat-form-field>
                    <input matInput [matDatepicker]="picker1" placeholder="Choose a date">
                    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
                    <mat-datepicker #picker1></mat-datepicker>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>   
    <div fxFlex="50" class="p-2"> 
        <mat-card>
            <mat-card-header fxLayoutAlign="center">                
                <mat-card-subtitle><h2>Datepicker start date</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayoutAlign="center">
                <mat-form-field>
                    <input matInput [matDatepicker]="picker2" placeholder="Choose a date">
                    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
                    <mat-datepicker #picker2 startView="year" [startAt]="startDate"></mat-datepicker>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex="50" class="p-2"> 
        <mat-card>
            <mat-card-header fxLayoutAlign="center">                
                <mat-card-subtitle><h2>Datepicker with min & max validation</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayoutAlign="center">
                <mat-form-field class="example-full-width">
                    <input matInput [min]="minDate" [max]="maxDate" [matDatepicker]="picker3" placeholder="Choose a date">
                    <mat-datepicker-toggle matSuffix [for]="picker3"></mat-datepicker-toggle>
                    <mat-datepicker #picker3></mat-datepicker>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div>  
    <div fxFlex="50" class="p-2"> 
        <mat-card>
            <mat-card-header fxLayoutAlign="center">                
                <mat-card-subtitle><h2>Datepicker with filter validation</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayoutAlign="center">
                <mat-form-field class="example-full-width">
                    <input matInput [matDatepickerFilter]="myFilter" [matDatepicker]="picker4" placeholder="Choose a date">
                    <mat-datepicker-toggle matSuffix [for]="picker4"></mat-datepicker-toggle>
                    <mat-datepicker #picker4></mat-datepicker>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div> 
    <div fxFlex="50" class="p-2"> 
        <mat-card>
            <mat-card-header fxLayoutAlign="center">                
                <mat-card-subtitle><h2>Datepicker input and change events</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayout="column" fxLayoutAlign="center center">
                <mat-form-field>
                    <input matInput [matDatepicker]="picker5" placeholder="Input & change events"
                          (dateInput)="addEvent('input', $event)" (dateChange)="addEvent('change', $event)">
                    <mat-datepicker-toggle matSuffix [for]="picker5"></mat-datepicker-toggle>
                    <mat-datepicker #picker5></mat-datepicker>
                </mat-form-field>                
                <div class="w-100 mat-elevation-z4 p-1 events-list" perfectScrollbar> 
                    <div *ngFor="let e of events">{{e}}</div>
                </div>
            </mat-card-content>
        </mat-card>
    </div>
    <div fxFlex="50" class="p-2"> 
        <mat-card>
            <mat-card-header fxLayoutAlign="center">                
                <mat-card-subtitle><h2>Disabled datepicker</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayout="column" fxLayoutAlign="center center">             
                <mat-form-field>
                    <input matInput [matDatepicker]="dp1" placeholder="Completely disabled" disabled>
                    <mat-datepicker-toggle matSuffix [for]="dp1"></mat-datepicker-toggle>
                    <mat-datepicker #dp1></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                    <input matInput [matDatepicker]="dp2" placeholder="Popup disabled">
                    <mat-datepicker-toggle matSuffix [for]="dp2" disabled></mat-datepicker-toggle>
                    <mat-datepicker #dp2></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                    <input matInput [matDatepicker]="dp3" placeholder="Input disabled" disabled>
                    <mat-datepicker-toggle matSuffix [for]="dp3"></mat-datepicker-toggle>
                    <mat-datepicker #dp3 disabled="false"></mat-datepicker>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div> 
    <div fxFlex="50" class="p-2"> 
        <mat-card>
            <mat-card-header fxLayoutAlign="center">                
                <mat-card-subtitle><h2>Datepicker touch UI</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayoutAlign="center">
                <mat-form-field>
                    <input matInput [matDatepicker]="picker6" placeholder="Choose a date">
                    <mat-datepicker-toggle matSuffix [for]="picker6"></mat-datepicker-toggle>
                    <mat-datepicker touchUi="true" #picker6></mat-datepicker>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div> 
    <div fxFlex="50" class="p-2"> 
        <mat-card>
            <mat-card-header fxLayoutAlign="center">                
                <mat-card-subtitle><h2>Datepicker open method</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayoutAlign="center center">
                <mat-form-field>
                    <input matInput [matDatepicker]="picker7" placeholder="Choose a date">
                    <mat-datepicker #picker7></mat-datepicker>
                </mat-form-field>
                <button mat-raised-button (click)="picker7.open()">Open</button>
            </mat-card-content>
        </mat-card>
    </div>                                 
</div>