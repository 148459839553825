export class Block {

    id : number
    designation : string;
    abbreviation : string;
    building_id : number;
    establishment_id : number;
    departments : string;

    constructor(building_id){
        this.establishment_id = JSON.parse(localStorage.getItem('user-infos')).establishment_id;
        this.building_id = building_id;
    }
}
