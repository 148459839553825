import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { ExcelService } from 'src/app/apis/excel/excel.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { TimingService } from 'src/app/apis/timing.service';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { Block } from 'src/app/viewModels/block';
import { Building } from 'src/app/viewModels/building';
import { Classroom } from 'src/app/viewModels/classroom';
import { Day } from 'src/app/viewModels/day';
import { Period } from 'src/app/viewModels/period';
declare var $ : any;
@Component({
  selector: 'app-classrooms-schedule',
  templateUrl: './classrooms-schedule.component.html',
  styleUrls: ['./classrooms-schedule.component.css']
})
export class ClassroomsScheduleComponent implements OnInit {

  public settings: Settings;

  constructor(private settingService: SettingsService,
    public appSettings: AppSettings,
    public translate: TranslateService,
    private excelService : ExcelService,
    private establishmentService: EstablishmentService,
    private apis: TimingService) {
    this.settings = this.appSettings.settings;
    
  }
  days: Day[];
  periods: Period[];
  buildings: Building[];
  blocks: Block[];
  classrooms: Classroom[];
  width = 20;
  selectedDay;
  selectedBuilding;
  selectedBlock;
  schedule: any[];
  schedule_source: any[];
  
selectedSemestre;
semestres_trimestres: any = [];
  ngOnInit(): void {
    this.establishmentService.getEstablishmentBuildings()
      .subscribe((data: Building[]) => {
        this.buildings = data;
        if (this.buildings && this.buildings.length > 0) {
          this.selectedBuilding = this.buildings[0].id;
          this.getBlocks();
        }
      })
    
    this.settingService.getDays()
      .subscribe((data: Day[]) => {
        this.days = data;
      })


    this.apis.getHiararchyPeriods()
      .subscribe((data: Period[]) => {

        this.periods = data.filter((x) => x.type == 'session').sort((a, b) => a.start_hour > b.start_hour ? 0 : -1);
        this.width = 100 / (this.periods.length + 1);
      })

      this.settingService.getYearDivisions()
      .subscribe((data: any[]) => {
        this.semestres_trimestres = data;
      })




  }

  getBlocks() {
    if (this.selectedBuilding) {
      this.establishmentService.getBuildingBlocks(this.selectedBuilding)
        .subscribe((data: Block[]) => {
          this.blocks = data;
          if (this.blocks && this.blocks.length > 0) {
            this.selectedBlock = this.blocks[0].id;
            this.getBlockSchedule();
          }
        })
    } else {
      this.blocks = [];
      this.schedule = [];
      this.schedule_source = [];
      this.selectedBlock = undefined;
    }
  }


  getBlockSchedule() {
    if (this.selectedBlock && this.selectedSemestre) {

      this.apis.getClassroomsSchedule(this.selectedBlock , this.selectedSemestre)
      .subscribe((data : any)=>{
        this.schedule = data.schedule
        this.schedule_source = data.schedule;
        console.log(data)
      })
    } else {
      this.schedule = [];
      this.schedule_source = [];

    }
  }
  
  handleDayChange(){
    if(this.selectedDay){
        this.schedule = this.schedule_source.filter((x)=> x.day_id == this.selectedDay)
        
        
        
        
        // window.print()
      }else{
        this.schedule = this.schedule_source
      }
    }
    
    export(){
      this.excelService.exportToFile('suivi' , 'print_ums_id')

  }

  print(){
    var $print = $('#print_ums_id')
    .clone()
    .addClass('ums_print')
    .prependTo('body');
    window.print();
    $print.remove();
  }

}
