import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/apis/config.service';
import { SubjectService } from 'src/app/apis/subject.service';
import { SubjectModule } from 'src/app/viewModels/subject-module';

@Component({
  selector: 'app-subject-module-form',
  templateUrl: './subject-module-form.component.html',
  styleUrls: ['./subject-module-form.component.css']
})
export class SubjectModuleFormComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<SubjectModuleFormComponent>,
    private config : ConfigService,
    @Inject(MAT_DIALOG_DATA) public data: SubjectModule,
    private apis: SubjectService
  ) { }

  
  types_ue = this.config.types_ue;
  form: FormGroup = new FormGroup({
    'abbreviation': new FormControl(Validators.required),
    'designation': new FormControl(Validators.required),
    'discipline' : new FormControl(Validators.required),
    'basic_module': new FormControl()
  })
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {

 
  }






}
