import { Session } from 'src/app/viewModels/session';
import { ExcelService } from './../../../apis/excel/excel.service';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/apis/config.service';
import { TimingService } from 'src/app/apis/timing.service';
import { Component, OnInit } from '@angular/core';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { Sector } from 'src/app/viewModels/sector';
import { Section } from 'src/app/viewModels/section';
declare var  $ : any 
@Component({
  selector: 'app-archives',
  templateUrl: './archives.component.html',
  styleUrls: ['./archives.component.css']
})
export class ArchivesComponent implements OnInit {

  logs = [];
  selectedVersion
  constructor(private apis: TimingService, private config: ConfigService, public translate: TranslateService, private hierarchyService: HiararchyService
    ) { }
  width = 20;
  colors = ['#f8f68f', '#f8e68f', '#f8d68f', '#f8c68f', '#f8e68fa1', '#f8f688ba', '#f8f48f', '#f8f28f']

  storageUrl = this.config.storage_name
  show_progess = false
  ngOnInit(): void {
    this.apis.getArchives()
      .subscribe((data: any[]) => {
        this.logs = data;
        console.log(this.logs)
      })
      this.hierarchyService.getHiararchySectors()
      .subscribe((data: Sector[]) => {
        this.sectors = data;
      })
  }

  timetable
  periods
  selectedSector;
  selectedSection;

  sectors: Sector[];
  sections: Section[];

  
  handleSectorChange() {
    if (this.selectedSector) {
      this.hierarchyService.getSectorSections(this.selectedSector)
        .subscribe((data: Section[]) => {
          this.sections = data;
        })
    } else {
      this.sections = [];
      this.timetable = []
    }
  }

  handleSectionChange() {
    console.log("cancel")
    if (this.selectedSection && this.selectedVersion) {
      this.show_progess = true
      this.apis.getSectionArchives(this.selectedVersion.id, this.selectedSection)
      .subscribe((dd: any) => {
        console.log(dd)
        this.show_progess = false
        this.timetable = dd.g1
        this.periods = dd.periods
        this.width = (100 - 6) / (this.periods.length);
      })




    } else {

      this.timetable = [];
    }
  
  }

  print() {
    var $print = $('#print_ums_id')
      .clone()
      .addClass('ums_print')
      .prependTo('body');
    window.print();
    $print.remove();
  }

  getDiff(timeString1, timeString2) {

    if (timeString1 && timeString2) {
      var timeTokens = timeString1.split(':');
      var timeTokens2 = timeString2.split(':');
      var d1 = new Date(1970, 0, 1, timeTokens[0], timeTokens[1], 0);
      var d2 = new Date(1970, 0, 1, timeTokens2[0], timeTokens2[1], 0);


      return (((d2.getTime() - d1.getTime()) / 60000) * -2) + 1;
    } else {
      return 0;
    }


  }

}
