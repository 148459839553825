import { Component, OnInit, Input } from '@angular/core';
import { Menu } from 'src/app/theme/components/menu/menu.model';
import { MenuService } from 'src/app/theme/components/menu/menu.service';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from 'src/app/app.settings';

@Component({
  selector: 'app-content-header',
  templateUrl: './content-header.component.html',
  styleUrls: ['./content-header.component.scss'],
  providers: [ MenuService ]
})
export class ContentHeaderComponent implements OnInit {
  @Input('icon') icon:any;
  @Input('title') title:any;
  @Input('desc') desc:any;
  @Input('hideBreadcrumb') hideBreadcrumb:boolean = false;
  @Input('hasBgImage') hasBgImage:boolean = false;
  @Input('class') class:any;
  @Input('target') target : string;
  constructor(private menuService : MenuService , private router : Router , private settingSrv : AppSettings) { }

  submenu : Menu[];
  currentRoute;
  setting = this.settingSrv.settings;
  ngOnInit() {
    console.log(this.setting)
    this.currentRoute = this.router.url;
    console.log(this.currentRoute)
    if(this.target){
      this.submenu = this.menuService.getHorizontalSubMenuItems().filter((s)=> s.target == this.target);
    }
  }

}
