<app-content-header

    [hideBreadcrumb]="true"
    [class]="'py-3'" [hasBgImage]="true"
    [target]="'establishment'"></app-content-header>

<div fxLayout="row wrap" fxLayoutAlign="center" class="p-2">
    <div  class="p-2" style="width: 100%;">
      <mat-card *ngFor="let building of buildings" style="width: 25%;" [style.float]="'float' | translate"  (click)="editBuild(building)">
        <mat-card-header fxLayoutAlign="center">
            <mat-icon >location_city</mat-icon>
        </mat-card-header>
        <mat-card-content fxLayout="column" fxLayoutAlign="center">
           
                <h3  fxLayoutAlign="center">  &nbsp;{{ building.designation}}</h3>
                
        </mat-card-content>
    </mat-card>

    <mat-card  style="width: 25%;" [style.float]="'float' | translate">
        <mat-card-header fxLayoutAlign="center" *ngIf="!show_form">
            <mat-icon (click)="showNewBuildForm()">add</mat-icon>
        </mat-card-header>
        <mat-card-content fxLayout="column" fxLayoutAlign="center" *ngIf="show_form">
           
            <form fxLayout="column" fxLayoutAlign="center" [formGroup]="form" (ngSubmit)="save()">
                <mat-form-field>
                    <input matInput placeholder="{{'designation' | translate}}"
                        [(ngModel)]="new_build.designation" required [formControlName]="'designation'">
                    <mat-hint></mat-hint>
                   
                </mat-form-field>

                <mat-form-field>
                    <input matInput placeholder="{{'abbreviation' | translate}}"
                        [(ngModel)]="new_build.abbreviation" required [formControlName]="'abbreviation'">
                    <mat-hint></mat-hint>
                  
                </mat-form-field>
                <div fxFlex="100">
                    <button mat-raised-button color="primary" fxFlex="50" class="mat-elevation-z6 w-50 mt-3 mr-1" type="submit">{{'buttons.save' | translate}}</button>
                    <button mat-raised-button color="secondary" (click)="cancel()"  fxFlex="50" class="mat-elevation-z6 w-50 mt-3 ml-1" >{{'buttons.cancel' | translate}}</button>
         
                </div>

              
            </form>
                
        </mat-card-content>
    </mat-card>
    </div>
   
</div>
