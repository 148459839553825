<app-content-header  [hasBgImage]="true" [hideBreadcrumb]="true" [class]="'py-3'" [target]="'subjectsAndTeachers'"></app-content-header>

<mat-card>

    <mat-card-content>
        <h4>{{'add_new' | translate}}</h4>
        <form fxLayout="row" [formGroup]="form" (ngSubmit)="save()">
            <mat-form-field class="pr-2 pl-2" fxFlex="30">
                <input matInput placeholder="{{'designation' | translate}}" [(ngModel)]="item.designation" required
                    [formControlName]="'designation'">


            </mat-form-field>

            <mat-form-field class="pr-2 pl-2" fxFlex="30">
                <input matInput placeholder="{{'abbreviation' | translate}}" [(ngModel)]="item.abbreviation" required
                    [formControlName]="'abbreviation'">

            </mat-form-field>
            <mat-form-field class="pr-2 pl-2" fxFlex="30">
                <input matInput placeholder="{{'hourly_charges' | translate}}" type="number"
                    [(ngModel)]="item.hourly_charges" required [formControlName]="'hourly_charges'">

            </mat-form-field>
            <div fxFlex="10">
                <button  [disabled]="!form.valid" mat-raised-button color="primary" class="ums_primary_button" [style.float]="'inverse_float' | translate" >
                    <mat-icon class="fs20">check</mat-icon> {{'buttons.save' | translate}}
                  </button>
            </div>
        </form>

    </mat-card-content>
</mat-card>
<div fxLayout="row wrap" fxLayoutAlign="center" class="p-2">
    <div fxFlex="100" class="p-2">

        <div class="mat-elevation-z8">
            <table mat-table style="width: 100%;" [dataSource]="dataSource">
                <!-- Position Column -->
                <ng-container matColumnDef="code">
                    <th mat-header-cell *matHeaderCellDef> # </th>
                    <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                </ng-container>
                <!-- Position Column -->
                <ng-container matColumnDef="designation">
                    <th mat-header-cell *matHeaderCellDef> {{'designation' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.designation}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="abbreviation">
                    <th mat-header-cell *matHeaderCellDef> {{'abbreviation' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.abbreviation}} </td>
                </ng-container>

                <ng-container matColumnDef="hourly_charges">
                    <th mat-header-cell *matHeaderCellDef> {{'hourly_charges' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.hourly_charges}} </td>
                </ng-container>
                <ng-container matColumnDef="actions" justify="end">
                    <th mat-header-cell *matHeaderCellDef [style.text-align]="'inverse_float' | translate">
                        {{'actions' | translate}} </th>
                    <td mat-cell *matCellDef="let element" [style.text-align]="'inverse_float' | translate">
                        <mat-icon class="warning" (click)="edit(element)">edit</mat-icon>
                        <mat-icon class="danger" (click)="remove(element.id)">delete_outline</mat-icon>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>

</div>