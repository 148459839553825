import { Injectable } from '@angular/core';
import { CanActivate } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {

  constructor() {}

  
  canActivate() {
   
    if(localStorage.getItem('user-infos') && JSON.parse(localStorage.getItem('user-infos')).establishment_id){
      return true;
    }else{
      return false
    }
   
  }
}
