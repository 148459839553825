export class TeacherType {

    id: number
    designation: string
    abbreviation: string
    hourly_charges: number
    establishment_id: number =  JSON.parse(localStorage.getItem('user-infos')).establishment_id

   
}
