<app-content-header [hideBreadcrumb]="true" [hasBgImage]="true" [class]="'py-3'" [target]="'establishment'">
</app-content-header>

<div fxLayout="row wrap" fxLayoutAlign="center">

  <mat-card fxFlex="100" fxLayoutAlign="center">
    <mat-form-field fxFlex="40" class="pr-2 pl-2">
      <input matInput placeholder="{{'search' | translate}}" (keyup)="filter($event.target.value)">

    </mat-form-field>
 
 
    <div fxFlex="40">

      <!--   <mat-icon class="icon-add" [style.float]="'inverse_float' | translate" (click)="openDialog()" title="add">add</mat-icon> &nbsp;
      <mat-icon class="icon-add" [style.float]="'inverse_float' | translate" (click)="import()" title="import data"> cloud_upload</mat-icon> &nbsp;
      <mat-icon class="icon-add" [style.float]="'inverse_float' | translate" (click)="export()" title="export data"> cloud_download</mat-icon> &nbsp;
      <a href="{{excel_model_path}}"><mat-icon class="icon-add" [style.float]="'inverse_float' | translate"  title="download model"> download</mat-icon></a> &nbsp;
      <input style="display : none" type="file" id="file" (change)="handleFileChange($event)">
   
 -->

      <button mat-raised-button color="primary" class="ums_primary_button" [style.float]="'inverse_float' | translate" (click)="openDialog()">
        <mat-icon class="fs20">add</mat-icon> {{'buttons.add' | translate}}
      </button>
      <button mat-raised-button color="basic" class="ums_basic_button" [style.float]="'inverse_float' | translate" (click)="import()">
        <mat-icon class="fs20">upload</mat-icon>  {{'buttons.import' | translate}}
      </button>
      <input style="display : none" type="file" id="file" (change)="handleFileChange($event)">
      <button mat-raised-button color="basic"  class="ums_basic_button" [style.float]="'inverse_float' | translate" (click)="export()">
        <mat-icon class="fs20">download</mat-icon> {{'buttons.export' | translate}}
      </button>
      <a href="{{excel_model_path}}" target="_blank"><button mat-raised-button color="basic"  class="ums_basic_button" [style.float]="'inverse_float' | translate"
          >
          <mat-icon class="fs20">system_update_alt</mat-icon> {{'buttons.download_model' | translate}}
        </button> </a>

       

    </div>


  </mat-card>





</div>



<div fxLayout="row wrap" fxLayoutAlign="center" class="p-2">
  <div fxFlex="100" class="p-2">

    <div class="mat-elevation-z8">
      <table mat-table style="width: 100%;" id="export_id" [dataSource]="dataSource">

        <ng-container matColumnDef="reference">
            <th mat-header-cell *matHeaderCellDef> {{'reference' | translate}} </th>
            <td mat-cell *matCellDef="let element"> {{element.reference}} </td>
          </ng-container>
        <!-- Position Column -->
        <ng-container matColumnDef="designation">
          <th mat-header-cell *matHeaderCellDef> {{'designation' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.designation}} </td>
        </ng-container>

        <!-- Name Column -->
        <ng-container matColumnDef="abbreviation">
          <th mat-header-cell *matHeaderCellDef> {{'abbreviation' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.abbreviation}} </td>
        </ng-container>


        <ng-container matColumnDef="description">
          <th mat-header-cell *matHeaderCellDef> {{'description' | translate}} </th>
          <td mat-cell *matCellDef="let element"> {{element.description}} </td>
        </ng-container>
        <ng-container matColumnDef="actions" justify="end">
          <th mat-header-cell *matHeaderCellDef [style.text-align]="'inverse_float' | translate"> {{'actions' |
            translate}} </th>
          <td mat-cell *matCellDef="let element" [style.text-align]="'inverse_float' | translate">

            <mat-icon class="warning" (click)="openEditDialog(element)">edit</mat-icon>
            <mat-icon class="danger" (click)="remove(element.id)">delete_outline</mat-icon>

          </td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
        <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
      </table>

      <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
    </div>
  </div>

</div>