import { Block } from "./block";

export class Classroom {
    id : number;
    designation : string;
    abbreviation : string;
    capacity : number = 35;
    tags : string;
    block_id : number;
    subject_type : string;
    establishment_id : number = JSON.parse(localStorage.getItem('user-infos')).establishment_id;

    equipments : any[];
    //only for view

    build_id : number;
    block : Block;

    
}
