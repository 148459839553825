
import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Level } from 'src/app/viewModels/level';
import { Sector } from 'src/app/viewModels/sector';
import { Cycle } from 'src/app/viewModels/cycle';
import { Department } from 'src/app/viewModels/department';

@Component({
    selector: 'app-sector-form',
    templateUrl: './sector-form.component.html',
    styleUrls: ['./sector-form.component.css']
})
export class SectorFormComponent implements OnInit {


    constructor(
        public translate: TranslateService,
        public dialogRef: MatDialogRef<SectorFormComponent>,

        @Inject(MAT_DIALOG_DATA) public data: Sector,
        private apis: HiararchyService,
    ) { }

    levels: Level[];
    cycles: Cycle[];
    departments : Department[];
    form: FormGroup = new FormGroup({
        'designation': new FormControl(Validators.required),
        'abbreviation': new FormControl(Validators.required),
        'cycle_id': new FormControl(Validators.required),
        'level_id': new FormControl(Validators.required),
        'department_id': new FormControl(),

    })
    onNoClick(): void {
        this.dialogRef.close();
    }

    ngOnInit() {

        
        this.apis.getHiararchyDepartments()
        .subscribe((data : Department[])=>{
            this.departments = data;
        })
        console.log(this.data)
        //get levels

        this.apis.getHiararchyCycles()
            .subscribe((data: Cycle[]) => {
                this.cycles = data;
                if(this.data.id){
                    this.data.cycle_id = this.data.level?.cycle_id;
                    this.handleCycleChange();
                }
            })
    }


    handleCycleChange() {
        if (this.data.cycle_id) {
            this.apis.getCycleLevels(this.data.cycle_id)
                .subscribe((data: Level[]) => {
                    this.levels = data;
                })
        } else {
            this.data.level_id = undefined;
            this.levels = []
        }

    }
}
