<div mat-dialog-title><h1 >{{translate.instant('classroom_data')}}</h1></div>
<div mat-dialog-content >
    <form fxLayout="column" [formGroup]="form"  >
       
        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('designation')}}" [(ngModel)]="data.designation"
                required [formControlName]="'designation'">
            
        </mat-form-field>
        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('abbreviation')}}"  [(ngModel)]="data.abbreviation"
                required [formControlName]="'abbreviation'">
            
        </mat-form-field>
        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('capacity')}}"  [(ngModel)]="data.capacity"
                required [formControlName]="'capacity'">
            
        </mat-form-field>
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('building')" formControlName="build_id"
                [(ngModel)]="data.build_id" (selectionChange)="changeBuild()">
                <mat-option *ngFor="let build of buildings" [value]="build.id">
                    {{build.abbreviation}}</mat-option>
            </mat-select>

           
        </mat-form-field>

     
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('block')" formControlName="block_id"
                [(ngModel)]="data.block_id">
                <mat-option *ngFor="let block of blocks" [value]="block.id">
                    {{block.abbreviation}}</mat-option>
            </mat-select>

           
        </mat-form-field>
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('subjectTypes')" formControlName="subject_type"
                [(ngModel)]="data.subject_type">
                <mat-option *ngFor="let type of subjectTypes" [value]="type.slug">
                    {{type.value}}</mat-option>
            </mat-select>

           
        </mat-form-field>

        <mat-form-field >
            <mat-select [placeholder]="translate.instant('equipments')" [(ngModel)]="selected_equipments" formControlName="equipments"
                multiple>

             
                <mat-option *ngFor="let equipment of equipments" [value]="equipment.id">
                    {{equipment.abbreviation}}</mat-option>
            </mat-select>


        </mat-form-field>
        
       
    </form>
</div>
<div mat-dialog-actions>

  <button  mat-raised-button color="basic"  class=" ums_basic_button form-cancel" (click)="onNoClick()">{{'buttons.no_thanks' | translate}}</button>
  <button  mat-raised-button color="primary" [disabled]="!form.valid" class="ums_primary_button form-save" [mat-dialog-close]="data" cdkFocusInitial>
   {{'buttons.save' | translate}}
  </button>
</div>

