export class SubjectModule {

    id: number;
    designation: string;
    abbreviation: string;
    establishment_id : number;
    discipline : string;
    basic_module : boolean = false;
    constructor(){
        this.establishment_id = JSON.parse(localStorage.getItem('user-infos')).establishment_id;
    }
}
