import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from 'src/environments/environment';
import { ConfigService } from './config.service';
import { TeacherType } from '../viewModels/teacher-type';
import { TeacherGrade } from '../viewModels/teacher-grade';
import { Teacher } from '../viewModels/teacher';

@Injectable({
  providedIn: 'root'
})
export class TeacherService {

  constructor(
    private http: HttpClient,
    private config: ConfigService) { }


  user = JSON.parse(localStorage.getItem('user-infos'));


  getEstablishmentTeacherTypes() {
    return this.http.get(this.config.baseUrl + '/teacher-types', this.config.getOptions())
  }

  createOrUpdateTeacherType(body : TeacherType) {
    return this.http.post(this.config.baseUrl + '/teacher-types', body, this.config.getOptions())
  }

  removeTeacherType(id) {
    return this.http.delete(this.config.baseUrl + '/teacher-types/'+id, this.config.getOptions())
  }






  getEstablishmentTeacherGrades() {
    return this.http.get(this.config.baseUrl + '/teacher-grades', this.config.getOptions())
  }

  createOrUpdateTeacherGrade(body : TeacherGrade) {
    return this.http.post(this.config.baseUrl + '/teacher-grades', body, this.config.getOptions())
  }

  removeTeacherGrade(id) {
    return this.http.delete(this.config.baseUrl + '/teacher-grades/'+id, this.config.getOptions())
  }


  getEstablishmentTeachers() {
    return this.http.get(this.config.baseUrl + '/teachers', this.config.getOptions())
  }

  createOrUpdateTeacher(body : Teacher) {
    return this.http.post(this.config.baseUrl + '/teachers', body, this.config.getOptions())
  }

  removeTeacher(id) {
    return this.http.delete(this.config.baseUrl + '/teachers/'+id, this.config.getOptions())
  }


  getTeacherAvailibility(teacher_id){
    return this.http.get(this.config.baseUrl+'/teachers/availibility/'+teacher_id , this.config.getOptions());
  }

  setTeacherAvailibility(teacher_id , body){
    return this.http.post(this.config.baseUrl+'/teachers/availibility/'+teacher_id , body, this.config.getOptions());
  }


  getDepartmentTeachers(department_id){
    
    return this.http.get(this.config.baseUrl+'/department/teachers/'+department_id , this.config.getOptions());

  }


  importTeachers(body : Teacher[]){
    console.log(body)
    return this.http.post(this.config.baseUrl+'/import/teachers' , body , this.config.getOptions())
  }
}
