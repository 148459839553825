import { Sector } from "./sector"
import { Regime } from "./regime"
import { SubjectModule } from "./subject-module"

export class Subject {
    id : number
    subject_code : string
    designation : string
    abbreviation : string
    coefficient : number
    degree : number
    sector_id : number = 1;
    establishment_id : number = JSON.parse(localStorage.getItem('user-infos')).establishment_id;
    sector : Sector
    regimes : Regime[]
    year_division_id : number;
    type_ue : string;
    subject_module_id : number;
    module : SubjectModule
}
