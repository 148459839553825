export class WeekType {
    id : number
    designation : string
    week1 : string
    week2 : string
    annual_calendar_id : number;
    start_date? : Date;
    end_date? : Date;
    type? : string;
    establishment_id : number = JSON.parse(localStorage.getItem('user-infos')).establishment_id;

constructor(annual_calendar_id){
    this.annual_calendar_id = annual_calendar_id
}
}
