<app-content-header [hasBgImage]="true" [hideBreadcrumb]="true" [class]="'py-3'" [target]="'archives'">
</app-content-header>


<div fxLayout="row" class="p-2 hide_print">
    <div fxFlex="100" class="p-2">
        <mat-card>
            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('version')" [(ngModel)]="selectedVersion"
                (selectionChange)="handleSectionChange()">
                    <mat-option *ngFor="let log of logs" [value]="log">
                        {{log.created_at | date :'dd MM yyy HH:mm'}}</mat-option>
                </mat-select>


            </mat-form-field>

            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('sector')" [(ngModel)]="selectedSector"
                    (selectionChange)="handleSectorChange()">
                    <mat-option *ngFor="let sector of sectors" [value]="sector.id">
                        {{sector.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>

            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('section')" [(ngModel)]="selectedSection"
                    (selectionChange)="handleSectionChange()">
                    <mat-option *ngFor="let section of sections" [value]="section.id">
                        {{section.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>
            <div fxFlex="40">



         
                <button mat-raised-button color="basic" class="ums_basic_button"
                    [style.float]="'inverse_float' | translate" (click)="print()">
                    <mat-icon class="fs20">print</mat-icon> {{'buttons.print' | translate}}
                </button>

                </div>

        </mat-card>
    </div>
</div>
<mat-progress-bar *ngIf="show_progess" mode="indeterminate"></mat-progress-bar>

<div class="row">
    <div fxFlex="100" class="p-2">
        <mat-card>
            <mat-card-content>
                <table class="table" id="print_ums_id" *ngIf="timetable && timetable.length > 0 ; else no_data">
                    <tr style="margin-bottom: 1px!important;">
                        <td fxFlex="6" class="bg-dark"></td>
                        <td fxFlex="{{width}}" *ngFor="let period of periods ; let index = index"
                            class="{{index % 2 == 0 ? 'bg-dark2' : 'bg-dark' }}">
                            {{period.start_hour}} - {{period.end_hour}}
                        </td>

                    </tr>
                    <tr *ngFor="let day of timetable ; let index = index"
                        [style.background]="index % 2 == 0 ? '#f3f3f3' : ''">
                        <td fxFlex="6" style="margin-bottom: 1px;" class="{{index % 2 == 0 ? 'bg-dark2' : 'bg-dark' }}">
                            {{day.abbreviation}}</td>
                        <td fxFlex="{{width}}" *ngFor="let periods of day.days ; let p_i = index" 
                            class="bag">

                            <span *ngFor="let period of periods.seances ; let p_index = index "
                                data-name="seance">
                                <mat-card [class.bg-c]="period.subject_type_id && period.subject_type_id.abbreviation =='C'"
                                    [class.bg-tp]="period.subject_type_id && period.subject_type_id.abbreviation =='TP'"
                                    [class.bg-td]="period.subject_type_id && period.subject_type_id.abbreviation =='TD'"
                                    [style.background]="period.group_tp_id ? colors[period.group_tp_id.abbreviation.substring(period.group_tp_id.abbreviation.length -1)] :''"
                                    [style.margin-left.px]="period.start_hour != period.period_id.start_hour ? getDiff(period.start_hour , period.period_id.start_hour) : 0"
                                    [style.margin-right.px]="period.end_hour != period.period_id.end_hour ? getDiff(period.period_id.end_hour , period.end_hour) : 0"
                                    
                                    [class.bg-extra]="period.type && period.type =='extra_session'"
                                    [class.bg-exceptional]="period.type && period.type =='exceptional_session'"
                                    >

                                    <span id="{{period.id}}">
                                        <span 
                                            style="font-size: 14px; text-transform:uppercase  !important; font-weight: bold;">
                                            {{period.subject_id && period.subject_id.display_name ? period.subject_id.display_name : period.subject_id.abbreviation}}
                                        </span>
                                        <br>
                                        <span style="font-size: 14px; text-transform:uppercase  !important;">
                                            {{period.classroom_id ? period.classroom_id.abbreviation : 'Pas Salle'}} |
                                            {{period.week_name}}
                                        </span>
                                        <span style="font-size:small;">
                                            <br>{{period.teacher_id?.first_name}}
                                            {{period.teacher_id?.last_name}}<br>

                                        </span>
                                        <span style="font-size:small;" *ngIf="period.type && period.type !='session'">
                                           {{period.start_date | date:'dd-MM-yyy'}}
                                          
                                        </span>
                                        
                                    </span>
                                    <div [class.bg-c1]="period.subject_type_id && period.subject_type_id.abbreviation =='C'"
                                        class="draggable p5"
                                        [class.bg-tp1]="period.subject_type_id && period.subject_type_id.abbreviation =='TP'"
                                        [class.bg-td1]="period.subject_type_id && period.subject_type_id.abbreviation =='TD'"
                                        class="top-right">
                                        {{period.subject_type_id.abbreviation}}
                                    </div>
                                   
                                    <div *ngIf="period.group_td_id || period.group_tp_id" class="top-bottom">
                                        {{ period.group_td_id?.abbreviation}}
                                        {{period.group_tp_id?.abbreviation}}
                                    </div>


                                </mat-card>
                            </span>



                        </td>
                    </tr>
                </table>
                <ng-template #no_data>
                    <div class="empty_frame">
                        <img src="assets/empty.png">
                    </div>
                </ng-template>
            </mat-card-content>
        </mat-card>

    </div>
</div>