<app-content-header [hideBreadcrumb]="true" [class]="'py-3'" [hasBgImage]="true" [target]="'timetable'">
</app-content-header>


<div fxLayout="row" class="p-2">
    <div fxFlex="100" class="p-2">
        <mat-card>
            <mat-form-field fxFlex="25" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('teacher')" [(ngModel)]="selectedTeacher"
                    (selectionChange)="handleTeacherChange()">
                    <mat-option *ngFor="let teacher of teachers" [value]="teacher.id">
                        {{teacher.first_name}} {{teacher.last_name}}</mat-option>
                </mat-select>


            </mat-form-field>
            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('semestre')" [(ngModel)]="selectedSemestre"
                    (selectionChange)="handleTeacherChange()">
                    <mat-option *ngFor="let sem of semestres_trimestres" [value]="sem.id">
                        {{sem.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>

        </mat-card>
        <mat-card>
            <mat-card-content>
                <table class="table" *ngIf="timetable && timetable.length > 0 ; else no_data">
                    <tr style="margin-bottom: 1px!important;">
                        <td fxFlex="6" class="bg-dark"></td>
                        <td fxFlex="{{width}}" *ngFor="let period of periods ; let index = index"
                            class="{{index % 2 == 0 ? 'bg-dark2' : 'bg-dark' }}">
                            {{period.start_hour}} - {{period.end_hour}}
                        </td>

                    </tr>
                    <tr *ngFor="let day of timetable; let index = index"
                        [style.background]="index % 2 == 0 ? '#f3f3f3' : ''">
                        <td fxFlex="6" style="margin-bottom: 1px;" class="{{index % 2 == 0 ? 'bg-dark2' : 'bg-dark' }}">
                            {{day.abbreviation}}</td>


                        <td fxFlex="{{width}}" *ngFor="let period of day.days ; let p_i = index" class="bag">
                            <span data-name="seance">
                                <mat-card *ngIf="period != null"
                                   
                                    [class.bg-c]="period.subject_type && period.subject_type.abbreviation =='C'"
                                    [class.bg-tp]="period.subject_type && period.subject_type.abbreviation =='TP'"
                                    [class.bg-td]="period.subject_type && period.subject_type.abbreviation =='TD'"
                                    [style.background]="period.group_tp ? colors[period.group_tp.abbreviation.substring(period.group_tp.abbreviation.length -1)] :''"
                                    [style.margin-left.px]="period.start_hour != period.period.start_hour ? getDiff(period.start_hour , period.period.start_hour) : 0"
                                    [style.margin-right.px]="period.end_hour != period.period.end_hour ? getDiff(period.period.end_hour , period.end_hour) : 0"
                                    
                                    [class.bg-extra]="period.type && period.type =='extra_session'"
                                    [class.bg-exceptional]="period.type && period.type =='exceptional_session'"
                                    
                                    
                                    >
                                    <span id="{{period.id}}">
                                        <span 
                                            style="font-size: 14px; text-transform:uppercase  !important; font-weight: bold;">
                                            {{period.subject && period.subject.display_name ? period.subject.display_name : period.subject.abbreviation}}
                                        </span>
                                        <br>
                                        <span style="font-size: 14px; text-transform:uppercase  !important;">
                                            {{period.classroom ? period.classroom.abbreviation : 'Pas Salle'}} |
                                            {{period.week_name}}
                                        </span>
                                        <span style="font-size:small;">
                                            <br>{{period.teacher?.first_name}}
                                            {{period.teacher?.last_name}}<br>

                                        </span>
                                        <span style="font-size:small;" *ngIf="period.type && period.type !='session'">
                                           {{period.start_date | date:'dd-MM-yyy'}}
                                          
                                        </span>
                                        
                                    </span>
                                    <div [class.bg-c1]="period.subject_type && period.subject_type.abbreviation =='C'"
                                        class="draggable p5"
                                        [class.bg-tp1]="period.subject_type && period.subject_type.abbreviation =='TP'"
                                        [class.bg-td1]="period.subject_type && period.subject_type.abbreviation =='TD'"
                                        class="top-right">
                                        {{period.subject_type.abbreviation}}
                                    </div>
                                   
                                    <div  class="top-bottom">
                                        {{ period.section?.abbreviation}} - 
                                        {{ period.group_td?.abbreviation}}
                                        {{period.group_tp?.abbreviation}}
                                    </div>
                                </mat-card>
                            </span>
                        </td>
                    </tr>
                </table>
               <ng-template #no_data>
                    <div class="empty_frame">
                        <img src="assets/empty.png">
                    </div>
                </ng-template>
            </mat-card-content>
        </mat-card>
    </div>

</div>