<app-content-header [hasBgImage]="true" [hideBreadcrumb]="true" [class]="'py-3'" [target]="'exams'">
</app-content-header>


<div fxLayout="row" class="p-2">
    <div fxFlex="100" class="p-2">
        <mat-card>

            <!--<mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('ExamsType')" [(ngModel)]="selectedLevel"
                    (selectionChange)="handleSectorChange()">
                    <mat-option *ngFor="let level of levels" [value]="level">
                        {{level.abbreviation}}</mat-option>
                </mat-select>
            </mat-form-field>
        -->

            <div fxFlex="60">

                <!--              <mat-icon class="icon-add" [style.float]="'inverse_float' | translate" (click)="export()"
                    title="export data"> cloud_download</mat-icon> &nbsp;
                &nbsp;
                <mat-icon class="icon-add" [style.float]="'inverse_float' | translate" (click)="print()" title="print">
                    print</mat-icon> &nbsp; &nbsp;
                <mat-icon class="icon-add" [style.float]="'inverse_float' | translate" (click)="generateDialog()"
                    title="start generation">model_training</mat-icon> &nbsp; -->

                <button mat-raised-button color="basic" class="ums_basic_button"
                    [style.float]="'inverse_float' | translate" (click)="print()">
                    <mat-icon class="fs20">print</mat-icon> {{'buttons.print' | translate}}
                </button>
                <button mat-raised-button color="basic" class="ums_basic_button"
                    [style.float]="'inverse_float' | translate" (click)="export()">
                    <mat-icon class="fs20">download</mat-icon> {{'buttons.export' | translate}}
                </button>

            </div>
        </mat-card>
    </div>
</div>
<mat-progress-bar *ngIf="show_progess" mode="indeterminate"></mat-progress-bar>

<div fxLayout="row" class="p-2">
    <div fxFlex="100" class="p-2">

        <mat-card>
            <mat-card-content>
                <table class="table" id="print_ums_id" *ngIf="schedule && schedule.length >0 ; else no_data">
                    <thead>
                        <tr>
                            <th rowspan="3">Discipline</th>
                            <th rowspan="3">Types d'études</th>

                            <th colspan="10">Nombre des heures d'études</th>
                            <th rowspan="3">Totale</th>
                        </tr>
                        <tr>

                            <th colspan="3">Cycle préparatoire </th>
                            <th colspan="4">Licences ou Technicien supérieur</th>
                            <th colspan="3">Mastères</th>
                        </tr>
                        <tr>

                            <th>première année</th>
                            <th>Deuxième année</th>
                            <th class="bg-gray">Totale</th>

                            <th>première année</th>
                            <th>Deuxième année</th>
                            <th> Troisième année</th>
                            <th class="bg-gray">Totale</th>

                            <th>première année</th>
                            <th>Deuxième année</th>

                            <th class="bg-gray">Totale</th>

                        </tr>
                    </thead>
                    <tbody>
                        <ng-container *ngFor="let row of schedule ">
                            <tr>
                                <th rowspan="3"> {{row.module}}</th>
                                <th>Cours</th>
                                <ng-container *ngFor="let cycle of row.data">
                                    <td *ngFor="let level of cycle.levels">{{level.c}}</td>


                                    <td>{{cycle.c}}</td>
                                </ng-container>
                                <td>{{row.c}}</td>
                            </tr>
                            <tr>
                                <th>Travaux dirigés</th>
                                <ng-container *ngFor="let cycle of row.data">
                                    <td *ngFor="let level of cycle.levels">{{level.td}}</td>


                                    <td>{{cycle.td}}</td>

                                </ng-container>
                                <td>{{row.td}}</td>
                            </tr>
                            <tr>
                                <th>Traveaux pratiques</th>
                                <ng-container *ngFor="let cycle of row.data">
                                    <td *ngFor="let level of cycle.levels">{{level.tp}}</td>


                                    <td>{{cycle.tp}}</td>
                                </ng-container>
                                <td>{{row.tp}}</td>
                            </tr>
                        </ng-container>
                        <!--       <tr>
                                <th>Totale discipline</th>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td> </td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td> </td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td> </td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td class="disabled"></td>
                                <td> 0</td>

                            </tr>
                        </ng-container> -->
                        <!--  <tr>
                            <th>Totale Général</th>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <th> {{totale_c}}</th>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <th> {{totale_td}}</th>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <th> {{totale_tp}}</th>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <td class="disabled"></td>
                            <th> 0</th>
                        </tr> -->
                    </tbody>
                </table>
                <ng-template #no_data>
                    <div class="empty_frame">
                        <img src="assets/empty.png">
                    </div>
                </ng-template>
            </mat-card-content>
        </mat-card>

    </div>

</div>