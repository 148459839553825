import { AnnualCalendar } from './../../../viewModels/annual-calendar';
import { WeekType } from './../../../viewModels/week-type';
import { Component, OnInit, Inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'src/app/viewModels/subject';
import { SubjectService } from 'src/app/apis/subject.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { Sector } from 'src/app/viewModels/sector';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SubjectType } from 'src/app/viewModels/subject-type';
import { Regime } from 'src/app/viewModels/regime';
import { TeacherService } from 'src/app/apis/teacher.service';
import { Teacher } from 'src/app/viewModels/teacher';
import { SettingsService } from 'src/app/apis/settings.service';
import { ConfigService } from 'src/app/apis/config.service';
import { SubjectModule } from 'src/app/viewModels/subject-module';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { Block } from 'src/app/viewModels/block';
import { Classroom } from 'src/app/viewModels/classroom';

@Component({
  selector: 'app-subject-form',
  templateUrl: './subject-form.component.html',
  styleUrls: ['./subject-form.component.css']
})
export class SubjectFormComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<SubjectFormComponent>,
    private config : ConfigService,
    @Inject(MAT_DIALOG_DATA) public data: Subject,
    private apis: SubjectService,
    private hiararchyService: HiararchyService,
    private teacherService: TeacherService,
    private settingsService: SettingsService, 
    private establishmentService : EstablishmentService
  ) { }

  modules : SubjectModule[];
  teachers: Teacher[];
  sectors: Sector[] = [];
  subject_types: SubjectType[];
  year_divisions: any[];
  types_ue = this.config.types_ue;
  blocks : Block[] = [];
  all_classrooms : Classroom[] = []
  classrooms : Classroom[]=[]
  week_types : WeekType[] =[]
  xx
  form: FormGroup = new FormGroup({
    'subject_code': new FormControl(Validators.required),
    'designation': new FormControl(Validators.required),
    'abbreviation': new FormControl(Validators.required),
    'coefficient': new FormControl(Validators.required),
    'subject_module_id': new FormControl(),
    'sector_id': new FormControl(Validators.required),
    'year_division_id': new FormControl(Validators.required),
    'type_ue': new FormControl(Validators.required),
    'data' : new FormControl()
  })
  
  sections
  groups_tp
  groups_td
  groupes
  courses = [];
  new_regime_line = new Regime()
  equipments
  onNoClick(): void {
    console.log(this.data.regimes)
    this.dialogRef.close();
  }

  ngOnInit() {
    if(this.data.sector_id){
      this.changeSector();
    }
    console.log('data' , this.data)

    this.establishmentService.getEstablishmentEquipment()
    .subscribe((data)=>{
      this.equipments = data;
      console.log(this.equipments);
    })
    this.teacherService.getEstablishmentTeachers()
        .subscribe((data: Teacher[]) => {
          this.teachers = data;
          console.log(this.teachers)
          
        })
    this.apis.getEstablishmentSubjectModules()
    .subscribe((data : SubjectModule[])=>{
      this.modules = data;
    })
    this.settingsService.getYearDivisions()
      .subscribe((data: any[]) => {
        this.year_divisions = data;
      })
 
    if (!this.data.regimes) {
      this.data.regimes = []
    }

    if (this.data.sector && this.data.sector.department_id) {
     
    }
    this.teacherService.getEstablishmentTeachers(/* this.data.sector.department_id */)
    .subscribe((data: Teacher[]) => {
      this.teachers = data
    })
    //get sectors
    this.hiararchyService.getHiararchySectors()
      .subscribe((data: Sector[]) => {
        this.sectors = data;
      })
    this.apis.getEstablishmentSubjectTypes()
      .subscribe((data: SubjectType[]) => {
        this.subject_types = data;

      })

      this.establishmentService.getEstablishmentBlocks()
      .subscribe((data: Block[])=>{
        this.blocks = data
      })

      this.establishmentService.getEstablishmentClassrooms()
      .subscribe((data: Classroom[])=>{
        this.all_classrooms = data
        this.classrooms = data
      })
      this.establishmentService.getEstablishmentCurrentAnnualCalendar()
      .subscribe((data : AnnualCalendar)=>{
        this.establishmentService.getAnnualCalendarWeekTypes(data.id)
        .subscribe((dataa : WeekType[])=>{
          this.week_types = dataa
          console.log(this.week_types)
        })
      })
  }

 
  changeSector() {
    console.log(this.data.sector)
    if (this.data.sector_id) {
      let sector = this.sectors.find((x) => x.id == this.data.sector_id)
   

      this.hiararchyService.getSectorSections(this.data.sector_id)
      .subscribe((sections : any)=>{
        this.sections = sections;
        console.log(this.sections)
      })

      this.hiararchyService.getSectorGroupTds(this.data.sector_id)
      .subscribe((groups_td : any)=>{
        this.groups_td = groups_td;
      })

      this.hiararchyService.getSectorGroupTps(this.data.sector_id)
      .subscribe((groups_tp : any)=>{
        this.groups_tp = groups_tp;
      })
    } else {

      this.teachers = []
    }
  }
  changeSection(type_id, value) {
    console.log(value)
    let i = this.data.regimes.findIndex(x => x.subject_type_id == type_id)
    if (i >= 0) {
      this.data.regimes[i].affectation = [{section_id : value}]
    }

    console.log(this.data.regimes)
  }
  changeDivision(){
    console.log(this.data.year_division_id)
  }

  addRegime(){
   if(!this.data.regimes){
     this.data.regimes = []
   }
    this.data.regimes.push(this.new_regime_line);
    this.new_regime_line = new Regime()
    console.log(this.data.regimes)
  }

  removeRegime(i){
    this.data.regimes.splice(i,1)
  }
  handleTypeChange(event){
    console.log(event)
    let subjecttype = this.subject_types.find(x => x.id == event.value)
    if(subjecttype.abbreviation == 'C'){
      this.groupes = this.sections
    }else if(subjecttype.abbreviation == 'TD'){
      this.groupes = this.groups_td
    }else if(subjecttype.abbreviation == 'TP'){
      this.groupes = this.groups_tp
    }

    console.log(this.groupes)

  }

  handleSectionChange(){

  }


  handleBlockChange(event){
    console.log(event.value)
    if(event.value && event.value.length >0){
      this.classrooms = this.all_classrooms.filter((c)=> event.value.indexOf(c.block_id) != -1)
    }else{
      this.classrooms = this.all_classrooms
    }
  } 

  checkUncheckAllClassrooms(){
    if(this.new_regime_line.classrooms && this.new_regime_line.classrooms.length >0){
      this.new_regime_line.classrooms =[]
    }else{
    this.new_regime_line.classrooms = this.classrooms.map((x)=>{return x.id})
    }
  }

  handleRegimeBlockChange(event , i){
    console.log(event.value)
    if(event.value && event.value.length >0){
      this.classrooms = this.all_classrooms.filter((c)=> event.value.indexOf(c.block_id) != -1)
    }else{
      this.classrooms = this.all_classrooms
    }
  } 


  checkUncheckRegimeAllClassrooms(i){
    if(this.data.regimes[i].classrooms && this.data.regimes[i].classrooms.length >0){
      this.data.regimes[i].classrooms =[]
    }else{
    this.data.regimes[i].classrooms = this.classrooms.map((x)=>{return x.id})
    }
  }






























  /* checkType(status, id) {
    console.log(status);
    if (status) {
      console.log("true")
      if (!this.data.regimes.find(x => x.subject_type_id == id)) {
        let r: Regime = new Regime();
        r.subject_type_id = id
        this.data.regimes.push(r)
      }
    } else {
      console.log("false")
      this.data.regimes = this.data.regimes.filter(x => x.subject_type_id != id)
    }
  }

  isCheckedType(type_id) {
    return this.data.regimes.find(x => x.subject_type_id == type_id)

  }

  getNbHours(type_id) {
    let i = this.data.regimes.findIndex(x => x.subject_type_id == type_id)
    if (i >= 0) {
      return this.data.regimes[i].nb_hours
    }
  }
  getTeacher(type_id) {
    let i = this.data.regimes.findIndex(x => x.subject_type_id == type_id)
    if (i >= 0) {
      return this.data.regimes[i].teacher_id
    }
  }


  changeNbHours(type_id, value) {
    console.log(value)
    let i = this.data.regimes.findIndex(x => x.subject_type_id == type_id)
    if (i >= 0) {
      this.data.regimes[i].nb_hours = value
    }

    console.log(this.data.regimes)

  }

  changeTeacher(type_id, value) {
    console.log(value)
    let i = this.data.regimes.findIndex(x => x.subject_type_id == type_id)
    if (i >= 0) {
      this.data.regimes[i].teacher_id = value
    }

    console.log(this.data.regimes)
  }


  IsChecked(id) {
    if (this.data.regimes) {
      if (this.data.regimes.find(x => x.subject_type_id == id)) {
        return true
      } else {
        return false
      }
    } else {
      return false
    }
  } */
}
