import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment} from '../environments/environment';
import { User } from './pages/users/user.model';
import { ConfigService } from './apis/config.service';
@Injectable({
  providedIn: 'root'
})
export class AuthService {

  constructor(
    private http : HttpClient,
    private config : ConfigService) {

   }


   login(user : any) {
     return this.http.post(this.config.baseUrl+'/login' , user)
   }


   hasPermission(action , table){
     let permissions = JSON.parse(localStorage.getItem('permissions'));
     if(permissions){
        if(permissions.find((s)=> s.key.indexOf(action)>-1 && s.table_name == table)){ // or s.key == action+'_'+table
          return true;
        }else{
          return false;
        }
     }else{
       return false;
     }
   }
}
