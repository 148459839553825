<app-content-header [hasBgImage]="true" [hideBreadcrumb]="true" [class]="'py-3'" [target]="'establishment'"></app-content-header>

<mat-card>
    <mat-form-field fxFlex="25">
        <input   matInput placeholder="{{'search' | translate}}" (keyup)="filter($event.target.value)">

    </mat-form-field>
    <div fxFlex="75">

        <button mat-raised-button color="primary" class="ums_primary_button" [style.float]="'inverse_float' | translate" (click)="openDialog()">
            <mat-icon class="fs20">add</mat-icon> {{'buttons.add' | translate}}
          </button>
         
    </div>
</mat-card>
<div fxLayout="row wrap" fxLayoutAlign="center" class="p-2">
    <div fxFlex="100" class="p-2">
        
        <div class="mat-elevation-z8">
            <table mat-table style="width: 100%;" [dataSource]="dataSource">

                <!-- Position Column -->
                <ng-container matColumnDef="designation">
                    <th mat-header-cell *matHeaderCellDef> {{'designation' | translate}} </th>
                    <td mat-cell *matCellDef="let element"  > {{element.designation}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="week1">
                    <th mat-header-cell *matHeaderCellDef> {{'week1' | translate}} </th>
                    <td mat-cell *matCellDef="let element"   > {{element.week1}} </td>
                </ng-container>
                <ng-container matColumnDef="week2">
                    <th mat-header-cell *matHeaderCellDef> {{'week2' | translate}} </th>
                    <td mat-cell *matCellDef="let element"   > {{element.week2}} </td>
                </ng-container>
                <ng-container matColumnDef="start_date">
                    <th mat-header-cell *matHeaderCellDef> {{'start_date' | translate}} </th>
                    <td mat-cell *matCellDef="let element"   > {{element.start_date}} </td>
                </ng-container>
                <ng-container matColumnDef="end_date">
                    <th mat-header-cell *matHeaderCellDef> {{'end_date' | translate}} </th>
                    <td mat-cell *matCellDef="let element"   > {{element.end_date}} </td>
                </ng-container>
                <ng-container matColumnDef="type">
                    <th mat-header-cell *matHeaderCellDef> {{'type' | translate}} </th>
                    <td mat-cell *matCellDef="let element"   > {{element.type}} </td>
                </ng-container>
                <ng-container matColumnDef="actions" justify="end" >
                    <th mat-header-cell *matHeaderCellDef [style.text-align]="'inverse_float' | translate"> {{'actions' | translate}} </th>
                    <td mat-cell *matCellDef="let element"    [style.text-align]="'inverse_float' | translate">
                        
                        <mat-icon class="warning" (click)="openEditDialog(element)">edit</mat-icon>
                        <mat-icon class="danger" (click)="remove(element.id)">delete_outline</mat-icon>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>

</div>