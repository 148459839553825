<app-content-header 
    [icon]="'playlist_add_check'" 
    [title]="'Select'" 
    [hideBreadcrumb]="false"
    [hasBgImage]="true" 
    [class]="'pb-4'"></app-content-header>

<div fxLayout="column" class="p-2">
    <div class="p-2">
        <mat-card>
            <mat-card-header>                
                <mat-card-subtitle><h2>Basic select</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field class="mx-2">
                    <mat-select placeholder="Favorite food" [(ngModel)]="selectedValue" name="food">
                        <mat-option *ngFor="let food of foods" [value]="food.value">
                            {{food.viewValue}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
                <p class="pt-3 mx-2"> Selected value: <b>{{selectedValue}}</b> </p>
            </mat-card-content>
        </mat-card>
    </div> 
    <div class="p-2">
        <mat-card>
            <mat-card-header>                
                <mat-card-subtitle><h2>Select with option groups</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field class="mx-2">
                    <mat-select placeholder="Pokemon" [formControl]="pokemonControl">
                        <mat-option>-- None --</mat-option>
                        <mat-optgroup *ngFor="let group of pokemonGroups" [label]="group.name" [disabled]="group.disabled">
                            <mat-option *ngFor="let pokemon of group.pokemon" [value]="pokemon.value">
                                {{ pokemon.viewValue }}
                            </mat-option>
                        </mat-optgroup>
                    </mat-select>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div> 
    <div class="p-2">
        <mat-card>
            <mat-card-header>                
                <mat-card-subtitle><h2>Select with multiple selection</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field class="mx-2">
                    <mat-select placeholder="Toppings" [formControl]="toppings" multiple>
                        <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div> 
    <div class="p-2">
        <mat-card>
            <mat-card-header>                
                <mat-card-subtitle><h2>Select with custom trigger text</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <mat-form-field class="mx-2">
                    <mat-select placeholder="Toppings" [formControl]="toppings2" multiple>
                        <mat-select-trigger>
                          {{toppings2.value ? toppings2.value[0] : ''}}
                          <small *ngIf="toppings2.value?.length > 1">
                            (+{{toppings2.value.length - 1}} others)
                          </small>
                        </mat-select-trigger>
                        <mat-option *ngFor="let topping of toppingList2" [value]="topping">{{topping}}</mat-option>
                    </mat-select>
                </mat-form-field>
            </mat-card-content>
        </mat-card>
    </div> 
</div>