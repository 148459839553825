<app-content-header 
    [icon]="'input'" 
    [title]="'Inputs'" 
    [hideBreadcrumb]="false"
    [hasBgImage]="true" 
    [class]="'pb-4'"></app-content-header>

<div fxLayout="column" class="p-2">
    <div class="p-2">
        <mat-card>
            <mat-card-header fxLayoutAlign="center">                
                <mat-card-subtitle><h2>Basic Inputs</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form fxLayout="column" fxLayoutAlign="center">
                    <mat-form-field>
                        <input matInput placeholder="Favorite food" value="Sushi">
                    </mat-form-field>
                    <mat-form-field>
                        <textarea matInput placeholder="Leave a comment"></textarea>
                    </mat-form-field>
                </form>                              
            </mat-card-content>
        </mat-card>
    </div>
    <div class="p-2">
        <mat-card>
            <mat-card-header fxLayoutAlign="center">                
                <mat-card-subtitle><h2>Input with a custom ErrorStateMatcher</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form fxLayout="column" fxLayoutAlign="center">
                    <mat-form-field>
                        <input matInput placeholder="Email" [formControl]="emailFormControl" [errorStateMatcher]="matcher">
                        <mat-hint>Errors appear instantly!</mat-hint>
                        <mat-error *ngIf="emailFormControl.hasError('email') && !emailFormControl.hasError('required')">Please enter a valid email address</mat-error>
                        <mat-error *ngIf="emailFormControl.hasError('required')">Email is <strong>required</strong></mat-error>
                    </mat-form-field>
                </form>                              
            </mat-card-content>
        </mat-card>
    </div>
    <div class="p-2">
        <mat-card>
            <mat-card-header fxLayoutAlign="center">                
                <mat-card-subtitle><h2>Auto-resizing textarea</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayout="column" fxLayoutAlign="center">
                <mat-form-field>
                    <textarea matInput 
                              placeholder="Autosize textarea" 
                              matTextareaAutosize 
                              matAutosizeMinRows="2"
                              matAutosizeMaxRows="5"></textarea>
                </mat-form-field>                       
            </mat-card-content>
        </mat-card>
    </div>
    <div class="p-2">
        <mat-card>
            <mat-card-header fxLayoutAlign="center">                
                <mat-card-subtitle><h2>Input with a clear button</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content fxLayout="column" fxLayoutAlign="center">
                <mat-form-field>
                    <input matInput type="text" placeholder="Clearable input" [(ngModel)]="value"/>
                    <button mat-button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="value=''">
                        <mat-icon>close</mat-icon>
                    </button>
                </mat-form-field>                       
            </mat-card-content>
        </mat-card>
    </div>
    <div class="p-2">
        <mat-card>
            <mat-card-header fxLayoutAlign="center">                
                <mat-card-subtitle><h2>Inputs in a form</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form fxLayout="column" fxLayoutAlign="center">
                  
                    <mat-form-field>
                        <input matInput placeholder="Company (disabled)" disabled value="Google">
                    </mat-form-field>

                    <table cellspacing="0" class="w-100">
                        <tr>
                            <td>
                                <mat-form-field class="w-100">
                                    <input matInput placeholder="First name">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="w-100">
                                    <input matInput placeholder="Long Last Name That Will Be Truncated">
                                </mat-form-field>
                            </td>
                        </tr>
                    </table>
                    
                    <mat-form-field class="w-100">
                        <textarea matInput placeholder="Address">1600 Amphitheatre Pkwy</textarea>
                    </mat-form-field>

                    <mat-form-field class="w-100">
                        <textarea matInput placeholder="Address 2"></textarea>
                    </mat-form-field>                   

                    <table cellspacing="0" class="w-100">
                        <tr>
                            <td>
                                <mat-form-field class="w-100">
                                    <input matInput placeholder="City">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="w-100">
                                    <input matInput placeholder="State">
                                </mat-form-field>
                            </td>
                            <td>
                                <mat-form-field class="w-100">
                                    <input matInput #postalCode maxlength="5" placeholder="Postal Code" value="94043">
                                    <mat-hint align="end">{{postalCode.value.length}} / 5</mat-hint>
                                </mat-form-field>
                            </td>
                        </tr>
                    </table>

                </form>
            </mat-card-content>
        </mat-card>
    </div>
</div>
