import { TeacherType } from "./teacher-type"
import { TeacherGrade } from "./teacher-grade"
import { Classroom } from "./classroom"

export class Teacher {
    id: number
    first_name: string
    last_name: string
    email: number
    phone : number
    cin : string
    establishment_id : number = JSON.parse(localStorage.getItem('user-infos')).establishment_id;
    teacher_grade_id : number
    teacher_type_id : number
    classroom_id : number
    teacher_type : TeacherType
    teacher_grade : TeacherGrade
    classroom : Classroom
    department_id : number;
    nb_max_days : number;

}
