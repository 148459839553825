<div mat-dialog-title><h1 >{{translate.instant('teacher_data')}}</h1></div>
<div mat-dialog-content >
    <mat-grid-list [cols]="days?.length +1 "  rowHeight="2:1">
        <mat-grid-tile></mat-grid-tile>
        <mat-grid-tile *ngFor="let day of days">{{day.abbreviation}}</mat-grid-tile>
        <span *ngFor="let session of periods">
            <mat-grid-tile>{{session.abbreviation}}</mat-grid-tile>
            <span *ngFor="let day of days">
                <mat-grid-tile>

                    <mat-checkbox (change)="checkSessionDay($event.checked,day.id, session.id)" [checked]="IsChecked(day.id , session.id)">
                    </mat-checkbox>
                </mat-grid-tile>
            </span>
        </span>

      </mat-grid-list>
</div>
<div mat-dialog-actions>
  <button  class="form-cancel" (click)="onNoClick()">{{'buttons.no_thanks' | translate }}</button>
  <button class="form-save" [mat-dialog-close]="data" cdkFocusInitial>Ok</button>
</div>

