import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/apis/config.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { SubjectService } from 'src/app/apis/subject.service';
import { TimingService } from 'src/app/apis/timing.service';
import { GenerateSetting } from 'src/app/viewModels/generate-setting';
import { Sector } from 'src/app/viewModels/sector';
import { SubjectType } from 'src/app/viewModels/subject-type';

@Component({
  selector: 'app-generate-dialog',
  templateUrl: './generate-dialog.component.html',
  styleUrls: ['./generate-dialog.component.css']
})
export class GenerateDialogComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private config : ConfigService,
    public dialogRef: MatDialogRef<GenerateDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: GenerateSetting,
    private apis: TimingService,
    private settingsApis : SettingsService,
    private hierarchyApis : HiararchyService,
    private subjectApis : SubjectService
  ) { }

  subject_types : SubjectType[];
  divisions : any[];
  sectors : Sector[];
periods_types : any[];
tp_major_sessions = this.config.tp_major_sessions

  form: FormGroup = new FormGroup({
    'nb_course_per_day': new FormControl(Validators.required),
    'nb_courses_basic_unity_per_day': new FormControl(Validators.required),
    'nb_courses_same_unity_per_day': new FormControl(Validators.required),
    'nb_courses_transversal_per_day': new FormControl(Validators.required),
    'set_generation_priority_to': new FormControl(Validators.required),
    'set_major_tp_on': new FormControl(Validators.required),
    'division_id': new FormControl(Validators.required),
    'sectors_ids': new FormControl(Validators.required),
    'is_all_sectors': new FormControl(Validators.required),
    'generate_vacant_sessions': new FormControl(Validators.required)

  })
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.getSubjectTypes();
    this.getYearDivisions();
    this.getSectors();
  }

  getSubjectTypes(){

    this.subjectApis.getEstablishmentSubjectTypes()
    .subscribe((data : SubjectType[])=>{
      this.subject_types = data.filter((x)=> x.abbreviation != 'TD');
    })
  }

  getYearDivisions(){
    this.settingsApis.getYearDivisions()
    .subscribe((data : any[])=>{
      this.divisions = data;
    })
  }

  getSectors(){
    this.hierarchyApis.getHiararchySectors()
    .subscribe((data : Sector[])=>{
      this.sectors = data;
    })
  }

}
