<app-content-header [hasBgImage]="true" [hideBreadcrumb]="true" [class]="'py-3'" [target]="'exams'">
</app-content-header>


<div fxLayout="row" class="p-2">
    <div fxFlex="100" class="p-2">
        <mat-card>
            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('sector')" [(ngModel)]="selectedSector"
                    (selectionChange)="handleSectorChange()">
                    <mat-option *ngFor="let sector of sectors" [value]="sector.id">
                        {{sector.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>
            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('ExamsType')" [(ngModel)]="selectedExamPeriod"
                    (selectionChange)="handleSectorChange()">
                    <mat-option *ngFor="let exam_data of examsperiods" [value]="exam_data">
                        {{exam_data.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>


            <div fxFlex="60">

                <!--              <mat-icon class="icon-add" [style.float]="'inverse_float' | translate" (click)="export()"
                    title="export data"> cloud_download</mat-icon> &nbsp;
                &nbsp;
                <mat-icon class="icon-add" [style.float]="'inverse_float' | translate" (click)="print()" title="print">
                    print</mat-icon> &nbsp; &nbsp;
                <mat-icon class="icon-add" [style.float]="'inverse_float' | translate" (click)="generateDialog()"
                    title="start generation">model_training</mat-icon> &nbsp; -->
                <button mat-raised-button color="primary" class="ums_primary_button"
                    [style.float]="'inverse_float' | translate" (click)="generateDialog()">
                    <mat-icon class="fs20">model_training</mat-icon> {{'buttons.generate' | translate}}
                </button>
                <button mat-raised-button color="basic" class="ums_basic_button"
                    [style.float]="'inverse_float' | translate" (click)="print()">
                    <mat-icon class="fs20">print</mat-icon> {{'buttons.print' | translate}}
                </button>
                <button mat-raised-button color="basic" class="ums_basic_button"
                    [style.float]="'inverse_float' | translate" (click)="export()">
                    <mat-icon class="fs20">download</mat-icon> {{'buttons.export' | translate}}
                </button>

            </div>
        </mat-card>
    </div>
</div>
<mat-progress-bar *ngIf="show_progess" mode="indeterminate"></mat-progress-bar>

<div fxLayout="row" class="p-2">
    <div fxFlex="85" class="p-2">

        <mat-card>
            <mat-card-content>
                <table class="table" id="print_ums_id" *ngIf="schedule && schedule.length >0 ; else no_data">
                    <!--   <tr style="margin-bottom: 1px!important;">
                        <td fxFlex="6"  class="bg-dark"></td>
                        <td fxFlex="{{width}}"  class="bg-dark" *ngFor="let period of periods">
                            {{period | date}}
                        </td>

                    </tr> -->
                    <tr *ngFor="let date of schedule">
                        <td fxFlex="20" style="margin-bottom: 1px;" class="bg-dark">{{date.date}}</td>
                        <td fxFlex="20" *ngFor="let exam of date.exams" class="draggable" dragula="bag-1"
                            id="{{date.date}};{{exam.id}}" class="bag"  data-name="seance">
                           

                            <mat-card style="background-color: #d1d1d1;">

                                <span id="{{exam.id}}">
                                    <span (click)="EditDialog(exam)"
                                        style="font-size: large; text-transform:uppercase  !important; font-weight: bold;">
                                        {{exam.subject?.abbreviation}}
                                    </span>
                                    <br>
                                    <span style="font-size: large; text-transform:uppercase  !important;">

                                        {{exam.start}} | {{exam.end}}
                                    </span>


                                </span>


                            </mat-card>




                        </td>
                    </tr>
                </table>
                <ng-template #no_data>
                    <div class="empty_frame">
                        <img src="assets/empty.png">
                    </div>
                </ng-template>
            </mat-card-content>
        </mat-card>

    </div>
    <div fxFlex="15" class="p-2">
        <!--  <mat-card>
            <mat-card-header fxLayoutAlign="center">
                <mat-card-subtitle fxLayoutAlign="center">
                    <h2>Pending list</h2>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content [dragula]="'bag-1'" class="bag">
                <mat-card *ngFor="let item of pending_list"
                    [class.bg-primary]="item.subject_type && item.subject_type.abbreviation =='C'" class="draggable p5"
                    [class.bg-accent]="item.subject_type && item.subject_type.abbreviation =='TP'"
                    [class.bg-default]="item.subject_type && item.subject_type.abbreviation =='TD'">
                    {{item.subject?.abbreviation}}<br>{{item.teacher?.first_name}} &nbsp;
                    {{item.teacher?.last_name}}<br>

                    {{item.section?.abbreviation}}
                </mat-card>
            </mat-card-content>
        </mat-card> -->
    </div>
</div>