<div class="content-header bg-primary hide_print" [class.bg-image]="hasBgImage" [ngClass]="class" *ngIf="setting.menu == 'horizontal'" >
  
 <!-- <app-breadcrumb [hidden]="hideBreadcrumb"></app-breadcrumb>
  <mat-icon *ngIf="icon" class="mat-icon-xlg">{{icon}}</mat-icon>-->
<h1 *ngIf="title  ">{{title | translate}} </h1> 
  <div [fxLayout]="'row'" fxLayoutAlign="center center">
    <div *ngFor="let menu of submenu" class="horizontal-menu-item">
        <a  mat-button  style="height: auto !important;"
            fxLayout="row" fxLayoutAlign=" 'center center'"
            [routerLink]="[menu.routerLink]" routerLinkActive="active-link" [routerLinkActiveOptions]="{exact:true}"
            [matTooltip]="menu.title" matTooltipPosition="above" [matTooltipDisabled]="'true'"
            [id]="'horizontal-menu-item-'+menu.id" >
            <mat-icon class="horizontal-menu-icon" style="color: #fff;" [class.selected-sub-menu]="currentRoute == menu.routerLink ">{{menu.icon}}</mat-icon>
            <span class="horizontal-menu-title" style="color: #fff;">{{menu.title | translate}}</span>
        </a>
  </div>
  
</div>