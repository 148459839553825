<div mat-dialog-title><h1 >{{translate.instant('teacher_data')}}</h1></div>
<div mat-dialog-content >
    <form fxLayout="column" [formGroup]="form"  >
        <mat-form-field class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('first_name')}}" [(ngModel)]="data.first_name"
                 [formControlName]="'first_name'">
           
            
        </mat-form-field>

        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('last_name')}}" [(ngModel)]="data.last_name"
                required [formControlName]="'last_name'">
            
        </mat-form-field>
        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('cin')}}" type="number" [(ngModel)]="data.cin"
                 [formControlName]="'cin'">
            
        </mat-form-field>
        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('email')}}" type="email"  email="true" [(ngModel)]="data.email"
                 [formControlName]="'email'">
            
        </mat-form-field>
        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('phone')}}"  [(ngModel)]="data.phone"
                 [formControlName]="'phone'">
            
        </mat-form-field>
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('teacher_type')" [formControlName]="'teacher_type_id'"
                [(ngModel)]="data.teacher_type_id">
                <mat-option *ngFor="let type of teacher_types" [value]="type.id">
                    {{type.designation}}</mat-option>
            </mat-select>

           
        </mat-form-field>

        <mat-form-field>
            <mat-select [placeholder]="translate.instant('teacher_grade')" [formControlName]="'teacher_grade_id'"
                [(ngModel)]="data.teacher_grade_id">
                <mat-option *ngFor="let grade of teacher_grades" [value]="grade.id">
                    {{grade.designation}}</mat-option>
            </mat-select>

           
        </mat-form-field>

        <mat-form-field>
            <mat-select [placeholder]="translate.instant('classroom')" [formControlName]="'classroom_id'"
                [(ngModel)]="data.classroom_id">
                <mat-option *ngFor="let class of classrooms" [value]="class.id">
                    {{class.designation}}</mat-option>
            </mat-select>

           
        </mat-form-field>
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('department')" [formControlName]="'department_id'"
                [(ngModel)]="data.department_id">
                <mat-option *ngFor="let department of departments" [value]="department.id">
                    {{department.designation}}</mat-option>
            </mat-select>

           
        </mat-form-field>
        <mat-form-field class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('nb_max_days')}}" [(ngModel)]="data.nb_max_days" type="number"
                 [formControlName]="'nb_max_days'">
           
            
        </mat-form-field>
    </form>
</div>
<div mat-dialog-actions>
  <button  mat-raised-button color="basic"  class=" ums_basic_button form-cancel" (click)="onNoClick()">{{'buttons.no_thanks' | translate}}</button>
  <button  mat-raised-button color="primary" [disabled]="!form.valid" class="ums_primary_button form-save" [mat-dialog-close]="data" cdkFocusInitial>
   {{'buttons.save' | translate}}
  </button>
</div>

