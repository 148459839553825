import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DragulaService } from 'ng2-dragula';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { ExcelService } from 'src/app/apis/excel/excel.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { TimingService } from 'src/app/apis/timing.service';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { AnnualCalendar } from 'src/app/viewModels/annual-calendar';
import { Day } from 'src/app/viewModels/day';
import { Sector } from 'src/app/viewModels/sector';
import { Teacher } from 'src/app/viewModels/teacher';
import { VacationExam } from 'src/app/viewModels/vacation-exam';
import { MonotoringGenerateDialogComponent } from '../monotoring-generate-dialog/monotoring-generate-dialog.component';
declare var $ : any;
@Component({
  selector: 'app-monotoring-schedule',
  templateUrl: './monotoring-schedule.component.html',
  styleUrls: ['./monotoring-schedule.component.css']
})
export class MonotoringScheduleComponent implements OnInit {

  public settings: Settings;
  subs = new Subscription();

  constructor(private settingService: SettingsService,
    private establishmentApis : EstablishmentService,
    public appSettings: AppSettings,
    private excelService: ExcelService,
    public translate: TranslateService,
    private hierarchyService: HiararchyService,
    private apis: TimingService,
    public dialog: MatDialog,
    private dragulaService: DragulaService,
    private toastr: ToastrService) {
    this.settings = this.appSettings.settings;




    this.subs.add(this.dragulaService.drop("bag-1")
      .subscribe(({ name, el, target, source, sibling }) => {
        // ...

        let sourceTab = source.id.split('-')
        let targetTab = target.id.split('-')

        let seance_id;
        console.log('from ' + sourceTab[0] + ':' + sourceTab[1] + '     to ' + targetTab[0] + ' : ' + targetTab[1])
         target.childNodes.forEach((x: any) => {
          if (x.dataset && x.dataset.name == 'seance' && x.id.indexOf(source.id)==0) {
            console.log(x.id)
            seance_id = x.id.split('-')[2]
          }
        })
        let body = {
         /*  source_day : this.days.find((x)=> x.id == Number(sourceTab[0])),
          source_period : this.periods.find((x)=> x.id == Number(sourceTab[1])),
          target_day : this.days.find((x)=> x.id == Number(targetTab[0])),
          target_period : this.periods.find((x)=> x.id == Number(targetTab[1])),
          session_id : seance_id */
        }

        console.log(body)
        this.updateSessionDialog(body);
     

        })
      );

 
  }
  days: Day[];
  width = 20;
  schedule: any[];
  selectedTeacher;
  teachers: Teacher[];
  pending_list: any[];

  semestres_trimestres: any = [];
  selectedSemestre;
  selectedExamPeriod
  selectedShowType
  types = [{ slug : 'dates' , value : 'par date'} , {slug : 'teachers' , value:'par enseignant'}]
  periods : String[];

  ngOnInit(): void {
    
  

this.getCurrentAnnualCalendar();
   


  }
  current_annual_calendar
  examsperiods : VacationExam[]
  getCurrentAnnualCalendar()
  {
    this.establishmentApis.getEstablishmentCurrentAnnualCalendar()
    .subscribe((data : AnnualCalendar)=>{
      this.current_annual_calendar = data;
      if(this.current_annual_calendar){
        this.getExamsPeriods()
       
      }
    })
  }
  getExamsPeriods() {
    this.establishmentApis.getAnnualCalendarVacationsExams(this.current_annual_calendar.id)
      .subscribe((data:  VacationExam[]) => {
        this.examsperiods = data.sort((a,b)=> a.start_date > b.start_date ? 0 : -1);
        console.log(this.examsperiods)
      })
  }

  getMonotorings(){
    
    if(this.selectedExamPeriod && this.selectedShowType && this.selectedShowType =='teachers'){
      this.apis.getTeachersMonotingSchedule( this.selectedExamPeriod.id)
      .subscribe((data: any) => {
        this.schedule = data;
        
        
        console.log(data)
      })
    }else if (this.selectedExamPeriod && this.selectedShowType && this.selectedShowType =='dates'){
this.handleExamPeriodChange()
    }
  }



  generateSemestre;
  show_progess = false;
  handleGenerateChange() {
    this.show_progess = true;
    this.apis.generateExamsSchedule(this.generateSemestre)
      .subscribe((data) => {
       
        this.show_progess = false;
        this.showSuccess('success')

      }, (err) => {
        console.log(err)
        this.show_progess = false;
        this.showError('generate_server_error')

      })
  }
  handleExamPeriodChange(){
    console.log(this.selectedExamPeriod)
    if(this.selectedExamPeriod){
    
        console.log(this.selectedExamPeriod)
        this.apis.getMonotingSchedule( this.selectedExamPeriod.id)
        .subscribe((data: any) => {
          this.schedule = data;
          
          
          console.log(data)
        })
  
  
      } else {
        this.schedule = []
      }
    
  }

  export() {
    this.excelService.exportToFile('surveillance', 'print_ums_id');
  }


  showSuccess(msg) {
    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg) {
    this.toastr.error(this.translate.instant(msg))
  }

  print() {
    var $print = $('#print_ums_id')
      .clone()
      .addClass('ums_print')
      .prependTo('body');
    window.print();
    $print.remove();
  }




  updateSessionDialog(body) {
   /*  let ss_body = {source_day_id : body.source_day.id , source_period_id : body.source_period.id , target_day_id : body.target_day.id , target_period_id : body.target_period.id , session_id : body.session_id};
    this.apis.checkSession(ss_body)
    .subscribe((check : any)=>{
      if(check.error){
        this.showError(check.error);
        this.handleSectorChange();
        this.show_progess = false;
      }else{
        const dialogRef = this.dialog.open(ExamsUpdateDialogComponent, {
          width: '500px',
          data: body
        });
    
        dialogRef.afterClosed().subscribe(result => {
          console.log('The dialog was closed', result);
          // console.log(this.item)
    
         
          
          this.show_progess = false
    
        });
      
      }
    }) */
 }

 generateDialog() {
  
  const dialogRef = this.dialog.open(MonotoringGenerateDialogComponent, {
    width: '500px',
    data: {annual_calendar_id : this.current_annual_calendar.id}
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed', result);
    // console.log(this.item)
    if (result) {
      this.show_progess = true;
      console.log(result)
      this.apis.generateMonotoringSchedule(result)
        .subscribe((data) => {
          console.log(data);
          this.show_progess = false;
          this.showSuccess('success')
          this.handleExamPeriodChange();

        }, (err) => {
          console.log(err)
          this.show_progess = false;
          this.showError('generate_server_error')

        })
    }

  });
}
}
