import { Component, OnInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { TeacherService } from 'src/app/apis/teacher.service';
import { TimingService } from 'src/app/apis/timing.service';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { Day } from 'src/app/viewModels/day';
import { Period } from 'src/app/viewModels/period';
import { Teacher } from 'src/app/viewModels/teacher';
@Component({
  selector: 'app-teacher-time-table',
  templateUrl: './teacher-time-table.component.html',
  styleUrls: ['./teacher-time-table.component.css']
})
export class TeacherTimeTableComponent implements OnInit {

  public settings: Settings;
  
  constructor(private settingService : SettingsService , 
    public appSettings:AppSettings,
    public translate : TranslateService,
    private teacherService : TeacherService,
    private apis : TimingService) { 
      this.settings = this.appSettings.settings;
    }
    colors = ['#f8f68f', '#f8e68f', '#f8d68f', '#f8c68f', '#f8e68fa1', '#f8f688ba', '#f8f48f', '#f8f28f']

days : Day[];
periods : Period[];
width = 20;
timetable : any[];
selectedTeacher;
selectedSemestre;
semestres_trimestres: any = [];
teachers : Teacher[];
  ngOnInit(): void {
    this.teacherService.getEstablishmentTeachers()
    .subscribe((data : Teacher[])=>{
      this.teachers = data;
    })
    this.settingService.getDays()
    .subscribe((data : Day[])=>{
      this.days = data;
    })


    this.apis.getHiararchyPeriods()
    .subscribe((data : Period[])=>{
     
      this.periods = data.filter((x)=> x.type != 'break')/* .sort((a , b)=> a.start_hour > b.start_hour ? 0 : -1 ); */
      this.width = 100/ (this.periods.length+1);
    })

    this.settingService.getYearDivisions()
    .subscribe((data: any[]) => {
      this.semestres_trimestres = data;
    })
    

    
   
  }



  handleTeacherChange(){
    if(this.selectedTeacher && this.selectedSemestre){
      this.apis.getTeacherTimeTable(this.selectedTeacher , this.selectedSemestre)
      .subscribe((data : any[])=>{
        this.timetable = data;
        console.log(this.timetable)
      })
      this.apis.getTeacherPendingTimeTable(this.selectedTeacher , this.selectedSemestre)
      .subscribe((data : any[])=>{
        console.log(data)
      })
    }else{
     
      this.timetable = []
    }
  }
  getDiff(timeString1, timeString2) {

    if (timeString1 && timeString2) {
      var timeTokens = timeString1.split(':');
      var timeTokens2 = timeString2.split(':');
      var d1 = new Date(1970, 0, 1, timeTokens[0], timeTokens[1], 0);
      var d2 = new Date(1970, 0, 1, timeTokens2[0], timeTokens2[1], 0);


      return (((d2.getTime() - d1.getTime()) / 60000) * -2) + 1;
    } else {
      return 0;
    }


  }
}
