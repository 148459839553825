import { Injectable } from '@angular/core';
import { HttpHeaders } from '@angular/common/http';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  constructor(private translate: TranslateService) { }


  // server = 'https://isimmtimetable.alwaysdata.net'
  // server = 'https://mysystem.alwaysdata.net'
  // server = 'https://ensit.alwaysdata.net'

  server = 'https://nachd.alwaysdata.net/sites/ums/UmsServer'
   readonly baseUrl = `${this.server}/public/api`;
  readonly hostName = 'https://ums.enstab.tn';
  readonly storage_name = `${this.server}/storage/app/public/`;








  establishment_id

  getOptions() {
    if (localStorage.getItem('user-infos')) {
      this.establishment_id = JSON.parse(localStorage.getItem('user-infos')).establishment_id
      let options = {
        headers: new HttpHeaders({ 'Establishmentid': this.establishment_id + '' })
      }

      return options
    }

  }

  period_types = [
    {
      slug: 'session', value: this.translate.instant('session')
    },{
      slug: 'session2', value: this.translate.instant('session2')
    }, {
      slug: 'break', value: this.translate.instant('break')
    }
  ]

  week_types = [
    {
      slug: 'H', value: this.translate.instant('H')
    }, {
      slug: 'Q-A-B', value: this.translate.instant('q_a_b')
    }, {
      slug: 'Z-1-2', value: this.translate.instant('zone_1_2')
    }, {
      slug: 'Z-3-4', value: this.translate.instant('zone-3-4')
    }, {
      slug: 'M-1-2', value: this.translate.instant('m-1-2')
    }, {
      slug: 'M-3-4', value: this.translate.instant('m-3-4')
    }
  ]

  vacation_exam_types = [
    {
      slug: 'exam', value: this.translate.instant('exam')
    }, {
      slug: 'vacation', value: this.translate.instant('vacation')
    }
  ]

  classroom_type = [{
    slug: 'TP',
    value: 'TP'
  }, {
    slug: 'C-TD',
    value: 'Cours/Td'
  }, {
    slug: 'C',
    value: 'Cours'
  }]

  types_ue = [{
    slug : 'fond',
    value : this.translate.instant('fond')
  },{
    slug : 'trans',
    value : this.translate.instant('trans')
  }]



  tp_major_sessions = [
    {
      slug : 'start',
      value : this.translate.instant('start')
    },
    {
      slug : 'end',
      value : this.translate.instant('end')
    },
    {
      slug : 'varied',
      value : this.translate.instant('varied')
    }
  ]


  session_types = [
   {
      slug : 'extra_session',
      value : this.translate.instant('extra_session')
    },{
      slug : 'exceptional_session',
      value : this.translate.instant('exceptional_session')
    }
  ]


}
