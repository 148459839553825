export class NotAvailableEstablishment {

    id;
    establishment_id = JSON.parse(localStorage.getItem('user-infos')).establishment_id;
    type;
    day_id;
    period_id;




}
