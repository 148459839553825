<div mat-dialog-title>
    <h1>{{translate.instant('settings')}}</h1>
</div>
<div mat-dialog-content>
    <form fxLayout="column" [formGroup]="form">
        <mat-form-field>
            <mat-select [placeholder]="translate.instant('Dates')" [formControlName]="'date'"
                [(ngModel)]="data.date" required>
                <mat-option *ngFor="let date of all_dates" [value]="date">
                    {{date}}</mat-option>
            </mat-select>

           
        </mat-form-field>
        <mat-form-field class="pr-2 pl-2">
            <input matInput type="time" placeholder="{{translate.instant('start')}}"
                [(ngModel)]="data.start" required [formControlName]="'start'">


        </mat-form-field>
        <mat-form-field class="pr-2 pl-2">
            <input matInput type="time" placeholder="{{translate.instant('end')}}"
                [(ngModel)]="data.end" required [formControlName]="'end'">


        </mat-form-field>
      



    </form>
</div>
<div mat-dialog-actions>

    <button mat-raised-button color="basic" class=" ums_basic_button form-cancel"
        (click)="onNoClick()">{{'buttons.no_thanks' | translate}}</button>
    <button mat-raised-button color="primary" [disabled]="!form.valid" class="ums_primary_button form-save"
        [mat-dialog-close]="data" cdkFocusInitial>
        {{'buttons.save' | translate}}
    </button>
</div>