import { SubjectType } from './../../../viewModels/subject-type';
import { AnnualCalendar } from './../../../viewModels/annual-calendar';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { Classroom } from './../../../viewModels/classroom';
import { WeekType } from './../../../viewModels/week-type';
import { Day } from './../../../viewModels/day';
import { Period } from './../../../viewModels/period';
import { Teacher } from './../../../viewModels/teacher';
import { TeacherService } from 'src/app/apis/teacher.service';
import { Subject } from './../../../viewModels/subject';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { SubjectService } from 'src/app/apis/subject.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { TimingService } from 'src/app/apis/timing.service';
import { GenerateSetting } from './../../../viewModels/generate-setting';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { ConfigService } from 'src/app/apis/config.service';
import { TranslateService } from '@ngx-translate/core';
import { Component, OnInit, Inject } from '@angular/core';

@Component({
  selector: 'app-add-session-dialog',
  templateUrl: './add-session-dialog.component.html',
  styleUrls: ['./add-session-dialog.component.css']
})
export class AddSessionDialogComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private config: ConfigService,
    public dialogRef: MatDialogRef<AddSessionDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private apis: TimingService,
    private settingsApis: SettingsService,
    private hierarchyApis: HiararchyService,
    private subjectApis: SubjectService,
    private teacherApis: TeacherService,
    private establishmentApis: EstablishmentService
  ) { }


  form: FormGroup = new FormGroup({
    'section_id': new FormControl(this.data.section_id),
    'group_tp_id': new FormControl(this.data.grp_tp_id),
    'group_td_id': new FormControl(this.data.grp_td_id),
    'division_id': new FormControl(this.data.division_id, Validators.required),
    'subject_id': new FormControl('',Validators.required),
    'display_name': new FormControl('',Validators.minLength(4)),
    'teacher_id': new FormControl('',Validators.required),
    'day_id': new FormControl('',Validators.required),
    'period_id': new FormControl('',Validators.required),
    'type': new FormControl('',Validators.required),
    'week_type_id': new FormControl('',Validators.required),
    'subject_type_id': new FormControl('',Validators.required),
    'week_name': new FormControl(''),
    'classroom_id': new FormControl(''),
    'start_date': new FormControl(''),
    'end_date': new FormControl(''),
    'start_hour': new FormControl(''),
    'end_hour': new FormControl(''),
    'teacher2_id': new FormControl('')

  })

  session_types = this.config.session_types
  subjects: Subject[] = []
  teachers: Teacher[] = []
  periods: Period[] = []
  days: Day[] = []
  week_types: WeekType[] = []
  classrooms: Classroom[] = []
  subject_types : SubjectType[]=[]

  week_names = []
  ngOnInit(): void {
    console.log(this.data)
    this.getSubjects();
    this.getTeachers();
    this.getPeriods();
    this.getDays();
    this.getWeekTypes();
    this.getClassrooms();
    this.getSubjectTypes();

  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  getSubjects() {
    this.subjectApis.getSectorSubjects(this.data.sector_id)
      .subscribe((result: Subject[]) => {
        this.subjects = result
        //console.log(result)
      })
  }

  getTeachers() {
    this.teacherApis.getEstablishmentTeachers()
      .subscribe((result: Teacher[]) => {
        this.teachers = result
      })
  }

  getPeriods() {
    this.apis.getHiararchyPeriods()
      .subscribe((result: Period[]) => {
        this.periods = result
      })

  }
  getDays() {
    this.settingsApis.getDays().subscribe((result: Day[]) => this.days = result)
  }

  getWeekTypes() {

    this.establishmentApis.getEstablishmentCurrentAnnualCalendar()
      .subscribe((data: AnnualCalendar) => {
        console.group('data' , data)
        this.establishmentApis.getAnnualCalendarWeekTypes(data.id)
          .subscribe((data2: WeekType[]) => {
            this.week_types = data2.sort((a, b) => a.start_date > b.start_date ? 0 : -1);
              console.group('data' , data2)
          })
      })
  }


  getClassrooms() {

  }

  handleSubjectchange(){
    if(this.data.subject_id){
      let s = this.subjects.find((x)=>x.id == this.data.subject_id)
     // this.form.get('display_name').setValue(s.abbreviation)
      this.data.display_name = s.abbreviation
    }else{
      console.log('else')
        this.data.display_name = undefined

    }
  }

  getSubjectTypes() {
    this.subjectApis.getEstablishmentSubjectTypes()
      .subscribe((result: SubjectType[]) => {
        this.subject_types = result
      })

  }
  handleWeekChange(){
    if(this.data.week_type_id){
      let w = this.week_types.find((x)=>x.id == this.data.week_type_id)
        this.week_names = [w.week1 , w.week2]

    }else{
      this.week_names = [];
      this.data.week_name = undefined
     

    }
  }

  handlePeriodChange(){
    if(this.data.period_id){
      let p = this.periods.find((x)=>x.id == this.data.period_id)
      this.data.start_hour = p.start_hour
      this.data.end_hour = p.end_hour
    }else{
      this.data.start_hour = undefined
      this.data.end_hour = undefined
    }
  }
  handleDayChange(){
    if(this.data.day_id){
   let d = new Date();
   console.log(this.data.day_id)
   d.setDate(d.getDate() + 7 - d.getDay()+ Number(this.data.day_id))
      this.data.start_date = d
      console.log(this.data)
    }
  }

}
