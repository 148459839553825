export class NotAvailableSector {
    
    id;
    establishment_id = JSON.parse(localStorage.getItem('user-infos')).establishment_id;
    sector_id;
    day_id;
    period_id;




    constructor(sector_id){
        this.sector_id = sector_id
    }
}
