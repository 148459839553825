import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators} from '@angular/forms';
import { emailValidator } from '../../theme/utils/app-validators';
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';
import { AuthService } from 'src/app/auth.service';
import { switchAll } from 'rxjs/operators';
import {ToastrService} from 'ngx-toastr';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html'
})
export class LoginComponent {
  public form:FormGroup;
  public settings: Settings;
  constructor(public appSettings:AppSettings, public fb: FormBuilder, public router:Router , private api : AuthService , private toastr : ToastrService){
    this.settings = this.appSettings.settings;
    this.form = this.fb.group({
      'email': [null, Validators.compose([Validators.required, emailValidator])],
      'password': [null, Validators.compose([Validators.required, Validators.minLength(6)])],
      'rememberMe': false
    });
  }

  public onSubmit(values:Object):void {
    if (this.form.valid) {
      this.api.login(values)
      .subscribe((data : any)=>{
        console.log(data);
        this.showSuccess("Success Authentication" , "")
        localStorage.setItem('user-infos' , JSON.stringify(data.user));
        localStorage.setItem('permissions' , JSON.stringify(data.permissions));
        this.router.navigate(['/ums'])
      } , (err)=>{
        console.log(err)
        this.showError("Error !" , err.error.error)
      })
    }
  }

  showSuccess(title , msg ) {
    this.toastr.success(title, msg);
  }
  showError(title , msg){
    this.toastr.error(title , msg)
  }
  ngAfterViewInit(){
    this.settings.loadingSpinner = false;
    if(JSON.parse(localStorage.getItem('user-infos'))){
      this.router.navigate(['/ums'])
    }
  }
}
