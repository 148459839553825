import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/apis/config.service';
import { TimingService } from 'src/app/apis/timing.service';
import { Period } from 'src/app/viewModels/period';

@Component({
  selector: 'app-period-form',
  templateUrl: './period-form.component.html',
  styleUrls: ['./period-form.component.css']
})
export class PeriodFormComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private config : ConfigService,
    public dialogRef: MatDialogRef<PeriodFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: Period,
    private apis: TimingService,
  ) { }

periods_types : any[];
periods : Period[];
  form: FormGroup = new FormGroup({
    'designation': new FormControl(Validators.required),
    'abbreviation': new FormControl(Validators.required),
    'start_hour': new FormControl(Validators.required),
    'end_hour': new FormControl(Validators.required),
    'type': new FormControl(Validators.required),
    'overlap_ids': new FormControl(),

  })
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.apis.getHiararchyPeriods()
    .subscribe((data : Period[])=>{
      this.periods = data
    })
   this.periods_types = this.config.period_types;
  }

}
