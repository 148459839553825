import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Teacher } from 'src/app/viewModels/teacher';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { TeacherService } from 'src/app/apis/teacher.service';
import { Classroom } from 'src/app/viewModels/classroom';
import { TeacherType } from 'src/app/viewModels/teacher-type';
import { TeacherGrade } from 'src/app/viewModels/teacher-grade';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { Department } from 'src/app/viewModels/department';
import { HiararchyService } from 'src/app/apis/hiararchy.service';

@Component({
  selector: 'app-teacher-form',
  templateUrl: './teacher-form.component.html',
  styleUrls: ['./teacher-form.component.css']
})
export class TeacherFormComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<TeacherFormComponent>,
    private hiarachyService : HiararchyService,
    @Inject(MAT_DIALOG_DATA) public data: Teacher,
    private apis: TeacherService,
    private establishmentService: EstablishmentService
  ) { }

  classrooms: Classroom[];
  teacher_types: TeacherType[];
  teacher_grades: TeacherGrade[];
  departments : Department[];

  form: FormGroup = new FormGroup({
    'first_name': new FormControl(),
    'last_name': new FormControl(Validators.required),
    'cin': new FormControl(),
    'email': new FormControl(),
    'phone': new FormControl(),
    'teacher_type_id': new FormControl(),
    'teacher_grade_id': new FormControl(),
    'classroom_id': new FormControl(),
    'department_id': new FormControl(),
    'nb_max_days' : new FormControl(),

  })
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
   
    this.apis.getEstablishmentTeacherGrades()
      .subscribe((data: TeacherGrade[]) => {
        this.teacher_grades = data;
      })

    this.apis.getEstablishmentTeacherTypes()
      .subscribe((data: TeacherType[]) => {
        this.teacher_types = data;
      })

      this.establishmentService.getEstablishmentClassrooms()
      .subscribe((data : Classroom[])=>{
        this.classrooms = data
      })

      this.hiarachyService.getHiararchyDepartments()
      .subscribe((data : Department[])=>{
        this.departments = data;
      })
  }
}

