import { Time } from "@angular/common";

export class Period {
    id : number
    designation : string;
    abbreviation : string;
    start_hour : Time;
    end_hour : Time;
    type : String;
    overlap_ids : any;
    establishment_id : number = JSON.parse(localStorage.getItem('user-infos')).establishment_id;
    
}
