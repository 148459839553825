import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { TimingService } from 'src/app/apis/timing.service';
import { Day } from 'src/app/viewModels/day';
import { EstablishmentSetting } from 'src/app/viewModels/establishment-setting';
import { NotAvailableSector } from 'src/app/viewModels/not-available-sector';
import { Period } from 'src/app/viewModels/period';
import { Sector } from 'src/app/viewModels/sector';

@Component({
  selector: 'app-sector-availibility',
  templateUrl: './sector-availibility.component.html',
  styleUrls: ['./sector-availibility.component.css']
})
export class SectorAvailibilityComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    public dialogRef: MatDialogRef<SectorAvailibilityComponent>,

    @Inject(MAT_DIALOG_DATA) public data: any,
    private apis: HiararchyService,
    private establishmentApis : EstablishmentService,
    private timingService : TimingService,
    private settingServices: SettingsService
  ) { }

days : Day[]
 
  onNoClick(): void {
    this.dialogRef.close();
  }
  periods : Period[];
  sector : Sector;
  ngOnInit() {
    this.timingService.getHiararchyPeriods()
    .subscribe((data : Period[])=>{
      this.periods = data.filter(x=> x.type == 'session')
    })
    this.sector = this.data.sector;
    let availibility : NotAvailableSector[];
     this.apis.getSectorAvailibility(this.sector.id)
    .subscribe((data : NotAvailableSector[])=>{
      if(data && data.length >0){
        this.data.availibility = data;
      }
      else{
        this.data.availibility = [];
      }
    })

    this.settingServices.getDays()
    .subscribe((data : Day[])=>{
      this.establishmentApis.getEstablishmentSettings()
      .subscribe((establishmentSettings : EstablishmentSetting)=>{
        this.days = data.filter((x : Day)=> establishmentSettings.weekend_days.indexOf(x.id) == -1)
      })
      
      console.log(this.days)
    })
  }


  IsChecked( day_id , session_id){
    if(this.data.availibility){
      if(this.data.availibility.find(x=> x.day_id == day_id && x.period_id == session_id)){
        return true
      }else{
        return false
      }
    }else{
      return false
    }
  }


  checkSessionDay(status , day_id , session_id){
    console.log(status);
    if(status){
      console.log("true")
      if(!this.data.availibility.find(x=> x.day_id == day_id && x.period_id == session_id)){
        let r : NotAvailableSector = new NotAvailableSector(this.sector.id);
        r.day_id = day_id;
        r.period_id = session_id;
        this.data.availibility.push(r)
      } 
    }else{
      console.log("false")
     this.data.availibility =  this.data.availibility.filter(x => !(x.day_id == day_id && x.period_id == session_id))
    }
  }

}
