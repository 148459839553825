import { Equipment } from './../viewModels/equipment';
import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';

import { ConfigService } from './config.service';
import { Classroom } from '../viewModels/classroom';

@Injectable({
  providedIn: 'root'
})
export class EstablishmentService {

  constructor(
    private http: HttpClient,
    private config: ConfigService) { }


  user = JSON.parse(localStorage.getItem('user-infos'));


  getEstablishmentById() {
    return this.http.get(this.config.baseUrl + '/establishment', this.config.getOptions())
  }


  getEstablishmentSettings() {
    return this.http.get(this.config.baseUrl + '/establishment-settings', this.config.getOptions())
  }

  createOrUpdateEstablishmentSettings(body) {
    return this.http.post(this.config.baseUrl + '/establishment-settings', body, this.config.getOptions())
  }

  //building CRUD

  getEstablishmentBuildings() {

    return this.http.get(this.config.baseUrl + '/buildings', this.config.getOptions());
  }

  getBuildingById(id) {

    return this.http.get(this.config.baseUrl + '/building/' + id, this.config.getOptions());
  }
  setBuilding(body: any) {

    return this.http.post(this.config.baseUrl + '/building', body, this.config.getOptions());
  }
  removeBuilding(id) {
    return this.http.delete(this.config.baseUrl + '/building/' + id, this.config.getOptions());
  }



  getBuildingBlocks(building_id) {
    return this.http.get(this.config.baseUrl + '/building/blocks/' + building_id, this.config.getOptions());

  }
  setBlock(body: any) {

    return this.http.post(this.config.baseUrl + '/block', body, this.config.getOptions());
  }


  getBlockClassrooms(block_id){
    return this.http.get(this.config.baseUrl + '/block/classrooms/'+block_id , this.config.getOptions())
  }

  getEstablishmentClassrooms(){
    return this.http.get(this.config.baseUrl + '/classrooms', this.config.getOptions())
  }

  getEstablishmentBlocks(){
    return this.http.get(this.config.baseUrl + '/blocks', this.config.getOptions())
  }

  setClassroom(body: any) {

    return this.http.post(this.config.baseUrl + '/classroom', body, this.config.getOptions());
  }
  removeClassroom(id) {
    return this.http.delete(this.config.baseUrl + '/classroom/' + id, this.config.getOptions());
  }



  getEstablishmentEquipment(){
    return this.http.get(this.config.baseUrl + '/equipments', this.config.getOptions())
  }


  setEquipment(body: any) {

    return this.http.post(this.config.baseUrl + '/equipment', body, this.config.getOptions());
  }
  removeEquipment(id) {
    return this.http.delete(this.config.baseUrl + '/equipment/' + id, this.config.getOptions());
  }


  getEstablishmentCurrentAnnualCalendar(){
    return this.http.get(this.config.baseUrl + '/annual-calendar/current' , this.config.getOptions());

  }

  getAnnualCalendarVacationsExams(annual_calendar_id){
    return this.http.get(this.config.baseUrl + '/annual-calendar/vacations-exams/'+annual_calendar_id , this.config.getOptions());

  }
  getVacationExamById(id){
    return this.http.get(this.config.baseUrl + '/vacation-exam/'+id , this.config.getOptions());

  }
  createOrUpdateVacationExam(body){
    return this.http.post(this.config.baseUrl + '/vacation-exam', body, this.config.getOptions());

  }
  removeVacationExam(id){
    return this.http.delete(this.config.baseUrl + '/vacation-exam/' + id, this.config.getOptions());

  }


  getAnnualCalendarWeekTypes(annual_calendar_id){
        return this.http.get(this.config.baseUrl + '/annual-calendar/week-types/'+annual_calendar_id , this.config.getOptions());

  }

  getWeekTypeById(id){
    return this.http.get(this.config.baseUrl + '/week-type/'+id , this.config.getOptions());

  }
  createOrUpdateWeekType(body){
    return this.http.post(this.config.baseUrl + '/week-type', body, this.config.getOptions());

  }
  removeWeekType(id){
    return this.http.delete(this.config.baseUrl + '/week-type/' + id, this.config.getOptions());

  }

  getEstablishmentAvailibility(){
    return this.http.get(this.config.baseUrl+'/establishment/availibility' , this.config.getOptions());
  }

  setEstablishmentClosedAvailibility(body){
    return this.http.post(this.config.baseUrl+'/establishment/availibility/closed' , body, this.config.getOptions());
  }

  setEstablishmentAvoidedAvailibility(body){
    return this.http.post(this.config.baseUrl+'/establishment/availibility/avoided' , body, this.config.getOptions());
  }

  importClassrooms(body : Classroom[]){
    console.log(body)
    return this.http.post(this.config.baseUrl+'/import/classrooms' , body , this.config.getOptions())
  }

  importEquipments(body : Equipment[]){
    console.log(body)
    return this.http.post(this.config.baseUrl+'/import/equipments' , body , this.config.getOptions())
  }


  getStats(){
    return this.http.get(this.config.baseUrl+'/stats', this.config.getOptions());
  }

  searchConfilts(){
    return this.http.get(this.config.baseUrl+'/timetable/search_conflit', this.config.getOptions());
  }
  getExamConflits(body : any){
    return this.http.get(this.config.baseUrl+'/exams/conflits' , this.config.getOptions() )
  }


  getAllPending(){
    return this.http.get(this.config.baseUrl+'/timetable/allpending', this.config.getOptions())
  }


  getAllWithoutClassroom(){
    return this.http.get(this.config.baseUrl+'/timetable/allNoClassroom', this.config.getOptions())

  }
}
