import { Component, OnInit } from '@angular/core';
import { Building } from 'src/app/viewModels/building';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { Block } from 'src/app/viewModels/block';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { Classroom } from 'src/app/viewModels/classroom';

import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import Swal from 'sweetalert2';
import { ClassroomFormComponent } from './classroom-form/classroom-form.component';
import { MatDialog } from '@angular/material/dialog';
import { ExcelService } from 'src/app/apis/excel/excel.service';
import { ConfigService } from 'src/app/apis/config.service';
declare var $ : any;
@Component({
  selector: 'app-classrooms',
  templateUrl: './classrooms.component.html',
  styleUrls: ['./classrooms.component.css']
})
export class ClassroomsComponent implements OnInit {
  classrooms: Classroom[];
  displayedColumns: string[] = ['designation', 'abbreviation', 'block','type', 'actions'];
  dataSource
  selectedItem: any;
  buildings: Building[]
  blocks: Block[];
  selected_building: number;
  selected_block: number;


  item: Classroom = new Classroom();
  show_form = false;

  constructor(
    private apis: EstablishmentService,
    public translate: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private excelService : ExcelService,
    private config : ConfigService

  ) { }
  excel_model_path = this.config.hostName+'/assets/excel_models/classrooms_model.xlsx';

 
  form: FormGroup = new FormGroup({
    'designation': new FormControl(Validators.required),
    'abbreviation': new FormControl(Validators.required),
    'capacity': new FormControl(Validators.required),
    'block_id': new FormControl(Validators.required),
    'subject_type': new FormControl(Validators.required),

  })
  ngOnInit(): void {
    this.getData();
  }


  getData() {
    this.apis.getEstablishmentBuildings()
      .subscribe((data: Building[]) => {
        if (!data || data.length == 0) {
          this.router.navigate(['/ums/buildings'])
        } else {
          this.buildings = data;
          console.log(this.buildings)
          this.selected_building = this.buildings[0].id;
          this.getBuildingBlocks();

        }
      })
  }

  getBuildingBlocks() {

    this.apis.getBuildingBlocks(this.selected_building)
      .subscribe((data: Block[]) => {
        this.blocks = data;
      })
  }


  changeBuilding() {
    console.log(this.selected_building)
    if (this.selected_building) {
      this.apis.getBuildingBlocks(this.selected_building)
        .subscribe((data: Block[]) => {
          this.blocks = data;
        })
    }

  }


  changeBlock() {
    if (this.selected_block) {
      this.apis.getBlockClassrooms(this.selected_block)
        .subscribe((data: Classroom[]) => {
          this.classrooms = data;
          console.log(this.classrooms);
          this.dataSource = new MatTableDataSource<Classroom>(this.classrooms);

        })
    }
  }





  save() {
    console.log(this.item)
    this.apis.setClassroom(this.item)
      .subscribe((data) => {

        this.changeBlock()
        this.showSuccess('saved');
        this.item = new Classroom();
      })
  }

  edit(element) {
    this.item = element
  }

  showSuccess(msg , nb?) {

    this.toastr.success((nb ? nb : '') +this.translate.instant(msg))
  }

  showError(msg , nb?) {
    this.toastr.error((nb ? nb : '') +this.translate.instant((msg)))
  }

  remove(id) {
    Swal.fire({
      title: this.translate.instant('alerts.delete_title'),
      text: this.translate.instant('alerts.delete_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('alerts.delete_yes'),
      cancelButtonText: this.translate.instant('alerts.delete_no')
    }).then((result) => {
      if (result.value) {
        this.apis.removeClassroom(id)
          .subscribe(() => {
            this.changeBlock()
            Swal.fire(
              this.translate.instant('alerts.deleted'),
              this.translate.instant('alerts.deleted_text'),
              'success'
            )
          })

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.translate.instant('alerts.canceled'),
          this.translate.instant('alerts.canceled_text'),
          'error'
        )
      }
    })
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(ClassroomFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.setClassroom(result)
          .subscribe((a) => { console.log(a)
            this.showSuccess('saved');
            this.changeBlock()

            this.item = new Classroom()
          })
      }

    });
  }
  openEditDialog(classroom): void {
    this.item = classroom;
    const dialogRef = this.dialog.open(ClassroomFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.setClassroom(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.changeBlock()

            this.item = new Classroom()
          })
      }

    });
  }

  filter(v) {
    this.dataSource.filter = v.trim().toLowerCase();
  }


  handleFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {

      const bstr: string = e.target.result;
      const data = <any[]>this.excelService.importFromFile(bstr);

      const header = data[0];
      //const header: string[] = Object.getOwnPropertyNames(new Subject());
      const importedData = data.slice(1, -1);
  

      let imported_subjects = importedData.map(arr => {
        
        const obj = {};
         for (let i = 0; i < header.length; i++) {
          const k = header[i];          
          obj[k] = arr[i];
        } 
        return <Classroom>obj;
      })

     console.log(imported_subjects)
        this.apis.importClassrooms(imported_subjects)
      .subscribe((data : any)=>{
        console.log(data)
        if(this.selected_block){
          this.changeBlock();
        }

        if(data && data.imported_rows > 0){
          this.showSuccess( 'files_imported' , data.imported_rows)
        }
        if(data && data.failed_rows > 0){
          this.showError( 'failed_imported' , data.failed_rows)
        }
      });  
      

    };
    reader.readAsBinaryString(target.files[0]);

  }
  import(){
    $('#file').click();
  }

  export(){
    this.excelService.exportToFile('classrooms' , 'export_id');
  }
}
