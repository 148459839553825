import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  constructor(
    private http : HttpClient,
    private config: ConfigService) { }

    user = JSON.parse(localStorage.getItem('user-infos'));


    getDays(){
      return this.http.get(this.config.baseUrl+'/days');
    }


    getYearDivisions(){
      return this.http.get(this.config.baseUrl+'/year-divisions');
    }


   
}
