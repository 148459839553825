import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';

import { DragulaService } from 'ng2-dragula';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { ExcelService } from 'src/app/apis/excel/excel.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { TimingService } from 'src/app/apis/timing.service';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { AnnualCalendar } from 'src/app/viewModels/annual-calendar';
import { Day } from 'src/app/viewModels/day';
import { GenerateExamsSetting } from 'src/app/viewModels/generate-exams-setting';
import { Period } from 'src/app/viewModels/period';
import { Sector } from 'src/app/viewModels/sector';
import { VacationExam } from 'src/app/viewModels/vacation-exam';
import { ExamsGenerateDialogComponent } from './exams-generate-dialog/exams-generate-dialog.component';
import { ExamsUpdateDialogComponent } from './exams-update-dialog/exams-update-dialog.component';
declare var $ : any;
@Component({
  selector: 'app-exams',
  templateUrl: './exams.component.html',
  styleUrls: ['./exams.component.css']
})
export class ExamsComponent implements OnInit {

  public settings: Settings;
  subs = new Subscription();

  constructor(private settingService: SettingsService,
    private establishmentApis : EstablishmentService,
    public appSettings: AppSettings,
    private excelService: ExcelService,
    public translate: TranslateService,
    private hierarchyService: HiararchyService,
    private apis: TimingService,
    public dialog: MatDialog,
    private dragulaService: DragulaService,
    private toastr: ToastrService) {
    this.settings = this.appSettings.settings;




    this.subs.add(this.dragulaService.drop("bag-1")
      .subscribe(({ name, el, target, source, sibling }) => {
        // ...

        let sourceTab = source.id.split(';')
        let targetTab = target.id.split(';')

        let seance_id;


        console.log(sourceTab)
        console.log(targetTab)

      
        console.log('from ' + sourceTab[0] + ':' + sourceTab[1] + '     to ' + targetTab[0] + ' : ' + targetTab[1])
         target.childNodes.forEach((x: any) => {
          if (x.dataset && x.dataset.name == 'seance' && x.id.indexOf(source.id)==0) {
            console.log(x.id)
            seance_id = x.id.split('-')[2]
          }
        })
      /*   let body = {
           source_day : this.days.find((x)=> x.id == Number(sourceTab[0])),
          source_period : this.periods.find((x : any)=> x.id == Number(sourceTab[1])),
          target_day : this.days.find((x)=> x.id == Number(targetTab[0])),
          target_period : this.periods.find((x : any)=> x.id == Number(targetTab[1])),
          session_id : seance_id 
        } */

        let body = {
          source :  sourceTab[1], target : targetTab[0]
        }

        console.log(body)
        this.updateSessionDialog(body);
     

        })
      );

 
  }
  days: Day[];
  width = 20;
  schedule: any[];
  selectedSector;
  sectors: Sector[];
  pending_list: any[];

  semestres_trimestres: any = [];
  selectedSemestre;
  selectedExamPeriod
  periods : any[];
  ngOnInit(): void {
    this.hierarchyService.getHiararchySectors()
      .subscribe((data: Sector[]) => {
        this.sectors = data;
      })
    this.settingService.getDays()
      .subscribe((data: Day[]) => {
        this.days = data;
      })
      this.apis.getHiararchyPeriods()
      .subscribe((result: Period[]) => {
        this.periods = result
      })

    this.settingService.getYearDivisions()
      .subscribe((data: any[]) => {
        this.semestres_trimestres = data;
      })

this.getCurrentAnnualCalendar();
   


  }
  current_annual_calendar
  examsperiods : VacationExam[]
  getCurrentAnnualCalendar()
  {
    this.establishmentApis.getEstablishmentCurrentAnnualCalendar()
    .subscribe((data : AnnualCalendar)=>{
      this.current_annual_calendar = data;
      if(this.current_annual_calendar){
        this.getExamsPeriods()
       
      }
    })
  }
  getExamsPeriods() {
    this.establishmentApis.getAnnualCalendarVacationsExams(this.current_annual_calendar.id)
      .subscribe((data:  VacationExam[]) => {
        this.examsperiods = data.sort((a,b)=> a.start_date > b.start_date ? 0 : -1);
        console.log(this.examsperiods)
      })
  }

  handleSectorChange() {
    if (this.selectedSector && this.selectedExamPeriod) {
      console.log(this.selectedExamPeriod)
      this.apis.getSectorExamsSchedule(this.selectedSector , this.selectedExamPeriod.id)
      .subscribe((data: any) => {
        this.schedule = data;

        console.log(data)
      })

   /*  this.apis.getSectionPending(this.selectedSection, this.selectedSemestre)
      .subscribe((data: any[]) => {
        this.pending_list = data;
      }) */
    } else {
      this.schedule = []
      this.pending_list = [];
    }
  }



  generateSemestre;
  show_progess = false;
  handleGenerateChange() {
    this.show_progess = true;
    this.apis.generateExamsSchedule(this.generateSemestre)
      .subscribe((data) => {
       
        this.show_progess = false;
        this.showSuccess('success')

      }, (err) => {
        console.log(err)
        this.show_progess = false;
        this.showError('generate_server_error')

      })
  }
  handleExamPeriodChange(){
    console.log(this.selectedExamPeriod && this.selectedSector)
    if(this.selectedExamPeriod){
      this.periods = this.getDates(new Date(this.selectedExamPeriod.start_date) , new Date(this.selectedExamPeriod.end_date));
      console.log(this.periods)
    }
  }
getDates(startDate,  stopDate) {
  console.log(startDate , stopDate)
    let dateArray = new Array();
    let currentDate : Date = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date (currentDate));
      
       currentDate.setDate(currentDate.getDate()+1);
    }
    return dateArray;
}
  export() {
    this.excelService.exportToFile('examsschedule', 'print_ums_id');
  }


  showSuccess(msg) {
    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg) {
    this.toastr.error(this.translate.instant(msg))
  }

  print() {
    var $print = $('#print_ums_id')
      .clone()
      .addClass('ums_print')
      .prependTo('body');
    window.print();
    $print.remove();
  }


  generateDialog() {
    let setting_data = new GenerateExamsSetting();
    setting_data.annual_calendar_id = this.current_annual_calendar.id;
    const dialogRef = this.dialog.open(ExamsGenerateDialogComponent, {
      width: '500px',
      data: setting_data
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      // console.log(this.item)
      if (result) {
        this.show_progess = true;
        console.log(result)
        this.apis.generateExamsSchedule(result)
          .subscribe((data) => {
            console.log(data);
            this.show_progess = false;
            this.showSuccess('success')
            this.handleSectorChange();

          }, (err) => {
            console.log(err)
            this.show_progess = false;
            this.showError('generate_server_error')

          })
      }

    });
  }


  updateSessionDialog(body) {
    console.log(body)
   // let ss_body = {source_day_id : body.source_day.id , source_period_id : body.source_period.id , target_day_id : body.target_day.id , target_period_id : body.target_period.id , session_id : body.session_id};
   this.show_progess = true
   this.apis.checkDropExam(body)
    .subscribe((check : any)=>{
      this.show_progess = false
      if(check.error){
        this.showError(check.error);
        this.handleSectorChange();
        this.show_progess = false;
      }else{
        this.showSuccess('Examen modifié avec success')
      
      }
    })
 }


 EditDialog(body){
  const dialogRef = this.dialog.open(ExamsUpdateDialogComponent, {
    width: '500px',
    data: body
  });

  dialogRef.afterClosed().subscribe(result => {
    console.log('The dialog was closed', result);
    if(result){
      this.show_progess = true;
      this.apis.updateExam(result)
      .subscribe(()=>{
        this.showSuccess('Examen modofié avec success')
        this.handleSectorChange();
        this.show_progess = false;
      })
    }
     console.log(result)
    
  });
 }
}
