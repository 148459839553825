<app-content-header 

    [hideBreadcrumb]="true"
    [class]="'py-3'" [hasBgImage]="true"
    [target]="'hierarchy'"></app-content-header>


    <mat-card>
        <mat-form-field fxFlex="25" class="pr-2 pl-2">
            <input   matInput placeholder="{{'search' | translate}}" (keyup)="filter($event.target.value)">
    
        </mat-form-field>
        <mat-form-field fxFlex="25" class="pr-2 pl-2">
            <mat-select [placeholder]="'select_cycle' |translate" 
                [(ngModel)]="selectedCycle"  (selectionChange)="changeCycle()">
                <mat-option *ngFor="let cycle of cycles" [value]="cycle.id">
                    {{cycle.abbreviation}}</mat-option>
            </mat-select>
        </mat-form-field>

        <mat-form-field fxFlex="25" class="pr-2 pl-2">
            <mat-select [placeholder]="'select_level' |translate" 
                [(ngModel)]="selectedLevel"  (selectionChange)="changeLevel()">
                <mat-option *ngFor="let level of levels" [value]="level.id">
                    {{level.abbreviation}}</mat-option>
            </mat-select>
        </mat-form-field>

        <div fxFlex="50">
    
        
            <button mat-raised-button color="primary" class="ums_primary_button" [style.float]="'inverse_float' | translate" (click)="openDialog()">
                <mat-icon class="fs20">add</mat-icon> {{'buttons.add' | translate}}
              </button>
              <input style="display : none" type="file" id="file" (change)="handleFileChange($event)">

              <button mat-raised-button color="basic" class="ums_basic_button" [style.float]="'inverse_float' | translate" (click)="import()">
                <mat-icon class="fs20">upload</mat-icon>  {{'buttons.import' | translate}}
              </button>
        </div>
    </mat-card>
    <div fxLayout="row wrap" fxLayoutAlign="center" class="p-2">
        <div fxFlex="100" class="p-2">
            
            <div class="mat-elevation-z8">
                <table mat-table style="width: 100%;" [dataSource]="dataSource">
    
                 
    
                    <!-- Name Column -->
                    <ng-container matColumnDef="code">
                        <th mat-header-cell *matHeaderCellDef> {{'code' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.id}} </td>
                    </ng-container>
                    <ng-container matColumnDef="designation">
                        <th mat-header-cell *matHeaderCellDef> {{'designation' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.designation}} </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="abbreviation">
                        <th mat-header-cell *matHeaderCellDef> {{'abbreviation' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.abbreviation}} </td>
                    </ng-container>
                  
                    <ng-container matColumnDef="level">
                        <th mat-header-cell *matHeaderCellDef> {{'level' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.level?.abbreviation}} </td>
                    </ng-container>
                    <ng-container matColumnDef="department">
                        <th mat-header-cell *matHeaderCellDef> {{'department' | translate}} </th>
                        <td mat-cell *matCellDef="let element"> {{element.department?.abbreviation}} </td>
                    </ng-container>
                    <ng-container matColumnDef="actions" justify="end" >
                        <th mat-header-cell *matHeaderCellDef [style.text-align]="'inverse_float' | translate"> {{'actions' | translate}} </th>
                        <td mat-cell *matCellDef="let element" [style.text-align]="'inverse_float' | translate">
                            <mat-icon class="success" (click)="openAvailabilityDialog(element)">date_range</mat-icon>

                            <mat-icon class="warning" (click)="openEditDialog(element)">edit</mat-icon>
                            <mat-icon class="danger" (click)="remove(element.id)">delete_outline</mat-icon>
    
                        </td>
                    </ng-container>
    
                    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                    <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
                </table>
    
                <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
            </div>
        </div>
    
    </div>