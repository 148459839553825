import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { ExcelService } from 'src/app/apis/excel/excel.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { TimingService } from 'src/app/apis/timing.service';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { AnnualCalendar } from 'src/app/viewModels/annual-calendar';
import { Day } from 'src/app/viewModels/day';
import { Level } from 'src/app/viewModels/level';
import { Sector } from 'src/app/viewModels/sector';
import { VacationExam } from 'src/app/viewModels/vacation-exam';
declare var $ : any;
@Component({
  selector: 'app-total-study-hours',
  templateUrl: './total-study-hours.component.html',
  styleUrls: ['./total-study-hours.component.css']
})
export class TotalStudyHoursComponent implements OnInit {

  public settings: Settings;
  subs = new Subscription();

  constructor(private settingService: SettingsService,
    private establishmentApis : EstablishmentService,
    public appSettings: AppSettings,
    private excelService: ExcelService,
    public translate: TranslateService,
    private hierarchyService: HiararchyService,
    private apis: TimingService,
    public dialog: MatDialog,
    private toastr: ToastrService) {
    this.settings = this.appSettings.settings;





 
  }
  days: Day[];
  width = 20;
  schedule: any[];
  selectedSector;
  sectors: Sector[];
  levels : Level[];
  semestres_trimestres: any = [];
  selectedSemestre;
  selectedLevel
  periods : String[];
  ngOnInit(): void {
   

    this.apis.getTotalHoursStudyPlan()
    .subscribe((data : any[])=>{
     console.log('aaa' , data)
     this.schedule = data;
    // this.getTotall()
    })
   


  }
  current_annual_calendar
  examsperiods : VacationExam[]
 

 
  JSONparse(ch)
  {
    return JSON.parse(ch).length
  }


  totale_c = 0;
  totale_tp = 0
  totale_td = 0;


  generateSemestre;
  show_progess = false;

getDates(startDate,  stopDate) {
  console.log(startDate , stopDate)
    let dateArray = new Array();
    let currentDate : Date = startDate;
    while (currentDate <= stopDate) {
        dateArray.push(new Date (currentDate));
      
       currentDate.setDate(currentDate.getDate()+1);
    }
    return dateArray;
}
  export() {
    this.excelService.exportToFile('Total_study_hours', 'print_ums_id');
  }


  showSuccess(msg) {
    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg) {
    this.toastr.error(this.translate.instant(msg))
  }

  print() {
    var $print = $('#print_ums_id')
      .clone()
      .addClass('ums_print')
      .prependTo('body');
    window.print();
    $print.remove();
  }


getTotals(){

}
}
