import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { ConfigService } from 'src/app/apis/config.service';
import { ExcelService } from 'src/app/apis/excel/excel.service';
import { SubjectService } from 'src/app/apis/subject.service';
import { SubjectModule } from 'src/app/viewModels/subject-module';
import Swal from 'sweetalert2';
import { SubjectModuleFormComponent } from '../subject-module-form/subject-module-form.component';
declare var $ :any
@Component({
  selector: 'app-subject-modules',
  templateUrl: './subject-modules.component.html',
  styleUrls: ['./subject-modules.component.css']
})
export class SubjectModulesComponent implements OnInit {
  items: SubjectModule[];

  displayedColumns: string[] = ['designation', 'abbreviation', 'basic', 'actions'];
  dataSource;
  constructor(
    private apis: SubjectService,
    private config: ConfigService,
    public translate: TranslateService,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private excelService: ExcelService,

  ) { }

  item: SubjectModule = new SubjectModule();
  show_form = false;

  excel_model_path = this.config.hostName + '/assets/excel_models/subject_modules_model.xlsx';
  ngOnInit(): void {

    this.getData()
  }



  getData() {

    this.apis.getEstablishmentSubjectModules()
      .subscribe((data: SubjectModule[]) => {
        this.items = data;
        this.dataSource = new MatTableDataSource<SubjectModule>(this.items);
      })
  }




  edit(element) {
    this.item = element
  }

  remove(id) {
    Swal.fire({
      title: this.translate.instant('alerts.delete_title'),
      text: this.translate.instant('alerts.delete_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('alerts.delete_yes'),
      cancelButtonText: this.translate.instant('alerts.delete_no')
    }).then((result) => {
      if (result.value) {
        this.apis.removeSubjectModule(id)
          .subscribe(() => {
            this.getData();
            Swal.fire(
              this.translate.instant('alerts.deleted'),
              this.translate.instant('alerts.deleted_text'),
              'success'
            )
          })

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.translate.instant('alerts.canceled'),
          this.translate.instant('alerts.canceled_text'),
          'error'
        )
      }
    })
  }

  showSuccess(msg, nb?) {

    this.toastr.success((nb ? nb : '') + this.translate.instant(msg))
  }

  showError(msg, nb?) {
    this.toastr.error((nb ? nb : '') + this.translate.instant((msg)))
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(SubjectModuleFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.createOrUpdateSubjectModule(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.getData();

            this.item = new SubjectModule()
          })
      } else {
        this.item = new SubjectModule()
      }

    });
  }

  openEditDialog(subject): void {
    this.item = subject;
    const dialogRef = this.dialog.open(SubjectModuleFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.createOrUpdateSubjectModule(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.getData();

            this.item = new SubjectModule()
          })
      } else {
        this.item = new SubjectModule()
      }

    });
  }

  filter(v) {
    this.dataSource.filter = v.trim().toLowerCase();
  }



  handleFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {

      const bstr: string = e.target.result;
      const data = <any[]>this.excelService.importFromFile(bstr);

      const header = data[0];
      //const header: string[] = Object.getOwnPropertyNames(new Subject());
      const importedData = data.slice(1, -1);


      let imported_subjects = importedData.map(arr => {

        const obj = {};
        for (let i = 0; i < header.length; i++) {
          const k = header[i];
          obj[k] = arr[i];
        }
        return <SubjectModule>obj;
      })

      console.log(imported_subjects)
    /*   this.apis.importSubjects(imported_subjects)
        .subscribe((data: any) => {
          console.log(data)
          this.getData()

          if (data && data.imported_rows > 0) {
            this.showSuccess('files_imported', data.imported_rows)
          }
          if (data && data.failed_rows > 0) {
            this.showError('failed_imported', data.failed_rows)
          }
        }); */


    };
    reader.readAsBinaryString(target.files[0]);

  }
  import() {
    $('#file').click();
  }

  export() {
    this.excelService.exportToFile('modules', 'export_id');
  }

}
