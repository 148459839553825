<app-content-header 
    [icon]="'radio_button_checked'" 
    [title]="'Radio button'" 
    [hideBreadcrumb]="false"
    [hasBgImage]="true" 
    [class]="'pb-4'"></app-content-header>

<div fxLayout="column" class="p-2">
    <div class="p-2">
        <mat-card>
            <mat-card-header>                
                <mat-card-subtitle><h2>Basic radios</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <mat-radio-group>
                    <mat-radio-button value="1" class="mx-2">Option 1</mat-radio-button>
                    <mat-radio-button value="2" class="mx-2">Option 2</mat-radio-button>
                </mat-radio-group>   
            </mat-card-content>
        </mat-card>
    </div> 
    <div class="p-2">
        <mat-card>
            <mat-card-header>                
                <mat-card-subtitle><h2>Radios with ngModel</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <mat-radio-group [(ngModel)]="favoriteSeason" fxLayout="column" fxLayoutAlign="center">
                    <mat-radio-button *ngFor="let season of seasons" [value]="season" class="season-item mx-2">
                      {{season}}
                    </mat-radio-button>
                </mat-radio-group>
                <div class="pt-3 mx-2">Your favorite season is: <b> {{favoriteSeason}} </b></div>
            </mat-card-content>
        </mat-card>
    </div> 
</div>