<mat-sidenav-container class="h-100">
  <div fxLayout="row" fxLayoutAlign="center center" class="h-100">
       <form [formGroup]="form" (ngSubmit)="onSubmit(form.value)" fxFlex="90" fxFlex.gt-sm="30" fxFlex.sm="60" class="mat-elevation-z6">
            <app-content-header 
                [icon]="'exit_to_app'" 
                [title]="'Login'" 
                [hideBreadcrumb]="true"
                [hasBgImage]="true" 
                [class]="'py-4'">
            </app-content-header>            
            <div class="p-3">
                <h2 style="text-align: center;     color: #673ab7;">Nachd UMS</h2>
                <h2> &nbsp;</h2>
                <mat-form-field class="w-100">
                    <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">mail</mat-icon>
                    <input matInput placeholder="Email" formControlName="email">
                    <mat-error *ngIf="form.controls.email.errors?.required">Email is required</mat-error>
                    <mat-error *ngIf="form.controls.email.hasError('invalidEmail')">Invalid email address</mat-error>
                </mat-form-field>
                <mat-form-field class="w-100">
                    <mat-icon matPrefix class="mat-icon-sm mb-2 mr-2 secondary-text-color">lock</mat-icon>
                    <input matInput placeholder="Password" formControlName="password" type="password">
                    <mat-error *ngIf="form.controls.password.errors?.required">Password is required</mat-error>
                    <mat-error *ngIf="form.controls.password.hasError('minlength')">Password isn't long enough, minimum of 6 characters</mat-error>
                </mat-form-field>               
  
                <button mat-raised-button color="primary" class="mat-elevation-z6 w-100 mt-3" type="submit">SIGN IN</button>
            </div>
        </form>
  </div>
</mat-sidenav-container>