<div mat-dialog-title><h1 >{{translate.instant('period_data')}}</h1></div>
<div mat-dialog-content >
    <form fxLayout="column" [formGroup]="form"  >
        <mat-form-field class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('designation')}}" [(ngModel)]="data.designation"
                required [formControlName]="'designation'">
           
            
        </mat-form-field>

        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('abbreviation')}}" [(ngModel)]="data.abbreviation"
                required [formControlName]="'abbreviation'">
            
        </mat-form-field>
        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('start_date')}}"  [min]="min_date" [max]="max_date" type="date" [(ngModel)]="data.start_date"
                required [formControlName]="'start_date'">
            
        </mat-form-field>
        <mat-form-field  class="pr-2 pl-2" >
            <input matInput placeholder="{{translate.instant('end_date')}}"  [min]="min_date" [max]="max_date" type="date" [(ngModel)]="data.end_date"
                required [formControlName]="'end_date'">
            
        </mat-form-field>
    

        <mat-form-field>
            <mat-select [placeholder]="translate.instant('type')" [formControlName]="'type'"
                [(ngModel)]="data.type" required>
                <mat-option *ngFor="let type of vacation_exam_types" [value]="type.slug">
                    {{type.value}}</mat-option>
            </mat-select>

           
        </mat-form-field>

    </form>
</div>
<div mat-dialog-actions>
 
    <button  mat-raised-button color="basic"  class=" ums_basic_button form-cancel" (click)="onNoClick()">{{'buttons.no_thanks' | translate}}</button>
    <button  mat-raised-button color="primary" [disabled]="!form.valid" class="ums_primary_button form-save" [mat-dialog-close]="data" cdkFocusInitial>
     {{'buttons.save' | translate}}
    </button>
</div>

