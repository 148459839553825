<app-content-header [hasBgImage]="true" [hideBreadcrumb]="true" [class]="'py-3'" [target]="'timetable'">
</app-content-header>


<div fxLayout="row" class="p-2 hide_print">
    <div fxFlex="100" class="p-2">
        <mat-card>
            <mat-form-field fxFlex="12" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('semestre')" [(ngModel)]="selectedSemestre"
                    (selectionChange)="handleSectionChange()">
                    <mat-option *ngFor="let sem of semestres_trimestres" [value]="sem.id">
                        {{sem.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>
            <mat-form-field fxFlex="12" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('sector')" [(ngModel)]="selectedSector"
                    (selectionChange)="handleSectorChange()">
                    <mat-option *ngFor="let sector of sectors" [value]="sector.id">
                        {{sector.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>

            <mat-form-field fxFlex="12" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('section')" [(ngModel)]="selectedSection"
                    (selectionChange)="handleSectionChange()">
                    <mat-option *ngFor="let section of sections" [value]="section.id">
                        {{section.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>
            <mat-form-field fxFlex="12" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('group_tp')" [(ngModel)]="selectedGroupTp"
                    (selectionChange)="handleGroupChange()">
                    <mat-option *ngFor="let grp of groupTP" [value]="grp.id">
                        {{grp.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>
            <mat-form-field fxFlex="12" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('group_td')" [(ngModel)]="selectedGroupTd"
                    (selectionChange)="handleGroupTDChange()">
                    <mat-option *ngFor="let grp of groupTD" [value]="grp.id">
                        {{grp.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>


            <div fxFlex="40">



                <button mat-raised-button color="primary" class="ums_primary_button"
                    [style.float]="'inverse_float' | translate" (click)="generateDialog()">
                    <mat-icon class="fs20">model_training</mat-icon> {{'buttons.generate' | translate}}
                </button>
                <button mat-raised-button color="basic" class="ums_basic_button"
                    [style.float]="'inverse_float' | translate" >
                    <a href="/isbm/print" target="_blank" style="color: unset;
                    text-decoration: none;">  <mat-icon class="fs20">print</mat-icon> {{'buttons.print' | translate}}</a>
                </button>
                <button mat-raised-button color="basic" class="ums_basic_button"
                    [style.float]="'inverse_float' | translate" >
                    <a href="/isbm/print_teachers" target="_blank" style="color: unset;
                    text-decoration: none;">  <mat-icon class="fs20">print</mat-icon> {{'buttons.print' | translate}} ({{'teachers' | translate}})</a>
                </button>
                <button mat-raised-button color="basic" class="ums_basic_button"
                    [style.float]="'inverse_float' | translate" (click)="export()">
                    <mat-icon class="fs20">download</mat-icon> {{'buttons.export' | translate}}
                </button>

               <!--  <button mat-raised-button color="primary" class="ums_primary_button"  [style.float]="'inverse_float' | translate" style="background-color: #f44336 !important;">
                  <a href="/print" target="_blank"><mat-icon class="fs20">publish</mat-icon></a>

                  </button>
                  <button mat-raised-button color="primary" class="ums_primary_button"  [style.float]="'inverse_float' | translate" style="background-color: #f44336 !important;">
                    <a href="/print_teachers" target="_blank"><mat-icon class="fs20">publish</mat-icon></a>
                    </button> -->
                <!-- </button> -->
                <!-- <button mat-raised-button color="primary" class="ums_primary_button" (click)="publish()"
                    [style.float]="'inverse_float' | translate" style="background-color: #f44336 !important;">
                    <mat-icon class="fs20">publish</mat-icon> {{'buttons.publish' | translate}}
                </button> -->






            </div>
        </mat-card>
    </div>
</div>
<div class="row hide_print">
    <div fxFlex="100" class="p-2">
        <mat-card>
            <form [formGroup]="filter_form">
            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <input matInput placeholder="{{translate.instant('start_date')}}"  name="start_date"   type="date"  [(ngModel)]="start_date"  [formControlName]="'start_date'">


            </mat-form-field>
            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <input matInput placeholder="{{translate.instant('end_date')}}" type="date"    [(ngModel)]="end_date"  [formControlName]="'end_date'">


            </mat-form-field>



            <div fxFlex="60">



                <button mat-raised-button color="primary" class="ums_primary_button"
                    [style.float]="'inverse_float' | translate" (click)="show()">
                    <mat-icon class="fs20">model_training</mat-icon> {{'buttons.show' | translate}}
                </button>

                <button mat-raised-button color="primary" class="ums_primary_button"
                [style.float]="'inverse_float' | translate" (click)="addExtraSessionDialog()">
                <mat-icon class="fs20">add</mat-icon> {{'buttons.add' | translate}}
            </button>





            </div>
            </form>
        </mat-card>
    </div>
</div>
<mat-progress-bar *ngIf="show_progess" mode="indeterminate"></mat-progress-bar>

<div fxLayout="row" class="p-2">
    <div fxFlex="85" class="p-2">

        <mat-card  style="height: 80vh; overflow-y: scroll">
            <mat-card-content>
                <table class="table" id="print_ums_id" *ngIf="timetable && timetable.length > 0 ; else no_data">
                    <tr style="margin-bottom: 1px!important;">
                        <td fxFlex="6" class="bg-dark"></td>
                        <td fxFlex="{{width}}" *ngFor="let period of periods ; let index = index"
                            class="{{index % 2 == 0 ? 'bg-dark2' : 'bg-dark' }}">
                            {{period.start_hour}} - {{period.end_hour}}
                        </td>

                    </tr>
                    <tr *ngFor="let day of timetable ; let index = index"
                        [style.background]="index % 2 == 0 ? '#f3f3f3' : ''">
                        <td fxFlex="6" style="margin-bottom: 1px;" class="{{index % 2 == 0 ? 'bg-dark2' : 'bg-dark' }}">
                            {{day.abbreviation}}</td>
                        <td fxFlex="{{width}}" *ngFor="let periods of day.days ; let p_i = index" class="draggable"
                            dragula="bag-1" id="{{day.day_id}}-{{periods.period_id}}" class="bag">

                            <span *ngFor="let period of periods.seances ; let p_index = index "
                                id="{{day.day_id}}-{{periods.period_id}}-{{period.id}}" data-name="seance">
                                <mat-card [class.bg-c]="period.subject_type && period.subject_type.abbreviation =='C'"
                                    [class.bg-tp]="period.subject_type && period.subject_type.abbreviation =='TP'"
                                    [class.bg-td]="period.subject_type && period.subject_type.abbreviation =='TD'"
                                    [style.background]="period.group_tp ? colors[period.group_tp.abbreviation.substring(period.group_tp.abbreviation.length -1)] :''"
                                    [style.margin-left.px]="period.start_hour != period.period.start_hour ? getDiff(period.start_hour , period.period.start_hour) : 0"
                                    [style.margin-right.px]="period.end_hour != period.period.end_hour ? getDiff(period.period.end_hour , period.end_hour) : 0"

                                    [class.bg-extra]="period.type && period.type =='extra_session'"
                                    [class.bg-exceptional]="period.type && period.type =='exceptional_session'"
                                    >

                                    <span id="{{period.id}}">
                                        <span (click)="showEditDialog(period  ,p_i,day.days )"
                                            style="font-size: 14px; text-transform:uppercase  !important; font-weight: bold;">
                                            {{period.subject?.abbreviation}} {{period.id}}

                                        </span>
                                        <br>
                                        <span style="font-size: 14px; text-transform:uppercase  !important;">
                                            {{period.classroom ? period.classroom.abbreviation : 'Pas de salle'}} |
                                            {{period.week_name}}
                                        </span>
                                        <span style="font-size:small;">
                                            <br>{{period.teacher?.first_name}} &nbsp;
                                            {{period.teacher?.last_name}}<br>

                                        </span>
                                        <span style="font-size:small;" *ngIf="period.type && period.type !='session'">
                                           {{period.start_date | date:'dd-MM-yyy'}}

                                        </span>

                                    </span>
                                    <div [class.bg-c1]="period.subject_type && period.subject_type.abbreviation =='C'"
                                        class="draggable p5"
                                        [class.bg-tp1]="period.subject_type && period.subject_type.abbreviation =='TP'"
                                        [class.bg-td1]="period.subject_type && period.subject_type.abbreviation =='TD'"
                                        class="top-right">
                                        {{period.subject_type.abbreviation}}
                                    </div>
                                    <div  class="lock" [style.color]="period.is_locked == 1 ? '#c4130f' : '#b7b7b7'">
                                        <mat-icon class="fs20 pointer" (click)="lock_unlock(period ,index , p_i ,  p_index)">lock</mat-icon>
                                    </div>
                                    <div *ngIf="period.group_td || period.group_tp" class="top-bottom">
                                        {{ period.group_td?.abbreviation}}
                                        {{period.group_tp?.abbreviation}}

                                    </div>


                                </mat-card>
                            </span>



                        </td>
                    </tr>
                </table>
                <ng-template #no_data>
                    <div class="empty_frame">
                        <img src="assets/empty.png">
                    </div>
                </ng-template>
            </mat-card-content>
        </mat-card>
        <!-- <mat-card class="hide_print" *ngIf="timetableGTP2 && timetableGTP2.length > 0">
            <mat-card-content>
                <table class="table">
                    <tr>
                        <td fxFlex="6" class="bg-dark"></td>
                        <td fxFlex="{{width}}" *ngFor="let period of periods ; let index = index"
                            class="{{index % 2 == 0 ? 'bg-dark2' : 'bg-dark' }}">
                            {{period.abbreviation}}
                        </td>

                    </tr>
                    <tr *ngFor="let day of timetableGTP2 ; let index = index">
                        <td fxFlex="6" class="{{index % 2 == 0 ? 'bg-dark2' : 'bg-dark' }}">{{day.abbreviation}}</td>
                        <td fxFlex="{{width}}" *ngFor="let periods of day.days" class="draggable" [dragula]="'bag-1'"
                            class="bag">
                            <span *ngFor="let period of periods.seances">
                                <mat-card [class.bg-c]="period.subject_type && period.subject_type.abbreviation =='C'"
                                    [class.bg-tp]="period.subject_type && period.subject_type.abbreviation =='TP'"
                                    [class.bg-td]="period.subject_type && period.subject_type.abbreviation =='TD'">


                                    <span>
                                        <span
                                            style="font-size: 14px; text-transform:uppercase  !important; font-weight: bold;">
                                            {{period.subject?.abbreviation}}
                                        </span>
                                        <br>
                                        <span style="font-size: 14px; text-transform:uppercase  !important;">
                                            {{period.classroom ? period.classroom.abbreviation : 'Pas Salle'}} |
                                            {{period.week_name}}
                                        </span>
                                        <span style="font-size:small;">
                                            <br>{{period.teacher?.first_name}}
                                            {{period.teacher?.last_name}}<br>

                                        </span>

                                    </span>
                                    <div [class.bg-c1]="period.subject_type && period.subject_type.abbreviation =='C'"
                                        class="draggable p5"
                                        [class.bg-tp1]="period.subject_type && period.subject_type.abbreviation =='TP'"
                                        [class.bg-td1]="period.subject_type && period.subject_type.abbreviation =='TD'"
                                        class="top-right">
                                        {{period.subject_type.abbreviation}}
                                    </div>
                                </mat-card>
                            </span>

                        </td>
                    </tr>
                </table>

            </mat-card-content>


        </mat-card> -->
    </div>
    <div fxFlex="15" class="p-2 hide_print">
        <mat-card  style="height: 80vh; overflow-y: scroll">
            <mat-card-header fxLayoutAlign="center">
                <mat-card-subtitle fxLayoutAlign="center">
                    <h2>{{'Pending_list' | translate}}</h2>
                </mat-card-subtitle>
            </mat-card-header>

            <mat-card>
                <div class="addbtn" (click)="addSessionDialog()">   <mat-icon class="fs20">add</mat-icon> </div>
            </mat-card>
            <mat-card-content class="bag">
                <!--  <mat-card *ngFor="let item of pending_list"
                    [class.bg-primary]="item.subject_type && item.subject_type.abbreviation =='C'" class="draggable p5"
                    [class.bg-accent]="item.subject_type && item.subject_type.abbreviation =='TP'"
                    [class.bg-default]="item.subject_type && item.subject_type.abbreviation =='TD'">
                    {{item.subject?.abbreviation}}<br>{{item.teacher?.first_name}} &nbsp;
                    {{item.teacher?.last_name}}<br>

                    {{item.section?.abbreviation}}
                </mat-card> -->

                <span *ngFor="let item of pending_list" id="pending-{{item.id}}" [dragula]="'bag-1'">
                    <mat-card [class.bg-c]="item.subject_type && item.subject_type.abbreviation =='C'"
                        [class.bg-tp]="item.subject_type && item.subject_type.abbreviation =='TP'"
                        [class.bg-td]="item.subject_type && item.subject_type.abbreviation =='TD'"
                        [style.background]="item.group_tp ? colors[item.group_tp.abbreviation.substring(item.group_tp.abbreviation.length -1)] :''"
                        [style.height.px]="110">


                        <span *ngIf="item.id "  >
                            <span style="font-size: 13px; text-transform:uppercase  !important; font-weight: bold;    display: block;
                            width: 90%;
                            height: 13px;
                            overflow: hidden"
                                (click)="showEditPendingDialog(item)">
                                {{item.subject?.abbreviation}} {{item.next >1 ? '('+item.next+' séances)' : ''}}
                            </span>
                            <br>
                            <span style="font-size: 14px; text-transform:uppercase  !important;">
                                {{item.classroom ? item.classroom.abbreviation : 'Pas Salle'}} |
                                {{item.week_id?.week1}}
                               <!--  {{ item.group_td?.abbreviation}}
                                {{item.group_tp?.abbreviation}} -->
                            </span>
                            <span style="font-size:small;"> {{item.teacher_id}}
                                <br>{{item.teacher?.first_name}}
                                {{item.teacher?.last_name}}<br>

                            </span>

                        </span>

                        <div *ngIf="item.id"
                            [class.bg-primary]="item.subject_type && item.subject_type.abbreviation =='C'"
                            class="draggable p5"
                            [class.bg-accent]="item.subject_type && item.subject_type.abbreviation =='TP'"
                            [class.bg-default]="item.subject_type && item.subject_type.abbreviation =='TD'"
                            class="top-right">
                            {{item.subject_type?.abbreviation}}
                        </div>
                        {{item.id}}
                        <div class="bottom-right" *ngIf="item.group_td || item.group_tp">
                            {{ item.group_td?.abbreviation}}
                            {{item.group_tp?.abbreviation}}


                        </div>
                    </mat-card>









                </span>

            </mat-card-content>
        </mat-card>
    </div>
</div>


<!--
<div fxLayout="row" class="p-2">
    <div fxFlex="85" class="p-2">

        <mat-card>
            <mat-card-content>
                <table class="table" id="print_ums_id" *ngIf="timetable && timetable.length > 0 ; else no_data">
                    <tr style="margin-bottom: 1px!important;">
                        <td class="bg-dark" style="width : 5%"></td>
                        <td *ngFor="let period of periods ; let index = index" style="width : 19%"
                            class="{{index % 2 == 0 ? 'bg-dark2' : 'bg-dark' }}">
                            {{period.start_hour}} - {{period.end_hour}}
                        </td>

                    </tr>
                    <ng-container *ngFor="let day of timetable ; let index = index">
                        <tr *ngFor="let s of [].constructor(day.nb_session); let x = index"
                            [style.background]="index % 2 == 0 ? '#f3f3f3' : ''">
                            <td *ngIf="x==0" style="margin-bottom: 1px;"
                                class="{{index % 2 == 0 ? 'bg-dark2' : 'bg-dark' }}" [attr.rowspan]="day.nb_session">
                                {{day.abbreviation}} {{day.nb_session}} - {{x}}</td>
                            <ng-container *ngFor="let periods of day.days">
                                <td *ngIf="periods.seances[x] && !periods.seances[x].related_id" class="draggable"
                                    dragula="bag-1"
                                    [attr.colspan]="periods.seances[x] && periods.seances[x].next ? periods.seances[x].next : 1"
                                    id="{{day.day_id}}-{{periods.period_id}}" class="bag">

                                    <span *ngIf="periods && periods.seances && periods.seances.length > x "
                                        id="{{day.day_id}}-{{periods.period_id}}-{{periods.seances[x].id}}"
                                        data-name="seance">
                                        <mat-card
                                            [class.bg-c]="periods.seances[x].subject_type && periods.seances[x].subject_type.abbreviation =='C'"
                                            [class.bg-tp]="periods.seances[x].subject_type && periods.seances[x].subject_type.abbreviation =='TP'"
                                            [class.bg-td]="periods.seances[x].subject_type && periods.seances[x].subject_type.abbreviation =='TD'"
                                            [style.background]="periods.seances[x].group_tp ? colors[periods.seances[x].group_tp.abbreviation.substring(periods.seances[x].group_tp.abbreviation.length -1)] :''">

                                            <span id="{{periods.seances[x].id}}">
                                                <span
                                                    (click)="showEditDialog(periods.seances[x]  , periods.seances[x+1] )"
                                                    style="font-size: 14px; text-transform:uppercase  !important; font-weight: bold;">
                                                    {{periods.seances[x].subject?.abbreviation}}
                                                    {{periods.seances[x].duree}}
                                                </span>
                                                <br>
                                                <span style="font-size: 14px; text-transform:uppercase  !important;">
                                                    {{periods.seances[x].classroom ?
                                                    periods.seances[x].classroom.abbreviation : 'Pas Salle'}} |
                                                    {{periods.seances[x].week_name}}
                                                </span>
                                                <span style="font-size:small;">
                                                    {{periods.seances[x].teacher?.last_name}}<br>

                                                </span>
                                            </span>
                                            <div [class.bg-c1]="periods.seances[x].subject_type && periods.seances[x].subject_type.abbreviation =='C'"
                                                class="draggable p5"
                                                [class.bg-tp1]="periods.seances[x].subject_type && periods.seances[x].subject_type.abbreviation =='TP'"
                                                [class.bg-td1]="periods.seances[x].subject_type && periods.seances[x].subject_type.abbreviation =='TD'"
                                                class="top-right">
                                                {{periods.seances[x].subject_type.abbreviation}}
                                            </div>
                                            <div *ngIf="periods.seances[x].group_td || periods.seances[x].group_tp"
                                                class="top-bottom">
                                                {{ periods.seances[x].group_td?.abbreviation}}
                                                {{periods.seances[x].group_tp?.abbreviation}}
                                            </div>


                                        </mat-card>






                                    </span>



                                </td>
                                <td *ngIf="!periods.seances[x]"></td>
                            </ng-container>
                        </tr>
                    </ng-container>
                </table>
                <ng-template #no_data>
                    <div class="empty_frame">
                        <img src="assets/empty.png">
                    </div>
                </ng-template>
            </mat-card-content>
        </mat-card>

    </div>

</div>
 -->
