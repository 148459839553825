export class Regime {

    nb_hours;
    subject_type_id;
    teacher_id;
    subject_id;
    group_id;
    affectation;
    blocks;
    classrooms;
    week_type_id;
    equipments;
}
