<app-content-header [hasBgImage]="true" [hideBreadcrumb]="true" [class]="'py-3'" [target]="'exams'">
</app-content-header>


<div fxLayout="row" class="p-2">
    <div fxFlex="100" class="p-2">
        <mat-card>

            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('ExamsType')" [(ngModel)]="selectedExamPeriod">
                    <mat-option *ngFor="let exam_data of examsperiods" [value]="exam_data">
                        {{exam_data.abbreviation}}</mat-option>
                </mat-select>


            </mat-form-field>

            <mat-form-field fxFlex="20" class="pr-2 pl-2">
                <mat-select [placeholder]="translate.instant('Type Affichage')" [(ngModel)]="selectedShowType">
                    <mat-option *ngFor="let exam_data of types" [value]="exam_data.slug">
                        {{exam_data.value}}</mat-option>
                </mat-select>


            </mat-form-field>



            <button fxFlex="15" mat-raised-button color="primary" style="height: 38px;"  [disabled]="!selectedExamPeriod || !selectedShowType"   (click)="getMonotorings()"
                class="ums_primary_button" [style.float]="'inverse_float' | translate" >
                <mat-icon class="fs20">eye</mat-icon> {{'buttons.show' | translate}}
            </button>

            <div fxFlex="40">

                <button mat-raised-button color="primary" class="ums_primary_button"
                    [style.float]="'inverse_float' | translate" (click)="generateDialog()">
                    <mat-icon class="fs20">model_training</mat-icon> {{'buttons.generate' | translate}}
                </button>
                <button mat-raised-button color="basic" class="ums_basic_button"
                    [style.float]="'inverse_float' | translate" (click)="print()">
                    <mat-icon class="fs20">print</mat-icon> {{'buttons.print' | translate}}
                </button>
                <button mat-raised-button color="basic" class="ums_basic_button"
                    [style.float]="'inverse_float' | translate" (click)="export()">
                    <mat-icon class="fs20">download</mat-icon> {{'buttons.export' | translate}}
                </button>


            </div>
        </mat-card>
    </div>
</div>
<mat-progress-bar *ngIf="show_progess" mode="indeterminate"></mat-progress-bar>

<div fxLayout="row" class="p-2">
    <div fxFlex="100" class="p-2">

        <mat-card *ngIf="selectedShowType && selectedShowType=='dates'">
            <mat-card-content>
                <table class="table" id="print_ums_id" *ngIf="schedule && schedule.length >0 ; else no_data">
                    <!--   <tr style="margin-bottom: 1px!important;">
                        <td fxFlex="6"  class="bg-dark"></td>
                        <td fxFlex="{{width}}"  class="bg-dark" *ngFor="let period of periods">
                            {{period | date}}
                        </td>

                    </tr> -->
                    <ng-container *ngFor="let date of schedule ;">

                        <tr>
                            <th colspan="4" class="datee">{{date.date}}</th>
                        </tr>

                        <tr>
                            <th *ngFor="let t of date.schedule ; let j = index">
                                {{t.start}} - {{t.end}}

                            </th>

                        </tr>
                        <tr>


                            <td *ngFor="let t of date.schedule "
                                style="vertical-align : top ; border-right: 1px solid #ababab ;">
                                <table>
                                    <tr *ngFor="let exam of t?.monotoring ; let j = index">
                                        <td [style.background]="j%2 == 0 ? '#f6f6f6' : ''">

                                            <span style="font-size: large; text-transform:uppercase  !important; ">
                                                &nbsp; &nbsp;{{exam.teacher?.first_name}} {{exam.teacher?.last_name}}
                                            </span>




                                        </td>
                                    </tr>
                                </table>
                            </td>


                        </tr>


                    </ng-container>
                </table>
                <ng-template #no_data>
                    <div class="empty_frame">
                        <img src="assets/empty.png">
                    </div>
                </ng-template>
            </mat-card-content>
        </mat-card>
        <mat-card *ngIf="selectedShowType && selectedShowType=='teachers'">
            <mat-card-content>
                <table class="table" id="print_ums_id" *ngIf="schedule && schedule.length >0 ; else no_data">
                    <!--   <tr style="margin-bottom: 1px!important;">
                        <td fxFlex="6"  class="bg-dark"></td>
                        <td fxFlex="{{width}}"  class="bg-dark" *ngFor="let period of periods">
                            {{period | date}}
                        </td>

                    </tr> -->
                    <ng-container *ngFor="let teacher of schedule ;">
                        <tr>
                            <th style="height:40px">&nbsp;</th>
                        </tr>
                        <tr>
                            <th colspan="4"  class="datee">{{teacher.teacher?.first_name}} {{teacher.teacher?.last_name}}</th>
                        </tr>

                        <tr *ngFor="let t of teacher.monotoring ; let j = index" [style.background]="j%2 == 0 ? '#f6f6f6' : ''">
                            <td >
                                {{t.date}}

                            </td>
                            <td >
                                {{t.start}} - {{t.end}}

                            </td>

                        </tr>
                    


                    </ng-container>
                </table>
                <ng-template #no_data>
                    <div class="empty_frame">
                        <img src="assets/empty.png">
                    </div>
                </ng-template>
            </mat-card-content>
        </mat-card>
    </div>
    <!--    <div fxFlex="15" class="p-2">
        <mat-card>
            <mat-card-header fxLayoutAlign="center">
                <mat-card-subtitle fxLayoutAlign="center">
                    <h2>Pending list</h2>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content [dragula]="'bag-1'" class="bag">
                <mat-card *ngFor="let item of pending_list"
                    [class.bg-primary]="item.subject_type && item.subject_type.abbreviation =='C'" class="draggable p5"
                    [class.bg-accent]="item.subject_type && item.subject_type.abbreviation =='TP'"
                    [class.bg-default]="item.subject_type && item.subject_type.abbreviation =='TD'">
                    {{item.subject?.abbreviation}}<br>{{item.teacher?.first_name}} &nbsp;
                    {{item.teacher?.last_name}}<br>

                    {{item.section?.abbreviation}}
                </mat-card>
            </mat-card-content>
        </mat-card>
    </div> -->
</div>