<div mat-dialog-title>
    <h1>{{translate.instant('settings')}}</h1>
</div>
<div mat-dialog-content>
    <form fxLayout="column" [formGroup]="form">


        
        <mat-form-field class="pr-2 pl-2">

            <mat-select [placeholder]="translate.instant('ExamsType')" [(ngModel)]="data.vacation_exam_id"
                [formControlName]="'vacation_exam_id'">
                <mat-option *ngFor="let exam_data of examsperiods" [value]="exam_data.id">
                    {{exam_data.abbreviation}}</mat-option>
            </mat-select>

        </mat-form-field>
        
     


    </form>
</div>
<div mat-dialog-actions>

    <button mat-raised-button color="basic" class=" ums_basic_button form-cancel"
        (click)="onNoClick()">{{'buttons.no_thanks' | translate}}</button>
    <button mat-raised-button color="primary" [disabled]="!form.valid" class="ums_primary_button form-save"
        [mat-dialog-close]="data" cdkFocusInitial>
        {{'buttons.generate' | translate}}
    </button>
</div>