import { Component, Inject, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { ConfigService } from 'src/app/apis/config.service';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { AnnualCalendar } from 'src/app/viewModels/annual-calendar';
import { VacationExam } from 'src/app/viewModels/vacation-exam';
@Component({
  selector: 'app-vacation-exam-form',
  templateUrl: './vacation-exam-form.component.html',
  styleUrls: ['./vacation-exam-form.component.css']
})
export class VacationExamFormComponent implements OnInit {

  constructor(
    public translate: TranslateService,
    private config : ConfigService,
    public dialogRef: MatDialogRef<VacationExamFormComponent>,
    @Inject(MAT_DIALOG_DATA) public data: VacationExam,
    private apis: EstablishmentService,
  ) { }
min_date : Date;
max_date : Date;
vacation_exam_types : any[];

  form: FormGroup = new FormGroup({
    'designation': new FormControl(Validators.required),
    'abbreviation': new FormControl(Validators.required),
    'start_date': new FormControl(Validators.required),
    'end_date': new FormControl(Validators.required),
    'type': new FormControl(Validators.required),
    'establishment_id': new FormControl(Validators.required)

  })
  onNoClick(): void {
    this.dialogRef.close();
  }

  ngOnInit() {
    this.apis.getEstablishmentCurrentAnnualCalendar()
    .subscribe((data : AnnualCalendar)=>{
      this.min_date = new Date(data.start_date);
      this.max_date = new Date(data.end_date);

    })
   this.vacation_exam_types = this.config.vacation_exam_types;
  }

}
