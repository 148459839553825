<app-content-header 
    [icon]="'check_box'" 
    [title]="'Checkbox'" 
    [hideBreadcrumb]="false"
    [hasBgImage]="true" 
    [class]="'pb-4'"></app-content-header>

<div fxLayout="column" class="p-2">
    <div class="p-2">
        <mat-card>
            <mat-card-header>                
                <mat-card-subtitle><h2>Checkbox configuration</h2></mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <section class="py-3">
                    <mat-checkbox [(ngModel)]="checked" class="mx-2">Checked</mat-checkbox>
                    <mat-checkbox [(ngModel)]="indeterminate" class="mx-2">Indeterminate</mat-checkbox>
                </section>
                <section class="py-3">
                    <label class="mx-2">Label position:</label>
                    <mat-radio-group [(ngModel)]="labelPosition">
                        <mat-radio-button value="after" class="mx-2">After</mat-radio-button>
                        <mat-radio-button value="before" class="mx-2">Before</mat-radio-button>
                    </mat-radio-group>
                </section>
                <section class="py-3">
                    <mat-checkbox [(ngModel)]="disabled" class="mx-2">Disabled</mat-checkbox>
                </section>
            </mat-card-content>
        </mat-card>
        <mat-card>
            <mat-card-content>
                <h2 class="py-3 mx-2">Result</h2>
                <section class="py-3">
                    <mat-checkbox
                        class="mx-2"
                        [(ngModel)]="checked"
                        [(indeterminate)]="indeterminate"
                        [labelPosition]="labelPosition"
                        [disabled]="disabled">
                        I'm a checkbox
                    </mat-checkbox>
                </section>
            </mat-card-content>
        </mat-card>
    </div> 
</div>