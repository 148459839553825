import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { ToastrService } from 'ngx-toastr';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { AnnualCalendar } from 'src/app/viewModels/annual-calendar';
import { WeekType } from 'src/app/viewModels/week-type';
import Swal from 'sweetalert2';
import { WeekTypeFormComponent } from './week-type-form/week-type-form.component';

@Component({
  selector: 'app-week-types',
  templateUrl: './week-types.component.html',
  styleUrls: ['./week-types.component.css']
})
export class WeekTypesComponent implements OnInit {

  items: WeekType[];
  displayedColumns: string[] = ['designation', 'week1' , 'week2', 'start_date','end_date','type','actions'];
  dataSource ;
  selectedItem: any;
  current_annual_calendar : AnnualCalendar;
  constructor(
    private apis: EstablishmentService,
    public translate: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog

  ) { }

  item: WeekType;
  show_form = false;


  ngOnInit(): void {
    console.log(this.item);
    this.getCurrentAnnualCalendar();
   
  }

  getCurrentAnnualCalendar()
  {
    this.apis.getEstablishmentCurrentAnnualCalendar()
    .subscribe((data : AnnualCalendar)=>{
      this.current_annual_calendar = data;
      if(this.current_annual_calendar){
        this.item =new WeekType(this.current_annual_calendar.id)
        this.getData()
      }
    })
  }
  getData() {
    this.apis.getAnnualCalendarWeekTypes(this.current_annual_calendar.id)
      .subscribe((data: WeekType[]) => {
        this.items = data.sort((a,b)=> a.start_date > b.start_date ? 0 : -1);
        console.log(this.items)
        this.dataSource = new MatTableDataSource<WeekType>(this.items);
      })
  }




  save() {
    console.log(this.item)
    this.apis.createOrUpdateWeekType(this.item)
      .subscribe((data) => {
        this.getData()
        this.showSuccess('saved');
        this.item = new WeekType(this.current_annual_calendar.id);
      })
  }

  edit(element) {
    this.item = element
  }

  remove(id) {
    Swal.fire({
      title: this.translate.instant('alerts.delete_title'),
      text: this.translate.instant('alerts.delete_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('alerts.delete_yes'),
      cancelButtonText: this.translate.instant('alerts.delete_no')
    }).then((result) => {
      if (result.value) {
        this.apis.removeWeekType(id)
          .subscribe(() => {
            this.getData();
            Swal.fire(
              this.translate.instant('alerts.deleted'),
              this.translate.instant('alerts.deleted_text'),
              'success'
            )
          })

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.translate.instant('alerts.canceled'),
          this.translate.instant('alerts.canceled_text'),
          'error'
        )
      }
    })
  }

  showSuccess(msg) {

    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg) {
    this.toastr.error(this.translate.instant((msg)))
  }

  showInfo(msg) {
    this.toastr.info(this.translate.instant((msg)))
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(WeekTypeFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.createOrUpdateWeekType(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.getData();

            this.item = new WeekType(this.current_annual_calendar.id)
          })
      }

    });
  }

  openEditDialog(period): void {
    this.item = period;
    const dialogRef = this.dialog.open(WeekTypeFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.createOrUpdateWeekType(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.getData();

            this.item = new WeekType(this.current_annual_calendar.id)
          })
      }

    });
  }



  filter(v){
    this.dataSource.filter = v.trim().toLowerCase();
  }

}
