<app-content-header [hasBgImage]="true" [hideBreadcrumb]="true" [class]="'py-3'" [target]="'subjectsAndTeachers'"></app-content-header>

<mat-card>
    <mat-form-field fxFlex="50">
        <input matInput placeholder="{{'search' | translate}}" (keyup)="filter($event.target.value)">

    </mat-form-field>
    <div fxFlex="50">
        <button mat-raised-button color="primary" class="ums_primary_button" [style.float]="'inverse_float' | translate" (click)="openDialog()">
            <mat-icon class="fs20">add</mat-icon> {{'buttons.add' | translate}}
          </button>
          <button mat-raised-button color="basic" class="ums_basic_button" [style.float]="'inverse_float' | translate" (click)="import()">
            <mat-icon class="fs20">upload</mat-icon>  {{'buttons.import' | translate}}
          </button>
          <input style="display : none" type="file" id="file" (change)="handleFileChange($event)">
          <button mat-raised-button color="basic"  class="ums_basic_button" [style.float]="'inverse_float' | translate" (click)="export()">
            <mat-icon class="fs20">download</mat-icon> {{'buttons.export' | translate}}
          </button>
          <a href="{{excel_model_path}}"><button mat-raised-button color="basic"  class="ums_basic_button" [style.float]="'inverse_float' | translate"
              (click)="export()">
              <mat-icon class="fs20">system_update_alt</mat-icon> {{'buttons.download_model' | translate}}
            </button> </a>
    </div>
</mat-card>
<div fxLayout="row wrap" fxLayoutAlign="center" class="p-2">
    <div fxFlex="100" class="p-2">

        <div class="mat-elevation-z8">
            <table mat-table style="width: 100%;" id="export_id" [dataSource]="dataSource">

                <!-- Position Column -->
                <ng-container matColumnDef="first_name">
                    <th mat-header-cell *matHeaderCellDef> {{'first_name' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.first_name}} </td>
                </ng-container>

                <!-- Name Column -->
                <ng-container matColumnDef="last_name">
                    <th mat-header-cell *matHeaderCellDef> {{'last_name' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.last_name}} </td>
                </ng-container>

                <ng-container matColumnDef="cin">
                    <th mat-header-cell *matHeaderCellDef> {{'cin' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.cin}} </td>
                </ng-container>
                <ng-container matColumnDef="email">
                    <th mat-header-cell *matHeaderCellDef> {{'email' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.email}} </td>
                </ng-container>
                <ng-container matColumnDef="phone">
                    <th mat-header-cell *matHeaderCellDef> {{'phone' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.phone}} </td>
                </ng-container>
                <ng-container matColumnDef="teacher_grade">
                    <th mat-header-cell *matHeaderCellDef> {{'teacher_grade' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.teacher_grade?.abbreviation}} </td>
                </ng-container>
                <ng-container matColumnDef="teacher_type">
                    <th mat-header-cell *matHeaderCellDef> {{'teacher_type' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.teacher_type?.abbreviation}} </td>
                </ng-container>
                <ng-container matColumnDef="classroom">
                    <th mat-header-cell *matHeaderCellDef> {{'classroom' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.classroom?.abbreviation}} </td>
                </ng-container>
                <ng-container matColumnDef="department">
                    <th mat-header-cell *matHeaderCellDef> {{'department' | translate}} </th>
                    <td mat-cell *matCellDef="let element"> {{element.department?.abbreviation}} </td>
                </ng-container>
                <ng-container matColumnDef="actions" justify="end">
                    <th mat-header-cell *matHeaderCellDef [style.text-align]="'inverse_float' | translate">
                        {{'actions' | translate}} </th>
                    <td mat-cell *matCellDef="let element" [style.text-align]="'inverse_float' | translate">
                        <mat-icon class="success" (click)="openAvailabilityDialog(element)">date_range</mat-icon>
                        <mat-icon class="warning" (click)="openEditDialog(element)">edit</mat-icon>
                        <mat-icon class="danger" (click)="remove(element.id)">delete_outline</mat-icon>

                    </td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
            </table>

            <mat-paginator [pageSizeOptions]="[5, 10, 20]" showFirstLastButtons></mat-paginator>
        </div>
    </div>

</div>