import { Component, OnInit} from '@angular/core';
import { Teacher } from 'src/app/viewModels/teacher';
import { MatTableDataSource } from '@angular/material/table';
import { TeacherService } from 'src/app/apis/teacher.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import Swal from 'sweetalert2';
import {  MatDialog } from '@angular/material/dialog';
import { TeacherFormComponent } from './teacher-form/teacher-form.component';
import { TeacherAvailibilityComponent } from './teacher-availibility/teacher-availibility.component';
import { ConfigService } from 'src/app/apis/config.service';
import { ExcelService } from 'src/app/apis/excel/excel.service';
declare var $ : any;
@Component({
  selector: 'app-teachers',
  templateUrl: './teachers.component.html',
  styleUrls: ['./teachers.component.css']
})
export class TeachersComponent implements OnInit {

  items: Teacher[];
  displayedColumns: string[] = ['first_name', 'last_name', 'cin','email','phone','teacher_type','teacher_grade','classroom', 'department', 'actions'];
  dataSource ;
  selectedItem: any;

  constructor(
    private apis: TeacherService,
    public translate: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private config : ConfigService,
    private excelService : ExcelService

  ) { }
  excel_model_path = this.config.hostName+'/assets/excel_models/teachers_model.xlsx';

 
  item: Teacher = new Teacher();
  show_form = false;


  ngOnInit(): void {
    console.log(this.item);

    this.getData();
  }


  getData() {
    this.apis.getEstablishmentTeachers()
      .subscribe((data: Teacher[]) => {
        this.items = data;
        console.log(this.items)
        this.dataSource = new MatTableDataSource<Teacher>(this.items);
      })
  }




  save() {
    console.log(this.item)
    this.apis.createOrUpdateTeacher(this.item)
      .subscribe((data) => {
        this.getData()
        this.showSuccess('saved');
        this.item = new Teacher();
      })
  }

  edit(element) {
    this.item = element
  }

  remove(id) {
    Swal.fire({
      title: this.translate.instant('alerts.delete_title'),
      text: this.translate.instant('alerts.delete_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('alerts.delete_yes'),
      cancelButtonText: this.translate.instant('alerts.delete_no')
    }).then((result) => {
      if (result.value) {
        this.apis.removeTeacher(id)
          .subscribe(() => {
            this.getData();
            Swal.fire(
              this.translate.instant('alerts.deleted'),
              this.translate.instant('alerts.deleted_text'),
              'success'
            )
          })

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.translate.instant('alerts.canceled'),
          this.translate.instant('alerts.canceled_text'),
          'error'
        )
      }
    })
  }

  showSuccess(msg , nb?) {

    this.toastr.success((nb ? nb : '')+this.translate.instant(msg))
  }

  showError(msg , nb?) {
    this.toastr.error((nb ? nb : '')+this.translate.instant((msg)))
  }


  openDialog(): void {
    const dialogRef = this.dialog.open(TeacherFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.createOrUpdateTeacher(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.getData();

            this.item = new Teacher()
          })
      }

    });
  }

  openEditDialog(teacher): void {
    this.item = teacher;
    const dialogRef = this.dialog.open(TeacherFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.createOrUpdateTeacher(result)
          .subscribe(() => {
            this.showSuccess('saved');
            this.getData();

            this.item = new Teacher()
          })
      }

    });
  }


  async openAvailabilityDialog(teacher){
    
    const dialogRef = this.dialog.open(TeacherAvailibilityComponent, {
      width: '500px',
      data: {teacher : teacher ,  availibility : []}
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(result)
      if (result) {
        this.apis.setTeacherAvailibility(teacher.id , result.availibility)
        .subscribe(()=>{
          this.showSuccess('saved')
        })
      }

    });
  }
  filter(v){
    this.dataSource.filter = v.trim().toLowerCase();
  }


  handleFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {

      const bstr: string = e.target.result;
      const data = <any[]>this.excelService.importFromFile(bstr);

      const header = data[0];
      //const header: string[] = Object.getOwnPropertyNames(new Subject());
      const importedData = data.slice(1, -1);
  

      let imported_teachers = importedData.map(arr => {
        
        const obj = {};
         for (let i = 0; i < header.length; i++) {
          const k = header[i];          
          obj[k] = arr[i];
        } 
        return <Teacher>obj;
      })

     console.log(imported_teachers)
        this.apis.importTeachers(imported_teachers)
      .subscribe((data : any)=>{
        console.log(data)
        
        this.getData();
        if(data && data.imported_rows > 0){
          this.showSuccess( 'files_imported' , data.imported_rows)
        }
        if(data && data.failed_rows > 0){
          this.showError( 'failed_imported' , data.failed_rows)
        }
      });  
      

    };
    reader.readAsBinaryString(target.files[0]);

  }
  import(){
    $('#file').click();
  }

  export(){
    this.excelService.exportToFile('teachers' , 'export_id');
  }
}
