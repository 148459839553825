export class GenerateExamsSetting {
    nb_exam_per_day : number = 1;
    exam_period_id : number;

    sectors_ids : number[];
    is_all_sectors : boolean = true;
    period1_start_time : string = '09:00';
    period1_end_time : string ='10:00';
    period2_start_time : string = '14:00';
    period2_end_time : string = '15:00';
    schedule_dates : any[];
    annual_calendar_id? : number
    year_division_id? : number
    vacation_exam_id? : number

}
