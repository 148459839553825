export class Cycle {

    id : number;
    designation : string;
    abbreviation : string;
    establishment_id : number;


    constructor(){
        this.establishment_id = JSON.parse(localStorage.getItem('user-infos')).establishment_id;
    }
}
