import { Component, OnInit } from '@angular/core';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { TranslateService } from '@ngx-translate/core';
import { Building } from 'src/app/viewModels/building';
import { Router } from '@angular/router';
import { Block } from 'src/app/viewModels/block';
import { ToastrService } from 'ngx-toastr';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { Department } from 'src/app/viewModels/department';
import { ExcelService } from 'src/app/apis/excel/excel.service';

@Component({
  selector: 'app-blocks',
  templateUrl: './blocks.component.html',
  styleUrls: ['./blocks.component.scss']
})
export class BlocksComponent implements OnInit {

  selectedItem : any;
  buildings : Building[];
  selected_building : number;
  constructor(
    private apis : EstablishmentService,
    private hierarchyService : HiararchyService,
    public translate : TranslateService,
    private router : Router,
    private toastr : ToastrService,
    private excelService : ExcelService

  ) { }
  departments : Department[];
   blocks : Block[];
   new_block : Block ;
   show_form = false;

   form : FormGroup = new FormGroup({
    'designation' : new FormControl(Validators.required),
    'abbreviation' : new FormControl(Validators.required),
    'departments' : new FormControl()

  })
  ngOnInit(): void {
    this.getData();
    this.hierarchyService.getHiararchyDepartments()
    .subscribe((data : Department[])=>{
      this.departments = data;
    })
  }


  getData(){
    this.apis.getEstablishmentBuildings()
    .subscribe((data : Building[])=>{
      if(!data || data.length ==0){
        this.router.navigate(['/ums/buildings'])
      }else{
        this.buildings = data;
        
        this.selected_building = this.buildings[0].id;
        console.log(this.buildings);

        this.getBuildingBlocks();
        
      }
    })
  }

  getBuildingBlocks(){
    
    this.apis.getBuildingBlocks(this.selected_building)
    .subscribe((data : Block[])=>{
      this.blocks = data;
    })
  }

  changeBuilding(){
    console.log(this.selected_building)
    this.apis.getBuildingBlocks(this.selected_building)
        .subscribe((data : Block[])=>{
          this.blocks = data;
        })
  }

  editBlock(b){
    console.log(b)
   this.blocks =  this.blocks.filter((x)=>x.id != b.id)
   
    this.show_form = true;
    this.new_block = b;
    if(this.new_block.departments){
      this.new_block.departments = JSON.parse(this.new_block.departments);
    }
    console.log(this.new_block)
  }

  showNewBuildForm(){
    if(this.selected_building){

      this.show_form = true;
      this.new_block = new Block(this.selected_building);
    }
  }


  cancel(){
    this.show_form = false;
    this.new_block = undefined;
  }

  save(){
    console.log(this.new_block)
    this.apis.setBlock(this.new_block)
    .subscribe((data)=>{
      console.log(data);
      this.showSuccess('toastr.success_save')
      this.getBuildingBlocks();
      this.cancel();
    })

  }

  showSuccess(msg){

    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg){
    this.toastr.error(this.translate.instant((msg)))
  }


 
}
