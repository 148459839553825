<div id="main" perfectScrollbar (psScrollY)="onPsScrollY($event)" class="h-100">

    <header class="header hide_print" [class.over]="showInfoContent">
        <app-top-info-content [showInfoContent]="showInfoContent" (onCloseInfoContent)="closeInfoContent(showInfoContent)"></app-top-info-content>
        
        <mat-toolbar class="top-toolbar" [class.mat-elevation-z2]="settings.fixedHeader">
            <div fxLayout="row" fxLayoutAlign="space-between center" fxFlex>           
                <div fxLayout="row" fxLayoutAlign="center center">               
                    <a  fxLayout="row" fxLayoutAlign="start center" class="logo" ngClass.xs="mobile" (click)="closeSubMenus()">
                      <!--   <span class="mx-2">NACHD </span>  -->
                      <mat-icon role="img" class="mat-icon " aria-hidden="true">school</mat-icon>
                        <!-- <img src="assets/ums_logo.png" alt="logo" [@rotate]="showSidenav" style="width: 120px;">  -->
                         <span class="mx-2">NACHD UMS</span> 

                        </a>
                </div> 
                <div fxLayout="row" fxLayoutAlign="start center" class="w-100"> 
                    <button *ngIf="settings.menu == 'vertical'" mat-icon-button (click)="sidenav.toggle()">
                        <mat-icon>menu</mat-icon>
                    </button> 
                    <button *ngIf="settings.menu == 'vertical'" mat-icon-button (click)="settings.sidenavIsPinned = !settings.sidenavIsPinned" fxShow="false" fxShow.gt-xs>
                        <svg class="pin">
                            <path *ngIf="!settings.sidenavIsPinned" d="M16,12V4H17V2H7V4H8V12L6,14V16H11.2V22H12.8V16H18V14L16,12Z" />
                            <path *ngIf="settings.sidenavIsPinned" d="M2,5.27L3.28,4L20,20.72L18.73,22L12.8,16.07V22H11.2V16H6V14L8,12V11.27L2,5.27M16,12L18,14V16H17.82L8,6.18V4H7V2H17V4H16V12Z" />
                        </svg> 
                    </button>                 
                    <button mat-icon-button (click)="showInfoContent = !showInfoContent" fxShow="false" fxShow.gt-xs>
                        <mat-icon [@rotate]="showInfoContent" class="mat-icon-lg">expand_more</mat-icon>
                    </button>
                    <app-favorites fxShow="false" fxShow.gt-sm></app-favorites>                                      
                </div>          
                <div fxLayout="row" fxLayoutAlign="end center">
                    <div fxLayout="row" fxLayoutAlign="start center" fxShow="false" fxShow.gt-xs class="search-bar">
                        <form method="get">
                            <input type="text" placeholder="Type to search..." class="mat-elevation-z3"  [class.show]="toggleSearchBar">
                            <button mat-icon-button (click)="toggleSearchBar = !toggleSearchBar" type="button">
                                <mat-icon>search</mat-icon>
                            </button> 
                        </form>
                    </div>
                    <app-flags-menu fxShow="false" fxShow.gt-sm></app-flags-menu>
                    <app-fullscreen></app-fullscreen> 
                   <!--  <app-applications fxShow="false" fxShow.gt-sm></app-applications> -->
                   <!--  <app-messages fxShow="false" fxShow.gt-xs></app-messages> -->
                    <app-user-menu></app-user-menu>
                </div>
            </div>               
        </mat-toolbar>
    </header>

    <mat-drawer-container class="page-wrapper">

        <mat-drawer #sidenav *ngIf="settings.menu == 'vertical'" [opened]="settings.sidenavIsOpened" [mode]="(settings.sidenavIsPinned) ? 'side' : 'over'" (openedStart)="showSidenav = !showSidenav" (closedStart)="showSidenav = !showSidenav" class="sidenav mat-elevation-z6 hide_print" autoFocus="false">
            <mat-toolbar *ngIf="settings.fixedSidenav" class="top-toolbar">
                <a routerLink="/" fxLayout="row" fxLayoutAlign="start center" class="logo">
                    <img src="assets/nachdums.png" alt="logo" [@rotate]="showSidenav">
                    <span class="mx-2">Nachd UMS</span> 
                </a>
            </mat-toolbar>            
            <app-sidenav></app-sidenav>
        </mat-drawer>

        <mat-drawer-content>

            <mat-toolbar *ngIf="settings.menu == 'horizontal'" class="horizontal-menu px-0 hide_print" >
                <div fxLayout="row" fxLayoutAlign="center center" class="w-100">            
                    <app-horizontal-menu [menuParentId]="0" [class.w-100]="(settings.menuType!='mini')"></app-horizontal-menu>
                </div>            
            </mat-toolbar>
            <router-outlet></router-outlet> 

        </mat-drawer-content>    
        
        <mat-drawer #options position="end" class="options hide_print">
            <div fxLayout="row" fxLayoutAlign="space-between center" class="op-header mat-elevation-z1">
                <h2>Settings</h2>
                <button mat-icon-button (click)="options.toggle()" class="ml-2">
                    <mat-icon>close</mat-icon>
                </button>
            </div> 
            <div [perfectScrollbar]="optionsPsConfig">
                <div fxLayout="column" class="control">
                    <h4>Layout</h4>        
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span>Fixed header</span>
                        <mat-slide-toggle [checked]="settings.fixedHeader" (change)="settings.fixedHeader = !settings.fixedHeader" labelPosition="before"></mat-slide-toggle>
                    </div> 
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span>Fixed sidenav</span>
                        <mat-slide-toggle [checked]="settings.fixedSidenav" (change)="settings.fixedSidenav = !settings.fixedSidenav" labelPosition="before" [disabled]="menuOption == 'horizontal'"></mat-slide-toggle>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span>Fixed footer</span>
                        <mat-slide-toggle [checked]="settings.fixedFooter" (change)="settings.fixedFooter = !settings.fixedFooter" labelPosition="before"></mat-slide-toggle>
                    </div>                
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span>RTL</span>
                        <mat-slide-toggle [checked]="settings.rtl" (change)="settings.rtl = !settings.rtl" labelPosition="before"></mat-slide-toggle>
                    </div>
                </div>
                <div fxLayout="column" class="control">
                    <h4>Choose menu</h4>
                    <mat-radio-group [(ngModel)]="menuOption" (change)="chooseMenu()">
                        <mat-radio-button *ngFor="let menu of menus" [value]="menu">{{menu}}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div fxLayout="column" class="control">
                    <h4>Choose menu type</h4>
                    <mat-radio-group [(ngModel)]="menuTypeOption" (change)="chooseMenuType()">
                        <mat-radio-button *ngFor="let menuType of menuTypes" [value]="menuType">{{menuType}}</mat-radio-button>
                    </mat-radio-group>
                </div>
                <div fxLayout="column" class="control">
                    <h4>Choose theme skin</h4>        
                    <div fxLayout="row" fxLayoutAlign="space-between center"> 
                        <div class="skin-primary indigo-light" (click)="changeTheme('indigo-light')"><div class="skin-secondary"></div></div>  
                        <div class="skin-primary teal-light" (click)="changeTheme('teal-light')"><div class="skin-secondary"></div></div>  
                        <div class="skin-primary red-light" (click)="changeTheme('red-light')"><div class="skin-secondary"></div></div> 
                        <div class="skin-primary gray-light" (click)="changeTheme('gray-light')"><div class="skin-secondary"></div></div>  
                    </div>
                     <div fxLayout="row" fxLayoutAlign="space-between center"> 
                        <div class="skin-primary blue-dark" (click)="changeTheme('blue-dark')"><div class="skin-secondary"></div></div>  
                        <div class="skin-primary green-dark" (click)="changeTheme('green-dark')"><div class="skin-secondary"></div></div>  
                        <div class="skin-primary pink-dark" (click)="changeTheme('pink-dark')"><div class="skin-secondary"></div></div>  
                        <div class="skin-primary gray-dark" (click)="changeTheme('gray-dark')"><div class="skin-secondary"></div></div>  
                    </div>
                </div>
                <div fxLayout="column" class="control">
                    <h4>Sidenav options</h4>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span>Opened sidenav</span>
                        <mat-slide-toggle [checked]="settings.sidenavIsOpened" (change)="settings.sidenavIsOpened = !settings.sidenavIsOpened" labelPosition="before"></mat-slide-toggle>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span>Pinned sidenav</span>
                        <mat-slide-toggle [checked]="settings.sidenavIsPinned" (change)="settings.sidenavIsPinned = !settings.sidenavIsPinned" labelPosition="before"></mat-slide-toggle>
                    </div>
                    <div fxLayout="row" fxLayoutAlign="space-between center">
                        <span>Fixed sidenav user content</span>
                        <mat-slide-toggle [checked]="settings.fixedSidenavUserContent" (change)="settings.fixedSidenavUserContent = !settings.fixedSidenavUserContent" labelPosition="before"></mat-slide-toggle>
                    </div>
                </div>
            </div>
        </mat-drawer>

    </mat-drawer-container>

    <div fxLayout="row" fxLayoutAlign="center center" class="options-icon hide_print" (click)="options.toggle()">
        <mat-icon>settings</mat-icon>
    </div>

    <div #backToTop fxLayout="row" fxLayoutAlign="center center" class="back-to-top hide_print" (click)="scrollToTop()">
        <mat-icon>arrow_upward</mat-icon>
    </div>

    <mat-toolbar color="primary" class="footer hide_print" [class.full-width]="!showSidenav">
       <small>All rights are reserved for Nachd IT Startup By Startup Act</small>
    </mat-toolbar>
</div>