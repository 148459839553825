import { FormGroup, FormControl } from '@angular/forms';
import Swal from 'sweetalert2';
import { CreatePendingDialogComponent } from './create-pending-dialog/create-pending-dialog.component';
import { AddSessionDialogComponent } from './add-session-dialog/add-session-dialog.component';
import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { DragulaService } from 'ng2-dragula';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { ExcelService } from 'src/app/apis/excel/excel.service';
import { HiararchyService } from 'src/app/apis/hiararchy.service';
import { SettingsService } from 'src/app/apis/settings.service';
import { TimingService } from 'src/app/apis/timing.service';
import { AppSettings } from 'src/app/app.settings';
import { Settings } from 'src/app/app.settings.model';
import { Day } from 'src/app/viewModels/day';
import { GenerateSetting } from 'src/app/viewModels/generate-setting';
import { GroupTd } from 'src/app/viewModels/group-td';
import { GroupTp } from 'src/app/viewModels/group-tp';
import { Period } from 'src/app/viewModels/period';
import { Section } from 'src/app/viewModels/section';
import { Sector } from 'src/app/viewModels/sector';
import { Session } from 'src/app/viewModels/session';
import { GenerateDialogComponent } from './generate-dialog/generate-dialog.component';
import { UpdateDialogComponent } from './update-dialog/update-dialog.component';
import { UpdateSessionDialogComponent } from './update-session-dialog/update-session-dialog.component';

declare var $: any;
@Component({
  selector: 'app-time-table',
  templateUrl: './time-table.component.html',
  styleUrls: ['./time-table.component.css']
})
export class TimeTableComponent implements OnInit {


  public settings: Settings;
  subs = new Subscription();
  colors = ['#f8f68f', '#f8e68f', '#f8d68f', '#f8c68f', '#f8e68fa1', '#f8f688ba', '#f8f48f', '#f8f28f']

  start_date
  end_date

  constructor(private settingService: SettingsService,
    public appSettings: AppSettings,
    private excelService: ExcelService,
    public translate: TranslateService,
    private hierarchyService: HiararchyService,
    private apis: TimingService,
    public dialog: MatDialog,
    private dragulaService: DragulaService,
    private toastr: ToastrService) {
    this.settings = this.appSettings.settings;




    this.subs.add(this.dragulaService.drop("bag-1")
      .subscribe(({ name, el, target, source, sibling }) => {
        // ...

        // console.log(source)
        let sourceTab = source.id.split('-')
        let targetTab = target.id.split('-')

        let seance_id;


        console.log(sourceTab)
        console.log(targetTab)
        if (targetTab[0] == 'pending') {
          console.log('hey')
          target.childNodes.forEach((x: any) => {
            if (x.dataset && x.dataset.name == 'seance' && x.id.indexOf(source.id) == 0) {
              console.log(x.id)
              seance_id = x.id.split('-')[2]
              console.log(seance_id)
              let body = {
                id: seance_id
              }
              this.apis.moveSessionToPending(body)
                .subscribe(() => {
                  this.showSuccess('updated')
                  if(this.selectedGroupTd){
                    this.handleGroupTDChange();
                  }else{
                    this.handleSectionChange();

                  }
                }, (err) => {
                  console.log(err)
                  this.showError('erreur')
                  if(this.selectedGroupTd){
                    this.handleGroupTDChange();
                  }else{
                    this.handleSectionChange();

                  }

                })
            }
          })
        }
        else if (sourceTab[0] == 'pending') {
          let body = {

            target_day: this.days.find((x) => x.id == Number(targetTab[0])),
            target_period: this.periods.find((x) => x.id == Number(targetTab[1])),
            session_id: sourceTab[1]

          }
          console.log(body)
          this.updatePendingSessionDialog(body);
        } else {
          console.log('from ' + sourceTab[0] + ':' + sourceTab[1] + '     to ' + targetTab[0] + ' : ' + targetTab[1])
          target.childNodes.forEach((x: any) => {
            if (x.dataset && x.dataset.name == 'seance' && x.id.indexOf(source.id) == 0) {
              console.log(x.id)
              seance_id = x.id.split('-')[2]
            }
          })
          let body = {
            source_day: this.days.find((x) => x.id == Number(sourceTab[0])),
            source_period: this.periods.find((x) => x.id == Number(sourceTab[1])),
            target_day: this.days.find((x) => x.id == Number(targetTab[0])),
            target_period: this.periods.find((x) => x.id == Number(targetTab[1])),
            session_id: seance_id
          }

          console.log(body)
          this.updateSessionDialog(body);
        }



      })
    );


  }
  days: Day[];
  periods: Period[];
  width = 20;
  timetable: any[];
  timetableGTP1: any[];
  timetableGTP2: any[];
  groupTP: GroupTp[];
  groupTD: GroupTd[];

  selectedSector;
  selectedSection;
  selectedGroupTp;
  selectedGroupTd;

  sectors: Sector[];
  sections: Section[];
  groupsTp: GroupTp[];

  groupsTd: GroupTd[];
  pending_list: any[];

  semestres_trimestres: any = [];
  selectedSemestre;
  filter_form = new FormGroup({
   start_date : new FormControl(),
   end_date : new FormControl()
  })
  ngOnInit(): void {
    var   start_date = new Date()
    var end_date = new Date()
    var first = start_date.getDate() -start_date.getDay() + 1; // First day is the day of the month - the day of the week
    var last = (start_date.getDate() - start_date.getDay()) + 12; // last day is the first day + 6
   start_date.setDate(first)
   end_date.setDate(last)

    this.start_date = start_date.getFullYear()+'-'+(start_date.getMonth() < 9 ? 0+(start_date.getMonth()+1) : (start_date.getMonth()+1) ) +'-'+(start_date.getDate() <9 ? '0'+start_date.getDate() : start_date.getDate())
    this.end_date = end_date.getFullYear()+'-'+(end_date.getMonth() < 9 ? 0+(end_date.getMonth()+1) : (end_date.getMonth()+1) )+'-' +(end_date.getDate() <9 ? '0'+end_date.getDate() : end_date.getDate())
    console.log(this.start_date)
    console.log(this.end_date)
    this.filter_form.patchValue({start_date : this.start_date , end_date : this.end_date})
    this.hierarchyService.getHiararchySectors()
      .subscribe((data: Sector[]) => {
        this.sectors = data;
      })
    this.settingService.getDays()
      .subscribe((data: Day[]) => {
        this.days = data;
      })
    this.settingService.getYearDivisions()
      .subscribe((data: any[]) => {
        this.semestres_trimestres = data;

        if(data.length > 1){
          this.selectedSemestre = this.semestres_trimestres[1].id
        }
      })


    this.apis.getHiararchyPeriods()
      .subscribe((data: Period[]) => {
        this.periods = data.filter((x) => x.type == 'session').sort((a, b) => a.start_hour > b.start_hour ? 0 : -1);
        this.width = (100 - 6) / (this.periods.length);
      })


  }


  handleSectorChange() {
    if (this.selectedSector) {
      this.hierarchyService.getSectorSections(this.selectedSector)
        .subscribe((data: Section[]) => {
          this.sections = data;
        })
    } else {
      this.sections = [];
      this.timetable = []

      this.timetableGTP1 = [];
      this.timetableGTP2 = [];
      this.pending_list = [];
    }
  }

  handleSectionChange() {
    console.log("cancel")
    if (this.selectedSection && this.selectedSemestre) {
      this.apis.getSectionTimeTable(this.selectedSection, this.selectedSemestre, this.start_date  , this.end_date)
        .subscribe((data: any) => {
          console.log("updated data")
          this.timetable = data.g1;
          this.periods = data.periods
         this.periods.map((p : any)=> {p.start_hour = p.start_hour.toString().slice(0,5)  ; p.end_hour = p.end_hour.toString().slice(0,5)})
          // this.timetableGTP2 = data.g2;



          console.log(data)
        })

        this.selectedGroupTd = undefined;
        this.selectedGroupTp = undefined
      this.getSectionPending()


    } else {

      this.timetable = [];
      this.timetableGTP1 = [];
      this.timetableGTP2 = [];
      this.pending_list = [];
    }
    this.hierarchyService.getSectionGroupTps(this.selectedSection)
      .subscribe((data: GroupTp[]) => {
        this.groupTP = data;
      })
    this.hierarchyService.getSectionGroupTds(this.selectedSection)
      .subscribe((data: GroupTd[]) => {
        this.groupTD = data;
      })
  }
  handleGroupChange() {
    console.log("cancel")
    if (this.selectedGroupTp && this.selectedSemestre) {
      this.apis.getSectionGroupTpTimeTable(this.selectedGroupTp, this.selectedSemestre)
        .subscribe((data: any) => {
          console.log("updated data")
          this.timetable = data.g1;
          //this.timetableGTP2 = data.g2;


          console.log(data)
        })



    } else {

      this.timetable = [];
      this.timetableGTP1 = [];
      this.timetableGTP2 = [];
      this.pending_list = [];
    }
  }

  handleGroupTDChange() {
    console.log("cancel")
    if (this.selectedGroupTd && this.selectedSemestre) {
      this.apis.getSectionGroupTdTimeTable(this.selectedGroupTd, this.selectedSemestre)
        .subscribe((data: any) => {
          console.log("updated data")
          this.timetable = data.g1;
          //this.timetableGTP2 = data.g2;


          console.log(data)
        })



    } else {

      this.timetable = [];
      this.timetableGTP1 = [];
      this.timetableGTP2 = [];
      this.pending_list = [];
    }
  }
  generateSemestre;
  show_progess = false;
  handleGenerateChange() {
    this.show_progess = true;
    this.apis.generateEcoleTimeTable(this.generateSemestre)
      .subscribe((data) => {
        console.log(data);
        this.show_progess = false;
        this.showSuccess('success')

      }, (err) => {
        console.log(err)
        this.show_progess = false;
        this.showError('generate_server_error')

      })
  }

  getSectionPending() {
    this.apis.getSectionPending(this.selectedSection, this.selectedSemestre)
      .subscribe((data: any[]) => {
        this.pending_list = data.filter((x) => !x.related_id);
        console.log('pending', this.pending_list)
        if (!this.pending_list || this.pending_list.length == 0) {
          this.pending_list = [new Session()]
        }
      })
  }

  export() {
    this.excelService.exportToFile('timetable', 'table_group');
  }


  showSuccess(msg) {
    this.toastr.success(this.translate.instant(msg))
  }

  showError(msg) {
    console.log(msg)
    this.toastr.error(this.translate.instant(msg))
  }

  print() {
    var $print = $('#print_ums_id')
      .clone()
      .addClass('ums_print')
      .prependTo('body');
    window.print();
    $print.remove();
  }


  generateDialog() {
    const dialogRef = this.dialog.open(GenerateDialogComponent, {
      width: '500px',
      data: new GenerateSetting()
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      // console.log(this.item)
      if (result) {
        this.show_progess = true;
        console.log(result)
        this.apis.generateEcoleTimeTable(result)
          .subscribe((data) => {
            console.log(data);
            this.show_progess = false;
            this.showSuccess('success')

          }, (err) => {
            console.log(err)
            this.show_progess = false;
            this.showError('generate_server_error')

          })
      }

    });
  }

  showEditDialog(period, index, day) {
    console.log(period)
    //console.log(next)

    let i = index + 1
    let next;
    let before
    let j = index - 1
    while (i < day.length && !next) {
      if (day[i].seances && day[i].seances.length > 0) {
        next = day[i].seances
      }
      i++
    }
    while (j >= 0 && !before) {
      if (day[j].seances && day[j].seances.length > 0) {
        before = day[j].seances
      }
      j--
    }
    console.log('next', next)
    console.log('before', before)
    const dialogRef = this.dialog.open(UpdateDialogComponent, {
      width: '500px',
      data: period
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.show_progess = true;
        console.log(result)


        let can_save = true
       /*  if (next && next.length > 0) {
          let end1 = this.toTime(result.end_hour) //.length == 8 ? result.period.end_hour : result.period.end_hour+':00'
          let end2 = this.toTime(result.end_hour) //.length == 8 ? result.end_hour : result.end_hour+':00'

          let next_start = this.toTime(next[0].start_hour ? next[0].start_hour : next[0]?.period_id?.start_hour)

          console.log('first', end1.getTime())
          console.log('second', end2.getTime())

          if (end2.getTime() > next_start.getTime()) {
            can_save = true
            this.showError('Chauvauchement avec la séance suivante')
            this.show_progess = false;
          }
        } */

        /* if (before && before.length > 0) {
          let end1 = this.toTime(result.start_hour) //.length == 8 ? result.period.end_hour : result.period.end_hour+':00'
          let start2 = this.toTime(result.start_hour) //.length == 8 ? result.end_hour : result.end_hour+':00'

          let before_end = this.toTime(before[0].end_hour ? before[0].end_hour : before[0]?.period_id?.end_hour)

          console.log('first', end1.getTime())
          console.log('second', start2.getTime())
          console.log('before_end', before_end.getTime())

          if (start2.getTime() < before_end.getTime()) {
            can_save = true
            this.showError('Chauvauchement avec la séance précédente')
            this.show_progess = false;
          }
        } */

        if (can_save) {
          let ss_body = { start_hour: result.start_hour, end_hour: result.end_hour, target_day_id: result.day_id, target_period_id: result.period_id, session_id: result.id, teacher_id: result.teacher_id, classroom_id: result.classroom_id , section2_id : result.section2_id };

          this.apis.checkSessionTeacher(ss_body)
            .subscribe((check: any) => {
              console.log(check)

              if (check.error) {
                this.showError(check.error);
                if(this.selectedGroupTd){
                  this.handleGroupTDChange();
                }else{
                  this.handleSectionChange();

                }
                this.show_progess = false;
              } else {
                console.log("check")
                if(this.selectedGroupTd){
                  this.handleGroupTDChange();
                }else{
                  this.handleSectionChange();

                }
                this.show_progess = false;

              }
            })
        }



      } else {
        this.handleSectionChange();
        this.show_progess = false;
      }
    })
  }

  showEditPendingDialog(period) {
    console.log(period)
    const dialogRef = this.dialog.open(UpdateDialogComponent, {
      width: '500px',
      data: period
    });

    dialogRef.afterClosed().subscribe(result => {
      if (result) {
        this.show_progess = true;
        console.log(result)


        let ss_body = { target_day_id: result.day_id, target_period_id: result.period_id, session_id: result.id, teacher_id: result.teacher_id, classroom_id: result.classroom_id };

        this.apis.checkSessionTeacher(ss_body)
          .subscribe((check: any) => {
            console.log(check)
            if (check.error) {
              this.showError(check.error);
              if(this.selectedGroupTd){
                this.handleGroupTDChange();
              }else{
                this.handleSectionChange();

              }
              this.show_progess = false;
            } else {
              console.log("check")
              if(this.selectedGroupTd){
                this.handleGroupTDChange();
              }else{
                this.handleSectionChange();

              }
              this.show_progess = false;

            }
          })
      } else {
        if(this.selectedGroupTd){
          this.handleGroupTDChange();
        }else{
          this.handleSectionChange();

        }
        this.show_progess = false;
      }
    })
  }
  updateSessionDialog(body) {
    let ss_body = { source_day_id: body.source_day.id, source_period_id: body.source_period.id, target_day_id: body.target_day.id, target_period_id: body.target_period.id, session_id: body.session_id };
    this.apis.checkSession(ss_body)
      .subscribe((check: any) => {
        console.log(check)

        if (check.error) {
          this.showError(check.error);
          if(this.selectedGroupTd){
            this.handleGroupTDChange();
          }else{
            this.handleSectionChange();

          }
          this.show_progess = false;
          console.log(check.error)
        } else {
          console.log(check)
          const dialogRef = this.dialog.open(UpdateSessionDialogComponent, {
            width: '500px',
            data: body
          });

          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed', result);
            // console.log(this.item)


            if (result) {
              this.show_progess = true;

              this.apis.checkDropSession(ss_body)
                .subscribe((resultt) => {
                  console.log(resultt)
                  if(this.selectedGroupTd){
                    this.handleGroupTDChange();
                  }else{
                    this.handleSectionChange();

                  }
                  this.show_progess = false;
                })

            } else {
              console.log('undefined')
              if(this.selectedGroupTd){
                this.handleGroupTDChange();
              }else{
                this.handleSectionChange();

              }
              this.show_progess = false;
            }

          });

        }
      })
  }

  // check pending -> tt

  updatePendingSessionDialog(body) {
    let ss_body = { target_day_id: body.target_day.id, target_period_id: body.target_period.id, session_id: body.session_id };
    this.apis.checkPendingSession(ss_body)
      .subscribe((check: any) => {
        console.log(check)

        if (check.error) {
          this.showError(check.error);
          if(this.selectedGroupTd){
            this.handleGroupTDChange();
          }else{
            this.handleSectionChange();

          }
          this.show_progess = false;
          console.log(check)
        } else {
          console.log(check)
          const dialogRef = this.dialog.open(UpdateSessionDialogComponent, {
            width: '500px',
            data: body
          });

          dialogRef.afterClosed().subscribe(result => {
            console.log('The dialog was closed', result);
            // console.log(this.item)


            if (result) {
              this.show_progess = true;

              this.apis.checkDropPendingSession(ss_body)
                .subscribe((resultt: any) => {
                  console.log(resultt)

                  if (resultt.error) {
                    this.showError(resultt.error);
                    if(this.selectedGroupTd){
                      this.handleGroupTDChange();
                    }else{
                      this.handleSectionChange();

                    }

                    this.show_progess = false;
                  } else {
                    console.log(resultt)
                    this.showSuccess('Mise à jour avec success')
                    if(this.selectedGroupTd){
                      this.handleGroupTDChange();
                    }else{
                      this.handleSectionChange();

                    }
                    this.show_progess = false;
                  }
                })

            } else {
              console.log('undefined')
              if(this.selectedGroupTd){
                this.handleGroupTDChange();
              }else{
                this.handleSectionChange();

              }
              this.show_progess = false;
            }

          });

        }
      })
  }


  addSessionDialog() {

    if (!this.selectedSemestre || !this.selectedSection || !this.selectedSection) {
      this.toastr.info(this.translate.instant('sector_section_required_info'))
    } else {
      const dialogRef = this.dialog.open(CreatePendingDialogComponent, {
        width: '500px',
        data: {
          'division_id': this.selectedSemestre,
          'sector_id': this.selectedSector,
          'section_id': this.selectedSection,
          'group_tp_id': this.selectedGroupTp,
          'group_td_id': this.selectedGroupTd
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        // console.log(this.item)
        if (result) {
          //this.show_progess = true;
          console.log(result)
          this.apis.addPendingSession(result)
            .subscribe((data) => {
              this.getSectionPending();
            })




        }

      });
    }

  }

  addExtraSessionDialog() {

    if (!this.selectedSemestre || !this.selectedSection || !this.selectedSection) {
      this.toastr.info(this.translate.instant('sector_section_required_info'))
    } else {
      const dialogRef = this.dialog.open(AddSessionDialogComponent, {
        width: '500px',
        data: {
          'division_id': this.selectedSemestre,
          'sector_id': this.selectedSector,
          'section_id': this.selectedSection,
          'group_tp_id': this.selectedGroupTp,
          'group_td_id': this.selectedGroupTd
        }
      });

      dialogRef.afterClosed().subscribe(result => {
        console.log('The dialog was closed', result);
        // console.log(this.item)
        if (result) {
          //this.show_progess = true;
          console.log(result)

          this.apis.verifExtraSession(result)
            .subscribe((data: any) => {
              if (data.error) {
                Swal.fire({
                  title: 'Probléme de création',
                  icon: 'info',
                  text: data.error,
                  showCancelButton: true,
                  confirmButtonColor: '#3085d6',
                  cancelButtonColor: '#d33',
                  confirmButtonText: 'Placer quand même'
                }).then((result2) => {
                  if (result2.isConfirmed) {
                    this.apis.createExtraSession(result)
                      .subscribe((dataa: any) => {

                        console.log(dataa)
                        if(this.selectedGroupTd){
                          this.handleGroupTDChange();
                        }else{
                          this.handleSectionChange();

                        }
                      })
                  }

                })
              } else {

                this.apis.createExtraSession(data)
                  .subscribe((data: any) => {
                    if(this.selectedGroupTd){
                      this.handleGroupTDChange();
                    }else{
                      this.handleSectionChange();

                    }
                  })
              }

            })




        }

      });
    }

  }

  toTime(timeString) {

    var timeTokens = timeString?.split(':');
    return new Date(1970, 0, 1, timeTokens[0], timeTokens[1], 0);

  }


  getDiff(timeString1, timeString2) {

    if (timeString1 && timeString2) {
      var timeTokens = timeString1.split(':');
      var timeTokens2 = timeString2.split(':');
      var d1 = new Date(1970, 0, 1, timeTokens[0], timeTokens[1], 0);
      var d2 = new Date(1970, 0, 1, timeTokens2[0], timeTokens2[1], 0);


      return (((d2.getTime() - d1.getTime()) / 60000) * -2) + 1;
    } else {
      return 0;
    }


  }

  show() {
    this.handleSectionChange()
  }

  lock_unlock(period, index, p_i, p_index) {
    console.log(period)
    this.apis.lock_unlock(period.id)
      .subscribe((result: any) => {
        console.log(result)
        console.log(this.timetable[index])
        console.log(this.timetable[index].days[p_i])
        console.log(this.timetable[index].days[p_i].seances[p_index])
        // if( this.timetable.length > index  && this.timetable[index].days.length >p_i && this.timetable[index].days[p_i].seances.length > p_index )
        this.timetable[index].days[p_i].seances[p_index].is_locked = result.is_locked
        console.log(this.timetable[index].days[p_i].seances[p_index].is_locked)
      })
  }


  publish(){
    this.show_progess = true
    this.apis.publier()
    .subscribe((d)=>{
      this.show_progess = false
      console.log(d)
      this.showSuccess('Emplois du temps publié avec success')
    })
  }
}
