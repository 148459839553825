export class NotAvailableTeacher {

    id;
    establishment_id = JSON.parse(localStorage.getItem('user-infos')).establishment_id;
    teacher_id;
    day_id;
    period_id;




    constructor(teacher_id){
        this.teacher_id = teacher_id
    }
}
