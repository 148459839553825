<app-content-header [hideBreadcrumb]="true" [class]="'py-3'"  [hasBgImage]="true"
    [target]="'establishment'"></app-content-header>

<div fxLayout="row wrap" fxLayoutAlign="center" class="p-2">

    <div fxFlex="30" class="p-2">
        <mat-card>
            <mat-card-content>

                <img [src]="host_name+establishment.logo" style="width : 180px">

                <h3>{{establishment.designation}}</h3>
                <h3>{{establishment.abbreviation}}</h3>
                <h3>{{'license_expiration' | translate}} : {{establishment.license_expiration_date | date}}</h3>
            </mat-card-content>

        </mat-card>
        <br>
        <mat-card>
            <mat-card-header fxLayoutAlign="center">
                <mat-card-subtitle>
                    <h2>{{'establishment_general_data' | translate}}</h2>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form fxLayout="column" fxLayoutAlign="center" [formGroup]="form" (ngSubmit)="update()">



                    <mat-form-field>
                        <mat-select [placeholder]="'weekend_days' | translate" [formControlName]="'weekend_days'"
                            [(ngModel)]="establishment_setting.weekend_days" multiple>
                            <mat-option *ngFor="let day of days" [value]="day.id">{{day.designation}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select [placeholder]="'language' | translate" [formControlName]="'language'"
                            [(ngModel)]="establishment_setting.language">
                            <mat-option *ngFor="let language of all_languages" [value]="language.id">
                                {{language.designation}}</mat-option>
                        </mat-select>


                    </mat-form-field>

                    <button mat-raised-button color="primary" class="mat-elevation-z6 w-100 mt-3"
                        type="submit">{{'buttons.update' | translate}}</button>
                </form>


            </mat-card-content>
        </mat-card>

    </div>
    <div fxFlex="35" class="p-2">

        <mat-card>
            <mat-card-header fxLayoutAlign="center">
                <mat-card-subtitle>
                    <h2>{{'establishment_timetable_settings' | translate}}</h2>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <form fxLayout="column" fxLayoutAlign="center" [formGroup]="form" (ngSubmit)="update()">


                    <mat-form-field>
                        <input matInput placeholder="{{'session_numbers' | translate}}" type="number"
                            [(ngModel)]="establishment_setting.session_numbers" required
                            [formControlName]="'session_numbers'">
                        <mat-hint></mat-hint>
                        <mat-error *ngIf="form.get('session_numbers').invalid">{{'session_numbers' | translate}}
                            <strong>{{'is_required' | translate}}</strong></mat-error>
                    </mat-form-field>

                    <mat-form-field>
                        <input matInput placeholder="{{'standard_duration' | translate}}" type="number"
                            [(ngModel)]="establishment_setting.standard_duration" required
                            [formControlName]="'standard_duration'">
                        <mat-hint></mat-hint>
                        <mat-error *ngIf="form.get('standard_duration').invalid">{{'standard_duration' | translate}}
                            <strong>{{'is_required' | translate}}</strong></mat-error>
                    </mat-form-field>


                    <mat-form-field>
                        <mat-select [placeholder]="'cours_best_sessions' | translate"
                            [formControlName]="'cours_best_sessions'"
                            [(ngModel)]="establishment_setting.cours_best_sessions" multiple>
                            <mat-option *ngFor="let session of periods" [value]="session.id">
                                {{session.designation}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select [placeholder]="'td_best_sessions' | translate"
                            [formControlName]="'td_best_sessions'" [(ngModel)]="establishment_setting.td_best_sessions"
                            multiple>
                            <mat-option *ngFor="let session of periods" [value]="session.id">
                                {{session.designation}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-form-field>
                        <mat-select [placeholder]="'tp_best_sessions' | translate"
                            [formControlName]="'tp_best_sessions'" [(ngModel)]="establishment_setting.tp_best_sessions"
                            multiple>
                            <mat-option *ngFor="let session of periods" [value]="session.id">
                                {{session.designation}}</mat-option>
                        </mat-select>
                    </mat-form-field>
                    <mat-checkbox formControlName="use_group_td" [(ngModel)]="establishment_setting.use_group_td">
                        {{'use_group_td' | translate}}</mat-checkbox>
                    <br>

                    <button mat-raised-button color="primary" class="mat-elevation-z6 w-100 mt-3"
                        type="submit">{{'buttons.update' | translate}}</button>
                </form>


            </mat-card-content>
        </mat-card>



    </div>

    <div fxFlex="35" class="p-2">
        <mat-card>
            <mat-card-header fxLayoutAlign="center">
                <mat-card-subtitle>
                    <h2>{{'establishment_closed_sessions' | translate}}</h2>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <table class="table" style="width: 100%;">
                    <tr>
                        <td>

                        </td>
                        <td *ngFor="let period of periods">
                            {{period.abbreviation}}
                        </td>
                    </tr>
                    <tr *ngFor="let day of filtred_days">
                        <td> {{day.designation}}</td>
                        <td *ngFor="let period of periods">
                          
                    <mat-checkbox (change)="checkClosedSessionDay($event.checked,day.id, period.id)" [checked]="IsClosedChecked(day.id , period.id)">
                    </mat-checkbox>
                        </td>

                    </tr>
                </table>
                
                <button mat-raised-button color="primary" class="mat-elevation-z6 w-100 mt-3" (click)="updateClosed()"
                type="submit">{{'buttons.update' | translate}}</button>
            </mat-card-content>
        </mat-card>

        <br>
        <mat-card>
            <mat-card-header fxLayoutAlign="center">
                <mat-card-subtitle>
                    <h2>{{'establishment_avoided_sessions' | translate}}</h2>
                </mat-card-subtitle>
            </mat-card-header>
            <mat-card-content>
                <table class="table" style="width: 100%;">
                    <tr>
                        <td>

                        </td>
                        <td *ngFor="let period of periods">
                            {{period.abbreviation}}
                        </td>
                    </tr>
                    <tr *ngFor="let day of filtred_days">
                        <td> {{day.designation}}</td>
                        <td *ngFor="let period of periods">
                            <mat-checkbox (change)="checkAvoidedSessionDay($event.checked,day.id, period.id)" [checked]="IsAvoidedChecked(day.id , period.id)">
                            </mat-checkbox>
                        </td>

                    </tr>
                </table>
                <button mat-raised-button color="primary" class="mat-elevation-z6 w-100 mt-3" (click)="updateAvoided()"
                type="submit">{{'buttons.update' | translate}}</button>
            </mat-card-content>
        </mat-card>
    </div>
</div>