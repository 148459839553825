<div id="contact" class="mt-2 pt-3">
    <div fxLayout="column" fxLayoutAlign="center center" class="title-section p-2 mb-2">  
        <h1>Contact Us</h1>             
        <p class="secondary-text-color">Feel free to drop us a line and We'll get back to you in 24 hours min!</p>
    </div>
    <div fxLayout="row wrap" fxLayoutAlign="center"> 
        <div fxFlex="100" fxFlex.gt-xs="50" class="p-2">
            <mat-card class="contact-form p-4">
                <h1 class="secondary-text-color title">Send us an email</h1>
                <form [formGroup]="contactForm" (ngSubmit)="onContactFormSubmit(contactForm.value)" fxLayout="row wrap">
                    <div fxFlex="100" class="mt-2">
                        <mat-form-field class="w-100">
                            <input matInput placeholder="Name" formControlName="name" required>
                            <mat-error *ngIf="contactForm.controls.name.errors?.required">Name is required</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="100" class="mt-2">
                        <mat-form-field class="w-100">
                            <input matInput placeholder="Email" formControlName="email" required>
                            <mat-error *ngIf="contactForm.controls.email.errors?.required">Email is required</mat-error>
                            <mat-error *ngIf="contactForm.controls.email.hasError('invalidEmail')">Invalid email address</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="100" class="mt-2">
                        <mat-form-field class="w-100">
                            <input matInput placeholder="Phone" formControlName="phone" required>
                            <mat-error *ngIf="contactForm.controls.phone.errors?.required">Phone is required</mat-error>
                        </mat-form-field>
                    </div>
                    <div fxFlex="100" class="mt-2">
                        <mat-form-field class="w-100">
                            <input matInput placeholder="Message" formControlName="message" required>
                            <mat-error *ngIf="contactForm.controls.message.errors?.required">Message is required</mat-error>
                        </mat-form-field>
                    </div> 
                    <div class="w-100 py-4 text-center">
                        <button mat-raised-button color="accent" type="submit" class="send">
                            <mat-icon class="mr-2">send</mat-icon>Send</button>
                    </div> 
                </form>
            </mat-card>
        </div> 
        <div fxFlex="100" fxFlex.gt-xs="50" class="p-2">
            <mat-card class="p-0">
                <div class="secondary-text-color p-4">
                    <div fxLayout="row" class="py-2">
                        <mat-icon class="mr-2">mail_outline</mat-icon>
                        <i>emilio.verdines@gmail.com</i>
                    </div>
                    <div fxLayout="row" class="py-2">
                        <mat-icon class="mr-2">call</mat-icon>
                        <i>(212) 457-2308</i>
                    </div> 
                    <div fxLayout="row" class="py-2">
                        <svg class="mat-icon mr-2" viewBox="0 0 24 24">
                            <path d="M18,6C20.07,8.04 20.85,10.89 20.36,13.55C20.77,14.27 21,15.11 21,16A5,5 0 0,1 16,21C15.11,21 14.27,20.77 13.55,20.36C10.89,20.85 8.04,20.07 6,18C3.93,15.96 3.15,13.11 3.64,10.45C3.23,9.73 3,8.89 3,8A5,5 0 0,1 8,3C8.89,3 9.73,3.23 10.45,3.64C13.11,3.15 15.96,3.93 18,6M12.04,17.16C14.91,17.16 16.34,15.78 16.34,13.92C16.34,12.73 15.78,11.46 13.61,10.97L11.62,10.53C10.86,10.36 10,10.13 10,9.42C10,8.7 10.6,8.2 11.7,8.2C13.93,8.2 13.72,9.73 14.83,9.73C15.41,9.73 15.91,9.39 15.91,8.8C15.91,7.43 13.72,6.4 11.86,6.4C9.85,6.4 7.7,7.26 7.7,9.54C7.7,10.64 8.09,11.81 10.25,12.35L12.94,13.03C13.75,13.23 13.95,13.68 13.95,14.1C13.95,14.78 13.27,15.45 12.04,15.45C9.63,15.45 9.96,13.6 8.67,13.6C8.09,13.6 7.67,14 7.67,14.57C7.67,15.68 9,17.16 12.04,17.16Z" />
                        </svg>
                        <i>emilio.verdines</i>
                    </div> 
                    <div fxLayout="row" class="py-2">
                        <mat-icon class="mr-2">location_on</mat-icon>
                        <i>2903 Avenue Z, Brooklyn, NY</i>
                    </div>                          
                </div>
                <agm-map [latitude]="lat" [longitude]="lng" [zoom]="zoom" [mapTypeControl]="true" [fullscreenControl]="true">
                    <agm-marker [latitude]="lat" [longitude]="lng" [markerDraggable]="true"></agm-marker>
                </agm-map>                    
            </mat-card>
        </div>      
    </div> 
</div>
