import { FormGroup, FormControl, Validators } from '@angular/forms';
import { ConfigService } from './../../../apis/config.service';
import { ExcelService } from 'src/app/apis/excel/excel.service';
import { MatDialog } from '@angular/material/dialog';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { EstablishmentService } from 'src/app/apis/establishment.service';
import { Equipment } from './../../../viewModels/equipment';
import { Component, OnInit } from '@angular/core';
import { EquipmentFormComponent } from './equipment-form/equipment-form.component';
import Swal from 'sweetalert2';
declare var $ : any;
@Component({
  selector: 'app-equipments',
  templateUrl: './equipments.component.html',
  styleUrls: ['./equipments.component.css']
})
export class EquipmentsComponent implements OnInit {
  equipments: Equipment[];
  displayedColumns: string[] = ['designation', 'abbreviation', 'reference','description', 'actions'];
  dataSource
  selectedItem: any;
  selected_building: number;
  selected_block: number;


  item: Equipment = new Equipment();
  show_form = false;

  constructor(
    private apis: EstablishmentService,
    public translate: TranslateService,
    private router: Router,
    private toastr: ToastrService,
    public dialog: MatDialog,
    private excelService : ExcelService,
    private config : ConfigService

  ) { }
  excel_model_path = this.config.hostName+'/assets/excel_models/equipments_model.xlsx';

 
 /*  form: FormGroup = new FormGroup({
    'designation': new FormControl(Validators.required),
    'abbreviation': new FormControl(Validators.required),
    'reference': new FormControl(Validators.required),
    'description': new FormControl(Validators.required)

  }) */
  ngOnInit(): void {
    this.getEstablishmentEquipments();
  }


  getData() {
   
  }

  getEstablishmentEquipments() {

    this.apis.getEstablishmentEquipment()
      .subscribe((data: Equipment[]) => {
        this.equipments = data;
        this.dataSource = data;
      })
  }





  save() {
    console.log(this.item)
    this.apis.setEquipment(this.item)
      .subscribe((data) => {

        
        this.showSuccess('saved');
        this.getEstablishmentEquipments()
        this.item = new Equipment();
      })
  }

  edit(element) {
    this.item = element
  }

  showSuccess(msg , nb?) {

    this.toastr.success((nb ? nb : '') +this.translate.instant(msg))
  }

  showError(msg , nb?) {
    this.toastr.error((nb ? nb : '') +this.translate.instant((msg)))
  }

  remove(id) {
    Swal.fire({
      title: this.translate.instant('alerts.delete_title'),
      text: this.translate.instant('alerts.delete_text'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: this.translate.instant('alerts.delete_yes'),
      cancelButtonText: this.translate.instant('alerts.delete_no')
    }).then((result) => {
      if (result.value) {
        this.apis.removeEquipment(id)
          .subscribe(() => {
            this.getEstablishmentEquipments()
            Swal.fire(
              this.translate.instant('alerts.deleted'),
              this.translate.instant('alerts.deleted_text'),
              'success'
            )
          })

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          this.translate.instant('alerts.canceled'),
          this.translate.instant('alerts.canceled_text'),
          'error'
        )
      }
    })
  }
  openDialog(): void {
    const dialogRef = this.dialog.open(EquipmentFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.setEquipment(result)
          .subscribe(() => {
            this.showSuccess('saved');
        
            this.getEstablishmentEquipments()
            this.item = new Equipment()
          })
      }

    });
  }
  openEditDialog(equipment): void {
    this.item = equipment;
    const dialogRef = this.dialog.open(EquipmentFormComponent, {
      width: '500px',
      data: this.item
    });

    dialogRef.afterClosed().subscribe(result => {
      console.log('The dialog was closed', result);
      console.log(this.item)
      if (result) {
        this.apis.setEquipment(result)
          .subscribe(() => {
            this.getEstablishmentEquipments()
            this.showSuccess('saved');
            this.item = new Equipment()
          })
      }

    });
  }

  filter(v) {
    this.dataSource.filter = v.trim().toLowerCase();
  }


  handleFileChange(evt: any) {
    const target: DataTransfer = <DataTransfer>(evt.target);
    if (target.files.length !== 1) throw new Error('Cannot use multiple files');

    const reader: FileReader = new FileReader();
    reader.onload = (e: any) => {

      const bstr: string = e.target.result;
      const data = <any[]>this.excelService.importFromFile(bstr);

      const header = data[0];
      //const header: string[] = Object.getOwnPropertyNames(new Subject());
      const importedData = data.slice(1, -1);
  

      let imported_subjects = importedData.map(arr => {
        
        const obj = {};
         for (let i = 0; i < header.length; i++) {
          const k = header[i];          
          obj[k] = arr[i];
        } 
        return <Equipment>obj;
      })

     console.log(imported_subjects)
        this.apis.importEquipments(imported_subjects)
      .subscribe((data : any)=>{
        console.log(data)
        if(this.selected_block){
          
        }

        if(data && data.imported_rows > 0){
          this.showSuccess( 'files_imported' , data.imported_rows)
        }
        if(data && data.failed_rows > 0){
          this.showError( 'failed_imported' , data.failed_rows)
        }
      });  
      

    };
    reader.readAsBinaryString(target.files[0]);

  }
  import(){
    $('#file').click();
  }

  export(){
    this.excelService.exportToFile('equipements' , 'export_id');
  }

}
